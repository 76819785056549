import "bootstrap/dist/css/bootstrap.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from 'react-redux';
import store from './App/Store/index'
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AdminLayout from "./App/Screens/Layout/Admin";
import AuthLayout from "./App/Screens/Layout/Auth";
import './App/Styles/GlobalStyles.css';
import './App/Styles/TailwindOutput.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ExternalUserLayout from "./App/Screens/Layout/ExternalUserLayout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ChangeUserData } from "App/Screens/Shared/store/userDataSlice";
import { useDispatch } from "react-redux";
import { ChangeDashboardNameForNavbar } from "App/Screens/ExternalUsers/Store/ExternalUsersSlice";
import { CheckLoginUser } from "App/Screens/Auth/Store/authSlice";
import { GetAdminProfileData } from "App/Screens/AdminProfile/Store/AdminProfileApi";

const App = () => {

    const history = useHistory();
    const dispatch = useDispatch()

    const { userData } = useSelector(state => state.userData)
    const {
        user,
        role,
        token,
    } = userData || {}


    useEffect(() => {
        async function CheckIsUserLogin() {
            const response = await sessionStorage.getItem("token") ? dispatch(CheckLoginUser(sessionStorage.getItem("token"))) : false
            console.log("🚀 ~ file: App.js:40 ~ CheckIsUserLogin ~ response:", response)

            const path = history.location.pathname;
            const pathLoc = window.location;
            const checkPathInc = path.includes("auth/setpassword");

            if (response?.payload?.data == false || response == false) {
                window.sessionStorage.removeItem("token");
                window.sessionStorage.removeItem("userData");

                if (checkPathInc) {
                    history.push(window.href)
                }
                else {
                    history.push("/auth/login")
                }
                return
            } else {
                const localStorageUserData = JSON.parse(window.sessionStorage.getItem("userData"))

                dispatch(ChangeUserData(localStorageUserData))
                dispatch(GetAdminProfileData())

                if (localStorageUserData && localStorageUserData?.token && (localStorageUserData?.role == "super-admin" || localStorageUserData?.role == "AWS Staff")) {
                    history.push("/admin/dashboard")
                } else if (localStorageUserData && localStorageUserData?.token && (localStorageUserData?.role == "Surveyor" || localStorageUserData?.role == "Insurer" || localStorageUserData?.role == "Broker" || localStorageUserData?.role == "Developer" || localStorageUserData?.role == "Contractor" || localStorageUserData?.role == "Financial Interest" || localStorageUserData?.role == "Sub Contractor")) {
                    localStorageUserData?.role == "Surveyor" ? dispatch(ChangeDashboardNameForNavbar("Surveyor Hub")) : localStorageUserData?.role == "Broker" ? dispatch(ChangeDashboardNameForNavbar("Broker Hub")) : localStorageUserData?.role == "Insurer" ? dispatch(ChangeDashboardNameForNavbar("Insurer Hub")) : localStorageUserData?.role == "Developer" ? dispatch(ChangeDashboardNameForNavbar("Developer Hub")) : localStorageUserData?.role == "Contractor" ? dispatch(ChangeDashboardNameForNavbar("Contractor Hub")) : localStorageUserData?.role == "Financial Interest" ? dispatch(ChangeDashboardNameForNavbar("Financial Interest Hub")) : dispatch(ChangeDashboardNameForNavbar("Sub Contractor Hub"))
                    // history.push("/surveyor/dashboard")
                    // history.push("/developer/dashboard")
                    history.push("/user/dashboard")
                } else {
                    history.push("/auth/login")

                }
            }
        }

        CheckIsUserLogin()
    }, [])

    return (

        <Switch>
            {userData ?
                <>
                    {role == "super-admin" || role == "AWS Staff" ?
                        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                        :
                        <Route path="/user" render={(props) => <ExternalUserLayout {...props} />} />
                    }
                </>
                :
                <>
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    {/* checkPathInc == false && <Redirect to="/auth/login" /> */}
                </>
            }
        </Switch>
    )
}

export default App