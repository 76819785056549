import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const stakeHolderSlice = createSlice({
    name: 'stakeHolder',
    initialState: {
        insurerOrBrokerBusinessStatus: [],
        insurerType: [],
        insurerOrBroker: [],
        addInsurerOrBrokerErrors: {},
        allInsurer: [],
        allBroker: [],
    },
    reducers: {
        ChangeUserData: (state, action) => {
            state.userData = action.payload
        }
    },
    extraReducers: {

        ['insurerOrBroker/GetInsurerType/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: AddNewInsurerOrBrokerSlice.js ~ line 20 ~ data", data)
            if (status >= 200 && status < 300) {

                state.insurerType = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.insurerType = data
            }
        },
        ['insurerOrBroker/GetAllBroker/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.allBroker = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.allBroker = data
            }
        },
        ['insurerOrBroker/GetAllInsurer/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.allInsurer = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.allInsurer = data
            }
        },
        ['insurerOrBroker/GetInsurerOrBrokerBusinessStatus/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: AddNewStakeHolderSlice.js ~ line 31 ~ data", data)
            if (status >= 200 && status < 300) {

                state.insurerOrBrokerBusinessStatus = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.insurerOrBrokerBusinessStatus = data
            }
        },
        ['insurerOrBroker/CreateInsurerOrBroker/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: AddNewInsurerOrBrokerSlice.js ~ line 31 ~ data", data)
            if (status >= 200 && status < 300) {

                state.insurerOrBroker = data?.data
                state.addInsurerOrBrokerErrors = {}
            }
            else if (status >= 400 && status < 500) {

                state.addInsurerOrBrokerErrors = data?.error
            }
        },

        // ['auth/Logout/fulfilled']: (state, action) => {
        //     state.userData = null

        // },
    },
});

export const {
    ChangeUserData,
} = stakeHolderSlice.actions;

export default stakeHolderSlice.reducer;