import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const stakeHolderSlice = createSlice({
    name: 'stakeHolder',
    initialState: {
        userTypeForInternalUser: [],
        internalUser: [],
        internalUserErrors: null
    },
    reducers: {
        ChangeUserData: (state, action) => {
            state.userData = action.payload
        }
    },
    extraReducers: {

        ['addUser/GetUserTypeForInternalUser/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.userTypeForInternalUser = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.userTypeForInternalUser = data
            }
        },

        ['addUser/CreateInternalUser/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.internalUser = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                state.internalUserErrors = data?.errors
            }
        },
    },
});

export const {
    ChangeUserData,
} = stakeHolderSlice.actions;

export default stakeHolderSlice.reducer;