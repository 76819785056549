import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FileBase64 from 'react-file-base64';
import Select from "react-select";
import {
    Button,
    Card, CardBody, CardHeader, Col, Container, FormGroup, Modal,
    Input, Progress, Row, Table
} from "reactstrap";
import { CreateAdministrationHistoryAddDefect, CreateAdministrationHistorySubCategory, CreateAdmnistrationHistoryCategory, GetAddDefectCategory, GetAddDiarySubCategoryByCategoryId, GetAdministrationHistoryAssignedTo } from '../AdministrationHistory/Store/AdministrationHistoryApi';

const AddCategory = ({ toggleModalFunction }) => {
    const dispatch = useDispatch()

    const [categoryNameField, setCategoryNameField] = useState("")
    const [assignToField, setAssignToField] = useState(null)

    const { administrationHistoryAssignedTo } = useSelector(state => state?.AdministrationHistory)


    useEffect(() => {
        dispatch(GetAdministrationHistoryAssignedTo())
    }, [])

    const addCategoryHandler = () => {
        const data = {
            category_type: "defect",
            category_name: categoryNameField,
            assigned_to: assignToField?.value,
        }
        console.log("🚀 ~ file: AdministrationHistoryAddDiary.js ~ line 23 ~ addCategoryHandler ~ data", data)

        dispatch(CreateAdmnistrationHistoryCategory(data))
        setCategoryNameField("")
        setAssignToField(null)
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Add Category
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Category Name</label>
                                <Input className="custom-input" placeholder="" type="text" value={categoryNameField} onChange={e => setCategoryNameField(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Assign To</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={assignToField}
                                        onChange={(value) => setAssignToField(value)}
                                        options={
                                            administrationHistoryAssignedTo?.map(data => ({
                                                value: data?.id, label: data?.name
                                            }))
                                            // {
                                            //     value: "",
                                            //     label: "Category",
                                            //     isDisabled: true,
                                            // },
                                            // { value: "6", label: "AWS Hub" },
                                            // { value: "2", label: "AWS Dev" },
                                            // { value: "3", label: "Surveyor" },
                                            // { value: "3", label: "ARK (Insurer)" },
                                            // { value: "3", label: "Broker" },
                                            // { value: "3", label: "Developer" },
                                            // { value: "3", label: "Contractor" },
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={addCategoryHandler} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

const AddSubCategory = ({ toggleModalFunction }) => {


    const dispatch = useDispatch()
    const { addDefectCategories } = useSelector(state => state?.AdministrationHistory)


    const [categoryIdField, setCategoryIdField] = useState(null)
    const [subCategoryNameField, setSubCategoryNameField] = useState("")

    const addSubCategoryHandler = () => {
        const data = {
            category_id: categoryIdField?.value,
            sub_category_name: subCategoryNameField,
        }
        console.log("🚀 ~ file: AdministrationHistoryAddDiary.js ~ line 23 ~ addCategoryHandler ~ data", data)

        dispatch(CreateAdministrationHistorySubCategory(data))
        setCategoryIdField(null)
        setSubCategoryNameField("")
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Add Sub Category
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Category</label>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={categoryIdField}
                                        onChange={(value) => setCategoryIdField(value)}
                                        options={

                                            addDefectCategories?.map(catgeory => (
                                                {
                                                    value: catgeory?.id, label: catgeory?.category_name
                                                }
                                            ))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Sub Category Name</label>
                                <Input className="custom-input" placeholder="" type="text" value={subCategoryNameField} onChange={e => setSubCategoryNameField(e.target.value)} />
                            </FormGroup>
                        </Col>

                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={addSubCategoryHandler} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

const AdministrationHistoryAddDefect = ({ toggleModalFunction }) => {
    const dispatch = useDispatch()

    const { currentSelectedAcceptedDevelopment } = useSelector(state => state?.ExternalUsers)
    const { addDefectCategories, addDiarySubCategoryByCategoryId } = useSelector(state => state?.AdministrationHistory)


    const [modalAddCategory, setModalAddCategory] = useState(false);
    const [modalAddSubCategory, setModalAddSubCategory] = useState(false);

    const [riskField, setRiskField] = useState(null);
    const [defectOrActionRequestField, setDefectOrActionRequestField] = useState(null);
    const [categoryIdField, setCategoryIdField] = useState(null)
    const [subCategoryIdField, setSubCategoryIdField] = useState(null)
    const [notificationDateField, setNotificationDate] = useState(null)
    const [blockNumberField, setBlockNumberField] = useState(null)
    const [unitNumberField, setUnitNumberField] = useState(null)
    const [descriptionField, setDescriptionField] = useState(null)
    const [attachmentField, setAttachmentField] = useState([])
    const [ifApplicableField, setIfApplicableField] = useState(0)


    const toggleModalAddCategory = () => {
        setModalAddCategory(!modalAddCategory);
    }
    const toggleModalAddSubCategory = () => {
        setModalAddSubCategory(!modalAddSubCategory);
    }

    useEffect(() => {
        dispatch(GetAddDefectCategory())
    }, [])

    const convertBase64 = (files) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handleFileInput = (e) => {
        const choosenFiles = Array.prototype.slice.call(e.target.files)
        setAttachmentField(choosenFiles)
    }

    const addDefectHandleChange = () => {
        const alterFiles = attachmentField?.map(file => ({
            file_name: file?.name,
            file: file?.base64,
            type: file?.type,
            extra: file?.file
        }))
        const data = {
            development_id: currentSelectedAcceptedDevelopment?.id,
            category_id: categoryIdField?.value,
            sub_category_id: subCategoryIdField?.value,
            defect_action: defectOrActionRequestField?.value,
            notification_date: notificationDateField,
            block: blockNumberField,
            unit: unitNumberField,
            risk: riskField?.value,
            description: descriptionField,
            // attachment: await convertBase64(attachmentField),
            multiplefile: alterFiles,
            if_applicable: ifApplicableField,
        }
        console.log("🚀 ~ file: AdministrationHistoryAddDefect.js ~ line 190 ~ addDefectHandleChange ~ data", data)

        dispatch(CreateAdministrationHistoryAddDefect(data))
        toggleModalFunction()
    }

    const categoryIdFieldHandler = (value) => {
        setCategoryIdField(value)
        dispatch(GetAddDiarySubCategoryByCategoryId(value?.value))
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Upload Defect
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Category</label>
                                    <span
                                        style={{ fontSize: "16px", padding: "0 5px", color: "#ffffff", borderRadius: "15px", marginLeft: "16px", cursor: "pointer", backgroundColor: "#51BCDA" }}
                                        onClick={toggleModalAddCategory}>+</span>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={categoryIdField}
                                        onChange={(value) => categoryIdFieldHandler(value)}
                                        options={
                                            // {
                                            //     value: "",
                                            //     label: "Category",
                                            //     isDisabled: true,
                                            // },

                                            addDefectCategories?.map(catgeory => (
                                                {
                                                    value: catgeory?.id, label: catgeory?.category_name
                                                }
                                            ))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Sub Category</label>
                                    <span style={{ fontSize: "16px", padding: "0 5px", color: "#ffffff", borderRadius: "15px", marginLeft: "16px", cursor: "pointer", backgroundColor: "#51BCDA" }} onClick={toggleModalAddSubCategory}>+</span>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={subCategoryIdField}
                                        onChange={(value) => setSubCategoryIdField(value)}
                                        options={
                                            addDiarySubCategoryByCategoryId?.map(catgeory => (
                                                {
                                                    value: catgeory?.id, label: catgeory?.sub_category_name
                                                }
                                            ))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Do you wish to record and upload a new defect or action request?</label>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={defectOrActionRequestField}
                                        onChange={(value) => setDefectOrActionRequestField(value)}
                                        options={[
                                            { value: "1", label: "Defect" },
                                            { value: "2", label: "Action Request" },
                                            { value: "3", label: "None" },
                                        ]}
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Notification Date</label>
                                <Input className="custom-input" placeholder="" type="date" value={notificationDateField} onChange={e => setNotificationDate(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <span className="text-sm font-bold mt-4">{currentSelectedAcceptedDevelopment?.developmentName || "Development Name"}</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <TextField
                                label="Block Number or Name"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                value={blockNumberField}
                                onChange={(e) => setBlockNumberField(e.target.value)}
                                type="text"
                            />
                        </Col>
                        <Col md="6">
                            <TextField
                                label="Unit Number or name"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                value={unitNumberField}
                                onChange={(e) => setUnitNumberField(e.target.value)}
                                type="text"
                            />
                        </Col>
                    </Row>
                    {/* <Col md="12"> */}
                    <FormGroup style={{ margin: 0 }}>
                        <div>
                            <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Risk</label>
                        </div>
                        <div className="custom-select-input">
                            <Select
                                className="react-select primary "
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={riskField}
                                onChange={(value) => setRiskField(value)}
                                options={[
                                    { value: "1", label: "High" },
                                    { value: "2", label: "Medium" },
                                    { value: "3", label: "Low" },
                                ]}
                                placeholder=""
                            />
                        </div>
                    </FormGroup>
                    {/* </Col> */}
                    {riskField == 1 &&
                        <span className="text-sm font-bold mt-4">Has potential to affect High risk physical conditions of the building and could lead to major defects. Must be rectified immediately. No further substantial works can commence until rectified. Surveyor to determine when it must rectified (date) and whether photo/video will suffice or if a further Survey visit is required. Failure to rectify immediately may result in cover being declined, or an endorsement applied excluding the potential risk or an increase in premium. </span>
                    }
                    {riskField == 2 &&
                        <span className="text-sm font-bold mt-4">Has potential to affect Medium risk physical conditions of the building. Must be rectified before the next survey stage is carried out. No further works can commence where the defect has been identified until rectified. Surveyor to determine when it must rectified (date) and whether photo/video will suffice or if a further Survey visit is required. Failure to rectify may result in cover being declined, or an endorsement applied excluding the potential risk or an increase in premium.  </span>
                    }
                    {riskField == 3 &&
                        <span className="text-sm font-bold mt-4">Low risk (minor) defects which affect the physical condition of the building. Photo/video evidence of rectification will suffice. Failure to rectify or notify us may result in an endorsement applied excluding the potential risk or an increase in premium. </span>
                    }
                    <Col md="12" style={{ padding: 0, marginTop: "8px" }}>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" placeholder='Description' max={150} style={{ minHeight: "80px", border: "1px solid #888888" }} value={descriptionField} onChange={e => setDescriptionField(e.target.value)} />
                        </FormGroup>
                    </Col>
                    {defectOrActionRequestField == 1 &&
                        <span className="text-sm font-bold mt-4">Please describe where the defect is, what the defect is and what must be done to remedy.</span>
                    }
                    {defectOrActionRequestField == 2 &&
                        <span className="text-sm font-bold mt-4">Please describe the Action Request. Please provide Stakeholders with as much information as possible.</span>
                    }

                    {/* <Col md="6">
                        <FormGroup>
                            <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Attachment</label>
                            <Input className="custom-input" placeholder="" type="file" multiple onChange={e => setAttachmentField(e.target.files[0])} />
                            <Input className="custom-input" placeholder="" type="file" multiple onChange={handleFileInput} />
                        </FormGroup>
                    </Col> */}
                    <Col md="6">
                        <FormGroup>
                            <FileBase64
                                multiple={true}
                                onDone={(files) => setAttachmentField(files)} />
                        </FormGroup>
                    </Col>
                    {defectOrActionRequestField == 2 &&
                        <>
                            <Col md="12">
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Please upload photo or video evidence (if applicable)</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={ifApplicableField}
                                        onChange={(e) => setIfApplicableField(e.target.value)}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={0} control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                            <span className="text-sm font-bold mt-4">If no response or action required please select no.</span>

                        </>
                    }
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={addDefectHandleChange} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
            {/* Add Category Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAddCategory}
                toggle={toggleModalAddCategory}
                size="lg"
            >
                <AddCategory toggleModalFunction={toggleModalAddCategory} />
            </Modal>

            {/* Add Sub Category Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAddSubCategory}
                toggle={toggleModalAddSubCategory}
                size="lg"
            >
                <AddSubCategory toggleModalFunction={toggleModalAddSubCategory} />
            </Modal>
        </>
    )
}

export default AdministrationHistoryAddDefect