import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import {
    Button,
    Card, CardBody, CardHeader, Col, Container, FormGroup,
    Input, Progress, Row, Table
} from "reactstrap";
import DoneIcon from '@mui/icons-material/Done';
import { GetAdmisistrationHistoryData, GetDiaries } from '../AdministrationHistory/Store/AdministrationHistoryApi';

import { GetAdmisistrationHistoryDataForSurveyor, GetSingleAddDiaryDetail, GetSingleAddNoteDetail, GetSingleMakeRequestDetails, GetSingleUploadDocumentDetail } from './../AdministrationHistory/Store/AdministrationHistoryApi'
import { ToggleModalAdministrationHistoryAddDiary } from 'App/Screens/Shared/store/sharedSlice';
import { ChangeAddDiaryMode } from 'App/Screens/Shared/store/sharedSlice';
import { ChangeAddNoteMode } from 'App/Screens/Shared/store/sharedSlice';
import { ToggleModalAdministrationHistoryAddNote } from 'App/Screens/Shared/store/sharedSlice';
import { ChangeMakeRequestMode } from 'App/Screens/Shared/store/sharedSlice';
import { ToggleModalAdministrationHistoryMakeRequest } from 'App/Screens/Shared/store/sharedSlice';
import { ChangeUploadDocumentMode } from 'App/Screens/Shared/store/sharedSlice';
import { ToggleModalAdministrationHistoryUploadDocument } from 'App/Screens/Shared/store/sharedSlice';
import HeightIcon from '@mui/icons-material/Height';

const AllDiaries = ({ toggleModalFunction, showFor, developmentId }) => {
    const dispatch = useDispatch()
    const scrollDiv1 = useRef(null);
    const {
        diaries
    } = useSelector(state => state.diaries)
    const { allDiaries, administrationHistoryData } = useSelector(state => state.AdministrationHistory)
    console.log("🚀 ~ file: AllDiaries.js ~ line 13 ~ AllDiaries ~ diaries", diaries)

    const handleOpenModalForView = (type, id) => {
        console.log("🚀 ~ file: AdministrationHistoryData.js:36 ~ handleOpenModalForView ~ type", type)
        type == "dairy" && (dispatch(ChangeAddDiaryMode("view")), dispatch(ToggleModalAdministrationHistoryAddDiary(true)), dispatch(GetSingleAddDiaryDetail(id)));
        type == "note" && (dispatch(ChangeAddNoteMode("view")), dispatch(ToggleModalAdministrationHistoryAddNote(true)), dispatch(GetSingleAddNoteDetail(id)));
        type == "make-request" && (dispatch(ChangeMakeRequestMode("view")), dispatch(ToggleModalAdministrationHistoryMakeRequest(true)), dispatch(GetSingleMakeRequestDetails(id)));
        type == "upload-document" && (dispatch(ChangeUploadDocumentMode("view")), dispatch(ToggleModalAdministrationHistoryUploadDocument(true)), dispatch(GetSingleUploadDocumentDetail(id)));
    }

    function RenderDiaries(data) {
        return data?.length > 0 ? data?.map((item, key) => {
            const { id, due_date, clientNo, from, subject, user, created_at, category, sub_category, types } = item
            return (
                <tr
                    key={id}
                >
                    <td className="w-13">{moment(created_at).format("DD/MM/YY")}</td>
                    <td className="clickable-col">{moment(due_date).format("DD/MM/YY")}</td>
                    <td className="clickable-col">{user?.username}</td>
                    <td>{user?.username}</td>
                    <td className="clickable-col" onClick={() => handleOpenModalForView(types, id)}>{subject}</td>
                    <td className="clickable-col">{category?.category_name}</td>
                    <td className="clickable-col">{sub_category?.sub_category_name}</td>
                </tr>
            )
        })
            :
            []
    }


    const [sortedData, setSortedData] = useState([]);
    const [sortOrder, setSortOrder] = useState("asc");

    const handleSort = (value) => {
        const order = sortOrder === "asc" ? "desc" : "asc";
        const sorted = [...sortedData].sort((a, b) => {
            if (typeof a[value] === "string") {
                return sortOrder === "asc"
                    ? a[value].localeCompare(b[value])
                    : b[value].localeCompare(a[value]);
            } else if (value.includes(".")) {
                return sortOrder === "asc" ? `a.${value.split(".")[0]}.${value.split(".")[1]}`.localeCompare(
                    `b.${value.split(".")[0]}.${value.split(".")[1]}`) : `b.${value.split(".")[0]}.${value.split(".")[1]}`.localeCompare(
                        `a.${value.split(".")[0]}.${value.split(".")[1]}`);
            } else {
                return sortOrder === "asc"
                    ? a[value] - b[value]
                    : b[value] - a[value];
            }
        });
        setSortedData(sorted);
        setSortOrder(order);
    };

    useEffect(() => {
        setSortedData(allDiaries)
    }, [allDiaries])

    return (
        <>
            <div className="modal-body" style={{ padding: 0 }}>
                <div
                    ref={scrollDiv1}
                >
                    <Table
                        className="custom-table"
                    >
                        <thead>
                            <tr>
                                <th>Created <HeightIcon onClick={() => { handleSort("created_at") }} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                <th>Due Date <HeightIcon onClick={() => { handleSort("due_date") }} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                <th>Client No <HeightIcon onClick={() => { handleSort("user.username") }} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                <th>From <HeightIcon onClick={() => { handleSort("user.username") }} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                <th>Subject <HeightIcon onClick={() => { handleSort("subject") }} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                <th>Category <HeightIcon onClick={() => { handleSort("category.category_name") }} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                <th>Sub Category <HeightIcon onClick={() => { handleSort("sub_category.sub_category_name") }} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                            </tr>
                        </thead>
                        <tbody>
                            {RenderDiaries(sortedData)}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}

export default AllDiaries