import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const userDataSlice = createSlice({
    name: 'userData',
    initialState: {
        userData: null,
        userDataById: null,
        contractorDetailForDevelopmentActivity: null,
        userDetailForTopLeftCard: null,
        setExternalUserPassword: null,
        authError: [],
        forgetPasswordSendEmail: null,
    },
    reducers: {
        ChangeUserData: (state, action) => {
            state.userData = action.payload
        },
        EmptyUserDetailForTopLeftCard: (state, action) => {
            state.userDetailForTopLeftCard = action.payload
        }
    },
    extraReducers: {
        ['auth/SignIn/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: userDataSlice.js ~ line 31 ~ data", data)
            if (status >= 200 && status < 300) {
                toast(data.message)
                state.userData = data?.data
            }
            else if (state == 403) {
                toast(data.message)
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                state.authError = data
                console.log("🚀 ~ file: userDataSlice.js ~ line 40 ~ data", data)
            }
        },

        ['auth/LogoutUser/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: userDataSlice.js ~ line 31 ~ data", data)
            if (status >= 200 && status < 300) {

                state.userData = null
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                state.authError = data
                console.log("🚀 ~ file: userDataSlice.js ~ line 40 ~ data", data)
            }
        },

        ['auth/GetUserDataById/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: userDataSlice.js ~ line 31 ~ data", data)
            if (status >= 200 && status < 300) {

                state.userDataById = data?.data
            }
            else if (status >= 400 && status < 500) {

                state.authError = data
            }
        },

        ['auth/GetContratorDetailForDevelopmentActivity/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.contractorDetailForDevelopmentActivity = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.userDataById = data
            }
        },

        ['auth/GetUserDetailForTopLeftCard/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.userDetailForTopLeftCard = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.userDataById = data
            }
        },

        ['auth/setExternalUserPassword/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: userDataSlice.js ~ line 31 ~ data", data)
            if (status >= 200 && status < 300) {

                state.setExternalUserPassword = data?.data
            }
            else if (status >= 400 && status < 500) {

                state.authError = data
            }
        },

        ['auth/ForgetPasswordSendEmail/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.forgetPasswordSendEmail = data?.message
            }
            else if (status >= 400 && status < 500) {
                state.forgetPasswordSendEmail = data
            }
        },

        ['auth/Logout/fulfilled']: (state, action) => {
            state.userData = null

        },
    },
});

export const {
    ChangeUserData, EmptyUserDetailForTopLeftCard
} = userDataSlice.actions;

export default userDataSlice.reducer;