import ExternalUsers from "App/Screens/ExternalUsers/ExternalUsers";
import ExternalUsersProfile from "../../App/Screens/ExternalUsers/ExternalUsersProfile/ExternalUsersProfile";
import AddNewDevelopment from "../../App/Screens/AddNewDevelopment/AddNewDevelopment";
import SystemMaintenance from "../../App/Screens/SystemMaintenance/SystemMaintenance";
import Login from "../Screens/Auth/Login";
import Dashboard from "../Screens/Dashboard/Dashboard";


const externalUserRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon nc-bank",
        // component: Dashboard,
        component: ExternalUsers,
        layout: "/user",
    },
    {
        path: "/profile",
        name: "Dashboard",
        icon: "nc-icon nc-bank",
        // component: Dashboard,
        component: ExternalUsersProfile,
        layout: "/user",
    },
    {
        path: "/add-new-development",
        name: "Add New Development",
        icon: "nc-icon nc-bank",
        component: AddNewDevelopment,
        layout: "/user",
    },
    {
        path: "/system-maintenance",
        name: "Add New Development",
        icon: "nc-icon nc-bank",
        component: SystemMaintenance,
        layout: "/user",
    },
    {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
    },
];

export default externalUserRoutes;
