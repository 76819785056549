import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { Button, CardFooter, Col, Input, Row } from "reactstrap";
import './SurveyRequirements.css';
import { useForm, Controller } from "react-hook-form";
import { Autocomplete, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormGroup, InputLabel, List, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { CreateDevelopmentAssignToSurveyor, CreateDevelopmentSurveyRequirements, GetDevelopmentRefurbishmentDetails, UpdateDevelopmentSurveyRequirements } from '../AddNewDevelopment/Store/AddNewDevelopmentApi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { GetAllSurveyor } from '../AddNewSurveyor/Store/AddNewSurveyorApi';
import { ChangeDevelopmentMode, ChangeDevelopmentStepValue } from '../AddNewDevelopment/Store/AddNewDevelopmentSlice';

function SimpleDialog(props) {
    const history = useHistory()
    const dispatch = useDispatch();
    const { onClose, selectedValue, open } = props;
    const [selectedSurveyor, setSelectedSurveyor] = useState([])
    const [dueDate, setDueDate] = useState([])
    const { advanceSearchResult } = useSelector(state => state.dashboard)
    const { allSurveyor } = useSelector(state => state?.AddNewSurveyor)

    useEffect(() => {
        const today = new Date()
        let tomorrow = new Date()

        setDueDate(new Date(tomorrow.setDate(today.getDate() + 1)))
    }, [])

    const { developmentGeneralDetails, developmentMode, developmentSurveyRequirementsError, } = useSelector(state => state.AddNewDevelopment)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        // onClose(value);
        // dispatch(AlterAdvanceSearchResult(value))
        history.push("/admin/dashboard")
    };

    useEffect(() => {
        dispatch(GetAllSurveyor())
    }, [])

    const assignToSurveyor = () => {
        const data = {
            generalDetailsId: developmentGeneralDetails?.id,
            surveyorId: selectedSurveyor,
            dueDate: dueDate,
        }
        console.log("🚀 ~ file: SurveyRequirements.js ~ line 52 ~ assignToSurveyor ~ data", data)

        dispatch(CreateDevelopmentAssignToSurveyor({ data: data, history: history, clearForm: clearFormFields }))
    }

    const clearFormFields = () => {
        setSelectedSurveyor([])
        setDueDate([])
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Select Surveyor</DialogTitle>
            <DialogContent>
                <Col className="mt-4 p-0" lg="12" sm="12" style={{ width: "400px" }}>
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Surveyors</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            // multiple
                            value={selectedSurveyor}
                            onChange={e => setSelectedSurveyor(e.target.value)}

                            input={<OutlinedInput label="Surveyors" />}
                            MenuProps={MenuProps}
                        >
                            {allSurveyor?.map((surveyor) => (
                                <MenuItem value={surveyor?.surveyor_id}>{surveyor?.trading_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                <Col className="mt-4 p-0" lg="12" sm="12" style={{ width: "400px" }}>
                    <FormGroup>
                        <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Due Date</label>
                        <Input className="custom-input" placeholder="" type="date" value={dueDate} onChange={e => setDueDate(e.target.value)} />
                    </FormGroup>
                </Col>
            </DialogContent>
            <DialogActions>
                <Button className='mr-3' onClick={assignToSurveyor} style={{ backgroundColor: '#2BA9CD' }}
                    color="info" size="sm">Submit</Button>
            </DialogActions>
        </Dialog>
    );
}

function SurveyRequirements(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        // setSelectedValue(value);
    };

    const dispatch = useDispatch()

    const defaultValues = {
        foundations: 0,
        substructure: 0,
        basements: 0,
        superstructure: 0,
        firstFix: 0,
        finalinspections: 0,
        totalSurveys: 0,
    }

    const { value, developmentGeneralDetails, getDevelopmentSurveyRequirementDetails, getDevelopmentGeneralDetails } = useSelector(state => state.AddNewDevelopment)


    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100]

    const [numberOfExcavations, setNumberOfExcavations] = useState(0)
    const [numberOfSubstructure, setNumberOfSubstructure] = useState(0)
    const [numberOfBasements, setNumberOfBasements] = useState(0)
    const [numberOfSuperstructure, setNumberOfSuperstructure] = useState(0)
    const [roofDifferentBlocks, setRoofDifferentBlocks] = useState(0)
    const [numberOfPlaster, setNumberOfPlaster] = useState(0)
    const [numberOfFinalInspections, setNumberOfFinalInspections] = useState(0)
    const [numberOfSurveys, setNumberOfSurveys] = useState(0)

    useEffect(() => {
        if (developmentMode !== "add") {
            setNumberOfExcavations(getDevelopmentSurveyRequirementDetails?.no_of_excavations || 0)
            setNumberOfSubstructure(getDevelopmentSurveyRequirementDetails?.no_of_substructure || 0)
            setNumberOfBasements(getDevelopmentSurveyRequirementDetails?.no_of_basements || 0)
            setNumberOfSuperstructure(getDevelopmentSurveyRequirementDetails?.no_of_superstructure || 0)
            setRoofDifferentBlocks(getDevelopmentSurveyRequirementDetails?.roof_differnt_blocks || 0)
            setNumberOfPlaster(getDevelopmentSurveyRequirementDetails?.no_of_plaster || 0)
            setNumberOfFinalInspections(getDevelopmentSurveyRequirementDetails?.no_of_final_inspection || 0)
            setNumberOfSurveys(getDevelopmentSurveyRequirementDetails?.total_no_survey || 0)
        }
    }, [getDevelopmentSurveyRequirementDetails])

    const [formData, setFormData] = useState(defaultValues)

    function handleChange(val, name) {
        setFormData((previousState) => {
            let totalSurveys = 0
            for (let key in previousState) {
                if (key != name && previousState[key] && key != 'totalSurveys')
                    totalSurveys = totalSurveys + parseInt(previousState[key])
            }
            if (val)
                totalSurveys = totalSurveys + parseInt(val)
            return {
                ...formData,
                [name]: val,
                totalSurveys,
            }
        })
    }

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });
    const { developmentMode } = useSelector(state => state.AddNewDevelopment)


    useEffect(() => {
        setNumberOfSurveys(numberOfExcavations + numberOfSubstructure + numberOfBasements + numberOfSuperstructure + roofDifferentBlocks + numberOfPlaster + numberOfFinalInspections)
    }, [numberOfExcavations, numberOfSubstructure, numberOfBasements, numberOfSuperstructure, roofDifferentBlocks, numberOfPlaster, numberOfFinalInspections])

    const [loading, setLoading] = useState(false);

    const CreateDevelopmentSurveyRequirementsHandler = async () => {
        setLoading(true)
        if (value != 4) {
            dispatch(ChangeDevelopmentStepValue(value + 1))
        }

        const data = {
            general_details_id: developmentGeneralDetails?.id || getDevelopmentGeneralDetails?.id,
            // general_details_id: "55",
            numberOfExcavations,
            numberOfSubstructure,
            numberOfBasements,
            numberOfSuperstructure,
            roofDifferentBlocks,
            numberOfPlaster,
            numberOfFinalInspections,
            numberOfSurveys,
        }
        try {
            developmentMode === "edit"
                ? await dispatch(UpdateDevelopmentSurveyRequirements({ id: getDevelopmentSurveyRequirementDetails?.id, data: data }))
                : await dispatch(CreateDevelopmentSurveyRequirements(data));

            console.log("API call successful!");
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
            handleClickOpen();
        }
    }

    return (
        <>
            <Row style={{ pointerEvents: `${developmentMode == "view" && 'none'}` }}>
                <Col className="mt-0" lg="12" sm="12">
                    <span className="text-lg font-bold mt-0">Total no of Surveys allocated at each Key Stage: </span>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <Autocomplete
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        options={numbers}
                        renderInput={(params) => <TextField {...params} label="(Stage1) Number of Excavations / Foundations inspections on differnt Blocks?" />}
                        value={numberOfExcavations}
                        onChange={(e, value) => setNumberOfExcavations(value)}
                    />
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={numbers}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="(Stage2) Number of Substructure / Ground Floor / Drainage on differnt Blocks?" />}
                        value={numberOfSubstructure}
                        onChange={(e, value) => setNumberOfSubstructure(value)}
                    />
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        options={numbers}
                        renderInput={(params) => <TextField {...params} label="(Stage2a) Number of Basements / Structured Water Proofing on differnt Blocks?" />}
                        value={numberOfBasements}
                        onChange={(e, value) => setNumberOfBasements(value)}
                    />
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <Autocomplete
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        options={numbers}
                        renderInput={(params) => <TextField {...params} label="(Stage3) Number of Superstructure on differnt Blocks?" />}
                        value={numberOfSuperstructure}
                        onChange={(e, value) => setNumberOfSuperstructure(value)}
                    />
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <Autocomplete
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        options={numbers}
                        renderInput={(params) => <TextField {...params} label="(Stage4) Roof on differnt Blocks?" />}
                        value={roofDifferentBlocks}
                        onChange={(e, value) => setRoofDifferentBlocks(value)}
                    />
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        options={numbers}
                        renderInput={(params) => <TextField {...params} label="(Stage5) Number of 1st Fix & pre plaster on differnt Units?" />}
                        value={numberOfPlaster}
                        onChange={(e, value) => setNumberOfPlaster(value)}
                    />
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <Autocomplete
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        options={numbers}
                        renderInput={(params) => <TextField {...params} label="(Stage6) Number of Final Inspections. N.B. Each unit must have a 'Final Inspection'" />}
                        value={numberOfFinalInspections}
                        onChange={(e, value) => setNumberOfFinalInspections(value)}
                    />
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Total number of Surveys allocated to Development:"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        multiline
                        maxRows={1}
                        disabled
                        name="primay_contact"
                        value={numberOfSurveys}
                        onChange={e => setNumberOfSurveys(e.target.value)}
                    />
                </Col>
            </Row>
            <CardFooter style={{ paddingRight: 0 }}>
                {developmentMode !== "view" && <div className="exit-btn-container">
                    <Button
                        disabled={value == 0}
                        className="mr-3"
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={() => { dispatch(ChangeDevelopmentMode("previousBtn")); dispatch(GetDevelopmentRefurbishmentDetails(developmentGeneralDetails?.id)); dispatch(ChangeDevelopmentStepValue(value - 1)) }} color="info" size="sm"
                    >
                        Previous
                    </Button>
                    <Button
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={CreateDevelopmentSurveyRequirementsHandler}
                        color="info" size="sm"
                    >
                        {
                            !loading
                                ?
                                value == 4 ?
                                    "Save"
                                    :
                                    "Next"
                                :
                                <CircularProgress color='inherit' size={18} />
                        }
                    </Button>
                </div>}
            </CardFooter>
            <SimpleDialog
                // selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </>
    );
}

export default SurveyRequirements;
