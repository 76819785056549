import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

const diariesSlice = createSlice({
    name: 'styles',
    initialState: {
        diaries: [
            {

                id: "1",
                dueDate: new Date(2022, 4, 1),
                clientNo: 1,
                from: "Zeeshan",
                subject: "This is subject 1",
                created: new Date(2022, 5, 1),
                category: "Admin",
                subCategory: "MID",
            },
            {
                id: "2",
                dueDate: new Date(2022, 4, 1),
                clientNo: 1,
                from: "Zeeshan",
                subject: "This is subject 1",
                created: new Date(2022, 5, 1),
                category: "Admin",
                subCategory: "MID",
            },
            {
                id: "3",
                dueDate: new Date(2022, 4, 1),
                clientNo: 1,
                from: "Zeeshan",
                subject: "This is subject 1",
                created: new Date(2022, 5, 1),
                category: "Admin",
                subCategory: "MID",
            },
        ],
    },
    reducers: {
    },
    extraReducers: {
    },
});

export const {
} = diariesSlice.actions;

export default diariesSlice.reducer;