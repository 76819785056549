import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetSurveyorProfileData = createAsyncThunk('stakeHolder/GetSurveyorProfileData', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-profile').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const SendRequestToUpdateProfile = createAsyncThunk('stakeHolder/SendRequestToUpdateProfile', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/send-edit-request', { ...params }).then(function (response) {
        toast("Request Send Successfully.")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

