import classnames from "classnames";
import React, {
  useEffect,
  useState
} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import {
  SetCurrentDashboard,
  SetIsViewDevelopmentDetails,
  SetShowSingleUnAllocatedDevelopments,
  SetShowStackholderActivity,
  ToggleResetDashboard,
} from "../store/pageSlice";
import { PopJumps, PushJumps, SetCurrentForm } from "../store/sharedSlice";
import './AdminNavbar.css';
import { ChangeDevelopmentMode } from "App/Screens/AddNewDevelopment/Store/AddNewDevelopmentSlice";
import { LogoutUser } from "App/Screens/Auth/Store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from "@mui/material";
import { ActionCreators } from "redux-undo";
import { ChangeDevelopmentStepValue } from "App/Screens/AddNewDevelopment/Store/AddNewDevelopmentSlice";

function AdminNavbar(props) {
  let history = useHistory()
  const dispatch = useDispatch()
  const {
    currentDashboard
  } = useSelector(state => state.page.present)

  const { userData } = useSelector(state => state.userData)
  const {
    jumps
  } = useSelector(state => state.shared)
  const { adminProfileData } = useSelector(state => state?.AdminProfile)

  const [collapseOpen, setCollapseOpen] = useState(false);

  const [profileData, setProfileData] = useState(null)

  useEffect(() => {
    setProfileData(adminProfileData)
  }, [adminProfileData])
  const [color, setColor] = useState("navbar-transparent");
  const location = useLocation();
  useEffect(() => {
    window.addEventListener("resize", updateColor);
  });
  useEffect(() => {
    if (
      window.outerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, [location]);

  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };

  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
    setCollapseOpen(!collapseOpen);
  };
  return (
    <>
      <Navbar
        className={classnames("navbar-absolute custom-navbar fixed-top py-0", color)}
        expand="lg"
        style={{ position: "sticky", zIndex: 2000, height: "50px", top: 0 }}
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <IconButton
              onClick={() => {

                if (jumps.length > 0) {

                  const currentJump = jumps[jumps.length - 1]

                  dispatch(ActionCreators.jump(
                    -currentJump.size
                  ))
                  dispatch(PopJumps())

                  if (currentJump.goBack)
                    history.goBack()
                }
              }}
              sx={{
                mr: 2,
              }}
            >
              <ArrowBackIcon
                sx={{
                  color: 'white'
                }}
              />
            </IconButton>
            <Link to="/admin/dashboard">
              <img
                src={require('../../../Assets/Images/ARKlogo2.png').default}
                style={{
                  height: 40,
                  cursor: "pointer"
                }}
                onClick={() => {

                  dispatch(PushJumps({
                    size: 4,
                    goBack: true,
                  }))

                  // dispatch(ToggleResetDashboard())
                  dispatch(SetShowSingleUnAllocatedDevelopments(false))
                  dispatch(SetShowStackholderActivity(false))
                  dispatch(SetIsViewDevelopmentDetails(false))
                  dispatch(SetCurrentDashboard("AWS Hub"))

                  dispatch(ChangeDevelopmentMode("add"))
                  dispatch(SetCurrentForm(""))
                }}
              />
            </Link>
          </div>
          <p style={{ marginLeft: "24px", color: "#ffffff" }}>{currentDashboard}</p>
          <div className=" navbar-button-container">
            <div
              onClick={() => {

                dispatch(PushJumps({
                  size: 1,
                  goBack: true,
                }))

                dispatch(SetCurrentDashboard("Add New Development"))

                history.push('/admin/add-new-development')
                dispatch(ChangeDevelopmentMode("add"))
                dispatch(ChangeDevelopmentStepValue(0))
                dispatch(SetCurrentForm(""))
              }}
              className="navbar-button flex flex-row  items-center"
            >
              <img
                src={require('../../../Assets/Svg/Plus.svg').default}
                style={{
                  height: 15,
                  width: 15,
                }}
              />
              <span className="navbar-btn-text">Add New Development</span>
            </div>
            <div
              onClick={() => {

                dispatch(PushJumps({
                  size: 4,
                  goBack: true,
                }))

                // dispatch(ToggleResetDashboard())
                dispatch(SetShowSingleUnAllocatedDevelopments(false))
                dispatch(SetShowStackholderActivity(false))
                dispatch(SetIsViewDevelopmentDetails(false))
                dispatch(SetCurrentDashboard("AWS Hub"))

                dispatch(ChangeDevelopmentMode("add"))
                history.push('/admin/dashboard')
                dispatch(SetCurrentForm(""))
              }}
              className="navbar-button flex flex-row  items-center"
            >
              <img
                src={require('../../../Assets/Svg/Home.svg').default}
                style={{
                  height: 15,
                  width: 15,
                }}
              />
              <span className="navbar-btn-text">Home</span>
            </div>
            {userData?.role == "super-admin" && <div
              onClick={() => {

                dispatch(PushJumps({
                  size: 1,
                  goBack: true,
                }))

                dispatch(ChangeDevelopmentMode("add"));
                dispatch(SetCurrentDashboard("System Maintenance"));
                history.push('/admin/system-maintenance')
              }}
              className="navbar-button flex flex-row  items-center"
            >
              <img
                src={require('../../../Assets/Svg/Switch.svg').default}
                style={{
                  height: 15,
                  width: 15,
                }}
              />
              <span className="navbar-btn-text">System Maintenance</span>
            </div>}
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            // data-target="#navigation"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={collapseOpen}
          >
            <Nav navbar>
              <UncontrolledDropdown className="btn-rotate" nav>
                <DropdownToggle
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  className="user-navitem"
                  nav
                >
                  <div className="navbar-user-wrapper ">
                    <div className="flex flex-column ">
                      <span className="navbar-username ">{profileData?.username || userData?.user?.username}</span>
                      <span className="navbar-role">{profileData?.role || userData?.role}</span>
                    </div>
                    <img
                      src={require('../../../Assets/Images/jisoo.png').default}
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: 40,
                        objectFit: 'cover',
                        marginLeft: 10,
                      }}
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu
                  persist
                  aria-labelledby="navbarDropdownMenuLink"
                  right
                >
                  <DropdownItem
                    href=""
                    onClick={(e) => history.push("/admin/profile")}
                  >
                    Profile
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch(LogoutUser({ history }))
                    }}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
