import React, { useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import {
    Button,
    Col,
    FormGroup,
    Input,
  } from "reactstrap";
import { SendPasswordReset } from 'App/Screens/Auth/Store/authSlice';
import { useDispatch } from 'react-redux';

const ResetEmailModal = ({
    handleClose,
    open,
    oldEmail,
}) => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState('')

    const handleResetPassword = async () => {
        const data = {
            old_email: oldEmail,
            new_email: email,
        }

        const response = await dispatch(SendPasswordReset({ data: data }))

        if(response.payload.status == 200) {
            handleClose()
        }
    }
    
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
                <Col
                    className="p-0"
                    lg="12"
                    sm="12"
                    style={{
                        width: "400px"
                    }}
                >
                    <FormGroup>
                        <label
                            htmlFor="DueDate"
                            style={{
                                fontSize: "12px",
                                marginTop: 0
                            }}
                        >
                            Email
                        </label>
                        <Input
                            className="custom-input"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </DialogContent>
            <DialogActions>
                <Button
                    className='mr-3'
                    onClick={handleResetPassword}
                    style={{
                        backgroundColor: '#2BA9CD'
                    }}
                    color="info"
                    size="sm"
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ResetEmailModal