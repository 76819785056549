import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetAdminProfileData = createAsyncThunk('admin/GetAdminProfileData', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-profile').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

