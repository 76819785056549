import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card, CardBody, CardHeader, Col, Row, Input, CardFooter, Button
} from "reactstrap";
import './AddNewInsurerOrBroker.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ReactDatetime from "react-datetime";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Input as MuiInput, List, ListItemButton, ListItemText, Switch } from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useHistory } from 'react-router-dom';
import { CreateInsurerOrBroker, GetInsurerOrBrokerBusinessStatus, GetInsurerType, UpdateInsurerOrBroker } from './Store/AddNewInsurerOrBrokerApi';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ResetEmailModal from 'components/ResetEmailModal/ResetEmailModal';

function AddNewInsurerOrBroker() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { insurerOrBrokerBusinessStatus, insurerType, insurerOrBroker, addInsurerOrBrokerErrors } = useSelector(state => state?.AddNewInsurerOrBroker)
    const { advanceSearchResult } = useSelector(state => state?.dashboard)

    // console.log("🚀 ~ file: AddNewStakeHolder.js ~ line 21 ~ AddNewStakeHolder ~ stakeHolderBusinessStatus", stakeHolderBusinessStatus)

    // const [otherTitle, setOtherTitle] = useState('');
    // const [formNavigateState, setFormNavigateState] = useState(1)
    const [businessStatusField, setBusinessStatusField] = useState(null)
    const [brokerInsurerTypeIdField, setBrokerInsurerTypeIdField] = useState(null)
    const [companyNameField, setCompanyNameField] = useState('')
    const [tradingNameField, setTradingNameField] = useState('')
    const [websiteUrlField, setWebsiteUrlField] = useState('')
    const [postCodeField, setPostCodeField] = useState('')
    const [correspondingAddressField, setCorrespondingAddressField] = useState('')
    const [siteAddressFieldData, setSiteAddressFieldData] = useState([])
    const [siteAddressFieldDataShow, setSiteAddressFieldDataShow] = useState(false)

    const [propertyNameField, setPropertyNameField] = useState('Dummy Name')
    const [titleField, setTitleField] = useState('')
    const [forenameField, setForenameField] = useState('')
    const [surnameField, setSurnameField] = useState('')
    const [jobTitleField, setJobTitleField] = useState('')
    const [emailField, setEmailField] = useState('')
    const [contactNumberField, setContactNumberField] = useState('')
    const [phoneNumberField, setPhoneNumberField] = useState('')
    const [otherContactTitleField, setOtherContactTitleField] = useState('')
    const [otherContactForenameField, setOtherContactForenameField] = useState('')
    const [otherContactSurnameField, setOtherContactSurnameField] = useState('')
    const [otherContactJobTitleField, setOtherContactJobTitleField] = useState('')
    const [otherContactEmailField, setOtherContactEmailField] = useState('')
    const [otherContactContactNumberField, setOtherContactContactNumberField] = useState('')
    const [otherContactPhoneNumberField, setOtherContactPhoneNumberField] = useState('')
    const [businessIsActiveField, setBusinessIsActiveField] = useState(0)
    const [otherContactIsActiveField, setOtherContactIsActiveField] = useState(0)
    const [primaryIsActiveField, setPrimaryIsActiveField] = useState(0)

    const [isAddOtherContact, setIsAddOtherContact] = useState(false)

    const [retrieveCompleteAddressFieldData, setRetrieveCompleteAddressFieldData] = useState(null)
    const [retrieveAddressField1, setRetrieveAddressField1] = useState("")
    const [retrieveAddressField2, setRetrieveAddressField2] = useState("")
    const [retrieveTownCity, setRetrieveTownCity] = useState("")
    const [retrieveCountyState, setRetrieveCountyState] = useState("")
    const [retrieveCountry, setRetrieveCountry] = useState("")

    const [resetEmail, setResetEmail] = useState('')


    const [otherContactFormValues, setOtherContactFormValues] = useState([{
        other_contact_title: "",
        other_contact_fore_name: "",
        other_contact_sur_name: "",
        other_contact_job_title: "",
        other_contact_email: "",
        other_contact_contact_number: "",
        other_contact_phone_number: "",
        other_contact_is_active: ""
    }])

    useEffect(() => {
        advanceSearchResult[0]?.other_contactuser?.length > 0 && setIsAddOtherContact(true)
        setBusinessStatusField(advanceSearchResult[0]?.business_details[0]?.business_status_id || null)
        setBrokerInsurerTypeIdField(advanceSearchResult[0]?.business_details[0]?.broker_insurer_type_id || null)
        setCompanyNameField(advanceSearchResult[0]?.business_details[0]?.company_name || '')
        setTradingNameField(advanceSearchResult[0]?.business_details[0]?.trading_name || '')
        setWebsiteUrlField(advanceSearchResult[0]?.business_details[0]?.website_url || '')
        setPostCodeField(advanceSearchResult[0]?.business_details[0]?.post_code || '')
        setCorrespondingAddressField(advanceSearchResult[0]?.business_details[0]?.correspondence_address || '')
        setPropertyNameField(advanceSearchResult[0]?.business_details[0]?.property_name || '')
        setTitleField(advanceSearchResult[0]?.title || advanceSearchResult[0]?.title || '')
        setForenameField(advanceSearchResult[0]?.fore_name || advanceSearchResult[0]?.fore_name || '')
        setSurnameField(advanceSearchResult[0]?.sur_name || advanceSearchResult[0]?.sur_name || '')
        setJobTitleField(advanceSearchResult[0]?.job_title || advanceSearchResult[0]?.job_title || '')
        setEmailField(advanceSearchResult[0]?.email || advanceSearchResult[0]?.email || '')
        setContactNumberField(advanceSearchResult[0]?.contact_number || advanceSearchResult[0]?.contact_number || '')
        setPhoneNumberField(advanceSearchResult[0]?.phone_number || advanceSearchResult[0]?.phone_number || '')
        setBusinessIsActiveField(advanceSearchResult[0]?.business_details[0]?.is_active || 0)
        setPrimaryIsActiveField(advanceSearchResult[0]?.is_active || advanceSearchResult[0]?.is_active || 0)

        setRetrieveAddressField1(advanceSearchResult[0]?.business_details[0]?.address_line1 || '')
        setRetrieveAddressField2(advanceSearchResult[0]?.business_details[0]?.address_line2 || '')
        setRetrieveTownCity(advanceSearchResult[0]?.business_details[0]?.city || '')
        setRetrieveCountyState(advanceSearchResult[0]?.business_details[0]?.state || '')
        setRetrieveCountry(advanceSearchResult[0]?.business_details[0]?.country || '')


        const data = advanceSearchResult[0]?.other_contactuser?.map(contact => {
            return {
                other_contact_title: contact.title,
                other_contact_fore_name: contact.fore_name,
                other_contact_sur_name: contact.sur_name,
                other_contact_job_title: contact.job_title,
                other_contact_email: contact.email,
                other_contact_contact_number: contact.contact_number,
                other_contact_phone_number: contact.phone_number,
                other_contact_is_active: contact.is_active
            }
        })

        advanceSearchResult[0]?.other_contactuser?.length > 0 && setOtherContactFormValues(data)

    }, [advanceSearchResult])

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const [insurer, setInsurer] = useState('');
    const handleInsurer = (event) => {
        setInsurer(event.target.value);
    };
    const [haveIdentityInsurance, setHaveIdentityInsurance] = useState('');
    const handleHaveIdentityInsurance = (event) => {
        setHaveIdentityInsurance(event.target.value);
    };
    const [OtherTitle, setOtherTitle] = useState('');
    const handleOtherTitle = (event) => {
        setOtherTitle(event.target.value);
    };
    const [title, setTitle] = useState('');
    const handleTitle = (event) => {
        setTitle(event.target.value);
    };
    const [insurersType, setInsurersType] = useState('');
    const handleInsurersType = (event) => {
        setInsurersType(event.target.value);
    };
    const [businessStatus, setBusinessStatus] = useState('');
    const handleBusinessStatus = (event) => {
        setBusinessStatus(event.target.value);
    };
    const [datePicker, setDatePicker] = useState(false)

    useEffect(() => {
        dispatch(GetInsurerOrBrokerBusinessStatus())
        dispatch(GetInsurerType())
    }, [])

    const createInsurerOrBrokerHandler = () => {
        const data = {
            title: titleField,
            fore_name: forenameField,
            sur_name: surnameField,
            job_title: jobTitleField,
            email: emailField,
            contact_number: contactNumberField,
            phone_number: phoneNumberField,
            insurer_or_broker_id: brokerInsurerTypeIdField,
            business_status_id: businessStatusField,
            trading_name: tradingNameField,
            website_url: websiteUrlField,
            post_code: postCodeField,
            correspondence_address: Array.isArray(correspondingAddressField) ? correspondingAddressField[1] : correspondingAddressField,
            property_name: 'propertyNameField',
            company_name: companyNameField,
            business_is_active: businessIsActiveField,
            primary_is_active: primaryIsActiveField,
            another_contact: !isAddOtherContact ? null : otherContactFormValues,

            address_line1: retrieveAddressField1,
            address_line2: retrieveAddressField2,
            city: retrieveTownCity,
            state: retrieveCountyState,
            country: retrieveCountry,
        }
        const userId = advanceSearchResult[0]?.insurer_id !== null ? advanceSearchResult[0]?.insurer_type?.id : advanceSearchResult[0]?.broker_type?.id
        sessionStorage.getItem("currentEditUserId") ? dispatch(UpdateInsurerOrBroker({ data: data, id: advanceSearchResult[0]?.id, clearForm: clearDataHandler })) : dispatch(CreateInsurerOrBroker({ data: data, clearForm: clearDataHandler }))
        // clearDataHandler()
    }

    const clearDataHandler = () => {
        setBusinessStatusField(null)
        setBrokerInsurerTypeIdField(null)
        setCompanyNameField('')
        setTradingNameField('')
        setWebsiteUrlField('')
        setPostCodeField('')
        setCorrespondingAddressField('')
        setPropertyNameField('')
        setTitleField('')
        setForenameField('')
        setSurnameField('')
        setJobTitleField('')
        setEmailField('')
        setContactNumberField('')
        setPhoneNumberField('')
        setBusinessIsActiveField(0)
        setPrimaryIsActiveField(0)
        setOtherContactFormValues([{
            other_contact_title: "",
            other_contact_fore_name: "",
            other_contact_sur_name: "",
            other_contact_job_title: "",
            other_contact_email: "",
            other_contact_contact_number: "",
            other_contact_phone_number: "",
            other_contact_is_active: ""
        }])
        setRetrieveAddressField1("")
        setRetrieveAddressField2("")
        setRetrieveTownCity("")
        setRetrieveCountyState("")
        setRetrieveCountry("")
    }

    let otherContactFormHandleChange = (i, e) => {
        let newOtherContactFormFormValues = [...otherContactFormValues];
        newOtherContactFormFormValues[i][e.target.name] = e.target.value;
        setOtherContactFormValues(newOtherContactFormFormValues);
    }

    let addOtherContactFormFields = () => {
        setOtherContactFormValues([...otherContactFormValues, {
            other_contact_title: "",
            other_contact_fore_name: "",
            other_contact_sur_name: "",
            other_contact_job_title: "",
            other_contact_email: "",
            other_contact_contact_number: "",
            other_contact_phone_number: "",
            other_contact_is_active: ""
        }])
    }

    let removeOtherContactFormFields = (i) => {
        let newOtherContactFormFormValues = [...otherContactFormValues];
        newOtherContactFormFormValues.splice(i, 1);
        setOtherContactFormValues(newOtherContactFormFormValues)
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <>
            <Col lg="5" sm="12">
                <Card className="pb-4">
                    <CardHeader className="flex mb-2">
                        <span className="text-lg font-bold">
                            About the Business
                        </span>
                    </CardHeader>
                    <hr />
                    <CardBody className=" ">
                        <Row>
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Business Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={businessStatus}
                                        label="BusinessStatus "
                                        // onChange={handleBusinessStatus}
                                        size="small"
                                        value={businessStatusField}
                                        onChange={(e) => setBusinessStatusField(e.target.value)}
                                    >
                                        {
                                            insurerOrBrokerBusinessStatus?.map(businessStatus => {
                                                const { id, business_name } = businessStatus
                                                return (
                                                    <MenuItem key={id} value={id}>{business_name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Type </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={insurersType}
                                        label="InsurersType  "
                                        // onChange={handleInsurersType}
                                        size="small"
                                        value={brokerInsurerTypeIdField}
                                        onChange={(e) => setBrokerInsurerTypeIdField(e.target.value)}
                                    >
                                        {
                                            insurerType?.map(insurer => {
                                                const { id, name } = insurer
                                                return (
                                                    <MenuItem key={id} value={id}>{name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Company Name "
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={companyNameField}
                                    onChange={(e) => { setCompanyNameField(e.target.value); setTradingNameField(e.target.value) }}
                                // type="string"
                                />
                                {addInsurerOrBrokerErrors?.company_name && <p className="text-danger">{addInsurerOrBrokerErrors?.company_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Business / Trading Name (if different)"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={tradingNameField}
                                    onChange={(e) => setTradingNameField(e.target.value)}
                                // type="string"
                                />
                                {addInsurerOrBrokerErrors?.trading_name && <p className="text-danger">{addInsurerOrBrokerErrors?.trading_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Website URL"
                                    className="custom-input"
                                    fullWidth
                                    type="url"
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={websiteUrlField}
                                    onChange={(e) => setWebsiteUrlField(e.target.value)}
                                // type="string"
                                />
                                {addInsurerOrBrokerErrors?.website_url && <p className="text-danger">{addInsurerOrBrokerErrors?.website_url}</p>}
                            </Col>

                            {/* <Col className="mt-4" lg="12" sm="12">
                                <TextField
                                    label="Correspondence Address "
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    value={correspondingAddressField}
                                    onChange={(e) => setCorrespondingAddressField(e.target.value)}
                                />
                            </Col> */}
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Begin Typing Address"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    multiline
                                    maxRows={1}
                                    value={correspondingAddressField}
                                    onFocus={e => setSiteAddressFieldDataShow(true)}
                                    onChange={(e) => {
                                        setCorrespondingAddressField(e.target.value)

                                        const fieldValue = e.target.value

                                        var myHeaders = new Headers();
                                        myHeaders.append("Content-Type", "application/json");

                                        var raw = JSON.stringify({
                                            "key": "46ab2-3af78-4dfa7-07fcf",
                                            "query": fieldValue,
                                            "country": "gbr"
                                        });

                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: raw,
                                            redirect: 'follow'
                                        };

                                        fetch("https://api.craftyclicks.co.uk/address/1.1/find/", requestOptions)
                                            .then(response => response.json())
                                            .then(result => { console.log("result==>", result); setSiteAddressFieldData(result?.results) })
                                            .catch(error => console.log('error', error));
                                    }}
                                />
                                {siteAddressFieldData?.length > 0 && siteAddressFieldDataShow &&
                                    <Box sx={{}} style={{ position: "absolute", background: "white", boxShadow: "0px 0px 1px 1px rgba(0,0,0,100%)", zIndex: "99999", width: '789.45px', height: siteAddressFieldData?.length * 48, minHeight: "70px", maxHeight: "400px", overflow: "scroll", maxWidth: '100%', }}>
                                        <List component="nav" aria-label="secondary mailbox folder">
                                            {
                                                siteAddressFieldData?.map(obj => (

                                                    <ListItemButton
                                                        // selected={selectedIndex === 2}
                                                        onClick={(e) => {
                                                            setCorrespondingAddressField(obj?.labels);
                                                            setSiteAddressFieldDataShow(false)
                                                            // setPostCodeField(obj?.labels[0]); 

                                                            var myHeaders = new Headers();
                                                            myHeaders.append("Content-Type", "application/json");

                                                            var raw = JSON.stringify({
                                                                "key": "46ab2-3af78-4dfa7-07fcf",
                                                                "id": obj?.id,
                                                                "country": "gbr"
                                                            });

                                                            var requestOptions = {
                                                                method: 'POST',
                                                                headers: myHeaders,
                                                                body: raw,
                                                                redirect: 'follow'
                                                            };

                                                            fetch("https://api.craftyclicks.co.uk/address/1.1/retrieve", requestOptions)
                                                                .then(response => response.json())
                                                                .then(result => {
                                                                    console.log("RETRIEVE==>", result?.result);
                                                                    setRetrieveCompleteAddressFieldData(result?.result)
                                                                    setRetrieveAddressField1(result?.result?.line_1)
                                                                    setRetrieveAddressField2(result?.result?.line_2)
                                                                    setRetrieveTownCity(result?.result?.locality)
                                                                    setRetrieveCountyState(result?.result?.province_name)
                                                                    setPostCodeField(result?.result?.postal_code);
                                                                    setRetrieveCountry(result?.result?.country_name);
                                                                })
                                                                .catch(error => console.log('error', error));
                                                        }}
                                                    >
                                                        <ListItemText primary={obj?.labels} />
                                                    </ListItemButton>
                                                ))
                                            }
                                        </List>
                                    </Box>
                                }
                                {addInsurerOrBrokerErrors?.correspondence_address && <p className="text-danger">{addInsurerOrBrokerErrors?.correspondence_address}</p>}
                            </Col>
                            {/* <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Post Code"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="text"
                                    value={postCodeField}
                                    onChange={(e) => setPostCodeField(e.target.value)}
                                />
                                {addInsurerOrBrokerErrors?.post_code && <p className="text-danger">{addInsurerOrBrokerErrors?.post_code}</p>}
                            </Col> */}

                            <>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Address Line 1"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveAddressField1}
                                        onChange={(e) => setRetrieveAddressField1(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Address Line 2"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveAddressField2}
                                        onChange={(e) => setRetrieveAddressField2(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>

                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Town/City"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveTownCity}
                                        onChange={(e) => setRetrieveTownCity(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="County/State"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveCountyState}
                                        onChange={(e) => setRetrieveCountyState(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>

                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Country"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveCountry}
                                        onChange={(e) => setRetrieveCountry(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Postcode"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type="text"
                                        value={postCodeField}
                                        onChange={(e) => setPostCodeField(e.target.value)}
                                    />
                                    {addInsurerOrBrokerErrors?.post_code && <p className="text-danger">{addInsurerOrBrokerErrors?.title}</p>}
                                </Col>
                            </>

                            {/* <Col className="mt-4" lg="12" sm="12">
                                <TextField
                                    label="Property Name/Number "
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    value={propertyNameField}
                                    onChange={(e) => setPropertyNameField(e.target.value)}
                                />
                                {addInsurerOrBrokerErrors?.property_name && <p className="text-danger">{addInsurerOrBrokerErrors?.property_name}</p>}
                            </Col> */}
                            <Col className="mt-2" lg="6" sm="12">
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={businessIsActiveField}
                                        onChange={(e) => setBusinessIsActiveField(e.target.value)}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                                {/* <span className="text-sm font-medium">
                                    Status
                                </span>
                                <div className="radio-buttons ">
                                    <div className="radio-buttons-and-label ">
                                        <span>
                                            <Input
                                                className="radio-btn"
                                                defaultValue="option1"
                                                id="businessActive"
                                                name="businessActiveField"
                                                type="radio"
                                                color="red"
                                                value={businessIsActiveField}
                                                onChange={(e) => setBusinessIsActiveField(1)}
                                            />
                                        </span>
                                        <span className="radio-label">Active</span>
                                    </div>
                                    <div className="radio-buttons-and-label ">
                                        <span>
                                            <Input
                                                className="radio-btn"
                                                defaultValue="option1"
                                                id="businessActive"
                                                name="businessActiveField"
                                                type="radio"
                                                color="red"
                                                value={businessIsActiveField}
                                                onChange={(e) => setBusinessIsActiveField(0)}
                                            />
                                        </span>
                                        <span className="radio-label">Inactive</span>
                                    </div>
                                </div> */}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <div className="exit-btn-container mr-0">
                            {/* <Button
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={null} color="info" size="sm"
                            >
                                Save
                            </Button> */}
                        </div>
                    </CardFooter>
                </Card>

                <div className="exit-btn-container mr-0">
                    {!isAddOtherContact ?
                        <Button
                            className="px-3"
                            style={{ backgroundColor: '#2BA9CD' }}
                            onClick={() => setIsAddOtherContact(true)} color="info" size="sm"
                        >
                            Add Another Contact
                        </Button>
                        :
                        <Button
                            className="px-3"
                            style={{ backgroundColor: '#2BA9CD' }}
                            onClick={() => {
                                setIsAddOtherContact(false)
                                setOtherContactFormValues([{
                                    other_contact_title: "",
                                    other_contact_fore_name: "",
                                    other_contact_sur_name: "",
                                    other_contact_job_title: "",
                                    other_contact_email: "",
                                    other_contact_contact_number: "",
                                    other_contact_phone_number: "",
                                    other_contact_is_active: ""
                                }])
                            }} color="info" size="sm"
                        >
                            Remove All Other Contact
                        </Button>
                    }
                </div>

                {isAddOtherContact &&
                    <Card className="pb-4">
                        <CardHeader className="flex mb-2">
                            <span className="text-lg font-bold">
                                Other Contact Details
                            </span>
                        </CardHeader>
                        <hr />
                        {
                            otherContactFormValues?.map((element, index) => (
                                <>
                                    <CardBody className=" " key={index}>
                                        <Row>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size="small">Title</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="OtherTitle"
                                                        size="small"
                                                        name="other_contact_title"
                                                        value={element?.other_contact_title}
                                                        onChange={(e) => otherContactFormHandleChange(index, e)}
                                                    >
                                                        <MenuItem value="Mr">Mr.</MenuItem>
                                                        <MenuItem value="Miss">Miss.</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Forename"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    name="other_contact_fore_name"
                                                    value={element?.other_contact_fore_name}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Surname"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    name="other_contact_sur_name"
                                                    value={element?.other_contact_sur_name}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Job Title"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    name="other_contact_job_title"
                                                    value={element?.other_contact_job_title}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Contact Number"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    type="text"
                                                    name="other_contact_contact_number"
                                                    value={element?.other_contact_contact_number}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Mobile Number"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    type="string"
                                                    name="other_contact_phone_number"
                                                    value={element?.other_contact_phone_number}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Email Address"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    type="email"
                                                    name="other_contact_email"
                                                    value={element?.other_contact_email}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                />
                                            </Col>
                                            <Col className="mt-2" lg="6" sm="12">
                                                <FormControl>
                                                    <FormLabel id="otherContactStatus">Status</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="otherContactStatus"
                                                        name="other_contact_is_active"
                                                        value={element?.other_contact_is_active}
                                                        onChange={(e) => otherContactFormHandleChange(index, e)}
                                                    >
                                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                        {
                                            otherContactFormValues?.length > 0 &&
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '16px'
                                            }}>
                                                <Button
                                                    className="px-3"
                                                    style={{ backgroundColor: '#EF8157', marginLeft: "16px" }}
                                                    onClick={() => removeOtherContactFormFields(index)}
                                                    color="danger" size="sm"
                                                >
                                                    Remove
                                                </Button>
                                                {
                                                    sessionStorage.getItem("currentEditUserId") &&
                                                    <span
                                                        style={{
                                                            fontSize: '16px',
                                                            color: '#EF8157',
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => { setResetEmail(element?.other_contact_email), handleClickOpen() }}
                                                    >
                                                        Send Password Reset
                                                    </span>
                                                }
                                            </div>
                                        }
                                    </CardBody>

                                </>
                            ))
                        }
                        <CardFooter>
                            <div className="exit-btn-container mr-0">
                                {sessionStorage.getItem("currentEditUserId") === null &&
                                    <Button
                                        className="px-3"
                                        style={{ backgroundColor: '#2BA9CD' }}
                                        onClick={addOtherContactFormFields} color="info" size="sm"
                                    >
                                        Add another contact
                                    </Button>
                                }
                            </div>
                        </CardFooter>
                    </Card>
                }
            </Col>
            <Col lg="4" sm="12">
                <Card className="pb-4">
                    <CardHeader className="flex mb-2">
                        <span className="text-lg font-bold">
                            Contact Details
                        </span>
                    </CardHeader>
                    <hr />
                    <CardBody className=" ">
                        <Row>
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Title</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={title}
                                        label="OtherTitle "
                                        // onChange={handleTitle}
                                        size="small"
                                        value={titleField}
                                        onChange={(e) => setTitleField(e.target.value)}
                                    >
                                        <MenuItem value="Mr">Mr.</MenuItem>
                                        <MenuItem value="Miss">Miss.</MenuItem>
                                    </Select>
                                </FormControl>
                                {addInsurerOrBrokerErrors?.title && <p className="text-danger">{addInsurerOrBrokerErrors?.title}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Forename"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={forenameField}
                                    onChange={(e) => setForenameField(e.target.value)}
                                // type="string"
                                />
                                {addInsurerOrBrokerErrors?.fore_name && <p className="text-danger">{addInsurerOrBrokerErrors?.fore_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Surname"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={surnameField}
                                    onChange={(e) => setSurnameField(e.target.value)}
                                // type="string"
                                />
                                {addInsurerOrBrokerErrors?.sur_name && <p className="text-danger">{addInsurerOrBrokerErrors?.sur_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Job Title"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={jobTitleField}
                                    onChange={(e) => setJobTitleField(e.target.value)}
                                // type="string"
                                />
                                {addInsurerOrBrokerErrors?.job_title && <p className="text-danger">{addInsurerOrBrokerErrors?.job_title}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Contact Number"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="text"
                                    value={contactNumberField}
                                    onChange={(e) => setContactNumberField(e.target.value)}
                                // type="string"
                                />
                                {addInsurerOrBrokerErrors?.contact_number && <p className="text-danger">{addInsurerOrBrokerErrors?.contact_number}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Mobile Number"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="string"
                                    value={phoneNumberField}
                                    onChange={(e) => setPhoneNumberField(e.target.value)}
                                />
                                {addInsurerOrBrokerErrors?.contact_number && <p className="text-danger">{addInsurerOrBrokerErrors?.contact_number}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Email Address"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="email"
                                    value={emailField}
                                    onChange={(e) => setEmailField(e.target.value)}
                                />
                                {addInsurerOrBrokerErrors?.email && <p className="text-danger">{addInsurerOrBrokerErrors?.email}</p>}
                            </Col>
                            <Col className="mt-2" lg="6" sm="12">
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={primaryIsActiveField}
                                        onChange={(e) => setPrimaryIsActiveField(e.target.value)}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                                {/* <span className="text-sm font-medium">
                                    Status
                                </span>
                                <div className="radio-buttons ">
                                    <div className="radio-buttons-and-label ">
                                        <span>
                                            <Input
                                                className="radio-btn"
                                                defaultValue="option1"
                                                id="primaryActive"
                                                name="primaryActiveField"
                                                type="radio"
                                                color="red"
                                                value={primaryIsActiveField}
                                                onChange={(e) => setPrimaryIsActiveField(1)}
                                            />
                                        </span>
                                        <span className="radio-label">Active</span>
                                    </div>
                                    <div className="radio-buttons-and-label ">
                                        <span>
                                            <Input
                                                className="radio-btn"
                                                defaultValue="option1"
                                                id="primaryActive"
                                                name="primaryActiveField"
                                                type="radio"
                                                color="red"
                                                value={primaryIsActiveField}
                                                onChange={(e) => setPrimaryIsActiveField(0)}
                                            />
                                        </span>
                                        <span className="radio-label">Inactive</span>
                                    </div>
                                </div> */}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <div className="exit-btn-container mr-0">
                            {
                                sessionStorage.getItem("currentEditUserId") &&
                                <span
                                    style={{
                                        fontSize: '16px',
                                        color: '#EF8157',
                                        fontWeight: 'bold',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => { setResetEmail(emailField), handleClickOpen() }}
                                >
                                    Send Password Reset
                                </span>
                            }
                            <Button
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD', marginLeft: "16px" }}
                                onClick={createInsurerOrBrokerHandler} color="info" size="sm"
                            >
                                {sessionStorage.getItem("currentEditUserId") ? "Update" : "Save"}
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Col>

            <ResetEmailModal
                handleClose={handleClose}
                open={open}
                oldEmail={resetEmail}
            />
        </>
    );
}

export default AddNewInsurerOrBroker;
