import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import baseURL from 'App/Config/baseUrl';
import { ChangeDashboardNameForNavbar } from 'App/Screens/ExternalUsers/Store/ExternalUsersSlice';
import { toast } from 'react-toastify';
// import baseURL from '../../../Config/baseURL';
// import axios from 'axios';
// import { apiInstance, apiNoLoaderInstance } from '../../../Config/AxiosInstances';

export const SignIn = createAsyncThunk('auth/SignIn', async (params, { dispatch, getState }) => {
    const { email, password } = params?.data;
    let result = await apiInstance.post('/api/user-login', {
        'email': email,
        'password': password,
    }).then(function (response) {
        console.log("🚀 ~ file: authSlice.js:16 ~ SignIn ~ response:", response)
        return response
    }).catch(function (error) {
        console.log("🚀 ~ file: authSlice.js:19 ~ SignIn ~ error:", error.response)
        if (error.response.status === 400 && email !== "" && password !== "") {
            toast(error.response.data.message)
        }
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: authSlice.js ~ line 19 ~ SignIn ~ result", result)
    if (status === 200) {
        window.sessionStorage.setItem("token", data?.data?.token);
        window.sessionStorage.setItem("userData", JSON.stringify(data?.data));
        data?.data?.role == "super-admin" || data?.data?.role == "AWS Staff" ? params.history.push("/admin/dashboard") : params.history.push("/user/dashboard")

        data?.data?.role == "Surveyor" ? dispatch(ChangeDashboardNameForNavbar("Surveyor Hub")) : data?.data?.role == "Broker" ? dispatch(ChangeDashboardNameForNavbar("Broker Hub")) : data?.data?.role == "Insurer" ? dispatch(ChangeDashboardNameForNavbar("Insurer Hub")) : data?.data?.role == "Developer" ? dispatch(ChangeDashboardNameForNavbar("Developer Hub")) : data?.data?.role == "Contractor" ? dispatch(ChangeDashboardNameForNavbar("Contractor Hub")) : data?.data?.role == "Financial Interest" ? dispatch(ChangeDashboardNameForNavbar("Financial Interest Hub")) : dispatch(ChangeDashboardNameForNavbar("Sub Contractor Hub"))
    }
    return { data, status }
})

export const CheckLoginUser = createAsyncThunk('auth/CheckLoginUser', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/user-check', {
        'token': params,
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const LogoutUser = createAsyncThunk('auth/LogoutUser', async (params, { dispatch, getState }) => {
    // const { email, password } = params?.data;

    let result = await apiInstance.get(`/api/logout`).then(function (response) {
        window.sessionStorage.removeItem("token");
        window.sessionStorage.removeItem("userData");
        params.history.push("/auth/login")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetUserDataById = createAsyncThunk('auth/GetUserDataById', async (params, { dispatch, getState }) => {
    // const { email, password } = params?.data;

    let result = await apiInstance.get(`/api/get-user/${params?.id}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })
    console.log("🚀 ~ file: authSlice.js:49 ~ result ~ result", result)

    const { data, status } = result
    if (data?.error) {
        params?.history.push("/auth/login")
    }
    return { data, status }
})

export const GetContratorDetailForDevelopmentActivity = createAsyncThunk('auth/GetContratorDetailForDevelopmentActivity', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/get-user/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetUserDetailForTopLeftCard = createAsyncThunk('auth/GetUserDetailForTopLeftCard', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-user-details/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const setExternalUserPassword = createAsyncThunk('auth/setExternalUserPassword', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post(`/api/set-password/${params?.id}`, {
        ...params?.data
    }).then(function (response) {
        // toast("Record updated successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    if (status === 200) {
        params.history.push("/auth/login")
    }
    return { data, status }
})
// export const Register = createAsyncThunk('auth/Register', async (params, { dispatch, getState }) => {
//     try {
//         // const { email, firstname, lastname, password } = params

//         // let result = await apiInstance.post('/api/signup', {
//         //     'first_name': firstname,
//         //     'last_name': lastname,
//         //     'email': email,
//         //     'password': password,
//         // }).then(function (response) {
//         //     return response
//         // }).catch(function (error) {
//         //     return error.response
//         // })

//         // const { data, status } = result
//         // return { data, status }

//     } catch (error) {
//         console.error("ERROR")
//         console.error(error)
//     }
// })
// export const Logout = createAsyncThunk('auth/Logout', async (params, { dispatch, getState }) => {
//     try {
//         // let result = await axios({
//         //     baseURL: baseURL,
//         //     timeout: 10000,
//         //     method: 'post',
//         //     url: '/api/logout',
//         //     headers: {
//         //         'Accept': 'Application/json',
//         //         'Access-Control-Max-Age': 0,
//         //         'Authorization': `Bearer ${getState().userData?.userData?.token}`
//         //     }
//         // }).then(function (response) {
//         //     return response
//         // }).catch(function (error) {
//         //     return error.response
//         // })

//         // const { data, status } = result
//         // return { data, status }

//     } catch (error) {
//         console.error("ERROR")
//         console.error(error)
//     }
// })
export const Logout = createAsyncThunk('auth/Logout', async (params, { dispatch, getState }) => {
    try {

        let status = 200;
        let data = {
            user: {
                email,
                password,
                uid: 1
            },
            token: 'FakeTokentoAccessUserData'
        }
        params.history.push("/admin/dashboard")

        return { data, status }


        // let result = await axios({
        //     baseURL: baseURL,
        //     timeout: 10000,
        //     method: 'post',
        //     url: '/api/logout',
        //     headers: {
        //         'Accept': 'Application/json',
        //         'Access-Control-Max-Age': 0,
        //         'Authorization': `Bearer ${getState().userData?.userData?.token}`
        //     }
        // }).then(function (response) {
        //     return response
        // }).catch(function (error) {
        //     return error.response
        // })

        // const { data, status } = result
        // return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

export const ForgetPasswordSendEmail = createAsyncThunk('auth/ForgetPasswordSendEmail', async (params, { dispatch, getState }) => {
    const formData = new FormData()
    formData.append("email", params)
    let result = await apiInstance.post('/api/send-email', formData).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })
    const { data, status } = result
    return { data, status }
})

export const SendPasswordReset = createAsyncThunk('auth/SendPasswordReset', async (params, { dispatch, getState }) => {
    const formData = new FormData()
    const { old_email, new_email } = params?.data
    formData.append("old_email", old_email)
    formData.append("new_email", new_email)
    let result = await apiInstance.post('/api/admin/send-password-reset', formData).then(function (response) {
        return response
    }).catch(function (error) {
        toast(error.response.data.error)
        return error.response
    })
    const { data, status } = result
    if(status == 200) {
        toast("Email send to the user.")
    }
    return { data, status }
})