import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetSingleDefectDetail = createAsyncThunk('DefectDetail/GetSingleDefectDetail', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-single-defect/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const MakeStakeholderResponse = createAsyncThunk('DefectDetail/MakeStakeholderResponse', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post(`/api/admin/response-defect/${params.id}`, { ...params.data }).then(function (response) {
        toast("Reponse send.")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const MakeSurveyorResponse = createAsyncThunk('DefectDetail/MakeSurveyorResponse', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post(`/api/admin/replied-defect`, { ...params }).then(function (response) {
        toast("Surveyor response is added.")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})
