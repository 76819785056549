import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetStakeHolderType = createAsyncThunk('stakeHolder/GetStakeHolderType', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/stake-holder-type').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetAllSurveyor = createAsyncThunk('surveyor/GetAllSurveyor', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-all-surveyor').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 24 ~ GetBusinessStatus ~ result", result)
    return { data, status }
})

export const GetSurveyorBusinessStatus = createAsyncThunk('surveyor/GetSurveyorBusinessStatus', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/business-status').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 24 ~ GetBusinessStatus ~ result", result)
    return { data, status }
})

export const GetSurveyorQualification = createAsyncThunk('surveyor/GetSurveyorQualification', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/qualification-type').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewSurveyorApi.js ~ line 36 ~ GetStakeHolderQualification ~ result", result)
    return { data, status }
})

export const GetSurveyorInsurer = createAsyncThunk('surveyor/GetSurveyorInsurer', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/insurer-type').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const CreateSurveyor = createAsyncThunk('surveyor/CreateSurveyor', async (params, { dispatch, getState }) => {
    console.log("🚀 ~ file: AddNewSurveyorApi.js ~ line 66 ~ CreateSurveyor ~ params", params)
    let result = await apiInstance.post('/api/admin/create/surveyor', { ...params?.data }).then(function (response) {
        toast("User created successfully")
        params.clearForm()
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewSurveyorApi.js ~ line 113 ~ CreateSurveyor ~ result", result)
    return { data, status }
})

export const UpdateSurveyor = createAsyncThunk('surveyor/UpdateSurveyor', async (params, { dispatch, getState }) => {
    console.log("🚀 ~ file: AddNewSurveyorApi.js ~ line 146 ~ UpdateSurveyor ~ params", params)
    let result = await apiInstance.post(`api/admin/update/surveyor/${params.id}`, {
        ...params?.data
    }).then(function (response) {
        toast("Record updated successfully")
        params?.clearForm()
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewSurveyorApi.js ~ line 220 ~ UpdateSurveyor ~ result", result)
    return { data, status }
})
