import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const addDevelopmentSlice = createSlice({
    name: 'stakeHolder',
    initialState: {
        developers: [],
        mainContractors: [],
        allDevelopments: [],
        stakeholderTypeBusinessName: [],
        developmentGeneralDetails: null,
        developmentGeneralDetailsError: {},
        developmentSiteInvestigation: null,
        developmentSiteInvestigationError: [],
        developmentBuildFactors: null,
        developmentBuildFactorsError: [],
        developmentRefurbishment: null,
        developmentRefurbishmentError: [],
        developmentSurveyRequirements: null,
        developmentSurveyRequirementsError: [],
        value: 0,
        developerPrimaryContact: null,
        developerOtherContact: null,
        mainContractorPrimaryContact: null,
        mainContractorOtherContact: null,
        // getSingleSurveyorDetails: null,
        stakeholderTypeBusinessNamePrimaryContact: null,
        insurerBrokerTypeBusinessNamePrimaryContact: null,
        insurerBrokerPrimaryAndOtherContact: null,
        insurerBrokerTypeBusinessNameOtherContact: null,
        stakeholderTypeBusinessNameOtherContact: null,
        createDevelopmentAssignToSurveyor: null,
        getDevelopmentGeneralDetails: null,
        getDevelopmentSiteInvestigationDetails: null,
        getDevelopmentBuildFactorDetails: null,
        getDevelopmentRefurbishmentDetails: null,
        getDevelopmentSurveyRequirementDetails: null,
        getDeveloperTrading: [],
        getDeveloperUsersByBusinessDetailId: [],
        getContractorTrading: [],
        getContractorUsersByBusinessDetailId: [],
        getInsurerTrading: [],
        getInsurerOrBrokerUsersByBusinessDetailId: [],
        getBrokerTrading: [],
        getFinancialInterestTrading: [],
        getSubContractorTrading: [],
        getUsersByBusinessDetailId: [],
        developmentMode: "add",
    },
    reducers: {
        ChangeDevelopmentStepValue: (state, action) => {
            state.value = action.payload
        },

        ChangeDevelopmentMode: (state, action) => {
            state.developmentMode = action.payload
            state.developmentGeneralDetailsError = {}
            state.developmentSiteInvestigationError = []
            state.developmentBuildFactorsError = []
            state.developmentRefurbishmentError = []
            state.developmentSurveyRequirementsError = []
        }
    },
    extraReducers: {

        ['addDevelopment/GetAllDeveloper/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.developers = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.developers = data
            }
        },

        ['addDevelopment/GetDevelopments/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.allDevelopments = data?.data
            }
            else if (status >= 400 && status < 500) {
                // state.allDevelopments = data
            }
        },

        ['addDevelopment/GetMainContractors/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.mainContractors = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.mainContractors = data
            }
        },

        ['addDevelopment/GetStakeholderTypeBusinessName/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.stakeholderTypeBusinessName = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.stakeholderTypeBusinessName = data
            }
        },

        ['addDevelopment/GetDeveloperPrimaryContact/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.developerPrimaryContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.developerPrimaryContact = data
            }
        },

        ['addDevelopment/GetDeveloperOtherContact/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.developerOtherContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.developerOtherContact = data
            }
        },

        ['addDevelopment/GetContractorPrimaryContact/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.mainContractorPrimaryContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.mainContractorPrimaryContact = data
            }
        },

        ['addDevelopment/GetContractorOtherContact/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.mainContractorOtherContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.mainContractorOtherContact = data
            }
        },

        ['addDevelopment/GetSingleSurveyorDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.mainContractorPrimaryContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.mainContractorPrimaryContact = data
            }
        },

        ['addDevelopment/CreateDevelopmentGeneralDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.developmentGeneralDetails = data?.data
                state.value = state.value + 1
            }
            else if (status >= 400 && status < 500) {

                state.developmentGeneralDetailsError = data?.error
                // state.developmentGeneralDetails = data
            }
        },

        ['addDevelopment/CreateDevelopmentSiteInvestigation/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.developmentSiteInvestigation = data?.data
                state.value = state.value + 1
            }
            else if (status >= 400 && status < 500) {

                state.developmentSiteInvestigationError = data?.error
            }
        },

        ['addDevelopment/CreateDevelopmentBuildFactors/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.developmentBuildFactors = data?.data
                state.value = state.value + 1
            }
            else if (status >= 400 && status < 500) {

                state.developmentBuildFactorsError = data?.error
            }
        },

        ['addDevelopment/CreateDevelopmentRefurbishment/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.developmentRefurbishment = data?.data
                state.value = state.value + 1
            }
            else if (status >= 400 && status < 500) {

                state.developmentRefurbishmentError = data?.error
            }
        },

        ['addDevelopment/CreateDevelopmentSurveyRequirements/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.developmentSurveyRequirements = data?.data
            }
            else if (status >= 400 && status < 500) {

                state.developmentSurveyRequirementsError = data?.error
            }
        },

        ['addDevelopment/GetStakeholderTypeBusinessNamePrimaryContact/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.stakeholderTypeBusinessNamePrimaryContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.stakeholderTypeBusinessNamePrimaryContact = data
            }
        },

        ['addDevelopment/GetInsurerBrokerTypeBusinessNamePrimaryContact/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.insurerBrokerTypeBusinessNamePrimaryContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.insurerBrokerTypeBusinessNamePrimaryContact = data
            }
        },

        ['addDevelopment/GetInsurerBrokerPrimaryAndOtherContact/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.insurerBrokerPrimaryAndOtherContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.insurerBrokerPrimaryAndOtherContact = data
            }
        },

        ['addDevelopment/GetInsurerBrokerTypeBusinessNameOtherContact/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.insurerBrokerTypeBusinessNameOtherContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.insurerBrokerTypeBusinessNameOtherContact = data
            }
        },

        ['addDevelopment/GetStakeholderTypeBusinessNameOtherContact/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.stakeholderTypeBusinessNameOtherContact = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.stakeholderTypeBusinessNameOtherContact = data
            }
        },

        ['addDevelopment/CreateDevelopmentAssignToSurveyor/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.createDevelopmentAssignToSurveyor = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.createDevelopmentAssignToSurveyor = data
            }
        },

        ['addDevelopment/GetDevelopmentGeneralDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getDevelopmentGeneralDetails = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getDevelopmentGeneralDetails = data
            }
        },

        ['addDevelopment/GetDevelopmentSiteInvestigationDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getDevelopmentSiteInvestigationDetails = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getDevelopmentSiteInvestigationDetails = data
            }
        },

        ['addDevelopment/GetDevelopmentBuildFactorDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getDevelopmentBuildFactorDetails = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getDevelopmentBuildFactorDetails = data
            }
        },

        ['addDevelopment/GetDevelopmentRefurbishmentDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getDevelopmentRefurbishmentDetails = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getDevelopmentRefurbishmentDetails = data
            }
        },

        ['addDevelopment/GetDevelopmentSurveyRequiremntDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getDevelopmentSurveyRequirementDetails = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getDevelopmentSurveyRequirementDetails = data
            }
        },

        ['addDevelopment/GetDeveloperTrading/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getDeveloperTrading = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getDeveloperTrading = data
            }
        },

        ['addDevelopment/GetDeveloperUsersByBusinessDetailId/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getDeveloperUsersByBusinessDetailId = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getDeveloperUsersByBusinessDetailId = data
            }
        },

        ['addDevelopment/GetContractorTrading/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getContractorTrading = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getContractorTrading = data
            }
        },

        ['addDevelopment/GetContractorUsersByBusinessDetailId/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getContractorUsersByBusinessDetailId = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getContractorUsersByBusinessDetailId = data
            }
        },

        ['addDevelopment/GetInsurerTrading/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getInsurerTrading = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getInsurerTrading = data
            }
        },

        ['addDevelopment/GetInsurerOrBrokerUsersByBusinessDetailId/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getInsurerOrBrokerUsersByBusinessDetailId = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getInsurerOrBrokerUsersByBusinessDetailId = data
            }
        },

        ['addDevelopment/GetBrokerTrading/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getBrokerTrading = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getBrokerTrading = data
            }
        },

        ['addDevelopment/GetFinancialInterestTrading/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getFinancialInterestTrading = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getFinancialInterestTrading = data
            }
        },

        ['addDevelopment/GetSubContractorTrading/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getSubContractorTrading = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getSubContractorTrading = data
            }
        },

        ['addDevelopment/GetUsersByBusinessDetailId/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.getUsersByBusinessDetailId = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getUsersByBusinessDetailId = data
            }
        },
    },
});

export const {
    ChangeDevelopmentStepValue, ChangeDevelopmentMode
} = addDevelopmentSlice.actions;

export default addDevelopmentSlice.reducer;