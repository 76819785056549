import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Autocomplete, Box, Checkbox, CircularProgress, InputAdornment, List, ListItemButton, ListItemText, OutlinedInput } from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, CardFooter, Col, Row } from "reactstrap";
import './GeneralDetails.css';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { CreateDevelopmentGeneralDetails, GetDeveloperPrimaryContact, GetDevelopers, GetMainContractors, GetDeveloperOtherContact, GetContractorPrimaryContact, GetContractorOtherContact, GetStakeholderTypeBusinessName, GetStakeholderTypeBusinessNamePrimaryContact, GetStakeholderTypeBusinessNameOtherContact, GetInsurerBrokerTypeBusinessNameOtherContact, GetInsurerBrokerTypeBusinessNamePrimaryContact, GetInsurerBrokerPrimaryAndOtherContact, GetDeveloperTrading, GetContractorTrading, GetInsurerTrading, GetBrokerTrading, GetFinancialInterestTrading, GetSubContractorTrading, GetDeveloperUsersByBusinessDetailId, GetContractorUsersByBusinessDetailId, GetInsurerOrBrokerUsersByBusinessDetailId, UpdateDevelopmentGeneralDetails } from '../AddNewDevelopment/Store/AddNewDevelopmentApi';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GetAllStakeHolder, GetStakeHolderType, GetStakeHolderTypeForGeneralDetails } from '../AddNewStakeHolder/Store/AddNewStakeHolderApi';
import { ChangeDevelopmentStepValue } from '../AddNewDevelopment/Store/AddNewDevelopmentSlice';
import { GetAllBroker, GetAllInsurer } from '../AddNewInsurerOrBroker/Store/AddNewInsurerOrBrokerApi';
import { FetchifyApiAddress } from '../Dashboard/Store/DashboardApi';

function GeneralDetails(props) {

    const dispatch = useDispatch()
    const { } = useSelector(state => state.defectDetail)
    const { fetchifyApiAddress, adminSingleDevelopmentDetail } = useSelector(state => state.dashboard)
    const { developers, value, developerPrimaryContact, developerOtherContact, mainContractorPrimaryContact, mainContractorOtherContact, mainContractors, stakeholderTypeBusinessName, stakeholderTypeBusinessNamePrimaryContact, getDevelopmentGeneralDetails, developmentMode, insurerBrokerPrimaryAndOtherContact, developmentGeneralDetailsError, getDeveloperTrading, getDeveloperUsersByBusinessDetailId, getContractorUsersByBusinessDetailId, getContractorTrading, getInsurerTrading, getBrokerTrading, getFinancialInterestTrading, getSubContractorTrading, } = useSelector(state => state.AddNewDevelopment)
    console.log("🚀 ~ file: GeneralDetails.js ~ line 35 ~ GeneralDetails ~ developerOtherContact", developerOtherContact?.stake_holder?.other_contact)
    const { stakeHolderType, stakeHolderTypeForGeneralDetails, allStakeHolder } = useSelector(state => state?.AddNewStakeHolder)
    const { allInsurer, allBroker } = useSelector(state => state?.AddNewInsurerOrBroker)

    const [visibleStatus, setVisibleStatus] = useState('');
    const [developmentName, setDevelopmentName] = useState('');
    const [addAnotherContact, setAddAnotherContact] = useState('');
    const [stackholderType, setStackholderType] = useState('');
    const [totalStakeholders, setTotalStakeholders] = useState(1);
    const [totalInsurerOrBroker, setTotalInsurerOrBroker] = useState(1);
    console.log("🚀 ~ file: GeneralDetails.js ~ line 40 ~ GeneralDetails ~ totalStakeholders", totalStakeholders)
    const [differentContractorDetails, setDifferentContractorDetails] = useState('');

    const [policyNumberField, setPolicyNumberField] = useState("")
    const [siteDevelopmentNameField, setSiteDevelopmentNameField] = useState("")
    const [siteAddressField, setSiteAddressField] = useState("")
    const [siteAddressFieldData, setSiteAddressFieldData] = useState([])
    const [retrieveCompleteAddressFieldData, setRetrieveCompleteAddressFieldData] = useState(null)
    const [retrieveAddressField1, setRetrieveAddressField1] = useState("")
    const [retrieveAddressField2, setRetrieveAddressField2] = useState("")
    const [retrieveTownCity, setRetrieveTownCity] = useState("")
    const [retrieveCountyState, setRetrieveCountyState] = useState("")
    const [retrieveCountry, setRetrieveCountry] = useState("")
    const [siteAddressFieldDataShow, setSiteAddressFieldDataShow] = useState(false)
    const [sitePostcodeField, setSitePostcodeField] = useState("")
    const [developerNameField, setDeveloperNameField] = useState("")
    const [developerPrimaryContactNameField, setDeveloperPrimaryContactNameField] = useState("")
    const [addOtherDeveloperContactField, setAddOtherDeveloperContactField] = useState(0);
    const [developerOtherContactField, setDeveloperOtherContactField] = useState([]);
    const [areContractorDetailsDifferentField, setAreContractorDetailsDifferentField] = useState(0);
    const [mainContractorNameField, setMainContractorNameField] = useState(0)
    const [mainContractorPrimaryContactNameField, setMainContractorPrimaryContactNameField] = useState("")
    const [addOtherContractorContactField, setAddOtherContractorContactField] = useState(0);
    const [mainContractorOtherContactField, setMainContractorOtherContactField] = useState([]);
    const [addOtherStakeholderField, setAddOtherStakeholderField] = useState(0);
    const [stakeholder_type_id, setStakeholderTypeField] = useState('');
    const [company_name, setStakeholderCompanyBusinessNameField] = useState('');
    const [primay_contact, setStakeholderPrimaryContactNameField] = useState('');
    const [stakeholder_another_contact, setAddOtherStakeholderContactField] = useState('');
    const [stakeholder_another_contact_id, setStakeholderOtherContactField] = useState('');
    const [siteContactNameField, setSiteContactNameField] = useState("");
    const [otherStakeholderContactData, setOtherStakeholderContactData] = useState({});
    const [otherInsurerOrBrokerContactData, setOtherInsurerOrBrokerContactData] = useState({});
    console.log("🚀 ~ otherInsurerOrBrokerContactData:", otherInsurerOrBrokerContactData)
    const [otherStakeholderBusinessNameData, setOtherStakeholderBusinessNameData] = useState({});
    const [addInsurerOrBrokerField, setAddInsurerOrBrokerField] = useState('');
    const [insurerBrokerList, setInsurerBrokerList] = useState([]);

    const [developerNameForSiteContactName, setDeveloperNameForSiteContactName] = useState("")
    const [mainContractorNameForSiteContactName, setMainContractorNameForSiteContactName] = useState("")

    useEffect(() => {
        if (developmentMode !== "add" || developmentMode === "previousBtn") {
            dispatch(GetDeveloperOtherContact(getDevelopmentGeneralDetails?.developer_id))
            setPolicyNumberField(getDevelopmentGeneralDetails?.policy_number || "")
            setSiteDevelopmentNameField(getDevelopmentGeneralDetails?.development_name || "")
            setSiteAddressField(getDevelopmentGeneralDetails?.site_address || "")
            setSitePostcodeField(getDevelopmentGeneralDetails?.site_postcode || "")
            setDeveloperNameField(getDevelopmentGeneralDetails?.developer_id || "")
            setDeveloperPrimaryContactNameField(getDevelopmentGeneralDetails?.d_primary_contact || "")
            setAddOtherDeveloperContactField(getDevelopmentGeneralDetails?.d_another_contact || 0)
            setDeveloperOtherContactField(getDevelopmentGeneralDetails?.developer_another_contacts?.map(d => d?.id) || [])
            setAreContractorDetailsDifferentField(getDevelopmentGeneralDetails?.is_main_contractor || 0)
            setMainContractorNameField(getDevelopmentGeneralDetails?.main_contractor_id || 0)
            setMainContractorPrimaryContactNameField(getDevelopmentGeneralDetails?.main_const_primary_contact || "")
            setAddOtherContractorContactField(getDevelopmentGeneralDetails?.main_const_another_contact || 0)
            setMainContractorOtherContactField(getDevelopmentGeneralDetails?.main_contractor_another_contacts?.map(d => d?.id) || [])
            setAddOtherStakeholderField(getDevelopmentGeneralDetails?.add_further_stakeholder || 0)
            setSiteContactNameField(getDevelopmentGeneralDetails?.selected_primary_contacts ? JSON.parse(getDevelopmentGeneralDetails?.selected_primary_contacts) : "")
            const constructOtherStakeholderData = getDevelopmentGeneralDetails?.another_stakeholder
            setOtherStakeholderFormValues(constructOtherStakeholderData || [])

            setAddInsurerOrBrokerField(getDevelopmentGeneralDetails?.add_insurer_broker)
            const constructOtherInsurerBrokerData = getDevelopmentGeneralDetails?.another_insurer_broker?.map(d => ({
                ...d,
                insurer_another_contact_id: d?.another_cont?.map(e => (e?.id))
            }))
            setOtherInsurerOrBrokerFormValues(constructOtherInsurerBrokerData || [])

            dispatch(GetDeveloperUsersByBusinessDetailId(getDevelopmentGeneralDetails?.developer_id))
            setDeveloperPrimaryContactNameField(getDevelopmentGeneralDetails?.d_primary_contact)
            setMainContractorPrimaryContactNameField(getDevelopmentGeneralDetails?.main_const_primary_contact)
            dispatch(GetContractorUsersByBusinessDetailId(getDevelopmentGeneralDetails?.main_contractor_id))
        }
    }, [getDevelopmentGeneralDetails])

    const [otherStakeholderFormValues, setOtherStakeholderFormValues] = useState([{
        stakeholder_type_id: "",
        primay_contact: "",
        primaryContactArray: [],
        company_name: "",
        stakeholder_another_contact: "",
        stakeholder_another_contact_id: []
    }])

    const [otherInsurerOrBrokerFormValues, setOtherInsurerOrBrokerFormValues] = useState([{
        insurer_broker_type_id: "",
        insurer_broker_id: "",
        company_name: "",
        primay_contact: "",
        primaryContactArray: [],
        is_insurer_broker_another_contact: "",
        insurer_another_contact_id: []
    }])

    useEffect(() => {
        dispatch(GetDevelopers())
        // dispatch(GetStakeHolderType())
        dispatch(GetStakeHolderTypeForGeneralDetails())
    }, [])

    useEffect(() => {
        dispatch(GetAllInsurer())
        dispatch(GetAllBroker())
    }, [])

    useEffect(() => {
        dispatch(GetMainContractors())
    }, [])

    useEffect(() => {
        dispatch(GetDeveloperTrading())
        dispatch(GetContractorTrading())
        dispatch(GetInsurerTrading())
        dispatch(GetBrokerTrading())
        dispatch(GetFinancialInterestTrading())
        dispatch(GetSubContractorTrading())
    }, [])

    useEffect(() => {
        developerPrimaryContact !== null && setDeveloperPrimaryContactNameField(developerPrimaryContact?.stake_holder?.username)
        // developmentMode == "edit" && dispatch(GetDeveloperUsersByBusinessDetailId(getDevelopmentGeneralDetails?.developer_id))
        // developerPrimaryContact !== null && setDeveloperPrimaryContactNameField(getDevelopmentGeneralDetails?.d_primary_contact)
    }, [developerPrimaryContact])

    useEffect(() => {
        // mainContractorPrimaryContact !== null && setMainContractorPrimaryContactNameField(mainContractorPrimaryContact?.stake_holder?.username)
        mainContractorPrimaryContact !== null && setMainContractorPrimaryContactNameField(mainContractorPrimaryContact?.stake_holder?.username)
    }, [mainContractorPrimaryContact])

    useEffect(() => {
        // stakeholderTypeBusinessNamePrimaryContact !== null && otherStakeholderFormHandleChange(totalStakeholders, {stakeholderTypeBusinessNamePrimaryContact})
        // stakeholderTypeBusinessNamePrimaryContact !== null && setOtherStakeholderFormValues(totalStakeholders, { stakeholderTypeBusinessNamePrimaryContact })
    }, [stakeholderTypeBusinessNamePrimaryContact])

    const handleChangeVisibleStatus = (event) => {
        setVisibleStatus(event.target.value);
    };
    const handleChangeDevelopmentName = (event) => {
        setDevelopmentName(event.target.value);
    };
    const handleAreContractorDetailsDifferent = (event) => {
        setAreContractorDetailsDifferentField(event.target.value);
        // if (event.target.value == 0) {
        //     dispatch(GetMainContractors())
        // }
    };
    const handleAddAnotherContact = (event) => {
        setAddAnotherContact(event.target.value);
    };
    const handleChangeStackholderType = (event) => {
        setStackholderType(event.target.value);
    };
    const handleChangeDifferentContractorDetails = (event) => {
        setDifferentContractorDetails(event.target.value);
    };



    function RenderOtherContacts() {
        return [...Array(totalContacts)]?.map((item, key) => {
            return (
                <>
                    {totalContacts != 1 &&
                        <Col className="mt-2" lg="12" sm="12">
                            <span className='text-sm font-bold'>{`Contact ${key + 1}`}</span>
                        </Col>
                    }
                    <Col className="mt-2" lg="6" sm="12">
                        <TextField
                            label="Describe contact role?"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                        />
                    </Col>
                    <Col className="mt-2" lg="6" sm="12">
                        <TextField
                            label="Contact Name"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                        />
                    </Col>
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Contact Trading Address"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                        />
                    </Col>
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Contact Postcode"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            type='number'
                        />
                    </Col>
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Individual Contact Name"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                        />
                    </Col>
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Contact Telephone Number"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            type='number'
                        />
                    </Col>
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Contact Email Address"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                        />
                    </Col>
                    <Col className="mt-4 " lg="12" sm="12">
                        <hr />
                    </Col>
                </>
            )
        })
    }

    let otherStakeholderFormHandleChange = (i, e) => {
        let newOtherStakeholderFormFormValues = [...otherStakeholderFormValues];
        newOtherStakeholderFormFormValues[i][e?.target?.name || e?.field_name] = e?.target?.value || e?.field_data;
        setOtherStakeholderFormValues(newOtherStakeholderFormFormValues);
    }

    let otherInsurerOrBrokerFormHandleChange = (i, e) => {
        let newOtherInsurerOrBrokerFormFormValues = [...otherInsurerOrBrokerFormValues];
        newOtherInsurerOrBrokerFormFormValues[i][e?.target?.name || e?.field_name] = e?.target?.value || e?.field_data;
        setOtherInsurerOrBrokerFormValues(newOtherInsurerOrBrokerFormFormValues);
    }

    let addOtherStakeholderFormFields = () => {
        setOtherStakeholderFormValues([...otherStakeholderFormValues, {
            stakeholder_type_id: "",
            primay_contact: "",
            company_name: "",
            stakeholder_another_contact: "",
            stakeholder_another_contact_id: []
        }])
        setTotalStakeholders(totalStakeholders + 1)
    }

    let addOtherInsurerOrBrokerFormFields = () => {
        setOtherInsurerOrBrokerFormValues([...otherInsurerOrBrokerFormValues, {
            insurer_broker_type_id: "",
            insurer_broker_id: "",
            company_name: "",
            is_insurer_broker_another_contact: "",
            insurer_another_contact_id: []
        }])
        setTotalInsurerOrBroker(totalInsurerOrBroker + 1)
    }

    function RenderStakeholder() {
        return (

            otherStakeholderFormValues?.length > 0 && otherStakeholderFormValues?.map((element, index) => (
                <>
                    {totalStakeholders != 1 &&
                        <Col className="mt-2" lg="12" sm="12">
                            <span className='text-sm font-bold'>{`Stakeholder ${index + 1}`}</span>
                        </Col>
                    }
                    <Col className="mt-4" lg="6" sm="12">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" size="small">Stakeholder Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Other Contact"
                                name="stakeholder_type_id"
                                value={element?.stakeholder_type_id}
                                onChange={async (e) => {
                                    otherStakeholderFormHandleChange(index, e);
                                    const result = await dispatch(GetStakeholderTypeBusinessName(e.target.value))
                                    console.log("🚀 ~ file: GeneralDetails.js ~ line 247 ~ onChange={ ~ result", result)
                                    setOtherStakeholderBusinessNameData({
                                        ...otherStakeholderBusinessNameData,
                                        [index]: result?.payload?.data?.data
                                    })
                                }}
                                size="small"
                            >
                                {
                                    stakeHolderTypeForGeneralDetails?.length > 0 && stakeHolderTypeForGeneralDetails?.map(stakeHolder => {
                                        const { id, stake_holder_type_name } = stakeHolder
                                        return (
                                            <MenuItem key={id} value={id}>{stake_holder_type_name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        {developmentGeneralDetailsError[`another_stakeholder.${index}.stakeholder_type_id`] && <p className="text-danger">{developmentGeneralDetailsError[`another_stakeholder.${index}.stakeholder_type_id`]}</p>}
                    </Col>
                    <Col className="mt-4" lg="6" sm="12">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" size="small">Stakeholder Company/Business Name</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Other Contact"
                                name="company_name"
                                value={element?.company_name}
                                onChange={async (e) => {
                                    otherStakeholderFormHandleChange(index, e);
                                    const result = await dispatch(GetInsurerOrBrokerUsersByBusinessDetailId(e.target.value))
                                    const result2 = await dispatch(GetStakeholderTypeBusinessNameOtherContact({ trading_id: e.target.value, selected_id: element?.primay_contact }))
                                    console.log("🚀 ~ result2:", result2)
                                    otherStakeholderFormHandleChange(index, { field_name: "primaryContactArray", field_data: result?.payload?.data?.data });
                                    setOtherStakeholderContactData(
                                        {
                                            ...otherStakeholderContactData,
                                            [index]: result2?.payload?.data?.data?.stake_holder
                                        }
                                    )
                                    console.log("🚀 ~ file: GeneralDetails.js ~ line 269 ~ onChange={ ~ result2", result2)
                                }}
                                size="small"
                            >
                                {
                                    otherStakeholderBusinessNameData[index]?.map(entity => {
                                        const { id, stake_holder_id, trading_name } = entity

                                        return (
                                            <MenuItem value={id}>{trading_name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        {developmentGeneralDetailsError[`another_stakeholder.${index}.company_name`] && <p className="text-danger">{developmentGeneralDetailsError[`another_stakeholder.${index}.company_name`]}</p>}
                    </Col>

                    <Col className="mt-4" lg="6" sm="12">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" size="small">Primary Contact Name</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Primary Contact Name"
                                value={element?.primay_contact}
                                name="primay_contact"
                                onChange={async (e) => {
                                    otherStakeholderFormHandleChange(index, e)
                                }}
                                size="small"
                            >
                                {
                                    element?.primaryContactArray?.map(contact => {
                                        const { id, username } = contact || {}

                                        return (
                                            <MenuItem value={id}>{username}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        {developmentGeneralDetailsError[`another_stakeholder.${index}.primay_contact`] && <p className="text-danger">{developmentGeneralDetailsError[`another_stakeholder.${index}.primay_contact`]}</p>}
                    </Col>

                    {/* <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Primary Contact Name"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            name="primay_contact"
                            value={element?.primay_contact}
                            onChange={async (e) => {
                                otherStakeholderFormHandleChange(index, e)
                            }}
                        />
                        {developmentGeneralDetailsError[`another_stakeholder.${index}.primay_contact`] && <p className="text-danger">{developmentGeneralDetailsError[`another_stakeholder.${index}.primay_contact`]}</p>}
                    </Col> */}
                    <Col className="mt-4" lg="6" sm="12">
                        <FormControl>
                            <FormLabel id="otherContactStatus">Add another contact?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="otherContactStatus"
                                name="stakeholder_another_contact"
                                value={element?.stakeholder_another_contact}
                                onChange={async (e) => {
                                    otherStakeholderFormHandleChange(index, e)
                                    // const result = await dispatch(GetStakeholderTypeBusinessNameOtherContact(e.target.value));
                                    // otherStakeholderFormHandleChange(index, { field_name: "stakeholder_another_contact_id", field_data: result?.payload?.data?.data?.stake_holder })
                                    // console.log("🚀 ~ file: GeneralDetails.js ~ line 312 ~ onChange={ ~ result", result)
                                    const result2 = await dispatch(GetStakeholderTypeBusinessNameOtherContact({ trading_id: element?.company_name, selected_id: element?.primay_contact }))
                                    console.log("🚀 ~ onChange={ ~ result2:", result2)
                                    setOtherStakeholderContactData(
                                        {
                                            ...otherStakeholderContactData,
                                            [index]: result2?.payload?.data?.data
                                        }
                                    )
                                }}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                <FormControlLabel value={0} control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                    {element?.stakeholder_another_contact == 1 &&
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Other Contact</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    name="stakeholder_another_contact_id"
                                    value={element?.stakeholder_another_contact_id}
                                    onChange={(e) => otherStakeholderFormHandleChange(index, e)}
                                    input={<OutlinedInput label="Other Contact" />}
                                    renderValue={(selected) => {
                                        return selected
                                            .map((id) => {
                                                const selectedContact = otherStakeholderContactData?.[index]?.find((contact) => contact.id === id);
                                                if (selectedContact) {
                                                    const { fore_name, sur_name } = selectedContact;
                                                    return `${fore_name} ${sur_name}`;
                                                }
                                                return "";
                                            })
                                            .join(", ");
                                    }}
                                >
                                    {
                                        otherStakeholderContactData[index]?.map(contact => {
                                            const { id, fore_name, sur_name } = contact
                                            return (
                                                <MenuItem value={id}>
                                                    <Checkbox checked={element["stakeholder_another_contact_id"].indexOf(`${fore_name} ${sur_name}`) > -1} />
                                                    <ListItemText primary={`${fore_name} ${sur_name}`} />
                                                </MenuItem>
                                            )
                                        })
                                    }

                                    {/* <MenuItem value={20}>
                                        <Checkbox checked={element["stakeholder_another_contact_id"].indexOf("Amazon") > -1} />
                                        <ListItemText primary={"Amazon"} />
                                    </MenuItem> */}

                                </Select>
                            </FormControl>
                        </Col>
                    }
                </>
            ))

        )
    }

    function RenderInsurerOrBroker() {
        return (

            otherInsurerOrBrokerFormValues?.length > 0 && otherInsurerOrBrokerFormValues?.map((element, index) => (
                <>
                    {totalInsurerOrBroker != 1 &&
                        <Col className="mt-2" lg="12" sm="12">
                            <span className='text-sm font-bold'>{`Insurer/Broker ${index + 1}`}</span>
                        </Col>
                    }
                    <Col className="mt-4" lg="6" sm="12">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" size="small">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Other Contact"
                                name="insurer_broker_type_id"
                                value={element?.insurer_broker_type_id}
                                onChange={async (e) => {
                                    otherInsurerOrBrokerFormHandleChange(index, e);
                                    const result = e?.target?.value == 1 ? await dispatch(GetAllInsurer()) : await dispatch(GetAllBroker())
                                    console.log("🚀 ~ file: GeneralDetails.js ~ line 462 ~ onChange={ ~ result", result)
                                    // TODO: OLD WAY
                                    // setInsurerBrokerList(result?.payload?.data?.data)
                                    // TODO: OLD WAY END HERE
                                    otherInsurerOrBrokerFormHandleChange(index, { field_name: "insurer_another_contact_id", field_data: [] });
                                    console.log("🚀 ~ file: GeneralDetails.js ~ line 247 ~ onChange={ ~ result", result)
                                    // setOtherStakeholderBusinessNameData({
                                    //     ...otherStakeholderBusinessNameData,
                                    //     [index]: result?.payload?.data?.data
                                    // })
                                }}
                                size="small"
                            >
                                <MenuItem key="1" value="1">Insurer</MenuItem>
                                <MenuItem key="2" value="2">Broker</MenuItem>
                                {/* {
                                    stakeHolderType?.map(stakeHolder => {
                                        const { id, stake_holder_type_name } = stakeHolder
                                        return (
                                            <MenuItem key={id} value={id}>{stake_holder_type_name}</MenuItem>
                                        )
                                    })
                                } */}
                            </Select>
                        </FormControl>
                        {developmentGeneralDetailsError[`insurerBroker.${index}.insurer_broker_type_id`] && <p className="text-danger">{developmentGeneralDetailsError[`insurerBroker.${index}.insurer_broker_type_id`]}</p>}
                    </Col>
                    {
                        element?.insurer_broker_type_id == 1 ?
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Insurer/broker Company/Business Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Other Contact"
                                        name="company_name"
                                        value={element?.company_name}
                                        onChange={async (e) => {
                                            otherInsurerOrBrokerFormHandleChange(index, e);
                                            // const result = await dispatch(GetInsurerBrokerPrimaryAndOtherContact(e?.target?.value))
                                            const result = await dispatch(GetInsurerOrBrokerUsersByBusinessDetailId(e?.target?.value))
                                            console.log("🚀 ~ file: GeneralDetails.js ~ line 499 ~ onChange={ ~ result", result)
                                            console.log("🚀 ~ file: GeneralDetails.js ~ line 499 ~ onChange={ ~ broker type", _.isEmpty(result?.payload?.data?.data?.broker_type))
                                            // otherInsurerOrBrokerFormHandleChange(index, { field_name: "primay_contact", field_data: result?.payload?.data?.data?.insurer_type?.username || result?.payload?.data?.data?.broker_type?.username });
                                            otherInsurerOrBrokerFormHandleChange(index, { field_name: "primaryContactArray", field_data: result?.payload?.data?.data || result?.payload?.data?.data });
                                            setOtherInsurerOrBrokerContactData(
                                                {
                                                    ...otherInsurerOrBrokerContactData,
                                                    [index]: result?.payload?.data?.data || result?.payload?.data?.data
                                                }
                                            )
                                        }}
                                        size="small"
                                    >
                                        {
                                            allInsurer?.length > 0 && allInsurer?.map(user => {
                                                const { id, trading_name } = user
                                                return (
                                                    <MenuItem key={id} value={user?.id}>{trading_name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                {developmentGeneralDetailsError[`insurerBroker.${index}.company_name`] && <p className="text-danger">{developmentGeneralDetailsError[`insurerBroker.${index}.company_name`]}</p>}
                            </Col>
                            :
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Insurer/broker Company/Business Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Other Contact"
                                        name="company_name"
                                        value={element?.company_name}
                                        onChange={async (e) => {
                                            otherInsurerOrBrokerFormHandleChange(index, e);
                                            // const result = await dispatch(GetInsurerBrokerPrimaryAndOtherContact(e?.target?.value))
                                            const result = await dispatch(GetInsurerOrBrokerUsersByBusinessDetailId(e?.target?.value))
                                            console.log("🚀 ~ file: GeneralDetails.js ~ line 499 ~ onChange={ ~ result", result)
                                            console.log("🚀 ~ file: GeneralDetails.js ~ line 499 ~ onChange={ ~ broker type", _.isEmpty(result?.payload?.data?.data?.broker_type))
                                            otherInsurerOrBrokerFormHandleChange(index, { field_name: "primaryContactArray", field_data: result?.payload?.data?.data || result?.payload?.data?.data });
                                            setOtherInsurerOrBrokerContactData(
                                                {
                                                    ...otherInsurerOrBrokerContactData,
                                                    [index]: result?.payload?.data?.data || result?.payload?.data?.data
                                                }
                                            )
                                            // console.log("🚀 ~ file: GeneralDetails.js ~ line 269 ~ onChange={ ~ result2", result2)
                                        }}
                                        size="small"
                                    >
                                        {
                                            allBroker?.length > 0 && allBroker?.map(user => {
                                                const { id, trading_name } = user
                                                return (
                                                    <MenuItem key={id} value={user?.id}>{trading_name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                {developmentGeneralDetailsError[`insurerBroker.${index}.company_name`] && <p className="text-danger">{developmentGeneralDetailsError[`insurerBroker.${index}.company_name`]}</p>}
                            </Col>
                    }

                    <Col className="mt-4" lg="6" sm="12">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" size="small">Primary Contact Name</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Primary Contact Name"
                                name="primay_contact"
                                value={element?.primay_contact}
                                onChange={async (e) => {
                                    otherInsurerOrBrokerFormHandleChange(index, e)
                                }}
                                size="small"
                            >
                                {
                                    element?.primaryContactArray?.map(contact => {
                                        // const { id, username } = contact?.insurer_type || contact?.broker_type || {}
                                        const { id, username } = contact || {}

                                        return (
                                            <MenuItem value={id}>{username}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        {developmentGeneralDetailsError[`insurerBroker.${index}.primay_contact`] && <p className="text-danger">{developmentGeneralDetailsError[`insurerBroker.${index}.primay_contact`]}</p>}
                    </Col>


                    {/* <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Primary Contact Name"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            name="primay_contact"
                            value={element?.primay_contact}
                            onChange={async (e) => {
                                otherInsurerOrBrokerFormHandleChange(index, e)
                            }}
                        />
                        {developmentGeneralDetailsError[`insurerBroker.${index}.primay_contact`] && <p className="text-danger">{developmentGeneralDetailsError[`insurerBroker.${index}.primay_contact`]}</p>}
                    </Col> */}
                    <Col className="mt-4" lg="6" sm="12">
                        <FormControl>
                            <FormLabel id="otherContactStatus">Add another contact?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="otherContactStatus"
                                name="is_insurer_broker_another_contact"
                                value={element?.is_insurer_broker_another_contact}
                                onChange={async (e) => {
                                    otherInsurerOrBrokerFormHandleChange(index, e)
                                    // const result = await dispatch(GetStakeholderTypeBusinessNameOtherContact(e.target.value));
                                    // otherStakeholderFormHandleChange(index, { field_name: "stakeholder_another_contact_id", field_data: result?.payload?.data?.data?.stake_holder })
                                    // console.log("🚀 ~ file: GeneralDetails.js ~ line 312 ~ onChange={ ~ result", result)
                                }}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                <FormControlLabel value={0} control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                    {element?.is_insurer_broker_another_contact == 1 &&
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Other Contact</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    name="insurer_another_contact_id"
                                    value={element?.insurer_another_contact_id}
                                    onChange={(e) => otherInsurerOrBrokerFormHandleChange(index, e)}
                                    input={<OutlinedInput label="Other Contact" />}
                                    renderValue={(selected) => {
                                        return selected
                                            .map((id) => {
                                                const selectedContact = otherInsurerOrBrokerContactData?.[index]?.find((contact) => contact.id === id);
                                                if (selectedContact) {
                                                    const { fore_name, sur_name } = selectedContact;
                                                    return `${fore_name} ${sur_name}`;
                                                }
                                                return "";
                                            })
                                            .join(", ");
                                    }}
                                >
                                    {
                                        otherInsurerOrBrokerContactData?.[index]?.filter(obj => obj?.id != element?.primay_contact)?.map(contact => {
                                            const { id, fore_name, sur_name } = contact
                                            return (
                                                <MenuItem value={id}>
                                                    <Checkbox checked={element["insurer_another_contact_id"].indexOf(`${fore_name} ${sur_name}`) > -1} />
                                                    <ListItemText primary={`${fore_name} ${sur_name}`} />
                                                </MenuItem>
                                            )
                                        })


                                    }

                                    {/* <MenuItem value={20}>
                                        <Checkbox checked={element["stakeholder_another_contact_id"].indexOf("Amazon") > -1} />
                                        <ListItemText primary={"Amazon"} />
                                    </MenuItem> */}

                                </Select>
                            </FormControl>
                        </Col>
                    }
                </>
            ))

        )
    }

    const [loading, setLoading] = useState(false);

    const CreateDevelopmentGeneralDetailsHandler = async () => {
        setLoading(true);
        const data = {
            policy_number: policyNumberField,
            development_name: siteDevelopmentNameField,
            site_address: siteAddressField.toString(),
            site_postcode: sitePostcodeField,
            // developer_id: developerNameField,
            // d_primary_contact: developerPrimaryContactNameField,
            developer_id: developerPrimaryContactNameField,
            d_primary_contact: developerNameField,
            d_another_contact: addOtherDeveloperContactField,
            d_another_contact_id: developerOtherContactField,
            is_main_contractor: areContractorDetailsDifferentField,
            // main_contractor_id: mainContractorNameField,
            // main_const_primary_contact: mainContractorPrimaryContactNameField,
            main_contractor_id: mainContractorPrimaryContactNameField,
            main_const_primary_contact: mainContractorNameField,
            main_const_another_contact: addOtherContractorContactField,
            main_const_another_contact_id: mainContractorOtherContactField,
            selected_primary_contacts: siteContactNameField,
            another_stakeholder: otherStakeholderFormValues,
            add_further_stakeholder: addOtherStakeholderField,
            add_insurer_broker: addInsurerOrBrokerField,
            insurerBroker: otherInsurerOrBrokerFormValues,

            site_address_line1: retrieveAddressField1,
            site_address_line2: retrieveAddressField2,
            site_city: retrieveTownCity,
            site_state: retrieveCountyState,
            site_country: retrieveCountry,
        }
        console.log("🚀 ~ file: GeneralDetails.js ~ line 331 ~ CreateDevelopmentGeneralDetailsHandler ~ data", data)
        // const response = developmentMode == "edit" ? dispatch(UpdateDevelopmentGeneralDetails({ id: adminSingleDevelopmentDetail?.id, data: data })) : dispatch(CreateDevelopmentGeneralDetails(data))

        try {
            const response = developmentMode === "edit"
                ? await dispatch(UpdateDevelopmentGeneralDetails({ id: adminSingleDevelopmentDetail?.id, data: data }))
                : await dispatch(CreateDevelopmentGeneralDetails(data));

            console.log("Response:", response);
            // Handle success, e.g., show success message or navigate to another page
        } catch (error) {
            console.error("Error:", error);
            // Handle error, e.g., show an error message to the user
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <Row style={{ pointerEvents: `${developmentMode == "view" && 'none'}` }}>
                <Col className="mt-0 " lg="12" sm="12">
                    <span className="text-lg font-bold mt-4">Policy Details</span>
                </Col>
                <Col className="mt-4" lg="12" sm="12">
                    <TextField
                        label="Policy Number"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        value={policyNumberField}
                        onChange={(e) => setPolicyNumberField(e.target.value)}
                    />
                    {developmentGeneralDetailsError?.policy_number && <p className="text-danger">{developmentGeneralDetailsError?.policy_number}</p>}
                </Col>
                <Col className="mt-4 " lg="12" sm="12">
                    <span className="text-lg font-bold mt-4">Site Details</span>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Site / Development Name"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        multiline
                        maxRows={1}
                        value={siteDevelopmentNameField}
                        onChange={(e) => setSiteDevelopmentNameField(e.target.value)}
                    />
                    {developmentGeneralDetailsError?.development_name && <p className="text-danger">{developmentGeneralDetailsError?.development_name}</p>}
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Site Address"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        multiline
                        maxRows={1}
                        // value={siteAddressField}
                        // onChange={(e) => setSiteAddressField(e.target.value)}
                        value={siteAddressField}
                        onFocus={e => setSiteAddressFieldDataShow(true)}
                        onChange={(e) => {
                            setSiteAddressField(e.target.value)

                            const fieldValue = e.target.value

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");

                            var raw = JSON.stringify({
                                "key": "46ab2-3af78-4dfa7-07fcf",
                                "query": fieldValue,
                                "country": "gbr"
                            });

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            };

                            fetch("https://api.craftyclicks.co.uk/address/1.1/find/", requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    console.log("result==>", result?.results);
                                    setSiteAddressFieldData(result?.results)
                                })
                                .catch(error => console.log('error', error));
                        }}
                    />
                    {siteAddressFieldData?.length > 0 && siteAddressFieldDataShow &&
                        <Box sx={{}} style={{ position: "absolute", background: "white", boxShadow: "0px 0px 1px 1px rgba(0,0,0,100%)", zIndex: "99999", width: '789.45px', height: siteAddressFieldData?.length * 48, minHeight: "70px", maxHeight: "400px", overflow: "scroll", maxWidth: '100%', }}>
                            <List component="nav" aria-label="secondary mailbox folder">
                                {
                                    siteAddressFieldData?.map(obj => (

                                        <ListItemButton
                                            // selected={selectedIndex === 2}
                                            onClick={(e) => {
                                                setSiteAddressField(obj?.labels);
                                                // setSitePostcodeField(obj?.labels[0]);
                                                setSiteAddressFieldDataShow(false)


                                                // TODO: Retrieve API Call
                                                // const fieldValue = obj?.id

                                                var myHeaders = new Headers();
                                                myHeaders.append("Content-Type", "application/json");

                                                var raw = JSON.stringify({
                                                    "key": "46ab2-3af78-4dfa7-07fcf",
                                                    "id": obj?.id,
                                                    "country": "gbr"
                                                });

                                                var requestOptions = {
                                                    method: 'POST',
                                                    headers: myHeaders,
                                                    body: raw,
                                                    redirect: 'follow'
                                                };

                                                fetch("https://api.craftyclicks.co.uk/address/1.1/retrieve", requestOptions)
                                                    .then(response => response.json())
                                                    .then(result => {
                                                        console.log("RETRIEVE==>", result?.result);
                                                        setRetrieveCompleteAddressFieldData(result?.result)
                                                        setRetrieveAddressField1(result?.result?.line_1)
                                                        setRetrieveAddressField2(result?.result?.line_2)
                                                        setRetrieveTownCity(result?.result?.locality)
                                                        setRetrieveCountyState(result?.result?.province_name)
                                                        setSitePostcodeField(result?.result?.postal_code);
                                                        setRetrieveCountry(result?.result?.country_name);
                                                    })
                                                    .catch(error => console.log('error', error));

                                            }}
                                        >
                                            <ListItemText primary={obj?.labels} />
                                        </ListItemButton>
                                    ))
                                }
                            </List>
                        </Box>
                    }
                    {developmentGeneralDetailsError?.site_address && <p className="text-danger">{developmentGeneralDetailsError?.site_address}</p>}
                </Col>
                {retrieveCompleteAddressFieldData !== null &&
                    <>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Address Line 1"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                type='text'
                                value={retrieveAddressField1}
                                onChange={(e) => setRetrieveAddressField1(e.target.value)}
                            />
                            {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Address Line 2"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                type='text'
                                value={retrieveAddressField2}
                                onChange={(e) => setRetrieveAddressField2(e.target.value)}
                            />
                            {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                        </Col>

                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Town/City"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                type='text'
                                value={retrieveTownCity}
                                onChange={(e) => setRetrieveTownCity(e.target.value)}
                            />
                            {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="County/State"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                type='text'
                                value={retrieveCountyState}
                                onChange={(e) => setRetrieveCountyState(e.target.value)}
                            />
                            {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                        </Col>

                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Country"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                type='text'
                                value={retrieveCountry}
                                onChange={(e) => setRetrieveCountry(e.target.value)}
                            />
                            {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Site Postcode"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                type='text'
                                value={sitePostcodeField}
                                onChange={(e) => setSitePostcodeField(e.target.value)}
                            />
                            {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>}
                        </Col>
                    </>
                }
                <Col className="mt-4" lg="12" sm="12">
                    <span className="text-lg font-bold mt-4">Developer Details</span>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" size="small">Developer Name</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Site / Development Name"
                            value={developerNameField}
                            onChange={(e) => { setDeveloperNameField(e.target.value); dispatch(GetDeveloperPrimaryContact(e.target.value)); dispatch(GetDeveloperUsersByBusinessDetailId(e.target.value)) }}
                            size="small"
                        >
                            {
                                getDeveloperTrading?.length > 0 && getDeveloperTrading?.map(developer => {
                                    const { id, trading_name } = developer || {}

                                    return (
                                        <MenuItem value={id}>{trading_name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    {developmentGeneralDetailsError?.developer_id && <p className="text-danger">{developmentGeneralDetailsError?.developer_id}</p>}
                </Col>

                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" size="small">Primary Contact Name</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Primary Contact Name"
                            value={developerPrimaryContactNameField}
                            onChange={
                                (e) => {
                                    setDeveloperPrimaryContactNameField(e.target.value);
                                    const data = getDeveloperUsersByBusinessDetailId?.filter(d => d?.id == e.target.value)
                                    console.log("🚀 ~ file: GeneralDetails.js:951 ~ data", data)
                                    setDeveloperNameForSiteContactName(data[0]?.username)
                                }}
                            size="small"
                        >
                            {
                                getDeveloperUsersByBusinessDetailId?.length > 0 && getDeveloperUsersByBusinessDetailId?.map(contact => {
                                    const { id, username } = contact || {}

                                    return (
                                        <MenuItem value={id} name={username}>{username}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    {developmentGeneralDetailsError?.d_primary_contact && <p className="text-danger">{developmentGeneralDetailsError?.d_primary_contact}</p>}
                </Col>
                {/* <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Primary Contact Name"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        multiline
                        maxRows={1}
                        value={developerPrimaryContactNameField}
                        onChange={(e) => setDeveloperPrimaryContactNameField(e.target.value)}
                    />
                    {developmentGeneralDetailsError?.d_primary_contact && <p className="text-danger">{developmentGeneralDetailsError?.d_primary_contact}</p>}
                </Col> */}
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl>
                        <FormLabel id="otherContactStatus">Allocate another Developer Contact</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="otherContactStatus"
                            name=""
                            value={addOtherDeveloperContactField}
                            onChange={(e) => {
                                setAddOtherDeveloperContactField(e.target.value);
                                dispatch(GetDeveloperOtherContact(
                                    {
                                        trading_id: developerNameField,
                                        selected_id: developerPrimaryContactNameField
                                    }
                                ))
                            }}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Col>
                {addOtherDeveloperContactField == 1 &&
                    <Col className="mt-4" lg="6" sm="12">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-dev" size="small">Other Contact</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-multiple-checkbox-label-dev"
                                id="demo-multiple-checkbox-dev"
                                multiple
                                name="developerOtherContactField"
                                value={developerOtherContactField}
                                onChange={(e) => { console.log('DEVELIPER OTHER CONTACT ==> ', e); setDeveloperOtherContactField(e.target.value) }}
                                input={<OutlinedInput label="Other Contact" />}
                                renderValue={(selected) => {
                                    return selected
                                        .map((id) => {
                                            const selectedContact = developerOtherContact.find((contact) => contact.id === id);
                                            if (selectedContact) {
                                                const { fore_name, sur_name } = selectedContact;
                                                return `${fore_name} ${sur_name}`;
                                            }
                                            return "";
                                        })
                                        .join(", ");
                                }}
                            // MenuProps={MenuProps}
                            >

                                {
                                    developerOtherContact?.map(contact => {
                                        const { id, fore_name, sur_name } = contact

                                        return (
                                            <MenuItem value={id}>
                                                <Checkbox checked={developerOtherContactField.indexOf(`${fore_name} ${sur_name}`) > -1} />
                                                <ListItemText primary={`${fore_name} ${sur_name}`} />
                                            </MenuItem>)
                                    })
                                }

                            </Select>
                        </FormControl>
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl>
                        <FormLabel id="areContractorDetailDifferent">Are you also the main contractor for this development?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="areContractorDetailDifferent"
                            name=""
                            value={areContractorDetailsDifferentField}
                            onChange={handleAreContractorDetailsDifferent}
                        // onChange={(e) => { setAreContractorDetailsDifferentField(e.target.value); dispatch(GetMainContractors()) }}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Col>
                {/* {areContractorDetailsDifferentField == 0 && areContractorDetailsDifferentField !== "" && */}
                {areContractorDetailsDifferentField == 0 &&
                    <>
                        <Col className="mt-4" lg="12" sm="12">
                            <span className="text-lg font-bold mt-4">Main Contractor Details</span>
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Main Contractor Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Main Contractor Name"
                                    value={mainContractorNameField}
                                    onChange={(e) => {
                                        setMainContractorNameField(e.target.value);
                                        dispatch(GetContractorPrimaryContact(e.target.value));
                                        dispatch(GetContractorUsersByBusinessDetailId(e.target.value))
                                    }}
                                    size="small"
                                >
                                    {
                                        getContractorTrading?.length > 0 && getContractorTrading?.map(developer => {
                                            const { id, trading_name } = developer || {}

                                            return (
                                                <MenuItem value={id}>{trading_name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            {developmentGeneralDetailsError?.main_contractor_id && <p className="text-danger">{developmentGeneralDetailsError?.main_contractor_id}</p>}
                        </Col>

                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Primary Contact Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Primary Contact Name"
                                    value={mainContractorPrimaryContactNameField}
                                    onChange={(e) => {
                                        setMainContractorPrimaryContactNameField(e.target.value);
                                        const data = getContractorUsersByBusinessDetailId?.filter(d => d?.id == e.target.value)
                                        console.log("🚀 ~ file: GeneralDetails.js:951 ~ data", data)
                                        setMainContractorNameForSiteContactName(data[0]?.username)
                                    }}
                                    size="small"
                                >
                                    {
                                        getContractorUsersByBusinessDetailId?.length > 0 && getContractorUsersByBusinessDetailId?.map(contact => {
                                            const { id, username } = contact || {}

                                            return (
                                                <MenuItem value={id}>{username}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            {developmentGeneralDetailsError?.main_const_primary_contact && <p className="text-danger">{developmentGeneralDetailsError?.main_const_primary_contact}</p>}
                        </Col>

                        {/* <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Primary Contact Name"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                multiline
                                maxRows={1}
                                value={mainContractorPrimaryContactNameField}
                                onChange={(e) => setMainContractorPrimaryContactNameField(e.target.value)}
                            />
                            {developmentGeneralDetailsError?.main_const_primary_contact && <p className="text-danger">{developmentGeneralDetailsError?.main_const_primary_contact}</p>}
                        </Col> */}
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl>
                                <FormLabel id="otherContactStatus">Allocate another Contractor Contact</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="otherContactStatus"
                                    name=""
                                    value={addOtherContractorContactField}
                                    onChange={(e) => {
                                        setAddOtherContractorContactField(e.target.value);
                                        dispatch(GetContractorOtherContact({
                                            trading_id: mainContractorNameField,
                                            selected_id: mainContractorPrimaryContactNameField
                                        }))
                                    }}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={0} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                        {addOtherContractorContactField == 1 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>

                                    <InputLabel id="demo-simple-select-label" size="small">Other Contact</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        name="stakeholder_another_contact_id"
                                        value={mainContractorOtherContactField}
                                        onChange={(e) => setMainContractorOtherContactField(e.target.value)}
                                        input={<OutlinedInput label="Other Contact" />}
                                        // renderValue={(selected) => selected.join(', ')}
                                        // renderValue={(selected) => selected?.map((obj, index) => `${mainContractorOtherContact?.stake_holder?.other_contact[index]?.fore_name} ${mainContractorOtherContact?.stake_holder?.other_contact[index]?.sur_name}`).join(", ")}
                                        renderValue={(selected) => {
                                            return selected
                                                .map((id) => {
                                                    const selectedContact = mainContractorOtherContact.find((contact) => contact.id === id);
                                                    if (selectedContact) {
                                                        const { fore_name, sur_name } = selectedContact;
                                                        return `${fore_name} ${sur_name}`;
                                                    }
                                                    return "";
                                                })
                                                .join(", ");
                                        }}
                                    // MenuProps={MenuProps}
                                    >
                                        {
                                            mainContractorOtherContact?.map(contact => {
                                                const { id, fore_name, sur_name } = contact

                                                return (
                                                    <MenuItem value={id}>
                                                        <Checkbox checked={mainContractorOtherContactField.indexOf(`${fore_name} ${sur_name}`) > -1} />
                                                        <ListItemText primary={`${fore_name} ${sur_name}`} />
                                                    </MenuItem>)
                                            })
                                        }

                                    </Select>
                                </FormControl>
                            </Col>
                        }
                    </>
                }

                {addAnotherContact == 10 &&
                    <>
                        <Col className="mt-4" lg="12" sm="12">
                            <span className="text-lg font-bold mt-4 ">Other Contact Details</span>
                        </Col>
                        {RenderOtherContacts()}
                        <Col className="mt-4" lg="12" sm="12">
                            <div className='flex flex-row align-center'>
                                <div
                                    className='flex flex-row cursor-pointer'
                                    onClick={() => setTotalContacts(totalContacts + 1)}
                                >
                                    <div
                                        style={{ backgroundColor: '#475BE2' }}
                                        className='h-5 w-5 rounded-xl flex justify-center align-center'
                                    >
                                        <AddIcon color='action' fontSize='small' />
                                    </div>
                                    <span className='font-bold ml-2' style={{ color: '#475BE2' }}>Click to Add another</span>
                                </div>
                                <div
                                    className={`flex flex-row ${totalContacts == 1 ? 'cursor-not-allowed' : 'cursor-pointer'} ml-3 `}
                                    onClick={() => {
                                        if (totalContacts != 1)
                                            setTotalContacts(totalContacts - 1)
                                    }}
                                >
                                    <div
                                        style={{ backgroundColor: '#dc3545', opacity: totalContacts == 1 ? 0.5 : 1 }}
                                        className='h-5 w-5 rounded-xl flex justify-center align-center'
                                    >
                                        <RemoveIcon color='action' fontSize='small' />
                                    </div>
                                    <span className='font-bold ml-2' style={{ color: '#dc3545', opacity: totalContacts == 1 ? 0.5 : 1 }}>Click to Remove</span>
                                </div>
                            </div>
                        </Col>
                    </>
                }

                <Col className="mt-4" lg="12" sm="12">
                    <span className="text-lg font-bold mt-4">Site Contact Name</span>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    {/* <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" size="small">Site Contact Name</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Site Contact Name"
                            value={siteContactNameField}
                            onChange={(e) => setSiteContactNameField(e.target.value)}
                            size="small"
                            multiple
                            input={<OutlinedInput label="Site Contact Name" />}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            <MenuItem value={1}>
                                <Checkbox checked={siteContactNameField.indexOf(`${developerPrimaryContactNameField}`) > -1} />
                                <ListItemText primary={`${developerPrimaryContactNameField}`} />
                            </MenuItem>
                            <MenuItem value={2}>
                                <Checkbox checked={siteContactNameField.indexOf(`${mainContractorPrimaryContactNameField}`) > -1} />
                                <ListItemText primary={`${mainContractorPrimaryContactNameField}`} />
                            </MenuItem>
                        </Select>
                    </FormControl> */}

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" size="small">Site Contact Name</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Site Contact Name"
                            value={siteContactNameField}
                            onChange={(e) => { setSiteContactNameField(e.target.value) }}
                            size="small"
                        >
                            <MenuItem value={1}>{`${developerNameForSiteContactName}`}</MenuItem>
                            <MenuItem value={2}>{`${mainContractorNameForSiteContactName}`} </MenuItem>
                        </Select>
                    </FormControl>

                    {developmentGeneralDetailsError?.selected_primary_contacts && <p className="text-danger">{developmentGeneralDetailsError?.selected_primary_contacts}</p>}
                </Col>

                <Col className="mt-4" lg="12" sm="12">
                    <span className="text-lg font-bold mt-4">Add Insurer or Broker</span>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl>
                        <FormLabel id="otherContactStatus">Do you wish to add an Insurer, and Broker (If applicable):</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="otherContactStatus"
                            name=""
                            value={addInsurerOrBrokerField}
                            onChange={(e) => {
                                setAddInsurerOrBrokerField(e.target.value); setTotalInsurerOrBroker(1); setOtherInsurerOrBrokerFormValues([{
                                    insurer_broker_type_id: "",
                                    insurer_broker_id: "",
                                    company_name: "",
                                    is_insurer_broker_another_contact: "",
                                    insurer_another_contact_id: []
                                }])
                            }}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Col>

                {addInsurerOrBrokerField == 1 &&
                    <>
                        {RenderInsurerOrBroker()}
                    </>
                }
                {addInsurerOrBrokerField == 1 &&
                    <Col className="mt-4" lg="12" sm="12">
                        <div
                            className='flex flex-row cursor-pointer'
                            // onClick={() => setTotalContacts(totalContacts + 1)}
                            onClick={() => { addOtherInsurerOrBrokerFormFields(); console.log("otherInsurerOrBrokerFormValues", otherInsurerOrBrokerFormValues) }}
                        >
                            <div
                                style={{ backgroundColor: '#475BE2' }}
                                className='h-5 w-5 rounded-xl flex justify-center align-center'
                            >
                                <AddIcon color='action' fontSize='small' />
                            </div>
                            <span className='font-bold ml-2' style={{ color: '#475BE2' }}>Click to add other insurer/broker</span>
                        </div>
                    </Col>
                }



                <Col className="mt-4" lg="12" sm="12">
                    <span className="text-lg font-bold mt-4">Other Stakeholder Details</span>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl>
                        <FormLabel id="otherContactStatus">Do you wish to add further Stakeholders?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="otherContactStatus"
                            name=""
                            value={addOtherStakeholderField}
                            onChange={(e) => {
                                setAddOtherStakeholderField(e.target.value); setTotalStakeholders(1); setOtherStakeholderFormValues([{
                                    stakeholder_type_id: "",
                                    primay_contact: "",
                                    company_name: "",
                                    stakeholder_another_contact: "",
                                    stakeholder_another_contact_id: []
                                }])
                            }}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Col>
                {addOtherStakeholderField == 1 &&
                    <>
                        {RenderStakeholder()}
                    </>
                }
                {addOtherStakeholderField == 1 &&
                    <Col className="mt-4" lg="12" sm="12">
                        <div
                            className='flex flex-row cursor-pointer'
                            // onClick={() => setTotalContacts(totalContacts + 1)}
                            onClick={addOtherStakeholderFormFields}
                        >
                            <div
                                style={{ backgroundColor: '#475BE2' }}
                                className='h-5 w-5 rounded-xl flex justify-center align-center'
                            >
                                <AddIcon color='action' fontSize='small' />
                            </div>
                            <span className='font-bold ml-2' style={{ color: '#475BE2' }}>Click to add other stakeholder</span>
                        </div>
                    </Col>
                }






            </Row>
            <CardFooter>
                {developmentMode !== "view" && <div className="exit-btn-container mr-3">
                    {/* <Button
                        disabled={value == 0}
                        className="mr-3"
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={() => setValue(value - 1)} color="info" size="sm"
                    >
                        Previous
                    </Button> */}
                    <Button
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={CreateDevelopmentGeneralDetailsHandler}
                        color="info" size="sm"
                        disabled={loading}
                    >
                        {
                            !loading ?
                                value == 4 ?
                                    "Save"
                                    :
                                    "Next"
                                :
                                <CircularProgress color='inherit' size={18} />
                        }
                    </Button>
                </div>}
            </CardFooter>
        </>
    );
}

export default GeneralDetails;
