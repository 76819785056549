// javascript plugin used to create scrollbars on windows
import externalUserRoutes from "App/Config/externalUserRoutes";
import PerfectScrollbar from "perfect-scrollbar";
import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import routes from "../../Config/routes";
import AdminNavbar from "../Shared/Components/AdminNavbar.js";
import ExternalUserNavbar from "../Shared/Components/ExternalUserNavbar";
import Footer from "../Shared/Components/Footer.js";
import './Admin.css'

var ps;

function ExternalUserLayout(props) {
    const location = useLocation();
    const [backgroundColor, setBackgroundColor] = React.useState("black");
    const [activeColor, setActiveColor] = React.useState("info");
    const [sidebarMini, setSidebarMini] = React.useState(false);
    const mainPanel = React.useRef();
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(mainPanel.current);
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.documentElement.className += " perfect-scrollbar-off";
                document.documentElement.classList.remove("perfect-scrollbar-on");
            }
        };
    });
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainPanel.current.scrollTop = 0;
    }, [location]);
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/user") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    const handleBgClick = (color) => {
        setBackgroundColor(color);
    };
    const handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            setSidebarMini(false);
        } else {
            setSidebarMini(true);
        }
        document.body.classList.toggle("sidebar-mini");
    };
    return (
        <div className="wrapper">
            <div className="main-panel" ref={mainPanel}>
                <ExternalUserNavbar {...props} handleMiniClick={handleMiniClick} />
                <Switch>{getRoutes(externalUserRoutes)}</Switch>
                {/* {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        } */}
            </div>
        </div>
    );
}

export default ExternalUserLayout;
