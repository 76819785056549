import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const externalUsersProfileSlice = createSlice({
    name: 'stakeHolder',
    initialState: {
        surveyorProfileData: null,
        sendRequestToUpdateProfile: null,
    },
    reducers: {
        ChangeUserData: (state, action) => {
            state.userData = action.payload
        }
    },
    extraReducers: {

        ['stakeHolder/GetSurveyorProfileData/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.surveyorProfileData = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.surveyorProfileData = data
            }
        },

        ['stakeHolder/SendRequestToUpdateProfile/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.sendRequestToUpdateProfile = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.sendRequestToUpdateProfile = data
            }
        },

    },
});

export const {
    ChangeUserData,
} = externalUsersProfileSlice.actions;

export default externalUsersProfileSlice.reducer;