import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

const defectDetailSlice = createSlice({
    name: 'styles',
    initialState: {
        defectDetail: {},
        stakeholderResponse: {},
        surveyorResponse: {},
        defectImages: [
            {
                id: 1,
                dateAndTime: new Date(2021, 3, 1, 2, 1),
                image: require('../../../Assets/Images/Defect1.png').default
            },
            {
                id: 2,
                dateAndTime: new Date(2021, 3, 1, 2, 2),
                image: require('../../../Assets/Images/Defect2.png').default
            },
            {
                id: 3,
                dateAndTime: new Date(2021, 3, 1, 2, 3),
                image: require('../../../Assets/Images/Defect3.png').default
            },
            {
                id: 4,
                dateAndTime: new Date(2021, 3, 1, 2, 4),
                image: require('../../../Assets/Images/Defect4.png').default
            },
            {
                id: 5,
                dateAndTime: new Date(2021, 3, 1, 2, 5),
                image: require('../../../Assets/Images/Defect5.png').default
            },
            {
                id: 6,
                dateAndTime: new Date(2021, 3, 1, 2, 5),
                image: require('../../../Assets/Images/Defect1.png').default
            },
        ]
    },
    reducers: {
    },
    extraReducers: {
        ['DefectDetail/GetSingleDefectDetail/fulfilled']: (state, action) => {
            // alert("CALLED tested")
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.defectDetail = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.defectDetail = data
            }
        },

        ['DefectDetail/MakeStakeholderResponse/fulfilled']: (state, action) => {
            // alert("CALLED tested")
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.stakeholderResponse = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.stakeholderResponse = data
            }
        },

        ['DefectDetail/MakeSurveyorResponse/fulfilled']: (state, action) => {
            // alert("CALLED tested")
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.surveyorResponse = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.surveyorResponse = data
            }
        },
    },
});

export const {
} = defectDetailSlice.actions;

export default defectDetailSlice.reducer;