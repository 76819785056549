import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import FileBase64 from 'react-file-base64';
import MUISelect from '@mui/material/Select';
import {
    Button,
    Card, CardBody, CardHeader, Col, Container, FormGroup,
    Input, Modal, Progress, Row, Table
} from "reactstrap";
import { GetSingleDefectDetail, MakeStakeholderResponse, MakeSurveyorResponse } from "./Store/DefectDetailApi";
import { SetShowDefectDetail } from '../Shared/store/pageSlice';
import { MenuItem } from "@mui/material";

const StakeholderResponse = ({ toggleModalFunction }) => {
    const dispatch = useDispatch()
    const { defectDetail } = useSelector(state => state.defectDetail)


    const [responseDateField, setResponseDateField] = useState("")
    const [descriptionField, setDescriptionField] = useState("")
    const [attachmentField, setAttachmentField] = useState([])

    const { administrationHistoryAssignedTo } = useSelector(state => state?.AdministrationHistory)

    useEffect(() => {
        setResponseDateField(new Date().toJSON().slice(0, 10))
    }, [])


    // useEffect(() => {
    //     dispatch(GetAdministrationHistoryAssignedTo())
    // }, [])

    const addCategoryHandler = () => {
        const alterFiles = attachmentField?.map(file => ({
            file_name: file?.name,
            file: file?.base64,
            type: file?.type,
            extra: file?.file
        }))
        const data = {
            comment: descriptionField,
            multiplefile: alterFiles,
        }
        console.log("🚀 ~ file: AdministrationHistoryAddDiary.js ~ line 23 ~ addCategoryHandler ~ data", data)

        dispatch(MakeStakeholderResponse({ id: defectDetail?.id, data: data }))
        setDescriptionField("")
        setAttachmentField([])
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Stakeholder Response
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Response Date</label>
                                <Input className="custom-input" placeholder="" type="date" value={responseDateField} onChange={e => setResponseDateField(e.target.value)} disabled />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Remedy or further information</label>
                                <Input type="textarea" name="text" id="exampleText" placeholder='Please describe what has been done to rectify or provide further information to the surveyor.' max={150} style={{ minHeight: "80px", border: "1px solid #888888" }} value={descriptionField} onChange={e => setDescriptionField(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Please upload photo or video evidence (if applicable)</label>
                                <FileBase64
                                    multiple={true}
                                    onDone={(files) => setAttachmentField(files)} />
                            </FormGroup>
                        </Col>

                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={addCategoryHandler} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

const SurveyorResponse = ({ toggleModalFunction, responseId }) => {
    const dispatch = useDispatch()

    const { defectDetail } = useSelector(state => state.defectDetail)


    const [responseDateField, setResponseDateField] = useState("")
    const [descriptionField, setDescriptionField] = useState("")
    const [commentSatisfiedField, setCommentSatisfiedField] = useState({})
    const [reconfirmRiskField, setReconfirmRiskField] = useState({})
    const [surveyorSatisfiedField, setSurveyorSatisfiedField] = useState("")
    const [surveyorWishesField, setSurveyorWishesField] = useState("")

    const { administrationHistoryAssignedTo } = useSelector(state => state?.AdministrationHistory)

    useEffect(() => {
        setResponseDateField(new Date().toJSON().slice(0, 10))
    }, [])

    // useEffect(() => {
    //     dispatch(GetAdministrationHistoryAssignedTo())
    // }, [])

    const addCategoryHandler = () => {
        const data = {
            user_response_id: responseId,
            surveyor_response: commentSatisfiedField?.value,
            comment: descriptionField,
            risk: reconfirmRiskField?.value,
            satisfied: surveyorSatisfiedField,
            arrange_visit: surveyorWishesField,
        }

        dispatch(MakeSurveyorResponse(data))
        setDescriptionField("")
        setCommentSatisfiedField({})
        setReconfirmRiskField({})
        setSurveyorSatisfiedField("")
        setSurveyorWishesField("")
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Surveyor Response
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Response Date</label>
                                <Input className="custom-input" placeholder="" type="date" value={responseDateField} onChange={e => setResponseDateField(e.target.value)} disabled />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Comment, mark as satisfied or arrange visit</label>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={commentSatisfiedField}
                                        onChange={(value) => setCommentSatisfiedField(value)}
                                        options={
                                            [
                                                {
                                                    value: "1", label: "Comment"
                                                },
                                                {
                                                    value: "2", label: "Satisfied"
                                                },
                                                {
                                                    value: "3", label: "Arrange Visit"
                                                },
                                            ]
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        {commentSatisfiedField?.value == 1 &&
                            <>
                                <Col md="12">
                                    <FormGroup>
                                        <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Surveyor Comments</label>
                                        <Input type="textarea" name="text" id="exampleText" placeholder='Please respond to Stakeholder with further commentary.' max={150} style={{ minHeight: "80px", border: "1px solid #888888" }} value={descriptionField} onChange={e => setDescriptionField(e.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col md="12 ">
                                    <FormGroup style={{ margin: 0 }}>
                                        <div>
                                            <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Reconfirm Risk</label>
                                        </div>
                                        <div className="custom-select-input">
                                            <Select
                                                className="react-select primary "
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                value={reconfirmRiskField}
                                                onChange={(value) => setReconfirmRiskField(value)}
                                                options={
                                                    [
                                                        {
                                                            value: "1", label: "High"
                                                        },
                                                        {
                                                            value: "2", label: "Medium"
                                                        },
                                                        {
                                                            value: "3", label: "Low"
                                                        },
                                                    ]
                                                }
                                                placeholder=""
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </>
                        }
                        {
                            reconfirmRiskField?.value == 1 &&
                            <span className="text-sm font-bold mt-4" style={{ paddingLeft: "15px" }}>Has potential to affect High risk physical conditions of the building and could lead to major defects. Must be rectified immediately. No further substantial works can commence until rectified. Surveyor to determine when it must rectified (date) and whether photo/video will suffice or if a further Survey visit is required. Failure to rectify immediately may result in cover being declined, or an endorsement applied excluding the potential risk or an increase in premium.</span>
                        }
                        {
                            reconfirmRiskField?.value == 2 &&
                            <span className="text-sm font-bold mt-4" style={{ paddingLeft: "15px" }}>Has potential to affect Medium risk physical conditions of the building. Must be rectified before the next survey stage is carried out. No further works can commence where the defect has been identified until rectified. Surveyor to determine when it must rectified (date) and whether photo/video will suffice or if a further Survey visit is required. Failure to rectify may result in cover being declined, or an endorsement applied excluding the potential risk or an increase in premium.</span>
                        }
                        {
                            reconfirmRiskField?.value == 3 &&
                            <span className="text-sm font-bold mt-4" style={{ paddingLeft: "15px" }}>Low risk (minor) defects which affect the physical condition of the building. Photo/video evidence of rectification will suffice. Failure to rectify or notify us may result in an endorsement applied excluding the potential risk or an increase in premium.</span>
                        }
                        {commentSatisfiedField?.value == 2 && <Col md="12">
                            <FormGroup>
                                <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Surveyor is Satisfied that Defect or Action Request has been remidied.</label>
                                <Input type="textarea" name="text" id="exampleText" placeholder='Please confirm your reasons for satisfaction.' max={150} style={{ minHeight: "80px", border: "1px solid #888888" }} value={surveyorSatisfiedField} onChange={e => setSurveyorSatisfiedField(e.target.value)} />
                            </FormGroup>
                        </Col>}
                        {commentSatisfiedField?.value == 3 && <Col md="12">
                            <FormGroup>
                                <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Surveyor wishes to revisit the site and inspect the Defect or Action Request.</label>
                                <Input type="textarea" name="text" id="exampleText" placeholder='Please confirm your reasons for the re-visit.' max={150} style={{ minHeight: "80px", border: "1px solid #888888" }} value={surveyorWishesField} onChange={e => setSurveyorWishesField(e.target.value)} />
                            </FormGroup>
                        </Col>}

                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={addCategoryHandler} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

function DefectDetail(props) {

    const { defectDetail, defectImages } = useSelector(state => state.defectDetail)
    const { defectsOfSingleDevelopment } = useSelector(state => state.dashboard)
    const { userData } = useSelector(state => state.userData)
    const [currentIdForResponse, setCurrentIdForResponse] = useState(null)
    const dispatch = useDispatch()


    const [defect, setDefect] = useState("1")
    const [modalStakeholderResponse, setStakeholderResponse] = useState(false);
    const [modalSurveyorResponse, setSurveyorResponse] = useState(false);

    function RenderImages() {
        const baseImageUrl = "https://arkws.crosip.com/storage"
        return defectDetail?.multiple_file?.map((item, key) => {
            const { id, file, } = item
            return (
                <div key={id} className="custom-shadow-div mr-3">
                    <img
                        src={`${baseImageUrl}/${file}`}
                        style={{
                            width: 200,
                            height: 150,
                            objectFit: 'cover',
                            borderRadius: 0,
                            borderTopRightRadius: 3,
                            borderTopLeftRadius: 3,
                        }}
                    />
                    {/* <div
                        style={{
                            backgroundColor: '#20C1EE',
                            borderBottomRightRadius: 3,
                            borderBottomLeftRadius: 3,
                        }}
                        className="flex flex-row  p-2"
                    >
                        <div style={{ color: 'white' }} className="flex flex-1  text-xs font-semibold ">
                            {moment(dateAndTime).format("DD/MM/YYYY")}
                        </div>
                        <div style={{ color: 'white' }} className="flex flex-1  text-xs font-semibold justify-end">
                            {moment(dateAndTime).format("hh:mm a").toUpperCase()}
                        </div>
                    </div> */}
                </div>
            )
        })
    }

    const toggleModalStakeholderResponse = () => {
        setStakeholderResponse(!modalStakeholderResponse);
    }

    const toggleModalSurveyorResponse = (value) => {
        setCurrentIdForResponse(value)
        setSurveyorResponse(!modalSurveyorResponse);
    }

    return (
        <>
            <Col lg={`${props.isFullWidth ? 12 : 9}`} sm={`${props.isFullWidth ? 12 : 9}`}>
                <Card className="pb-12 pt-3">
                    <CardHeader>
                        <div className="flex flex-row  ">
                            <div className="defects-div flex  items-center mr-4">
                                <span className="font-bold  ">Defects</span>
                            </div>
                            <div className="flex-1 ">
                                {/* <Select
                                    label="Defects"
                                    className="react-select primary "
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={defect}
                                    onChange={(value) => setDefect(value)}
                                    options={[
                                        { value: "1", label: "Defect 1", },
                                        { value: "2", label: "Defect 2" },
                                        { value: "3", label: "Defect 3" },
                                    ]}
                                    placeholder="Select Defect"
                                /> */}

                                <MUISelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="BusinessStatus "
                                    size="small"
                                    fullWidth
                                    value={defect}
                                    onChange={(e) => { dispatch(GetSingleDefectDetail(e.target.value)); setDefect(e.target.value) }}
                                >
                                    {
                                        defectsOfSingleDevelopment?.map(defect => (

                                            <MenuItem value={defect?.id}>{defect?.defect_action == "1" ? "Defect" : "Action"}</MenuItem>
                                        ))
                                    }

                                </MUISelect>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="custom-shadow-div table-12-div">
                            <Table
                                // responsive
                                className="custom-table"
                            >
                                <thead>
                                    <tr>
                                        <th>Notification</th>
                                        <th>Notified</th>
                                        <th>Stage</th>
                                        <th>Section within Stage</th>
                                        <th>Description</th>
                                        <th>Unit/Block</th>
                                        <th>Prirority</th>
                                        <th>Photo / Video Available</th>
                                        <th>Being Actions</th>
                                        <th>Work Complete?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>{defectDetail?.defect_action == "1" ? "Defect" : "Action"}</td>
                                        <td>{moment(defectDetail?.notification_date).format("DD/MM/YY")}</td>
                                        <td>{defectDetail?.category?.category_name}</td>
                                        <td>{defectDetail?.sub_category?.sub_category_name}</td>
                                        <td>{defectDetail?.description}</td>
                                        <td>{defectDetail?.unit} / {defectDetail?.block}</td>
                                        <td>{defectDetail?.risk == 1 ? "High" : defectDetail?.risk == 2 ? "Medium" : defectDetail?.risk == 3 ? "Low" : ""}</td>
                                        <td>{defectDetail?.multiple_file?.length > 0 ? "Yes" : "No"}</td>
                                        <td>{"NA"}</td>
                                        <td>{"NA"}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="custom-shadow-div p-3">
                            <span className="font-bold text-sm">Surveyor Comments</span>
                            <div style={{ color: '#5C5C5C' }} className="mt-3 leading-6">
                                {defectDetail?.description}
                            </div>
                        </div>
                        <div className="custom-shadow-div p-3 mt-4">
                            <div>
                                <span className="font-bold text-sm">Priority:</span>
                                <span style={{ color: '#F10D0D' }} className="font-semibold text-sm ml-3">{defectDetail?.risk == 1 ? "High" : defectDetail?.risk == 2 ? "Medium" : defectDetail?.risk == 3 ? "Low" : ""}</span>
                            </div>
                            <div style={{ color: '#5C5C5C' }} className="mt-3 leading-6">
                                {defectDetail?.risk_description}
                            </div>
                        </div>
                        <div className="mt-4 flex">
                            {RenderImages()}
                        </div>
                        {userData?.role != "Surveyor" && <div className="exit-btn-container ">
                            <Button
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={toggleModalStakeholderResponse}
                                color="info"
                                size="sm"
                            >
                                Respond to Surveyor
                            </Button>
                        </div>}
                        {defectDetail?.user_response_defect?.map(response => (
                            <>
                                <div className="custom-shadow-div p-3 mt-4 mb-3">
                                    <div className="mt-3 font-bold text-sm">Ask Warranty Comments:</div>
                                    <div style={{ color: '#5C5C5C' }} className="leading-6">
                                        {response?.comment}
                                    </div>
                                    <div className="mt-4 flex">
                                        {
                                            response?.multiple_file?.map((item, key) => {
                                                const baseImageUrl = "https://arkws.crosip.com/storage"
                                                const { id, file, } = item
                                                return (
                                                    <div key={id} className="custom-shadow-div mr-3">
                                                        <img
                                                            src={`${baseImageUrl}/${file}`}
                                                            style={{
                                                                width: 200,
                                                                height: 150,
                                                                objectFit: 'cover',
                                                                borderRadius: 0,
                                                                borderTopRightRadius: 3,
                                                                borderTopLeftRadius: 3,
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {response?.reply_reponse?.map(reply => (
                                        <>
                                            <div className="font-bold text-sm mt-2">Surveyor Response:</div>
                                            <div style={{ color: '#5C5C5C' }} className="leading-6">
                                                {reply?.comment}
                                            </div>
                                        </>
                                    ))
                                    }
                                    {userData?.role == "Surveyor" && <div className="exit-btn-container ">
                                        <Button
                                            className="px-3"
                                            style={{ backgroundColor: '#2BA9CD' }}
                                            onClick={() => toggleModalSurveyorResponse(response?.id)}
                                            color="info"
                                            size="sm"
                                        >
                                            Respond to User
                                        </Button>
                                    </div>}
                                </div>
                                <div className="ml-3 flex flex-column justify-items-start">
                                    <span className="font-bold text-sm  ">Diary Date:</span>
                                    <div className="custom-shadow-div w-fit	py-1 px-4 mt-2">
                                        <span>{moment(response?.created_at).format("DD/MM/YY")}</span>
                                    </div>
                                </div>
                            </>
                        ))
                        }
                        <div className="exit-btn-container ">
                            <Button
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={() => { dispatch(SetShowDefectDetail(false)); props.setShowAllDefectsOnTopSide(false); props.setHideAllDefects(true) }}
                                color="info"
                                size="sm"
                            >
                                Exit
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalStakeholderResponse}
                toggle={toggleModalStakeholderResponse}
                size="lg"
            >
                <StakeholderResponse toggleModalFunction={toggleModalStakeholderResponse} />
            </Modal>

            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalSurveyorResponse}
                toggle={toggleModalSurveyorResponse}
                size="lg"
            >
                <SurveyorResponse toggleModalFunction={toggleModalSurveyorResponse} responseId={currentIdForResponse} />
            </Modal>
        </>
    );
}

export default DefectDetail;
