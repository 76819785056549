import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetInsurerType = createAsyncThunk('insurerOrBroker/GetInsurerType', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/insurer-or-broker').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetInsurerOrBrokerBusinessStatus = createAsyncThunk('insurerOrBroker/GetInsurerOrBrokerBusinessStatus', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/business-status').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 24 ~ GetBusinessStatus ~ result", result)
    return { data, status }
})

export const GetAllBroker = createAsyncThunk('insurerOrBroker/GetAllBroker', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-all-broker').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 24 ~ GetBusinessStatus ~ result", result)
    return { data, status }
})

export const GetAllInsurer = createAsyncThunk('insurerOrBroker/GetAllInsurer', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/insurer-type').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const CreateInsurerOrBroker = createAsyncThunk('insurerOrBroker/CreateInsurerOrBroker', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/create/insurer-broker', {
        ...params.data
    }).then(function (response) {
        toast("User created successfully")
        params.clearForm()
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})

export const UpdateInsurerOrBroker = createAsyncThunk('insurerOrBroker/UpdateInsurerOrBroker', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post(`/api/admin/update/insurer-broker/${params?.id}`, {
        ...params?.data
    }).then(function (response) {
        toast("Record updated successfully")
        params?.clearForm()
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})