import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const systemMaintenanceSlice = createSlice({
    name: 'styles',
    initialState: {
        advanceSearchResult: []
    },
    reducers: {
    },
    extraReducers: {
        // ['search/AdvanceSearch/fulfilled']: (state, action) => {
        //     const { data, status } = action.payload || {}
        //     if (status >= 200 && status < 300) {
        //         
        //         state.advanceSearchResult = data?.data
        //     }
        //     else if (status >= 400 && status < 500) {
        //         // toast(data.message)
        //         state.advanceSearchResult = data
        //     }
        // },
    },
});

export const {
} = systemMaintenanceSlice.actions;

export default systemMaintenanceSlice.reducer;