import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetSurveyorDevelopments = createAsyncThunk('externalUsers/GetSurveyorDevelopments', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-surveyor-unallocated-developments').then(function (response) {
        const alterResponse = response?.data?.data?.filter(f => f.status != 1)?.map(d => (
            {
                srNo: d?.id,
                surveyor: d?.surveyor?.username,
                development: d?.development_name,
                dateSubmitted: d?.created_at,
                status: d?.status
            }
        ))
        console.log("🚀 ~ file: AddNewDevelopmentApi.js ~ line 20 ~ result ~ response", response)
        return { ...response, data: { ...data, data: alterResponse } }
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetSurveyorAcceptedDevelopments = createAsyncThunk('externalUsers/GetSurveyorAcceptedDevelopments', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/surveyor-development-accepted').then(function (response) {
        console.log("🚀 ~ file: ExternalUsersApi.js ~ line 40 ~ result ~ alterResponse----", response?.data?.data)
        const alterResponse = response?.data?.data?.map(d => (
            {
                id: d?.id,
                developmentName: d?.development_name,
                mainContractor: d?.is_main_contractor == 1 ? d?.developer?.username : d?.main_contractor?.username,
                expectedCompletionDate: new Date(2022, 1, 7),
                surveyor: d?.surveyor?.username,
                nextSurveyDate: new Date(2022, 1, 7),
                actionNotified: new Date(2022, 1, 7),
                totalDefects: d?.defect[0]?.count,
                totalActions: 0,
                progress: 0,
            }
        ))
        return { ...response, data: { ...data, data: alterResponse } }
        // console.log("🚀 ~ file: ExternalUsersApi.js ~ line 46 ~ result ~ response", response)
        // return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: ExternalUsersApi.js ~ line 51 ~ GetSurveyorAcceptedDevelopments ~ result", result)
    return { data, status }
})

export const ChangeDevelopmentStatus = createAsyncThunk('externalUsers/ChangeDevelopmentStatus', async (params, { dispatch, getState }) => {
    console.log("🚀 ~ file: ExternalUsersApi.js ~ line 56 ~ ChangeDevelopmentStatus ~ params", params)
    var formData = new FormData();
    formData.append('status', params?.data);
    let result = await apiInstance.post(`/api/admin/status-development-by-surveyor/${params?.id}`, formData).then(function (response) {
        // toast("User created successfully")
        dispatch(GetSurveyorDevelopments())
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetCurrentViewedDevelopmentDetails = createAsyncThunk('externalUsers/GetCurrentViewedDevelopmentDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-development/${params}`).then(function (response) {
        // toast("User created successfully")
        // dispatch(GetSurveyorDevelopments())
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetMakeRequests = createAsyncThunk('externalUsers/GetMakeRequests', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get/make-request`).then(function (response) {
        // toast("User created successfully")
        // dispatch(GetSurveyorDevelopments())
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetMakeRequestsForOtherUsers = createAsyncThunk('externalUsers/GetMakeRequestsForOtherUsers', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/survey/make-request`).then(function (response) {
        // toast("User created successfully")
        // dispatch(GetSurveyorDevelopments())
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetSurveyorDetails = createAsyncThunk('externalUsers/GetSurveyorDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-surveyor-details`).then(function (response) {
        // toast("User created successfully")
        // dispatch(GetSurveyorDevelopments())
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})
