import React from "react";
import classnames from "classnames";
import { Prompt, useLocation } from "react-router-dom";
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
} from "reactstrap";
import './AdminNavbar.css'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ChangeDashboardNameForNavbar } from "App/Screens/ExternalUsers/Store/ExternalUsersSlice";
import { LogoutUser } from "App/Screens/Auth/Store/authSlice";
import { ToggleResetPage, ToggleResetDashboard } from "../store/pageSlice";

function ExternalUserNavbar(props) {
    let history = useHistory()
    const dispatch = useDispatch()
    const { dashboardNameForNavbar } = useSelector(state => state.ExternalUsers)
    const { userData } = useSelector(state => state.userData)

    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [color, setColor] = React.useState("navbar-transparent");
    const location = useLocation();
    React.useEffect(() => {
        window.addEventListener("resize", updateColor);
    });
    React.useEffect(() => {
        if (
            window.outerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
    }, [location]);
    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && collapseOpen) {
            setColor("bg-white");
        } else {
            setColor("navbar-transparent");
        }
    };
    // this function opens and closes the sidebar on small devices
    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        setSidebarOpen(!sidebarOpen);
    };
    // this function opens and closes the collapse on small devices
    // it also adds navbar-transparent class to the navbar when closed
    // ad bg-white when opened
    const toggleCollapse = () => {
        if (!collapseOpen) {
            setColor("bg-white");
        } else {
            setColor("navbar-transparent");
        }
        setCollapseOpen(!collapseOpen);
    };
    return (
        <>
            <Navbar
                className={classnames("navbar-absolute custom-navbar fixed-top py-0", color)}
                expand="lg"
                style={{ position: "sticky", zIndex: 2000, height: "50px", top: 0 }}
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <Link to="/user/dashboard">
                            <img
                                src={require('../../../Assets/Images/ARKlogo2.png').default}
                                style={{
                                    height: 40,
                                    cursor: "pointer"
                                }}
                                onClick={() => { dispatch(ToggleResetDashboard()); userData?.role == "Surveyor" ? dispatch(ChangeDashboardNameForNavbar("Surveyor Hub")) : userData?.role == "Broker" ? dispatch(ChangeDashboardNameForNavbar("Broker Hub")) : userData?.role == "Insurer" ? dispatch(ChangeDashboardNameForNavbar("Insurer Hub")) : userData?.role == "Developer" ? dispatch(ChangeDashboardNameForNavbar("Developer Hub")) : userData?.role == "Contractor" ? dispatch(ChangeDashboardNameForNavbar("Contractor Hub")) : userData?.role == "Financial Interest" ? dispatch(ChangeDashboardNameForNavbar("Financial Interest Hub")) : dispatch(ChangeDashboardNameForNavbar("Sub Contractor Hub")) }}
                            />
                        </Link>
                    </div>
                    <p style={{ marginLeft: "24px", color: "#ffffff" }}>{dashboardNameForNavbar}</p>
                    <div className=" navbar-button-container">

                        <div
                            onClick={() => {
                                history.push('/user/dashboard');
                                dispatch(ToggleResetDashboard());
                                dispatch(ToggleResetPage());
                                userData?.role == "Surveyor" ?
                                    dispatch(ChangeDashboardNameForNavbar("Surveyor Hub"))
                                    :
                                    userData?.role == "Broker" ?
                                        dispatch(ChangeDashboardNameForNavbar("Broker Hub"))
                                        :
                                        userData?.role == "Insurer" ?
                                            dispatch(ChangeDashboardNameForNavbar("Insurer Hub"))
                                            :
                                            userData?.role == "Developer" ?
                                                dispatch(ChangeDashboardNameForNavbar("Developer Hub"))
                                                :
                                                userData?.role == "Contractor" ?
                                                    dispatch(ChangeDashboardNameForNavbar("Contractor Hub"))
                                                    :
                                                    userData?.role == "Financial Interest" ?
                                                        dispatch(ChangeDashboardNameForNavbar("Financial Interest Hub"))
                                                        :
                                                        dispatch(ChangeDashboardNameForNavbar("Sub Contractor Hub"))
                            }}
                            className="navbar-button flex flex-row  items-center"
                        >
                            <img
                                src={require('../../../Assets/Svg/Home.svg').default}
                                style={{
                                    height: 15,
                                    width: 15,
                                }}
                            />
                            <span className="navbar-btn-text">Home</span>
                        </div>
                    </div>
                    <button
                        aria-controls="navigation-index"
                        aria-expanded={collapseOpen}
                        aria-label="Toggle navigation"
                        className="navbar-toggler"
                        // data-target="#navigation"
                        data-toggle="collapse"
                        type="button"
                        onClick={toggleCollapse}
                    >
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                    </button>
                    <Collapse
                        className="justify-content-end"
                        navbar
                        isOpen={collapseOpen}
                    >
                        <Nav navbar>
                            <UncontrolledDropdown className="btn-rotate" nav>
                                <DropdownToggle
                                    aria-haspopup={true}
                                    caret
                                    color="default"
                                    data-toggle="dropdown"
                                    id="navbarDropdownMenuLink"
                                    className="user-navitem"
                                    nav
                                >
                                    <div className="navbar-user-wrapper ">
                                        <div className="flex flex-column ">
                                            <span className="navbar-username ">{userData?.user?.username}</span>
                                            <span className="navbar-role">{userData?.role}</span>
                                        </div>
                                        <img
                                            src={require('../../../Assets/Images/jisoo.png').default}
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: 40,
                                                objectFit: 'cover',
                                                marginLeft: 10,
                                            }}
                                        />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu
                                    persist
                                    aria-labelledby="navbarDropdownMenuLink"
                                    right
                                >
                                    <DropdownItem
                                        href=""
                                        onClick={(e) => history.push("/user/profile")}
                                    >
                                        Profile
                                    </DropdownItem>
                                    <DropdownItem
                                        href=""
                                        onClick={(e) => {
                                            e.preventDefault()
                                            dispatch(LogoutUser({ history }))
                                        }}
                                    >
                                        Logout
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default ExternalUserNavbar;
