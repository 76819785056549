import {
  createTheme,
  ThemeProvider
} from '@mui/material/styles';
import App from "App";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "bootstrap/dist/css/bootstrap.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import store from './App/Store/index';
import './App/Styles/GlobalStyles.css';
import './App/Styles/TailwindOutput.css';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1B2330'
    },
    secondary: {
      main: '#2BA9CD'
    },
    action: {
      main: '#fff'
    },
  }
});

ReactDOM.render(
  <>

    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>,
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      theme='light' //todo: might need to change it with theme later
      progressStyle={{
        background: '#34B5B8'
      }}
      style={{
      }}
    />
  </>,
  document.getElementById("root")
);
