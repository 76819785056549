import React from 'react'
import Select from "react-select";
import {
    Button,
    Card, CardBody, CardHeader, Col, Container, FormGroup, Modal,
    Input, Progress, Row, Table
} from "reactstrap";

const AllocateDevelopment = ({ toggleModalFunction }) => {
    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Allocate Development
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Category</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        // value={stakeHolder}
                                        // onChange={(value) => setStakeHolder(value)}
                                        options={[
                                            {
                                                value: "",
                                                label: "Category",
                                                isDisabled: true,
                                            },
                                            { value: "2", label: "Category 1" },
                                            { value: "3", label: "Category 2" },
                                        ]}
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Action</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        // value={stakeHolder}
                                        // onChange={(value) => setStakeHolder(value)}
                                        options={[
                                            {
                                                value: "",
                                                label: "Action",
                                                isDisabled: true,
                                            },
                                            { value: "2", label: "Action 1" },
                                            { value: "3", label: "Action 2" },
                                        ]}
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Sub Category</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        // value={stakeHolder}
                                        // onChange={(value) => setStakeHolder(value)}
                                        options={[
                                            {
                                                value: "",
                                                label: "Sub Category",
                                                isDisabled: true,
                                            },
                                            { value: "2", label: "Sub Category 1" },
                                            { value: "3", label: "Sub Category 2" },
                                        ]}
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>To</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        // value={stakeHolder}
                                        // onChange={(value) => setStakeHolder(value)}
                                        options={[
                                            {
                                                value: "",
                                                label: "To",
                                                isDisabled: true,
                                            },
                                            { value: "2", label: "Contractor & Developer and All stakeholders" },
                                            { value: "3", label: "To 2" },
                                            { value: "4", label: "To 3" },
                                        ]}
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Due</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        // value={stakeHolder}
                                        // onChange={(value) => setStakeHolder(value)}
                                        options={[
                                            {
                                                value: "",
                                                label: "Due",
                                                isDisabled: true,
                                            },
                                            { value: "2", label: "Due 1" },
                                            { value: "3", label: "Due 2" },
                                        ]}
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Priority</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        // value={stakeHolder}
                                        // onChange={(value) => setStakeHolder(value)}
                                        options={[
                                            {
                                                value: "",
                                                label: "Priority",
                                                isDisabled: true,
                                            },
                                            { value: "2", label: "Priority 1" },
                                            { value: "3", label: "Priority 2" },
                                            { value: "4", label: "Priority 3" },
                                        ]}
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Col md="12" style={{ padding: 0 }}>
                        <FormGroup>
                            <label htmlFor="Details" style={{ fontSize: "12px", marginTop: 0 }}>Details</label>
                            <Input className="custom-input" placeholder="" type="text" />
                        </FormGroup>
                    </Col>
                    <Col md="12" style={{ padding: 0 }}>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" placeholder='Text here' style={{ minHeight: "150px", border: "1px solid #888888" }} />
                        </FormGroup>
                    </Col>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={toggleModalFunction} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllocateDevelopment