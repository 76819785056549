import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import './Login.css'
import { ForgetPasswordSendEmail, SignIn } from "./Store/authSlice";

function Login() {

  const dispatch = useDispatch();
  const history = useHistory();
  const { authError, forgetPasswordSendEmail } = useSelector(state => state?.userData)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [forgetPasswordEmail, setForgetPasswordEmail] = useState("");

  const [forgetPassword, setForgetPassword] = useState(false)

  function handleGetStatedClick(e) {
    e.preventDefault()
    history.push('/admin/dashboard')
  }


  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });
  return (
    <div className="login-page">
      {
        !forgetPassword &&
        <Container className="login-container">
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              {/* {authError?.data && <div className="alert alert-danger">{authError?.data?.error}</div>} */}
              <form className="form" method="" onSubmit={(e) => {
                e.preventDefault()
                dispatch(SignIn({ data: { email, password }, history }));
              }}>
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <div className="text-3xl text-center">
                        Login
                      </div>
                    </CardHeader>
                  </CardHeader>
                  <CardBody className="my-4">
                    {authError?.data && <p className="text-danger">{authError?.data?.email}</p>}
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </InputGroup>
                    {authError?.data && <p className="text-danger">{authError?.data?.password}</p>}
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                    <br />
                    <div className="login-additional-options">
                      {/* <div className="left-flex">
                      <Input defaultChecked defaultValue="" type="checkbox" />
                      <span className="remember-me">Remember Me</span>
                    </div> */}
                      <div className="right-flex">
                        <span className="forgot-pass" onClick={() => setForgetPassword(true)}>Forgot Password?</span>
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <button
                      block
                      type="submit"
                      className="btn-round mb-3 custom-btn btn-info"
                      // href="#pablo"
                      style={{ width: "100%", paddingTop: "8px", paddingBottom: "8px" }}
                    // onClick={() => {
                    //   dispatch(SignIn({ data: { email, password }, history }));
                    // }}
                    >
                      Get Started
                    </button>
                  </CardFooter>
                </Card>
              </form>
            </Col>
          </Row>
        </Container>
      }
      {
        forgetPassword &&
        <Container className="login-container">
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <form className="form" method="" onSubmit={(e) => {
                e.preventDefault()
                dispatch(ForgetPasswordSendEmail(forgetPasswordEmail));
              }}>
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <div className="text-3xl text-center">
                        Forget Password
                      </div>
                    </CardHeader>
                  </CardHeader>
                  {forgetPasswordSendEmail == null ?
                    <>
                      <CardBody className="my-4">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Email" type="email" value={forgetPasswordEmail} onChange={(e) => setForgetPasswordEmail(e.target.value)} />
                        </InputGroup>
                        <br />
                      </CardBody>
                      <CardFooter>
                        <button
                          block
                          type="submit"
                          className="btn-round mb-3 custom-btn btn-info"
                          // href="#pablo"
                          style={{ width: "100%", paddingTop: "8px", paddingBottom: "8px" }}
                        // onClick={() => {
                        //   dispatch(SignIn({ data: { email, password }, history }));
                        // }}
                        >
                          Send
                        </button>
                      </CardFooter>
                    </>
                    :
                    <div className="text-xl" style={{ padding: "16px", color: "blue" }}>{forgetPasswordSendEmail}</div>
                  }
                </Card>
              </form>
            </Col>
          </Row>
        </Container>
      }
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../Assets/Images/AuthBackground.png").default})`,
        }}
      />
    </div >
  );
}

export default Login;
