import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { GetDefectsOfSingleDevelopment } from 'App/Screens/Dashboard/Store/DashboardApi';
import { toast } from 'react-toastify';

export const GetAdminDevelopments = createAsyncThunk('administrationHistory/GetAdminDevelopments', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-development-approved').then(function (response) {
        // const alterResponse = response?.data?.data?.filter(f => f.status != 1)?.map(d => (
        //     {
        //         srNo: d?.id,
        //         surveyor: d?.surveyor?.username,
        //         development: d?.development_name,
        //         dateSubmitted: d?.created_at,
        //         status: d?.status == 0 && "Pending"
        //     }
        // ))
        // console.log("🚀 ~ file: AddNewDevelopmentApi.js ~ line 20 ~ result ~ response", response)
        // return { ...response, data: { ...data, data: alterResponse } }
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AdministrationHistoryApi.js ~ line 24 ~ GetAdminDevelopments ~ data", data)
    return { data, status }
})

export const GetAddDiaryCategory = createAsyncThunk('administrationHistory/GetAddDiaryCategory', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-category/dairy').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetSingleMakeRequestDetail = createAsyncThunk('administrationHistory/GetSingleMakeRequestDetail', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-single/make-request/${params.id}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, currentState: params?.currentState, status }
})

export const GetSingleAddDiaryDetail = createAsyncThunk('administrationHistory/GetSingleAddDiaryDetail', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-single/dairy/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, currentState: params?.currentState, status }
})

export const GetSingleAddNoteDetail = createAsyncThunk('administrationHistory/GetSingleAddNoteDetail', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-single/note/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, currentState: params?.currentState, status }
})

export const GetSingleMakeRequestDetails = createAsyncThunk('administrationHistory/GetSingleMakeRequestDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-single/make-request/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, currentState: params?.currentState, status }
})

export const GetSingleUploadDocumentDetail = createAsyncThunk('administrationHistory/GetSingleUploadDocumentDetail', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-single/upload-document/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, currentState: params?.currentState, status }
})

export const GetAddDefectCategory = createAsyncThunk('administrationHistory/GetAddDefectCategory', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-category/defect').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetAddNoteCategory = createAsyncThunk('administrationHistory/GetAddNoteCategory', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-category/note').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetMakeRequestCategory = createAsyncThunk('administrationHistory/GetMakeRequestCategory', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-category/make-request').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetUploadDocumentCategory = createAsyncThunk('administrationHistory/GetUploadDocumentCategory', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-category/upload-document').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetAddDiarySubCategoryByCategoryId = createAsyncThunk('administrationHistory/GetAddDiarySubCategoryByCategoryId', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-sub-category/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const AdministrationHistoryMarkAsComplete = createAsyncThunk('administrationHistory/AdministrationHistoryMarkAsComplete', async (params, { dispatch, getState }) => {
    const formData = new FormData()
    formData.append("status", params?.status)
    let result = await apiInstance.post(`/api/admin/mark-complete/${params.id}`, formData).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetUsersOfDevelopments = createAsyncThunk('administrationHistory/GetUsersOfDevelopments', async (params, { dispatch, getState }) => {
    console.log("🚀 ~ file: AdministrationHistoryApi.js ~ line 66 ~ GetUsersOfDevelopments ~ params", params)
    let result = await apiInstance.get(`api/admin/get-stake-internal-user/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetAdministrationHistoryAssignedTo = createAsyncThunk('administrationHistory/GetAdministrationHistoryAssignedTo', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-roles').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetAdministrationHistoryPriority = createAsyncThunk('administrationHistory/GetAdministrationHistoryPriority', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-priority').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetDiaries = createAsyncThunk('administrationHistory/GetDiaries', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get/dairy/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AdministrationHistoryApi.js:220 ~ GetDiaries ~ result", result)
    return { data, status }
})

export const GetLoginUserDiaries = createAsyncThunk('administrationHistory/GetLoginUserDiaries', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-user/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetAdmisistrationHistoryDataForSurveyor = createAsyncThunk('administrationHistory/GetAdmisistrationHistoryDataForSurveyor', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-administration/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetAdmisistrationHistoryToFieldDataForSurveyor = createAsyncThunk('administrationHistory/GetAdmisistrationHistoryToFieldDataForSurveyor', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-surveyor-user/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetNotes = createAsyncThunk('administrationHistory/GetNotes', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get/note/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetDefects = createAsyncThunk('administrationHistory/GetDefects', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-defect-by-surveyor').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const CreateAdmnistrationHistoryCategory = createAsyncThunk('administrationHistory/CreateAdmnistrationHistoryCategory', async (params, { dispatch, getState }) => {
    const { category_type,
        category_name,
        assigned_to } = params
    var formData = new FormData();
    formData.append('category_type', category_type);
    formData.append('category_name', category_name);
    formData.append('assigned_to', assigned_to);
    let result = await apiInstance.post('/api/admin/create-category', formData).then(function (response) {
        toast("Category created successfully")
        dispatch(GetAddDiaryCategory())
        dispatch(GetAddNoteCategory())
        dispatch(GetMakeRequestCategory())
        dispatch(GetAddDefectCategory())
        dispatch(GetUploadDocumentCategory())
        return response
    }).catch(function (error) {
        toast("Something went worng.")
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})

export const CreateAdministrationHistorySubCategory = createAsyncThunk('administrationHistory/CreateAdministrationHistorySubCategory', async (params, { dispatch, getState }) => {
    const { category_id,
        sub_category_name } = params
    var formData = new FormData();
    formData.append('category_id', category_id);
    formData.append('sub_category_name', sub_category_name);
    let result = await apiInstance.post('/api/admin/create-sub-category', formData).then(function (response) {
        toast("Sub category created successfully")
        // dispatch(GetAddDiaryCategory())
        return response
    }).catch(function (error) {
        toast("Something went wrong.")
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})

export const CreateAdministrationHistoryAddDiary = createAsyncThunk('administrationHistory/CreateAdministrationHistoryAddDiary', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/store/dairy', { ...params }).then(function (response) {
        toast("Diary added successfully")
        dispatch(GetDiaries(params?.development_id))
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})

export const CreateAdministrationHistoryAddNote = createAsyncThunk('administrationHistory/CreateAdministrationHistoryAddNote', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/store/note', { ...params }).then(function (response) {
        toast("Note added successfully")
        // dispatch(GetAddDiaryCategory())
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})

export const CreateAdministrationHistoryMakeRequest = createAsyncThunk('administrationHistory/CreateAdministrationHistoryMakeRequest', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/store/make-request', { ...params }).then(function (response) {
        toast("Request added successfully")
        // dispatch(GetAddDiaryCategory())
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})

export const CreateAdministrationHistoryUploadDocument = createAsyncThunk('administrationHistory/CreateAdministrationHistoryUploadDocument', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/store/upload-document', { ...params }).then(function (response) {
        toast("Document uploaded successfully")
        // dispatch(GetAddDiaryCategory())
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})

export const CreateAdministrationHistoryAddDefect = createAsyncThunk('administrationHistory/CreateAdministrationHistoryAddDefect', async (params, { dispatch, getState }) => {
    const {
        development_id,
        defect_action,
        notification_date,
        block,
        unit,
        risk,
        description,
        multiplefile,
        if_applicable,
        category_id,
        sub_category_id
    } = params

    // const formData = new FormData()

    // formData.append("development_id", development_id)
    // formData.append("defect_action", defect_action)
    // formData.append("notification_date", notification_date)
    // formData.append("block", block)
    // formData.append("unit", unit)
    // formData.append("risk", risk)
    // formData.append("description", description)
    // formData.append("multiplefile ", multiplefile)
    // formData.append("if_applicable", if_applicable)
    // formData.append("category_id", category_id)
    // formData.append("sub_category_id", sub_category_id)
    let result = await apiInstance.post('/api/admin/add-defect', { ...params }).then(function (response) {
        defect_action == "1" ? toast("Defect added successfully") : toast("Action Request added successfully")
        dispatch(GetDefectsOfSingleDevelopment(development_id))
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})
