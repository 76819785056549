import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import moment from 'moment';
import { toast } from 'react-toastify';

export const GetDevelopers = createAsyncThunk('addDevelopment/GetAllDeveloper', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-all-developer').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetDevelopments = createAsyncThunk('addDevelopment/GetDevelopments', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-development-surveyor').then(function (response) {
        console.log("🚀 ~ file: AddNewDevelopmentApi.js ~ line 18 ~ result ~ response", response)
        const alterResponse = response?.data?.data?.map(d => (
            {
                srNo: d?.id,
                surveyorId: d?.surveyor?.id,
                surveyor: d?.surveyor?.username,
                development: d?.development_name,
                dateSubmitted: d?.created_at,
                // status: d?.status == 0 && "Pending"
                status: d?.status == 0 && new Date(new Date().toJSON().slice(0, 10)) > new Date(d?.due_date) ? "Outstanding" : "Pending"
            }
        ))
        console.log("🚀 ~ file: AddNewDevelopmentApi.js ~ line 20 ~ result ~ response", response)
        return { ...response, data: { ...data, data: alterResponse } }
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetMainContractors = createAsyncThunk('addDevelopment/GetMainContractors', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-all-contractor').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetStakeholderTypeBusinessName = createAsyncThunk('addDevelopment/GetStakeholderTypeBusinessName', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-stakeholder/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetDeveloperPrimaryContact = createAsyncThunk('addDevelopment/GetDeveloperPrimaryContact', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-primary/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetDeveloperOtherContact = createAsyncThunk('addDevelopment/GetDeveloperOtherContact', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-another/${params?.trading_id}/${params?.selected_id}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetContractorPrimaryContact = createAsyncThunk('addDevelopment/GetContractorPrimaryContact', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-cont-prim-contact/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetContractorOtherContact = createAsyncThunk('addDevelopment/GetContractorOtherContact', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-cont-anoth-contact/${params?.trading_id}/${params?.selected_id}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetSingleSurveyorDetails = createAsyncThunk('addDevelopment/GetSingleSurveyorDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-surveyor/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetStakeholderTypeBusinessNamePrimaryContact = createAsyncThunk('addDevelopment/GetStakeholderTypeBusinessNamePrimaryContact', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-stk-primary-contact/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetStakeholderTypeBusinessNameOtherContact = createAsyncThunk('addDevelopment/GetStakeholderTypeBusinessNameOtherContact', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-stk-another-contact/${params?.trading_id}/${params?.selected_id}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetInsurerBrokerTypeBusinessNamePrimaryContact = createAsyncThunk('addDevelopment/GetInsurerBrokerTypeBusinessNamePrimaryContact', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-insuerorbroker-primary-contact/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetInsurerBrokerTypeBusinessNameOtherContact = createAsyncThunk('addDevelopment/GetInsurerBrokerTypeBusinessNameOtherContact', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-insuerorbroker-another-contact/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetInsurerBrokerPrimaryAndOtherContact = createAsyncThunk('addDevelopment/GetInsurerBrokerPrimaryAndOtherContact', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/getContactInsurerBroker/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const CreateDevelopmentGeneralDetails = createAsyncThunk('addDevelopment/CreateDevelopmentGeneralDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/general-details', {
        ...params
    }).then(function (response) {
        // toast("User created successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const UpdateDevelopmentGeneralDetails = createAsyncThunk('addDevelopment/UpdateDevelopmentGeneralDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post(`/api/admin/update-general-details/${params?.id}`, {
        ...params?.data
    }).then(function (response) {
        // toast("User created successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const CreateDevelopmentSiteInvestigation = createAsyncThunk('addDevelopment/CreateDevelopmentSiteInvestigation', async (params, { dispatch, getState }) => {
    const {
        isExposureZone,
        isPlasticityIndexReport,
        areTreesOnSite,
        isFloodRisksReport,
        isMiningAreaReport,
        isContaminatedLandReport,
        isGroundGasesReport,
        requiredEnhancedGeoInfo,
        coastalLocationField,
        geotechnicalInformationRequired,
        groundGasReportFile,
        landReportFile,
        miningAreaReportFile,
        floodRisksReportFile,
        treeOnSiteFile,
        soilsTypeFile,
        exposureReportFile,
        general_details_id
    } = params
    const formData = new FormData();
    formData.append("coastel_location", coastalLocationField),
        formData.append("is_report_recieved", geotechnicalInformationRequired),
        formData.append("enhance_geo_info", requiredEnhancedGeoInfo),
        formData.append("ground_gases_report", isGroundGasesReport),
        formData.append("land_report", isContaminatedLandReport),
        formData.append("mining_area_report", isMiningAreaReport),
        formData.append("flood_risks_report", isFloodRisksReport),
        formData.append("tree_on_site", areTreesOnSite),
        formData.append("soils_type", isPlasticityIndexReport),
        formData.append("exposure_report", isExposureZone),
        formData.append("general_details_id", general_details_id),
        formData.append("ground_gases_report_file", groundGasReportFile),
        formData.append("land_report_file", landReportFile),
        formData.append("mining_area_report_file", miningAreaReportFile),
        formData.append("flood_risks_report_file", floodRisksReportFile),
        formData.append("tree_on_site_file", treeOnSiteFile),
        formData.append("soils_type_file", soilsTypeFile),
        formData.append("exposure_report_file", exposureReportFile)

    let result = await apiInstance.post('/api/admin/site-investigation',
        formData
    ).then(function (response) {
        // toast("User created successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const UpdateDevelopmentSiteInvestigation = createAsyncThunk('addDevelopment/UpdateDevelopmentSiteInvestigation', async (params, { dispatch, getState }) => {
    const {
        isExposureZone,
        isPlasticityIndexReport,
        areTreesOnSite,
        isFloodRisksReport,
        isMiningAreaReport,
        isContaminatedLandReport,
        isGroundGasesReport,
        requiredEnhancedGeoInfo,
        coastalLocationField,
        geotechnicalInformationRequired,
        groundGasReportFile,
        landReportFile,
        miningAreaReportFile,
        floodRisksReportFile,
        treeOnSiteFile,
        soilsTypeFile,
        exposureReportFile,
        general_details_id
    } = params?.data
    const formData = new FormData();
    formData.append("coastel_location", coastalLocationField),
        formData.append("is_report_recieved", geotechnicalInformationRequired),
        formData.append("enhance_geo_info", requiredEnhancedGeoInfo),
        formData.append("ground_gases_report", isGroundGasesReport),
        formData.append("land_report", isContaminatedLandReport),
        formData.append("mining_area_report", isMiningAreaReport),
        formData.append("flood_risks_report", isFloodRisksReport),
        formData.append("tree_on_site", areTreesOnSite),
        formData.append("soils_type", isPlasticityIndexReport),
        formData.append("exposure_report", isExposureZone),
        formData.append("general_details_id", general_details_id),
        formData.append("ground_gases_report_file", groundGasReportFile),
        formData.append("land_report_file", landReportFile),
        formData.append("mining_area_report_file", miningAreaReportFile),
        formData.append("flood_risks_report_file", floodRisksReportFile),
        formData.append("tree_on_site_file", treeOnSiteFile),
        formData.append("soils_type_file", soilsTypeFile),
        formData.append("exposure_report_file", exposureReportFile)

    let result = await apiInstance.post(`api/admin/update-site-investigation/${params?.id}`,
        formData
    ).then(function (response) {
        // toast("User created successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const CreateDevelopmentBuildFactors = createAsyncThunk('addDevelopment/CreateDevelopmentBuildFactors', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/build-factor', {
        ...params
    }).then(function (response) {
        // toast("User created successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const UpdateDevelopmentBuildFactors = createAsyncThunk('addDevelopment/UpdateDevelopmentBuildFactors', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post(`api/admin/update-build-factor/${params?.id}`, {
        ...params?.data
    }).then(function (response) {
        // toast("User created successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const CreateDevelopmentRefurbishment = createAsyncThunk('addDevelopment/CreateDevelopmentRefurbishment', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/refurbishment', {
        ...params
    }).then(function (response) {
        // toast("User created successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const UpdateDevelopmentRefurbishment = createAsyncThunk('addDevelopment/UpdateDevelopmentRefurbishment', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post(`api/admin/update-refurbishment/${params?.id}`, {
        ...params?.data
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const CreateDevelopmentSurveyRequirements = createAsyncThunk('addDevelopment/CreateDevelopmentSurveyRequirements', async (params, { dispatch, getState }) => {
    const {
        numberOfExcavations,
        numberOfSubstructure,
        numberOfBasements,
        numberOfSuperstructure,
        roofDifferentBlocks,
        numberOfPlaster,
        numberOfFinalInspections,
        numberOfSurveys,
        general_details_id
    } = params
    let formData = new FormData();
    formData.append('general_details_id', general_details_id);
    formData.append('no_of_excavations', numberOfExcavations);
    formData.append('no_of_substructure', numberOfSubstructure);
    formData.append('no_of_basements', numberOfBasements);
    formData.append('no_of_superstructure', numberOfSuperstructure);
    formData.append('roof_differnt_blocks', roofDifferentBlocks);
    formData.append('no_of_plaster', numberOfPlaster);
    formData.append('no_of_final_inspection', numberOfFinalInspections);
    formData.append('total_no_survey', numberOfSurveys);
    let result = await apiInstance.post('/api/admin/survey-requirement', formData).then(function (response) {
        // toast("User created successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const UpdateDevelopmentSurveyRequirements = createAsyncThunk('addDevelopment/UpdateDevelopmentSurveyRequirements', async (params, { dispatch, getState }) => {
    const {
        numberOfExcavations,
        numberOfSubstructure,
        numberOfBasements,
        numberOfSuperstructure,
        roofDifferentBlocks,
        numberOfPlaster,
        numberOfFinalInspections,
        numberOfSurveys,
        general_details_id
    } = params?.data
    let formData = new FormData();
    formData.append('general_details_id', general_details_id);
    formData.append('no_of_excavations', numberOfExcavations);
    formData.append('no_of_substructure', numberOfSubstructure);
    formData.append('no_of_basements', numberOfBasements);
    formData.append('no_of_superstructure', numberOfSuperstructure);
    formData.append('roof_differnt_blocks', roofDifferentBlocks);
    formData.append('no_of_plaster', numberOfPlaster);
    formData.append('no_of_final_inspection', numberOfFinalInspections);
    formData.append('total_no_survey', numberOfSurveys);
    let result = await apiInstance.post(`api/admin/update-survey-requirement/${params?.id}`, formData).then(function (response) {
        // toast("User created successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const CreateDevelopmentAssignToSurveyor = createAsyncThunk('addDevelopment/CreateDevelopmentAssignToSurveyor', async (params, { dispatch, getState }) => {
    const {
        generalDetailsId,
        surveyorId,
        dueDate
    } = params?.data;
    let formData = new FormData();
    formData.append('general_details_id', generalDetailsId);
    formData.append('surveyor_id', surveyorId);
    formData.append('due_date', dueDate);
    let result = await apiInstance.post('/api/admin/assign-to-surveyor', formData).then(function (response) {
        toast("Development assigned to surveyor successfully")
        params?.clearForm()
        params.history.push("/admin/dashboard")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetDevelopmentGeneralDetails = createAsyncThunk('addDevelopment/GetDevelopmentGeneralDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-general-details/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetDevelopmentSiteInvestigationDetails = createAsyncThunk('addDevelopment/GetDevelopmentSiteInvestigationDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-site-investigation/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetDevelopmentBuildFactorDetails = createAsyncThunk('addDevelopment/GetDevelopmentBuildFactorDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-build-factor/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetDevelopmentRefurbishmentDetails = createAsyncThunk('addDevelopment/GetDevelopmentRefurbishmentDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-refurbishment/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})


export const GetDevelopmentSurveyRequiremntDetails = createAsyncThunk('addDevelopment/GetDevelopmentSurveyRequiremntDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-survey-requirement/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetDeveloperTrading = createAsyncThunk('addDevelopment/GetDeveloperTrading', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-all-trading/developer`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetContractorTrading = createAsyncThunk('addDevelopment/GetContractorTrading', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-all-trading/contractor`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetInsurerTrading = createAsyncThunk('addDevelopment/GetInsurerTrading', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-all-trading/insurer`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetBrokerTrading = createAsyncThunk('addDevelopment/GetBrokerTrading', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-all-trading/broker`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetFinancialInterestTrading = createAsyncThunk('addDevelopment/GetFinancialInterestTrading', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-all-trading/financialinterest`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetSubContractorTrading = createAsyncThunk('addDevelopment/GetSubContractorTrading', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-all-trading/subcontractor`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetDeveloperUsersByBusinessDetailId = createAsyncThunk('addDevelopment/GetDeveloperUsersByBusinessDetailId', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-user-by-trading/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetContractorUsersByBusinessDetailId = createAsyncThunk('addDevelopment/GetContractorUsersByBusinessDetailId', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-user-by-trading/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetInsurerOrBrokerUsersByBusinessDetailId = createAsyncThunk('addDevelopment/GetInsurerOrBrokerUsersByBusinessDetailId', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`api/admin/get-user-by-trading/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})