import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EuroIcon from '@mui/icons-material/Euro';
import { CircularProgress, FormControlLabel, FormLabel, IconButton, Input, InputAdornment, Radio, RadioGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardFooter, Col, Row } from "reactstrap";
import { CreateDevelopmentRefurbishment, GetDevelopmentBuildFactorDetails, UpdateDevelopmentRefurbishment } from '../AddNewDevelopment/Store/AddNewDevelopmentApi';
import { ChangeDevelopmentMode, ChangeDevelopmentStepValue } from '../AddNewDevelopment/Store/AddNewDevelopmentSlice';
import './Refurbishment.css';

function Refurbishment(props) {

    const dispatch = useDispatch()
    const { value, developmentGeneralDetails, getDevelopmentRefurbishmentDetails, developmentMode, developmentRefurbishmentError, getDevelopmentGeneralDetails } = useSelector(state => state.AddNewDevelopment)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const separatingWallsFloorsCompliances = [
        'Iron',
        'Wood',
        'Plastic',
    ];

    const [StructuralDeckDrainage, setStructuralDeckDrainage] = useState('');
    const [externalDoorsNewOrExisting, setExternalDoorsNewOrExisting] = useState('');
    const handleChangeExternalDoorsNewOrExisting = (event) => {
        setExternalDoorsNewOrExisting(event.target.value);
    };
    const [specialistCladdingReports, setSpecialistCladdingReports] = useState('');
    const [specialistCladdingReportsFile, setSpecialistCladdingReportsFile] = useState(null);
    const handleChangeSpecialistCladdingReports = (event) => {
        setSpecialistCladdingReports(event.target.value);
    };
    const [areTheFlatroofcoveringsNew, setAreTheFlatroofcoveringsNew] = useState('');
    const handleChangeAreTheFlatroofcoveringsNew = (event) => {
        setAreTheFlatroofcoveringsNew(event.target.value);
    };
    const floorsCompliancesList = [
        {
            id: 1,
            name: 'Part E',
        },
        {
            id: 2,
            name: 'Not applicable',
        },


    ];
    const [floorsCompliances, setFloorsCompliances] = useState([]);
    const handleChangeFloorsCompliances = (event) => {
        const {
            target: { value },
        } = event;
        setFloorsCompliances(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const flatRoofDrainages = [
        {
            id: 1,
            name: 'Foul ',
        },
        {
            id: 2,
            name: 'Surface',
        },


    ];
    const [flatRoofDrainage, setFlatRoofDrainage] = useState([]);
    const handleChangeFlatRoofDrainages = (event) => {
        const {
            target: { value },
        } = event;
        setFlatRoofDrainage(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const flatRoofRiskAreasList = [
        {
            id: 1,
            name: 'Balcony',
        },
        {
            id: 2,
            name: 'Parapet',
        },
        {
            id: 3,
            name: 'Chimney',
        },
        {
            id: 4,
            name: 'Basement ',
        },
        {
            id: 5,
            name: 'Podium Deck ',
        },





    ];
    const [flatRoofRiskAreas, setFlatRoofRiskAreas] = useState([]);
    const handleChangeFlatRoofRiskAreas = (event) => {
        const {
            target: { value },
        } = event;
        setFlatRoofRiskAreas(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const flatRoofCoveringTypes = [
        {
            id: 1,
            name: 'Single Ply',
        },
        {
            id: 2,
            name: 'Mastic Asphalt',
        },
        {
            id: 3,
            name: 'Liquid Applied',
        },
        {
            id: 4,
            name: 'GRP',
        },




    ];
    const [flatRoofCoveringType, setflatRoofCoveringType] = useState([]);
    const handleChangeflatRoofCoveringTypes = (event) => {
        const {
            target: { value },
        } = event;
        setflatRoofCoveringType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const flatRoofConstructionTypes = [
        {
            id: 1,
            name: 'Timber',
        },
        {
            id: 2,
            name: 'Concrete',
        },
        {
            id: 3,
            name: 'Metal Deck',
        },
        {
            id: 4,
            name: 'Warm Deck',
        },
        {
            id: 5,
            name: 'Cold Deck',
        },
        {
            id: 6,
            name: 'Inverted Warm Deck',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [flatRoofConstructionType, setFlatRoofConstructionType] = useState([]);
    const handleChangeFlatRoofConstructionTypes = (event) => {
        const {
            target: { value },
        } = event;
        setFlatRoofConstructionType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const roofCoveringTypes = [
        {
            id: 1,
            name: 'Clay Tile',
        },
        {
            id: 2,
            name: 'Concrete Tile',
        },
        {
            id: 3,
            name: 'Synthetic Slate',
        },
        {
            id: 4,
            name: 'Natural Slate',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [roofCoveringType, setRoofCoveringType] = useState([]);
    const handleChangeRoofCoveringTypes = (event) => {
        const {
            target: { value },
        } = event;
        setRoofCoveringType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const roofStructureTypes = [
        {
            id: 1,
            name: 'Trussed Rafter',
        },
        {
            id: 2,
            name: 'Traditional Cut',
        },
        {
            id: 3,
            name: 'Cassette',
        },
        {
            id: 0,
            name: 'Other',
        },

    ];
    const [roofStructureType, setRoofStructureType] = useState([]);
    const handleChangeRoofStructureTypes = (event) => {
        const {
            target: { value },
        } = event;
        setRoofStructureType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const upperFloorConstructionTypes = [
        {
            id: 1,
            name: 'Solid Timber Joist',
        },
        {
            id: 2,
            name: 'Engineered Timber Joist',
        },
        {
            id: 3,
            name: 'Beam & Block',
        },
        {
            id: 4,
            name: 'Concrete Plank',
        },
        {
            id: 5,
            name: 'Metal Deck',
        },





    ];
    const [upperFloorConstructionType, setUpperFloorConstructionType] = useState([]);
    const handleChangeUpperFloorConstructionTypes = (event) => {
        const {
            target: { value },
        } = event;
        setUpperFloorConstructionType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const [roofCoveringMaintained, setRoofCoveringMaintained] = useState([{}]);
    const handleChangeRoofCoveringMaintaineds = (event) => {
        const {
            target: { value },
        } = event;
        setRoofCoveringMaintained(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const [roofCoveringNewOrExisting, setRoofCoveringNewOrExisting] = useState(null);
    const handleChangeRoofCoveringNewOrExistings = (event) => {
        const {
            target: { value },
        } = event;
        setRoofCoveringNewOrExisting(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const [roofStructuresNewOrExisting, setRoofStructuresNewOrExisting] = useState(null);
    const handleChangeRoofStructuresNewOrExistings = (event) => {
        const {
            target: { value },
        } = event;
        setRoofStructuresNewOrExisting(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const [upperFloorsNewOrExisting, setUpperFloorsNewOrExisting] = useState(null);
    const handleChangeUpperFloorsNewOrExistings = (event) => {
        const {
            target: { value },
        } = event;
        setUpperFloorsNewOrExisting(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const externalWallFinishTypes = [
        {
            id: 1,
            name: 'Brick Clad',
        },
        {
            id: 2,
            name: 'Stone Clad',
        },
        {
            id: 3,
            name: 'Render',
        },
        {
            id: 4,
            name: 'Timber Clad',
        },
        {
            id: 5,
            name: 'Brick Slip',
        },
        {
            id: 6,
            name: 'Rainscreen ',
        },
        {
            id: 7,
            name: 'Repointing',
        },
        {
            id: 0,
            name: 'Other',
        },








    ];
    const [externalWallFinishType, setExternalWallFinishType] = useState([]);
    const handleChangeExternalWallFinishTypes = (event) => {
        const {
            target: { value },
        } = event;
        setExternalWallFinishType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const insulationMaterialTypes = [
        {
            id: 1,
            name: 'Fibreglass',
        },
        {
            id: 2,
            name: 'Mineral Wool',
        },
        {
            id: 3,
            name: 'Cellulose',
        },
        {
            id: 4,
            name: 'Natural Fibres',
        },
        {
            id: 5,
            name: 'Polystyrene',
        },
        {
            id: 6,
            name: 'Polyisocyanurate',
        },
        {
            id: 7,
            name: 'Polyurethane',
        },
        {
            id: 8,
            name: 'Vermiculate and perlite',
        },
        {
            id: 9,
            name: 'Urea-formaldehyde foam',
        },
        {
            id: 10,
            name: 'Cementitious foam',
        },
        {
            id: 11,
            name: 'Phenolic foam',
        },
        {
            id: 12,
            name: 'Insulation facings',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [insulationMaterialType, setInsulationMaterialType] = useState([]);
    const handleChangeInsulationMaterialTypes = (event) => {
        const {
            target: { value },
        } = event;
        setInsulationMaterialType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const insulationFills = [
        {
            id: 1,
            name: 'Partial Fill',
        },
        {
            id: 2,
            name: 'Full Fill',
        },
        {
            id: 3,
            name: 'Independently lined',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];

    const [insulationFill, setInsulationFill] = useState([]);
    const handleChangeInsulationFills = (event) => {
        const {
            target: { value },
        } = event;
        setInsulationFill(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const externalWallConstructionTypes = [
        {
            id: 1,
            name: 'Traditional Cavity',
        },
        {
            id: 2,
            name: 'Solid Wall',
        },
        {
            id: 3,
            name: 'Steel Frame',
        },
        {
            id: 4,
            name: 'Timber Frame',
        },
        {
            id: 5,
            name: 'ICF',
        },
        {
            id: 6,
            name: 'MMC',
        },
        {
            id: 0,
            name: 'Other',
        },




    ];
    const [externalWallConstructionType, setExternalWallConstructionType] = useState([]);
    const handleChangeExternalWallConstructionTypes = (event) => {
        const {
            target: { value },
        } = event;
        setExternalWallConstructionType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const groundFloorConstructionTypes = [
        {
            id: 1,
            name: 'Ground Bearing',
        },
        {
            id: 2,
            name: 'Suspended Slab',
        },
        {
            id: 3,
            name: 'Beam & Block',
        },
        {
            id: 0,
            name: 'Other',
        },




    ];
    const [groundFloorConstructionType, setGroundFloorConstructionType] = useState([]);
    const handleChangeGroundFloorConstructionTypes = (event) => {
        const {
            target: { value },
        } = event;
        setGroundFloorConstructionType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const [isTheGroundFloorNew, setIsTheGroundFloorNew] = useState('');
    const handleChangeIsTheGroundFloorNew = (event) => {
        setIsTheGroundFloorNew(event.target.value);
    };
    const [basementUndergoingAnyChange, setBasementUndergoingAnyChange] = useState('');
    const handleChangeBasementUndergoingAnyChange = (event) => {
        setBasementUndergoingAnyChange(event.target.value);
    };
    const [areFoundationsNew, setAreFoundationsNew] = useState('');
    const handleChangeAreFoundationsNew = (event) => {
        setAreFoundationsNew(event.target.value);
    };
    const [timberReportsAvailable, setTimberReportsAvailable] = useState('');
    const handleChangeTimberReportsAvailable = (event) => {
        setTimberReportsAvailable(event.target.value);
    };
    const [areSpecialistDrainageReports, setAreSpecialistDrainageReports] = useState('');
    const [areSpecialistDrainageReportsFile, setAreSpecialistDrainageReportsFile] = useState(null);
    const handleChangeAreSpecialistDrainageReports = (event) => {
        setAreSpecialistDrainageReports(event.target.value);
    };
    const [specialistRoofingReports, setSpecialistRoofingReports] = useState('');
    const [specialistRoofingReportsFile, setSpecialistRoofingReportsFile] = useState(null);
    const handleChangeSpecialistRoofingReports = (event) => {
        setSpecialistRoofingReports(event.target.value);
    };
    const [basementWaterproofReports, setBasementWaterproofReports] = useState('');
    const [basementWaterproofReportsFile, setBasementWaterproofReportsFile] = useState(null);
    const handleChangeBasementWaterproofReports = (event) => {
        setBasementWaterproofReports(event.target.value);
    };
    const [specialistTimberReports, setSpecialistTimberReports] = useState('');
    const [specialistTimberReportsFile, setSpecialistTimberReportsFile] = useState(null);
    const handleChangeSpecialistTimberReports = (event) => {
        setSpecialistTimberReports(event.target.value);
    };
    const [specialistDampReports, setSpecialistDampReports] = useState('');
    const [specialistDampReportsFile, setSpecialistDampReportsFile] = useState(null);
    const handleChangeSpecialistDampReports = (event) => {
        setSpecialistDampReports(event.target.value);
    };
    const [typeOfStructuralEngineers, setTypeOfStructuralEngineers] = useState('');
    const handleChangeTypeOfStructuralEngineers = (event) => {
        setTypeOfStructuralEngineers(event.target.value);
    };
    const [refurbishmentOrRenovationWorksAtDevelopment, setRefurbishmentOrRenovationWorksAtDevelopment] = useState('');
    const handleChangeRefurbishmentOrRenovationWorksAtDevelopment = (event) => {
        setRefurbishmentOrRenovationWorksAtDevelopment(event.target.value);
    };
    const [anyRefurbishmentAtDevelopment, setAnyRefurbishmentAtDevelopment] = useState(0);
    const handleChangeAnyRefurbishmentAtDevelopment = (event) => {
        setAnyRefurbishmentAtDevelopment(event.target.value);
    };
    const foundationTypes = [
        {
            id: 1,
            name: 'Piled',
        },
        {
            id: 2,
            name: 'Strip',
        },
        {
            id: 3,
            name: 'Trench (mass) Filled',
        },
        {
            id: 4,
            name: 'Raft',
        },
        {
            id: 5,
            name: 'Underpinned',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [otherFoundationType, setOtherFoundationType] = useState([]);
    const [foundationType, setFoundationType] = useState([]);
    const handleChangeFoundationTypes = (event) => {
        const {
            target: { value },
        } = event;
        setFoundationType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const [IsDrainageNew, setIsDrainageNew] = useState('');
    const handleChangeIsDrainageNew = (event) => {
        setIsDrainageNew(event.target.value);
    };

    const [IsRefurbishment, setIsRefurbishment] = useState('');

    const handleChangeStructuralDeckDrainage = (event) => {
        setStructuralDeckDrainage(event.target.value);
    };
    const handleChangeIsRefurbishment = (event) => {
        setIsRefurbishment(event.target.value);
    };

    const [existingValue, setExistingValue] = useState(null)
    const [otherGroundFloorType, setOtherGroundFloorType] = useState("")
    const [otherExternalWallConstructionType, setOtherExternalWallConstructionType] = useState("")
    const [otherInsulationFill, setOtherInsulationFill] = useState("")
    const [otherInsulationMaterialType, setOtherInsulationMaterialType] = useState("")
    const [otherExternalWallId, setOtherExternalWallId] = useState("")
    const [otherRoofStructureType, setOtherRoofStructureType] = useState("")
    const [otherRoofCoveringType, setOtherRoofCoveringType] = useState("")
    const [otherFlatConstructionType, setOtherFlatConstructionType] = useState("")

    const convertBase64 = (file) => {
        console.log("🚀 ~ file: Refurbishment.js ~ line 663 ~ convertBase64 ~ file", file)
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    useEffect(() => {
        if (developmentMode !== "add" || developmentMode === "previousBtn") {
            setAnyRefurbishmentAtDevelopment(getDevelopmentRefurbishmentDetails?.refurbishment_type || 0)
            setRefurbishmentOrRenovationWorksAtDevelopment(getDevelopmentRefurbishmentDetails?.define_refurbishment_type || "")
            setExistingValue(getDevelopmentRefurbishmentDetails?.existing_value || null)
            setTypeOfStructuralEngineers(getDevelopmentRefurbishmentDetails?.type_of_structural_report || "")
            setSpecialistDampReports(getDevelopmentRefurbishmentDetails?.specialist_damp_report || "")
            setSpecialistDampReportsFile(getDevelopmentRefurbishmentDetails?.specialist_damp_file || null)
            setSpecialistTimberReports(getDevelopmentRefurbishmentDetails?.specialist_timber_report || "")
            setSpecialistTimberReportsFile(getDevelopmentRefurbishmentDetails?.specialist_timber_file || null)
            setBasementWaterproofReports(getDevelopmentRefurbishmentDetails?.specialist_basement_report || "")
            setBasementWaterproofReportsFile(getDevelopmentRefurbishmentDetails?.specialist_basement_file || null)
            setSpecialistCladdingReports(getDevelopmentRefurbishmentDetails?.specialist_cladding_report || "")
            setSpecialistCladdingReportsFile(getDevelopmentRefurbishmentDetails?.specialist_cladding_file || null)
            setSpecialistRoofingReports(getDevelopmentRefurbishmentDetails?.specialist_roofing_report || "")
            setSpecialistRoofingReportsFile(getDevelopmentRefurbishmentDetails?.specialist_roofing_file || null)
            setAreSpecialistDrainageReports(getDevelopmentRefurbishmentDetails?.specialist_drainage_report || "")
            setAreSpecialistDrainageReportsFile(getDevelopmentRefurbishmentDetails?.specialist_drainage_file || null)
            setBasementUndergoingAnyChange(getDevelopmentRefurbishmentDetails?.basement_undergoing || "")
            setIsDrainageNew(getDevelopmentRefurbishmentDetails?.is_drainage || "")
            setAreFoundationsNew(getDevelopmentRefurbishmentDetails?.foundation || "")

            const constructFoundationTypeData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "foundation_type")
            const constructFoundationTypeDataSimplified = constructFoundationTypeData?.map(b => b?.value)
            setFoundationType(constructFoundationTypeDataSimplified || [])


            const constructFoundationTypeOtherData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "foundation_type" && b?.value == 0)
            const constructFoundationTypeOtherDataSimplified = constructFoundationTypeOtherData?.map(b => b?.other)
            setOtherFoundationType(constructFoundationTypeOtherDataSimplified || "")

            // setIsTheGroundFloorNew(getDevelopmentRefurbishmentDetails?. || )
            // setGroundFloorConstructionType(getDevelopmentRefurbishmentDetails?. || )
            // setOtherGroundFloorType(getDevelopmentRefurbishmentDetails?. || )

            const constructExternalWallConstructionData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "super_structure")
            const constructExternalWallConstructionDataSimplified = constructExternalWallConstructionData?.map(b => b?.value)
            setExternalWallConstructionType(constructExternalWallConstructionDataSimplified || [])

            const constructExternalWallConstructionOtherData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "super_structure" && b?.value == 0)
            const constructExternalWallConstructionOtherDataSimplified = constructExternalWallConstructionOtherData?.map(b => b?.other)
            setOtherExternalWallConstructionType(constructExternalWallOtherDataSimplified || "")

            const constructInsulationFillData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "insulation_fill")
            const constructInsulationFillDataSimplified = constructInsulationFillData?.map(b => b?.value)
            setInsulationFill(constructInsulationFillDataSimplified || [])

            const constructInsulationFillOtherData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "insulation_fill" && b?.value == 0)
            const constructInsulationFillOtherDataSimplified = constructInsulationFillOtherData?.map(b => b?.other)
            setOtherInsulationFill(constructInsulationFillOtherDataSimplified || "")

            const constructInsulationMaterialData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "insulation_material")
            const constructInsulationMaterialDataSimplified = constructInsulationMaterialData?.map(b => b?.value)
            setInsulationMaterialType(constructInsulationMaterialDataSimplified || [])

            const constructInsulationMaterialOtherData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "insulation_material" && b?.value == 0)
            const constructInsulationMaterialOtherDataSimplified = constructInsulationMaterialOtherData?.map(b => b?.other)
            setOtherInsulationMaterialType(constructInsulationMaterialOtherDataSimplified || "")

            const constructFloorComplianceData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "separating_wall")
            const constructFloorComplianceDataSimplified = constructFloorComplianceData?.map(b => b?.value)
            setFloorsCompliances(constructFloorComplianceDataSimplified || [])

            const constructExternalWallData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "external_wall")
            const constructExternalWallDataSimplified = constructExternalWallData?.map(b => b?.value)
            setExternalWallFinishType(constructExternalWallDataSimplified || [])

            const constructExternalWallOtherData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "external_wall" && b?.value == 0)
            const constructExternalWallOtherDataSimplified = constructExternalWallOtherData?.map(b => b?.other)
            setOtherExternalWallId(constructExternalWallOtherDataSimplified || "")

            setUpperFloorsNewOrExisting(getDevelopmentRefurbishmentDetails?.is_upper_floor || "")
            setRoofCoveringNewOrExisting(getDevelopmentRefurbishmentDetails?.is_roof_covering || "")

            const constructUpperFloorData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "upper_floor")
            const constructUpperFloorDataSimplified = constructUpperFloorData?.map(b => b?.value)
            setUpperFloorConstructionType(constructUpperFloorDataSimplified || [])

            setRoofStructuresNewOrExisting(getDevelopmentRefurbishmentDetails?.is_roof_structure || "")


            const constructRoofStructureData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "roof_structure")
            const constructRoofStructureDataSimplified = constructRoofStructureData?.map(b => b?.value)
            setRoofStructureType(constructRoofStructureDataSimplified || [])

            const constructRoofStructureOtherData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "roof_structure" && b?.value == 0)
            const constructRoofStructureOtherDataSimplified = constructRoofStructureOtherData?.map(b => b?.other)
            setOtherRoofStructureType(constructRoofStructureOtherDataSimplified || "")

            const constructRoofCoveringData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "roof_covering")
            const constructRoofCoveringDataSimplified = constructRoofCoveringData?.map(b => b?.value)
            setRoofCoveringType(constructRoofCoveringDataSimplified || [])

            const constructRoofCoveringOtherData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "roof_covering" && b?.value == 0)
            const constructRoofCoveringOtherDataSimplified = constructRoofCoveringOtherData?.map(b => b?.other)
            setOtherRoofCoveringType(constructRoofCoveringOtherDataSimplified || "")

            setRoofCoveringMaintained(getDevelopmentRefurbishmentDetails?.is_flat_roof || "")

            const constructFlatRoofConstructionData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "flat_covering")
            const constructFlatRoofConstructionDataSimplified = constructFlatRoofConstructionData?.map(b => b?.value)
            setFlatRoofConstructionType(constructFlatRoofConstructionDataSimplified || [])

            const constructFlatRoofConstructionOtherData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "flat_covering" && b?.value == 0)
            const constructFlatRoofConstructionOtherDataSimplified = constructFlatRoofConstructionOtherData?.map(b => b?.other)
            setOtherFlatConstructionType(constructFlatRoofConstructionOtherDataSimplified || "")

            setAreTheFlatroofcoveringsNew(getDevelopmentRefurbishmentDetails?.flat_roof_covering || "")

            // setflatRoofCoveringType(getDevelopmentRefurbishmentDetails?. || )

            const constructFlatRoofRiskData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "specific_flat_roof")
            const constructFlatRoofRiskSimplified = constructFlatRoofRiskData?.map(b => b?.value)
            setFlatRoofRiskAreas(constructFlatRoofRiskSimplified || [])

            const constructFlatRoofDrainageData = getDevelopmentRefurbishmentDetails?.refurb_foundation_type?.filter(b => b?.type === "specific_drainage")
            const constructFlatRoofDrainageSimplified = constructFlatRoofDrainageData?.map(b => b?.value)
            setFlatRoofDrainage(constructFlatRoofDrainageSimplified || [])

            setExternalDoorsNewOrExisting(getDevelopmentRefurbishmentDetails?.external_door || "")
        }
    }, [getDevelopmentRefurbishmentDetails])

    const [loading, setLoading] = useState(false);

    const CreateDevelopmentRefurbishmentHandler = async () => {
        setLoading(true);
        const data = {
            general_details_id: developmentGeneralDetails?.id || getDevelopmentGeneralDetails?.id,
            // general_details_id: 43,
            refurbishment_type: anyRefurbishmentAtDevelopment,
            define_refurbishment_type: refurbishmentOrRenovationWorksAtDevelopment,
            existing_value: Number(existingValue),
            type_of_structural_report: typeOfStructuralEngineers,
            specialist_damp_report: specialistDampReports,
            specialist_damp_file: specialistDampReportsFile == null ? null : await convertBase64(specialistDampReportsFile),
            specialist_damp_filename: specialistDampReportsFile?.name,
            specialist_timber_report: specialistTimberReports,
            specialist_timber_file: specialistTimberReportsFile == null ? null : await convertBase64(specialistTimberReportsFile),
            specialist_timber_filename: specialistTimberReportsFile?.name,
            specialist_basement_report: basementWaterproofReports,
            specialist_basement_file: basementWaterproofReportsFile == null ? null : await convertBase64(basementWaterproofReportsFile),
            specialist_basement_filename: basementWaterproofReportsFile?.name,
            specialist_basement: "asdasdasd",
            specialist_cladding_report: specialistCladdingReports,
            specialist_cladding_file: specialistCladdingReportsFile == null ? null : await convertBase64(specialistCladdingReportsFile),
            specialist_cladding_filename: specialistCladdingReportsFile?.name,
            specialist_roofing_report: specialistRoofingReports,
            specialist_roofing_file: specialistRoofingReportsFile == null ? null : await convertBase64(specialistRoofingReportsFile),
            specialist_roofing_filename: specialistRoofingReportsFile?.name,
            specialist_drainage_report: areSpecialistDrainageReports,
            specialist_drainage_file: areSpecialistDrainageReportsFile == null ? null : await convertBase64(areSpecialistDrainageReportsFile),
            specialist_drainage_filename: areSpecialistDrainageReportsFile?.name,
            basement_undergoing: basementUndergoingAnyChange,
            is_drainage: IsDrainageNew,
            foundation: areFoundationsNew,
            foundation_type: foundationType?.map(b => ({
                foundation_type_id: b
            })),
            is_ground_floor: isTheGroundFloorNew,
            ground_floor_type: groundFloorConstructionType?.map(b => ({
                ground_floor_type_id: b
            })),
            other_ground_floor: otherGroundFloorType,
            super_structure: externalWallConstructionType?.map(b => ({
                super_structure_id: b
            })),
            other_super_structure: otherExternalWallConstructionType,
            insulation_fill: insulationFill?.map(b => ({
                insulation_fill_id: b
            })),
            insulation_material: insulationMaterialType?.map(b => ({
                other_insulation_fill: otherInsulationFill,
                insulation_material_id: b
            })),
            other_insulation_material: otherInsulationMaterialType,
            separating_wall: floorsCompliances?.map(b => ({
                separating_wall_id: b
            })),
            external_wall: externalWallFinishType?.map(b => ({
                external_wall_id: b
            })),
            other_external_wall_id: otherExternalWallId,
            is_upper_floor: upperFloorsNewOrExisting,
            upper_floor: upperFloorConstructionType?.map(b => ({
                upper_floor_id: b
            })),
            is_roof_structure: roofStructuresNewOrExisting,
            roof_structure: roofStructureType?.map(b => ({
                roof_structure_type: b
            })),
            other_roof_structure: otherRoofStructureType,
            is_roof_covering: roofCoveringNewOrExisting,
            roof_covering: roofCoveringType?.map(b => ({
                roof_covering_id: b
            })),
            other_roof_covering: otherRoofCoveringType,
            is_flat_roof: Number(roofCoveringMaintained[0]),
            flat_construction: flatRoofConstructionType?.map(b => ({
                flat_construction_id: b
            })),
            other_flat_construction: otherFlatConstructionType,
            flat_roof_covering: areTheFlatroofcoveringsNew,
            flat_covering: flatRoofCoveringType?.map(b => ({
                flat_covering_id: b
            })),
            specific_flat_roof: flatRoofRiskAreas?.map(b => ({
                specific_flat_roof_id: b
            })),
            specific_drainage: flatRoofDrainage?.map(b => ({
                specific_drainage_id: b
            })),
            external_door: externalDoorsNewOrExisting,
        }
        console.log("🚀 ~ file: Refurbishment.js ~ line 735 ~ CreateDevelopmentRefurbishmentHandler ~ data", data)

        try {
            developmentMode === "edit"
                ? await dispatch(UpdateDevelopmentRefurbishment({ id: getDevelopmentRefurbishmentDetails?.id, data: data }))
                : await dispatch(CreateDevelopmentRefurbishment(data));

            console.log("API call successful!");
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }


    return (
        <>
            <Row style={{ pointerEvents: `${developmentMode == "view" && 'none'}` }}>
                <Col className="mt-0" lg="12" sm="12">
                    <span className="text-lg font-bold mt-0">Refurbishment/Renovation Build Factors at Development </span>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl>
                        <FormLabel id="otherContactStatus">Any Refurbishment or Renovation Works at the Development?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="otherContactStatus"
                            name=""
                            value={anyRefurbishmentAtDevelopment}
                            onChange={handleChangeAnyRefurbishmentAtDevelopment}                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Col>
                {anyRefurbishmentAtDevelopment == 1 &&
                    <>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Please define the Refurbishment or Renovation Works at the Development?</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={refurbishmentOrRenovationWorksAtDevelopment}
                                    label="Please define the Refurbishment or Renovation Works at the Development?"
                                    onChange={handleChangeRefurbishmentOrRenovationWorksAtDevelopment}
                                    size="small"
                                >
                                    <MenuItem value={1}>Refurbishment and/or Renovation</MenuItem>
                                    <MenuItem value={0}>Mix of new build, renovation, refurbishment</MenuItem>
                                </Select>
                            </FormControl>
                            {developmentRefurbishmentError?.define_refurbishment_type && <p className="text-danger">{developmentRefurbishmentError?.define_refurbishment_type}</p>}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth variant="outlined">
                                <InputLabel size='small' htmlFor="outlined-adornment-date">Value of Existing Structure(s) </InputLabel>
                                <OutlinedInput
                                    size='small'
                                    id="outlined-adornment-date"
                                    value={existingValue}
                                    onChange={(e) => setExistingValue(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {/* <EuroIcon /> */}
                                            <span>£</span>
                                        </InputAdornment>
                                    }
                                    label="Value of Existing Structure(s)"
                                    type='number'
                                />
                            </FormControl>
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Please confirm which type of Structural Engineers report is available?</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typeOfStructuralEngineers}
                                    label="Please define the Refurbishment or Renovation Works at the Development?"
                                    onChange={handleChangeTypeOfStructuralEngineers}
                                    size="small"
                                >
                                    <MenuItem value={1}>Full Structural Engineers reports available</MenuItem>
                                    <MenuItem value={2}>Part Structural Engineers reports available</MenuItem>
                                    <MenuItem value={3}>No Report available</MenuItem>
                                </Select>
                            </FormControl>
                            {developmentRefurbishmentError?.type_of_structural_report && <p className="text-danger">{developmentRefurbishmentError?.type_of_structural_report}</p>}
                        </Col>
                        {typeOfStructuralEngineers == 1 &&
                            <Col className="mt-2" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: Full Structural Engineers reports are available for inspection.</span>
                            </Col>
                        }
                        {typeOfStructuralEngineers == 2 &&
                            <Col className="mt-2" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: Part Structural Engineers reports are available for inspection.</span>
                            </Col>
                        }
                        {typeOfStructuralEngineers == 3 &&
                            <Col className="mt-2" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: There are currently no Full or Part Engineers Reports available for inspection.</span>
                            </Col>
                        }
                        <Col className="mt-4 flex flex-row" lg="6" sm="12">
                            {specialistDampReports == 1 &&
                                // <div className='select-left-btn-wrap select-left-btn-wrap-2'>
                                //     <IconButton
                                //         className='mr-1 border-red select-left-btn'
                                //         onClick={null}
                                //         aria-label="toggle date visibility"
                                //         edge="end"
                                //     >
                                //         <AttachFileIcon />
                                //     </IconButton>
                                // </div>

                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file1">
                                        <Input accept="image/*" id="icon-button-file1" type="file" style={{ display: "none" }} onChange={(e) => setSpecialistDampReportsFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Are Specialist Damp reports Available?</InputLabel>
                                <Select
                                    className={specialistDampReports == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={specialistDampReports}
                                    label="Are Specialist Damp reports Available?"
                                    onChange={handleChangeSpecialistDampReports}
                                    size="small"
                                >
                                    <MenuItem value={1}>Specialist Damp reports available</MenuItem>
                                    <MenuItem value={2}>No Specialist Damp reports available</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {specialistDampReports == 1 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: Specialist Damp reports are available for inspection.</span>
                            </Col>
                        }
                        {specialistDampReports == 2 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: There are currently no Specialist Damp reports available for inspection.</span>
                            </Col>
                        }
                        <Col className="mt-4 flex flex-row" lg="6" sm="12">
                            {specialistTimberReports == 1 &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file2">
                                        <Input accept="image/*" id="icon-button-file2" type="file" style={{ display: "none" }}
                                            onChange={(e) => setSpecialistTimberReportsFile(e.target.files[0])}
                                        />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Are Specialist Timber Reports Available?</InputLabel>
                                <Select
                                    className={specialistTimberReports == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={specialistTimberReports}
                                    label="Are Specialist Timber Reports Available?"
                                    onChange={handleChangeSpecialistTimberReports}
                                    size="small"
                                >
                                    <MenuItem value={1}>Specialist Damp reports available</MenuItem>
                                    <MenuItem value={2}>No Specialist Damp reports available</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {specialistTimberReports == 1 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: Specialist Timber report are available for inspection.</span>
                            </Col>
                        }
                        {specialistTimberReports == 2 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: There are currently no Specialist Timber reports available for inspection.</span>
                            </Col>
                        }
                        <Col className="mt-4 flex flex-row" lg="6" sm="12">
                            {basementWaterproofReports == 1 &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file3">
                                        <Input accept="image/*" id="icon-button-file3" type="file" style={{ display: "none" }} onChange={(e) => setSpecialistRoofingReportsFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Are Specialist Basement Waterproof reports available?</InputLabel>
                                <Select
                                    className={basementWaterproofReports == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={basementWaterproofReports}
                                    label="Are Specialist Basement Waterproof reports available?"
                                    onChange={handleChangeBasementWaterproofReports}
                                    size="small"
                                >
                                    <MenuItem value={1}>Specialist Basement Waterproof reports available</MenuItem>
                                    <MenuItem value={2}>No Specialist Basement Waterproof reports available</MenuItem>
                                    <MenuItem value={3}>Not applicable</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {basementWaterproofReports == 1 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: Specialist Basement Waterproof Reports are available for inspection.</span>
                            </Col>
                        }
                        {basementWaterproofReports == 2 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: There are currently no Specialist Basement Waterproof reports available for inspection.</span>
                            </Col>
                        }
                        {basementWaterproofReports == 3 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Requirement for a Specialist Basement Waterproof reports is not applicable. Please explain why?"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    multiline
                                    maxRows={1}
                                />
                            </Col>
                        }
                        <Col className="mt-4 flex flex-row" lg="6" sm="12">
                            {specialistCladdingReports == 1 &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file4">
                                        <Input accept="image/*" id="icon-button-file4" type="file" style={{ display: "none" }} onChange={(e) => setSpecialistCladdingReportsFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Are Specialist Cladding reports available?</InputLabel>
                                <Select
                                    className={specialistCladdingReports == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={specialistCladdingReports}
                                    label="Are Specialist Cladding reports available?"
                                    onChange={handleChangeSpecialistCladdingReports}
                                    size="small"
                                >
                                    <MenuItem value={1}>Specialst Cladding reports available</MenuItem>
                                    <MenuItem value={2}>No Specialist Cladding reports available</MenuItem>
                                    <MenuItem value={3}>Not applicable</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {specialistCladdingReports == 1 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: Specialist Cladding reports are available for inspection.</span>
                            </Col>
                        }
                        {specialistCladdingReports == 2 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: There are currently no Specialist Cladding reports available for inspection.</span>
                            </Col>
                        }
                        <Col className="mt-4 flex flex-row" lg="6" sm="12">
                            {specialistRoofingReports == 1 &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file5">
                                        <Input accept="image/*" id="icon-button-file5" type="file" style={{ display: "none" }} onChange={(e) => setBasementWaterproofReportsFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Are Specialist Roofing reports available?</InputLabel>
                                <Select
                                    className={specialistRoofingReports == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={specialistRoofingReports}
                                    label="Are Specialist Roofing reports available?"
                                    onChange={handleChangeSpecialistRoofingReports}
                                    size="small"
                                >
                                    <MenuItem value={1}>Specialist Roofing reports available</MenuItem>
                                    <MenuItem value={2}>No Specialist Roofing reports available</MenuItem>
                                    <MenuItem value={3}>Not applicable</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {specialistRoofingReports == 1 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: Specialist Roofing reports are available for inspection.</span>
                            </Col>
                        }
                        {specialistRoofingReports == 2 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: There are currently no Specialist Roofing reports available for inspection.</span>
                            </Col>
                        }
                        <Col className="mt-4 flex flex-row" lg="6" sm="12">
                            {areSpecialistDrainageReports == 1 &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file6">
                                        <Input accept="image/*" id="icon-button-file6" type="file" style={{ display: "none" }} onChange={(e) => setAreSpecialistDrainageReportsFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Are Specialist Drainage reports available?</InputLabel>
                                <Select
                                    className={areSpecialistDrainageReports == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={areSpecialistDrainageReports}
                                    label="Are Specialist Drainage reports available?"
                                    onChange={handleChangeAreSpecialistDrainageReports}
                                    size="small"
                                >
                                    <MenuItem value={1}>Specialist Drainage reports available</MenuItem>
                                    <MenuItem value={2}>No Specialist Drainage report available</MenuItem>
                                    <MenuItem value={3}>Not applicable</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {areSpecialistDrainageReports == 1 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: Specialist Drainage reports are available for inspection.</span>
                            </Col>
                        }
                        {areSpecialistDrainageReports == 2 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment or Renovation Works: There are currently no Specialist Drainage reports available for inspection.</span>
                            </Col>
                        }
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Are Foundations New or Existing?</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={areFoundationsNew}
                                    label="Are Foundations New or Existing?"
                                    onChange={handleChangeAreFoundationsNew}
                                    size="small"
                                >
                                    <MenuItem value={1}>New</MenuItem>
                                    <MenuItem value={2}>Existing</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {areFoundationsNew == 1 &&
                            <>
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">What are the Foundation Type(s)</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={foundationType}
                                            onChange={handleChangeFoundationTypes}

                                            input={<OutlinedInput label="What are the Foundation Type(s)" />}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? foundationTypes?.length : obj;
                                                    return foundationTypes[id - 1].name
                                                }).join(", ")}
                                            MenuProps={MenuProps}
                                        >
                                            {foundationTypes?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={foundationType.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                {foundationType.includes(0) &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Other Foundation Type(s)"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            multiline
                                            maxRows={1}
                                            value={otherFoundationType}
                                            onChange={(e) => setOtherFoundationType(e.target.value)}
                                        />
                                    </Col>
                                }
                                <Col className="mt-4" lg="6" sm="12">
                                    {/* <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size="small">Is the basement undergoing any change of use or upgrade?</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={basementUndergoingAnyChange}
                                            label="Is the basement undergoing any change of use or upgrade?"
                                            onChange={handleChangeBasementUndergoingAnyChange}
                                            size="small"
                                        >
                                            <MenuItem value={1}>Yes</MenuItem>
                                            <MenuItem value={2}>No</MenuItem>
                                        </Select>
                                    </FormControl> */}

                                    <FormControl>
                                        <FormLabel id="otherContactStatus">Is the basement undergoing any change of use or upgrade?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="otherContactStatus"
                                            name=""
                                            value={basementUndergoingAnyChange}
                                            onChange={handleChangeBasementUndergoingAnyChange}                        >
                                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={2} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Col>
                                {basementUndergoingAnyChange == 1 &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <span className="text-sm font-bold mt-0">Basement / Underground areas are undergoing a change of use or uprgade: Please investigate further.</span>
                                    </Col>
                                }
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size="small">Is drainage New or Existing?</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={IsDrainageNew}
                                            label="Is drainage New or Existing?"
                                            onChange={handleChangeIsDrainageNew}
                                            size="small"
                                        >
                                            <MenuItem value={1}>New</MenuItem>
                                            <MenuItem value={2}>Existing</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size="small">Is the Ground floor New or Existing?</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={isTheGroundFloorNew}
                                            label="Is the Ground floor New or Existing?"
                                            onChange={handleChangeIsTheGroundFloorNew}
                                            size="small"
                                        >
                                            <MenuItem value={1}>New</MenuItem>
                                            <MenuItem value={2}>Existing</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                                {isTheGroundFloorNew == 1 &&
                                    <>
                                        <Col className="mt-4" lg="6" sm="12">
                                            <FormControl fullWidth sx={{}}>
                                                <InputLabel size="small" id="demo-multiple-checkbox-label">What are the Ground Floor Construction Type(s)?</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    value={groundFloorConstructionType}
                                                    onChange={handleChangeGroundFloorConstructionTypes}
                                                    input={<OutlinedInput label="What are the Ground Floor Construction Type(s)?" />}
                                                    renderValue={(selected) =>
                                                        selected?.map(obj => {
                                                            const id = obj == 0 ? groundFloorConstructionTypes?.length : obj;
                                                            return groundFloorConstructionTypes[id - 1].name
                                                        }).join(", ")
                                                    }
                                                    MenuProps={MenuProps}
                                                >
                                                    {groundFloorConstructionTypes?.map((name) => (
                                                        <MenuItem key={name?.id} value={name?.id}>
                                                            <Checkbox checked={groundFloorConstructionType.indexOf(name?.id) > -1} />
                                                            <ListItemText primary={name?.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        {groundFloorConstructionType.includes(0) &&
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Other Ground Floor Construction Type(s)"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    multiline
                                                    maxRows={1}
                                                    value={otherGroundFloorType}
                                                    onChange={e => setOtherGroundFloorType(e.target.value)}
                                                />
                                            </Col>
                                        }
                                    </>
                                }
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">Superstructure - What are the External Wall Construction Type(s)?</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={externalWallConstructionType}
                                            onChange={handleChangeExternalWallConstructionTypes}
                                            input={<OutlinedInput label="Superstructure - What are the External Wall Construction Type(s)?" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? externalWallConstructionTypes?.length : obj;
                                                    return externalWallConstructionTypes[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                        >
                                            {externalWallConstructionTypes?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={externalWallConstructionType.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                {externalWallConstructionType.includes(0) &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Other External Wall Construction Type(s)"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            multiline
                                            maxRows={1}
                                            value={otherExternalWallConstructionType}
                                            onChange={e => setOtherExternalWallConstructionType(e.target.value)}
                                        />
                                    </Col>
                                }
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">What are the Insulation Fill(s)?</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={insulationFill}
                                            onChange={handleChangeInsulationFills}
                                            input={<OutlinedInput label="What are the Insulation Fill(s)?" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? insulationFills?.length : obj;
                                                    return insulationFills[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                        >
                                            {insulationFills?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={insulationFill.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                {insulationFill.includes(0) &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Other Insulation Fill(s)"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            multiline
                                            maxRows={1}
                                            value={otherInsulationFill}
                                            onChange={e => setOtherInsulationFill(e.target.value)}
                                        />
                                    </Col>
                                }
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">What are the Insulation Material Type(s)?</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={insulationMaterialType}
                                            onChange={handleChangeInsulationMaterialTypes}
                                            input={<OutlinedInput label="What are the Insulation Material Type(s)?" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? insulationMaterialTypes?.length : obj;
                                                    return insulationMaterialTypes[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                        >
                                            {insulationMaterialTypes?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={insulationMaterialType.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                {insulationMaterialType.includes(0) &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Other Insulation Material Type(s)"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            multiline
                                            maxRows={1}
                                            value={otherInsulationMaterialType}
                                            onChange={e => setOtherInsulationMaterialType(e.target.value)}
                                        />
                                    </Col>
                                }
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">What are the Separating Walls / Floors Compliances? (choose which apply)</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={floorsCompliances}
                                            onChange={handleChangeFloorsCompliances}
                                            input={<OutlinedInput label="What are the Separating Walls / Floors Compliances? (choose which apply)" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? floorsCompliancesList?.length : obj;
                                                    return floorsCompliancesList[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                        >
                                            {floorsCompliancesList?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={floorsCompliances.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">What are the External Wall Finish Type(s)</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={externalWallFinishType}
                                            onChange={handleChangeExternalWallFinishTypes}
                                            input={<OutlinedInput label="What are the External Wall Finish Type(s)" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? externalWallFinishTypes?.length : obj;
                                                    return externalWallFinishTypes[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                        >
                                            {externalWallFinishTypes?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={externalWallFinishType.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                {externalWallFinishType.includes(0) &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Other External Wall Finish Type(s)"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            multiline
                                            maxRows={1}
                                            value={otherExternalWallId}
                                            onChange={e => setOtherExternalWallId(e.target.value)}
                                        />
                                    </Col>
                                }
                            </>
                        }
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">Are Upper Floors New or Existing?</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={upperFloorsNewOrExisting}
                                    onChange={handleChangeUpperFloorsNewOrExistings}
                                    input={<OutlinedInput label="Are Upper Floors New or Existing?" />}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={1}>New</MenuItem>
                                    <MenuItem value={2}>Existing</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {upperFloorsNewOrExisting == 1 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth sx={{}}>
                                    <InputLabel size="small" id="demo-multiple-checkbox-label">What are the Upper Floor(s) Construction Type(s)?</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        value={upperFloorConstructionType}
                                        onChange={handleChangeUpperFloorConstructionTypes}
                                        input={<OutlinedInput label="What are the Upper Floor(s) Construction Type(s)?" />}
                                        // renderValue={(selected) => selected.join(', ')}
                                        renderValue={(selected) =>
                                            selected?.map(obj => {
                                                const id = obj == 0 ? upperFloorConstructionTypes?.length : obj;
                                                return upperFloorConstructionTypes[id - 1].name
                                            }).join(", ")
                                        }
                                        MenuProps={MenuProps}
                                        multiple
                                    >
                                        {upperFloorConstructionTypes?.map((name) => (
                                            <MenuItem key={name?.id} value={name?.id}>
                                                <Checkbox checked={upperFloorConstructionType.indexOf(name?.id) > -1} />
                                                <ListItemText primary={name?.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        }
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">Are the Roof Structures New or Existing?</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={roofStructuresNewOrExisting}
                                    onChange={handleChangeRoofStructuresNewOrExistings}
                                    input={<OutlinedInput label="Are the Roof Structures New or Existing?" />}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={1}>New</MenuItem>
                                    <MenuItem value={2}>Existing</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {roofStructuresNewOrExisting == 1 &&
                            <>
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">What are the Roof Structure Type(s)?</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            value={roofStructureType}
                                            onChange={handleChangeRoofStructureTypes}
                                            input={<OutlinedInput label="What are the Roof Structure Type(s)?" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? roofStructureTypes?.length : obj;
                                                    return roofStructureTypes[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                            multiple
                                        >
                                            {roofStructureTypes?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={roofStructureType.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                {roofStructureType.includes(0) &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Other Roof Structure Type(s)"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            multiline
                                            maxRows={1}
                                            value={otherRoofStructureType}
                                            onChange={e => setOtherRoofStructureType(e.target.value)}
                                        />
                                    </Col>
                                }
                            </>
                        }
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">Roof Covering New or Existing</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={roofCoveringNewOrExisting}
                                    onChange={handleChangeRoofCoveringNewOrExistings}
                                    input={<OutlinedInput label="Roof Covering New or Existing" />}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={1}>New</MenuItem>
                                    <MenuItem value={2}>Existing</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {roofCoveringNewOrExisting == 1 &&
                            <>
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">Roof Covering Type(s)</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            value={roofCoveringType}
                                            onChange={handleChangeRoofCoveringTypes}
                                            input={<OutlinedInput label="Roof Covering Type(s)" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? roofCoveringTypes?.length : obj;
                                                    return roofCoveringTypes[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                            multiple
                                        >
                                            {roofCoveringTypes?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={roofCoveringType.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                {roofCoveringType.includes(0) &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Other Roof Covering Type(s)"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            multiline
                                            maxRows={1}
                                            value={otherRoofCoveringType}
                                            onChange={e => setOtherRoofCoveringType(e.target.value)}
                                        />
                                    </Col>
                                }
                            </>
                        }
                        {roofCoveringNewOrExisting == 2 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment/Renovation: Refer to underwriting as existing Roof Covering is being maintained.</span>
                            </Col>
                        }
                        <Col className="mt-4" lg="6" sm="12">
                            {/* <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">Is there a Flat Roof / Structural Deck (Any roof type / structural deck / balcony with a gradient of less than 7 degrees).</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={roofCoveringMaintained}
                                    onChange={handleChangeRoofCoveringMaintaineds}
                                    input={<OutlinedInput label="Is there a Flat Roof / Structural Deck (Any roof type / structural deck / balcony with a gradient of less than 7 degrees)." />}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={1}>Yes</MenuItem>
                                    <MenuItem value={2}>No</MenuItem>
                                </Select>
                            </FormControl> */}

                            <FormControl fullWidth>
                                <FormLabel id="otherContactStatus">Is there a Flat Roof / Structural Deck (Any roof type / structural deck / balcony with a gradient of less than 7 degrees).</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="otherContactStatus"
                                    name=""
                                    label="Pre planning Desktop Survey report received?"
                                    value={roofCoveringMaintained}
                                    onChange={handleChangeRoofCoveringMaintaineds}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={2} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                        {roofCoveringMaintained == 1 &&
                            <>
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">What are the Flat Roof / Structural Deck Construction Type(s)?</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            value={flatRoofConstructionType}
                                            onChange={handleChangeFlatRoofConstructionTypes}
                                            input={<OutlinedInput label="What are the Flat Roof / Structural Deck Construction Type(s)?" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? flatRoofConstructionTypes?.length : obj;
                                                    return flatRoofConstructionTypes[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                            multiple
                                        >
                                            {flatRoofConstructionTypes?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={flatRoofConstructionType.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                {flatRoofConstructionType.includes(0) &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Other Flat Roof / Structural Deck Construction Type(s)"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            multiline
                                            maxRows={1}
                                            value={otherFlatConstructionType}
                                            onChange={e => setOtherFlatConstructionType(e.target.value)}
                                        />
                                    </Col>
                                }
                                {/* <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Refer to underwriting as existing Roof Covering is being maintained."
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                    />
                                </Col> */}

                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size="small">Are the flat roof coverings New or Existing?</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={areTheFlatroofcoveringsNew}
                                            label="Are the flat roof coverings New or Existing?"
                                            onChange={handleChangeAreTheFlatroofcoveringsNew}
                                            size="small"
                                        >
                                            <MenuItem value={1}>New</MenuItem>
                                            <MenuItem value={2}>Existing</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                                {areTheFlatroofcoveringsNew == 1 &&
                                    <>
                                        <Col className="mt-4" lg="6" sm="12">
                                            <FormControl fullWidth sx={{}}>
                                                <InputLabel size="small" id="demo-multiple-checkbox-label">What are the flat roof / structural deck covering type(s)?</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    value={flatRoofCoveringType}
                                                    onChange={handleChangeflatRoofCoveringTypes}
                                                    input={<OutlinedInput label="What are the flat roof / structural deck covering type(s)?" />}
                                                    // renderValue={(selected) => selected.join(', ')}
                                                    renderValue={(selected) =>
                                                        selected?.map(obj => {
                                                            const id = obj == 0 ? flatRoofCoveringTypes?.length : obj;
                                                            return flatRoofCoveringTypes[id - 1].name
                                                        }).join(", ")
                                                    }
                                                    MenuProps={MenuProps}
                                                    multiple
                                                >
                                                    {flatRoofCoveringTypes?.map((name) => (
                                                        <MenuItem key={name?.id} value={name?.id}>
                                                            <Checkbox checked={flatRoofCoveringType.indexOf(name?.id) > -1} />
                                                            <ListItemText primary={name?.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        {flatRoofCoveringTypes.includes(0) &&
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Other Flat Roof / Structural Deck Construction Type(s)"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    multiline
                                                    maxRows={1}
                                                />
                                            </Col>
                                        }
                                    </>
                                }
                                {areTheFlatroofcoveringsNew == 2 &&
                                    <Col className="mt-4" lg="6" sm="12">
                                        <span className="text-sm font-bold mt-0">Refurbishment/Renovation: Refer to underwriting as existing flat roof Covering is being maintained.</span>
                                    </Col>
                                }
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">What are the specific Flat Roof / Structural Deck Risk Areas?</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            value={flatRoofRiskAreas}
                                            onChange={handleChangeFlatRoofRiskAreas}
                                            input={<OutlinedInput label="What are the specific Flat Roof / Structural Deck Risk Areas?" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? flatRoofRiskAreasList?.length : obj;
                                                    return flatRoofRiskAreasList[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                            multiple
                                        >
                                            {flatRoofRiskAreasList?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={flatRoofRiskAreas.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth sx={{}}>
                                        <InputLabel size="small" id="demo-multiple-checkbox-label">What is the Flat Roof / Structural Deck Drainage?</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            value={flatRoofDrainage}
                                            onChange={handleChangeFlatRoofDrainages}
                                            input={<OutlinedInput label="What is the Flat Roof / Structural Deck Drainage?" />}
                                            // renderValue={(selected) => selected.join(', ')}
                                            renderValue={(selected) =>
                                                selected?.map(obj => {
                                                    const id = obj == 0 ? flatRoofDrainages?.length : obj;
                                                    return flatRoofDrainages[id - 1].name
                                                }).join(", ")
                                            }
                                            MenuProps={MenuProps}
                                            multiple
                                        >
                                            {flatRoofDrainages?.map((name) => (
                                                <MenuItem key={name?.id} value={name?.id}>
                                                    <Checkbox checked={flatRoofDrainage.indexOf(name?.id) > -1} />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </>
                        }
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Are windows and external doors New or Existing?</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={externalDoorsNewOrExisting}
                                    label="Are windows and external doors New or Existing?"
                                    onChange={handleChangeExternalDoorsNewOrExisting}
                                    size="small"
                                >
                                    <MenuItem value={1}>New</MenuItem>
                                    <MenuItem value={2}>Existing</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {externalDoorsNewOrExisting == 2 &&
                            <Col className="mt-4" lg="6" sm="12">
                                <span className="text-sm font-bold mt-0">Refurbishment/Renovation: Refer to underwriting as existing windows and external doors are being maintained.</span>
                            </Col>
                        }
                    </>
                }
                {/* */}
                {/* */}
            </Row>
            <CardFooter>
                {developmentMode !== "view" && <div className="exit-btn-container mr-3">
                    <Button
                        disabled={value == 0}
                        className="mr-3"
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={() => { dispatch(ChangeDevelopmentMode("previousBtn")); dispatch(GetDevelopmentBuildFactorDetails(developmentGeneralDetails?.id)); dispatch(ChangeDevelopmentStepValue(value - 1)) }} color="info" size="sm"
                    >
                        Previous
                    </Button>
                    <Button
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={CreateDevelopmentRefurbishmentHandler}
                        color="info" size="sm"
                    >
                        {
                            !loading
                                ?
                                value == 4 ?
                                    "Save"
                                    :
                                    "Next"
                                :
                                <CircularProgress color='inherit' size={18} />
                        }
                    </Button>
                </div>}
            </CardFooter>
        </>
    );
}

export default Refurbishment;
