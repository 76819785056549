import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card, CardBody, CardFooter, CardHeader, Col, Row
} from "reactstrap";
// import './AddNewUser.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { CreateInternalUser, GetUserTypeForInternalUser, UpdateInternalUser } from './Store/AddNewUserApi';
import { GetStakeHolderOtherTitle } from '../AddNewStakeHolder/Store/AddNewStakeHolderApi';
import { GetAdminProfileData } from './Store/AdminProfileApi';
import { CreateInternalUser, GetUserTypeForInternalUser, UpdateInternalUser } from '../AddNewUser/Store/AddNewUserApi';


function AdminProfile() {

    const dispatch = useDispatch()
    const { userData } = useSelector(state => state.userData)

    const { userTypeForInternalUser, internalUser } = useSelector(state => state?.AddNewUser)
    const { stakeHolderOtherTitle } = useSelector(state => state?.AddNewStakeHolder)
    const { advanceSearchResult } = useSelector(state => state?.dashboard)
    const { adminProfileData } = useSelector(state => state?.AdminProfile)

    const [titleField, setTitleField] = useState("")
    const [otherTitleField, setOtherTitleField] = useState('')
    const [forenameField, setForenameField] = useState("")
    const [surnameField, setSurnameField] = useState("")
    const [jobTitleField, setJobTitleField] = useState("")
    const [emailField, setEmailField] = useState("")
    const [contactNumberField, setContactNumberField] = useState("")
    const [phoneNumberField, setPhoneNumberField] = useState("")
    const [userTypeField, setUserTypeField] = useState(null)
    const [primaryIsActiveField, setPrimaryIsActiveField] = useState(false)

    const [permision, setPermision] = useState('');
    const handlePermision = (event) => {
        setPermision(event.target.value);
    };
    const [status, setStatus] = useState('');
    const handleStatus = (event) => {
        setStatus(event.target.value);
    };

    useEffect(() => {
        if (window.sessionStorage.getItem("token")) {
            dispatch(GetAdminProfileData())
        }
    }, [])

    useEffect(() => {
        dispatch(GetUserTypeForInternalUser())
    }, [])


    useEffect(() => {
        setTitleField(adminProfileData?.title || "")
        // setOtherTitleField(advanceSearchResult[0]?.business_status_id || null)
        setForenameField(adminProfileData?.fore_name || "")
        setSurnameField(adminProfileData?.sur_name || "")
        setJobTitleField(adminProfileData?.job_title || "")
        setEmailField(adminProfileData?.email || "")
        setContactNumberField(adminProfileData?.contact_number || "")
        setPhoneNumberField(adminProfileData?.phone_number || "")
        setUserTypeField(adminProfileData?.user_type_id || null)
        setPrimaryIsActiveField(adminProfileData?.is_active || false)
    }, [adminProfileData])

    const CreateInternalUserHandler = () => {
        const data = {
            titleField,
            otherTitleField,
            forenameField,
            surnameField,
            jobTitleField,
            emailField,
            contactNumberField,
            phoneNumberField,
            userTypeField,
            primaryIsActiveField,
        }
        // sessionStorage.getItem("currentEditUserId") ? dispatch(UpdateInternalUser({ data, id: userData?.user?.id, })) : dispatch(CreateInternalUser({ data: data, clearForm: clearDataHandler }))
        dispatch(UpdateInternalUser({ data, id: userData?.user?.id, }))
        // clearDataHandler()
    }

    const clearDataHandler = () => {
        setTitleField("")
        setForenameField("")
        setSurnameField("")
        setJobTitleField("")
        setEmailField("")
        setContactNumberField("")
        setPhoneNumberField("")
        setUserTypeField(null)
        setPrimaryIsActiveField(false)
    }

    return (
        <Col lg="9" sm="12">
            <Card className="pb-4">
                <CardHeader className="flex mb-2">
                    <span className="custom-card-header text-lg font-bold">
                        Admin Profile
                    </span>
                </CardHeader>
                <hr />
                <CardBody className=" ">
                    <Row>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Title</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Title"
                                    size="small"
                                    value={titleField}
                                    onChange={(e) => setTitleField(e.target.value)}
                                >
                                    <MenuItem value="Mr">Mr</MenuItem>
                                    <MenuItem value="Mrs">Mrs</MenuItem>
                                    <MenuItem value="Miss">Miss</MenuItem>
                                    <MenuItem value="Ms">Ms</MenuItem>
                                    <MenuItem value="Dr">Dr</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {titleField == "Other" &&
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Other Title</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Title"
                                        size="small"
                                        value={otherTitleField}
                                        onChange={(e) => setOtherTitleField(e.target.value)}
                                    >
                                        {
                                            stakeHolderOtherTitle?.map(stakeholder => {
                                                const { id, other_title } = stakeholder
                                                return (
                                                    <MenuItem key={id} value={other_title}>{other_title}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Col>}
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Forename"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                value={forenameField}
                                onChange={(e) => setForenameField(e.target.value)}
                            />
                            {internalUser?.error?.fore_name && <p className="text-danger">{internalUser?.error?.fore_name}</p>}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Surname"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                value={surnameField}
                                onChange={(e) => setSurnameField(e.target.value)}
                            />
                            {internalUser?.error?.sur_name && <p className="text-danger">{internalUser?.error?.sur_name}</p>}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Job Title"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                value={jobTitleField}
                                onChange={(e) => setJobTitleField(e.target.value)}
                            />
                            {internalUser?.error?.job_title && <p className="text-danger">{internalUser?.error?.job_title}</p>}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Contact Number"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                type="text"
                                value={contactNumberField}
                                onChange={(e) => setContactNumberField(e.target.value)}
                            />
                            {internalUser?.error?.contact_number && <p className="text-danger">{internalUser?.error?.contact_number}</p>}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Mobile Number"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                type="string"
                                value={phoneNumberField}
                                onChange={(e) => setPhoneNumberField(e.target.value)}
                            />
                            {internalUser?.error?.phone_number && <p className="text-danger">{internalUser?.error?.phone_number}</p>}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Email Address"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                type="email"
                                value={emailField}
                                onChange={(e) => setEmailField(e.target.value)}
                            />
                            {internalUser?.error?.email && <p className="text-danger">{internalUser?.error?.email}</p>}
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Status"
                                    size="small"
                                    value={primaryIsActiveField}
                                    onChange={(e) => setPrimaryIsActiveField(e.target.value)}
                                >
                                    <MenuItem value={1}>Active</MenuItem>
                                    <MenuItem value={2}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Permission</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Permission"
                                    size="small"
                                    value={userTypeField}
                                    onChange={(e) => setUserTypeField(e.target.value)}
                                >
                                    {
                                        userTypeForInternalUser?.map(user => {
                                            const { id, user_type } = user
                                            return (

                                                <MenuItem value={id}>{user_type}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <div className="exit-btn-container mr-0">
                        <Button
                            className="px-3"
                            style={{ backgroundColor: '#2BA9CD' }}
                            onClick={CreateInternalUserHandler} color="info" size="sm"
                        >
                            Update
                        </Button>
                    </div>
                </CardFooter>
            </Card>
        </Col>
    );
}

export default AdminProfile;
