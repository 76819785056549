import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import moment from "moment";
import { toast } from 'react-toastify';

const dashboardSlice = createSlice({
    name: 'styles',
    initialState: {
        advanceSearchResult: [],
        defectsOfSingleDevelopment: [],
        adminSingleDevelopmentDetail: null,
        getUserHistory: null,
        fetchifyApiAddress: [],
        singleDevelopmentProgressBar: null,
        administrationHistoryDataForAdmin: [],
        unAllocatedDevelopments: [
            {
                srNo: 1,
                surveyor: "Zeeshan",
                development: "Battle Grounds",
                dateSubmitted: new Date(2022, 1, 1),
                status: "Approved"
            },
            {
                srNo: 2,
                surveyor: "Arslan",
                development: "Amazon Gardens",
                dateSubmitted: new Date(2022, 1, 2),
                status: "Declined"
            },
            {
                srNo: 3,
                surveyor: "Showkat",
                development: "Silk Center",
                dateSubmitted: new Date(2022, 1, 3),
                status: "Approved"
            },
            {
                srNo: 4,
                surveyor: "Manson",
                development: "eSquall",
                dateSubmitted: new Date(2022, 1, 4),
                status: "Approved"
            },
            {
                srNo: 5,
                surveyor: "Usman",
                development: "Comsats Islamabad",
                dateSubmitted: new Date(2022, 1, 5),
                status: "Pending"
            },
            {
                srNo: 6,
                surveyor: "Qammar",
                development: "Baria University",
                dateSubmitted: new Date(2022, 1, 6),
                status: "Pending"
            },
            {
                srNo: 7,
                surveyor: "Sabir",
                development: "Washington Park",
                dateSubmitted: new Date(2022, 1, 7),
                status: "Declined"
            },
            {
                srNo: 8,
                surveyor: "Manson",
                development: "eSquall",
                dateSubmitted: new Date(2022, 1, 4),
                status: "Approved"
            },
            {
                srNo: 9,
                surveyor: "Usman",
                development: "Comsats Islamabad",
                dateSubmitted: new Date(2022, 1, 5),
                status: "Declined"
            },
            {
                srNo: 10,
                surveyor: "Qammar",
                development: "Baria University",
                dateSubmitted: new Date(2022, 1, 6),
                status: "Declined"
            },
            {
                srNo: 11,
                surveyor: "Sabir",
                development: "Washington Park",
                dateSubmitted: new Date(2022, 1, 7),
                status: "Pending"
            },
            {
                srNo: 12,
                surveyor: "Kashif",
                development: "Washington Park",
                dateSubmitted: new Date(2022, 1, 7),
                status: "Declined"
            },
        ],
        surveyRequests: [
            {
                srNo: 1,
                requestType: "Request 5",
                name: "Zeeshan",
                dateMade: new Date(2021, 5, 1),
            },
            {
                srNo: 2,
                requestType: "Request 1",
                name: "Arslan",
                dateMade: new Date(2021, 5, 2),
            },
            {
                srNo: 3,
                requestType: "Request 2",
                name: "Showkat",
                dateMade: new Date(2021, 5, 3),
            },
            {
                srNo: 4,
                requestType: "Request 3",
                name: "Fawad",
                dateMade: new Date(2021, 5, 4),
            },
            {
                srNo: 5,
                requestType: "Request 1",
                name: "Jawad",
                dateMade: new Date(2021, 5, 5),
            },
            {
                srNo: 6,
                requestType: "Request 4",
                name: "Zain",
                dateMade: new Date(2021, 5, 6),
            },
            {
                srNo: 7,
                requestType: "Request 1",
                name: "John",
                dateMade: new Date(2021, 5, 7),
            },
            {
                srNo: 8,
                requestType: "Request 4",
                name: "Smith",
                dateMade: new Date(2021, 5, 8),
            },
            {
                srNo: 9,
                requestType: "Request 3",
                name: "William",
                dateMade: new Date(2021, 5, 5),
            },
            {
                srNo: 10,
                requestType: "Request 1",
                name: "Zubair",
                dateMade: new Date(2021, 5, 6),
            },
            {
                srNo: 11,
                requestType: "Request 2",
                name: "Farhan",
                dateMade: new Date(2021, 5, 7),
            },
            {
                srNo: 12,
                requestType: "Request 1",
                name: "Zahoor",
                dateMade: new Date(2021, 5, 8),
            },
        ],
        userHistory: [
            {
                srNo: 1,
                type: "Viewed",
                name: "Developer",
                lookedOn: new Date(2021, 5, 3, 2, 30, 0),
                // lookedOn: moment(new Date("2015-06-17 14:24:36")).format("YY-MM-DD HH:mm:ss")
            },
            {
                srNo: 2,
                type: "Add New",
                name: "Surveyor",
                lookedOn: new Date(2021, 5, 3, 2, 30, 0),
                // lookedOn: moment(new Date(2021, 5, 2, 1, 30, 0)).format('MMMM Do YYYY, h:mm:ss')
            },
            {
                srNo: 3,
                type: "Edit Existing",
                name: "Developer",
                lookedOn: new Date(2021, 5, 3, 2, 30, 0),
            },
            {
                srNo: 4,
                type: "Replied to",
                name: "Surveyor",
                lookedOn: new Date(2021, 5, 4, 3, 30, 0),
            },
            {
                srNo: 5,
                type: "Note Added",
                name: "Developer",
                lookedOn: new Date(2021, 5, 5, 12, 30, 0),
            },
            {
                srNo: 6,
                type: "Diary Added/Edited",
                name: "Surveyor",
                lookedOn: new Date(2021, 5, 6, 1, 30, 0),
            },
            {
                srNo: 7,
                type: "Defect Added",
                name: "Developer",
                lookedOn: new Date(2021, 5, 7, 4, 20, 0),
            },
            {
                srNo: 8,
                type: "Action Added",
                name: "Developer",
                lookedOn: new Date(2021, 5, 8, 5, 30, 0),
            },
            {
                srNo: 9,
                type: "Replied to",
                name: "Developer",
                lookedOn: new Date(2021, 5, 9, 6, 30, 0),
            },
            {
                srNo: 10,
                type: "Viewed",
                name: "Developer",
                lookedOn: new Date(2021, 5, 6, 1, 30, 0),
            },
            {
                srNo: 11,
                type: "Add New",
                name: "Developer",
                lookedOn: new Date(2021, 5, 7, 4, 30, 0),
            },
            {
                srNo: 12,
                type: "Viewed",
                name: "Faizan",
                lookedOn: new Date(2021, 5, 8, 5, 30, 0),
            },
            {
                srNo: 13,
                type: "Add New",
                name: "Surveyor",
                lookedOn: new Date(2021, 5, 9, 6, 30, 0),
            },
        ],
        developments: [
            {
                id: 1,
                developmentName: "Mehria Construction",
                mainContractor: "Zeeshan",
                expectedCompletionDate: new Date(2022, 7, 1),
                surveyor: "Lorem ispum",
                nextSurveyDate: new Date(2022, 4, 1),
                progress: 10,
                actionNotified: new Date(2022, 5, 1),
                totalDefects: 1,
                totalActions: 1,
            },
            {
                id: 2,
                developmentName: "Town B Block Const",
                mainContractor: "Ali",
                expectedCompletionDate: new Date(2022, 7, 2),
                surveyor: "Lorem ispum",
                nextSurveyDate: new Date(2022, 4, 2),
                progress: 20,
                actionNotified: new Date(2022, 5, 2),
                totalDefects: 2,
                totalActions: 2,
            },
            {
                id: 3,
                developmentName: "Zee Construction",
                mainContractor: "Bilal",
                expectedCompletionDate: new Date(2022, 7, 3),
                surveyor: "Lorem ispum",
                nextSurveyDate: new Date(2022, 4, 3),
                progress: 30,
                actionNotified: new Date(2022, 5, 3),
                totalDefects: 3,
                totalActions: 3,
            },
            {
                id: 4,
                developmentName: "Soft Const ",
                mainContractor: "Fahad",
                expectedCompletionDate: new Date(2022, 7, 4),
                surveyor: "Lorem ispum",
                nextSurveyDate: new Date(2022, 4, 4),
                progress: 40,
                actionNotified: new Date(2022, 5, 4),
                totalDefects: 4,
                totalActions: 4,
            },
            {
                id: 5,
                developmentName: "Paper Industry Const",
                mainContractor: "Zain",
                expectedCompletionDate: new Date(2022, 7, 5),
                surveyor: "Lorem ispum",
                nextSurveyDate: new Date(2022, 4, 5),
                progress: 50,
                actionNotified: new Date(2022, 5, 5),
                totalDefects: 5,
                totalActions: 5,
            },
            {
                id: 6,
                developmentName: "Javaid Tech Const",
                mainContractor: "Jamal",
                expectedCompletionDate: new Date(2022, 7, 6),
                surveyor: "Lorem ispum",
                nextSurveyDate: new Date(2022, 4, 6),
                progress: 60,
                actionNotified: new Date(2022, 5, 6),
                totalDefects: 6,
                totalActions: 6,
            },
            {
                id: 7,
                developmentName: "Valeed Construction",
                mainContractor: "Arhaam",
                expectedCompletionDate: new Date(2022, 7, 7),
                surveyor: "Lorem ispum",
                nextSurveyDate: new Date(2022, 4, 7),
                progress: 70,
                actionNotified: new Date(2022, 5, 7),
                totalDefects: 7,
                totalActions: 7,
            },
        ],
        developmentNotifications: [
            {
                id: 1,
                notification: "Defect",
                notified: new Date(2021, 3, 1),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
            {
                id: 2,
                notification: "Defect",
                notified: new Date(2021, 3, 2),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
            {
                id: 3,
                notification: "Defect",
                notified: new Date(2021, 3, 3),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
            {
                id: 4,
                notification: "Defect",
                notified: new Date(2021, 3, 4),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
            {
                id: 5,
                notification: "Defect",
                notified: new Date(2021, 3, 5),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
            {
                id: 6,
                notification: "Defect",
                notified: new Date(2021, 3, 6),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
            {
                id: 7,
                notification: "Defect",
                notified: new Date(2021, 3, 7),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
            {
                id: 8,
                notification: "Defect",
                notified: new Date(2021, 3, 8),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
            {
                id: 9,
                notification: "Defect",
                notified: new Date(2021, 3, 9),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
            {
                id: 10,
                notification: "Defect",
                notified: new Date(2021, 3, 10),
                stage: "1",
                sectionwithinStage: "Foundation / Strip & Trench",
                description: "lorem ipsum is a dummy text use for",
                unitBlock: "Block 1",
                prirority: "High",
                photoVideoAvailable: "Yes",
                beingActions: "No",
                isRemedialWorkComplete: "No"
            },
        ],
        notes: [
            {
                srNo: 1,
                requestType: "Zen Toe",
                name: "John Doe",
                dateMade: new Date(2020, 1, 1),
            },
            {
                srNo: 2,
                requestType: "Hovic Vr",
                name: "Sameer",
                dateMade: new Date(2020, 1, 2),
            },
            {
                srNo: 3,
                requestType: "Jim HJ",
                name: "Smith",
                dateMade: new Date(2020, 1, 3),
            },
            {
                srNo: 4,
                requestType: "Kinvi",
                name: "Adnan",
                dateMade: new Date(2020, 1, 4),
            },
            {
                srNo: 5,
                requestType: "Solve Design",
                name: "Nabeel",
                dateMade: new Date(2020, 1, 5),
            },
            {
                srNo: 6,
                requestType: "Delta",
                name: "Jim",
                dateMade: new Date(2020, 1, 6),
            },
            {
                srNo: 7,
                requestType: "Walt",
                name: "Qammar",
                dateMade: new Date(2020, 1, 7),
            },
            {
                srNo: 8,
                requestType: "Opium",
                name: "Waqas",
                dateMade: new Date(2020, 1, 8),
            },
        ],
        userDevelopments: [
            {
                srNo: 1,
                development: "lorem ipsum",
                postCode: "0000",
                completed: new Date(2022, 1, 1, 1, 1),
            },
            {
                srNo: 2,
                development: "lorem ipsum",
                postCode: "0000",
                completed: new Date(2022, 1, 2, 1, 1),
            },
            {
                srNo: 3,
                development: "lorem ipsum",
                postCode: "0000",
                completed: new Date(2022, 1, 3, 1, 1),
            },
            {
                srNo: 4,
                development: "lorem ipsum",
                postCode: "0000",
                completed: new Date(2022, 1, 4, 1, 1),
            },
            {
                srNo: 5,
                development: "lorem ipsum",
                postCode: "0000",
                completed: new Date(2022, 1, 5, 1, 1),
            },
            {
                srNo: 6,
                development: "lorem ipsum",
                postCode: "0000",
                completed: new Date(2022, 1, 6, 1, 1),
            },
            {
                srNo: 7,
                development: "lorem ipsum",
                postCode: "0000",
                completed: new Date(2022, 1, 7, 1, 1),
            },
            {
                srNo: 8,
                development: "lorem ipsum",
                postCode: "0000",
                completed: new Date(2022, 1, 8, 1, 1),
            },
        ],
        defects: [
            {
                id: 1,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 2,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 3,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 4,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 5,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 6,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 7,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 8,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 9,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 10,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 11,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
            {
                id: 12,
                defectsAction: "Defect",
                notified: new Date(2022, 11, 1),
                stage: "1",
                sectionWithStage: "Foundation Strip & Trench",
                description: "Lorem ispum is a dummy",
                unitBlock: "lorem 1",
                priority: "Hight",
                photoVideoAvailable: "Yes",
                beingActioned: "NO",
                remediaWorkCompleted: "NO",
            },
        ]
    },
    reducers: {
        AlterAdvanceSearchResult: (state, action) => {
            const result = state.advanceSearchResult.filter(item => item?.id === action?.payload.objId)
            state.advanceSearchResult = result
        },
        EmptyAdvanceSearchResult: (state, action) => {
            state.advanceSearchResult = action.payload
        },
        EmptyAdminSingleDevelopmentDetail: (state, action) => {
            state.adminSingleDevelopmentDetail = action.payload
        }
    },
    extraReducers: {
        ['search/AdvanceSearch/fulfilled']: (state, action) => {
            console.log("🚀 ~ file: dashboardSlice.js ~ line 736 ~ action", action)
            // alert("CALLED tested")
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: dashboardSlice.js ~ line 734 ~ data", data)
            if (status >= 200 && status < 300) {

                state.advanceSearchResult = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.advanceSearchResult = data
            }
        },

        ['dashboard/GetDefectsOfSingleDevelopment/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.defectsOfSingleDevelopment = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.defectsOfSingleDevelopment = data
            }
        },

        ['administrationHistory/GetAdmisistrationHistoryDataForAdmin/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.administrationHistoryDataForAdmin = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.administrationHistoryDataForAdmin = data
            }
        },

        ['dashboard/GetAdminSingleDevelopmentDetail/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.adminSingleDevelopmentDetail = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.adminSingleDevelopmentDetail = data
            }
        },

        ['dashboard/GetUserHistory/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.getUserHistory = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.getUserHistory = data
            }
        },

        ['dashboard/FetchifyApiAddress/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.fetchifyApiAddress = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.fetchifyApiAddress = data
            }
        },

        ['dashboard/CreateProgressBarOfSingleDevelopment/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.singleDevelopmentProgressBar = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.singleDevelopmentProgressBar = data
            }
        },
    },
});

export const {
    AlterAdvanceSearchResult, EmptyAdvanceSearchResult, EmptyAdminSingleDevelopmentDetail
} = dashboardSlice.actions;

export default dashboardSlice.reducer;