import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import MUISelect from '@mui/material/Select';
import {
    Button,
    Card, CardBody, CardHeader, Col, Container, FormGroup, Modal,
    Input, Progress, Row, Table
} from "reactstrap";
import { CreateAdministrationHistoryMakeRequest, CreateAdministrationHistorySubCategory, CreateAdmnistrationHistoryCategory, GetAddDiarySubCategoryByCategoryId, GetAdministrationHistoryAssignedTo, GetAdministrationHistoryPriority, GetMakeRequestCategory } from '../AdministrationHistory/Store/AdministrationHistoryApi';
import { Checkbox, FormControlLabel, ListItemText, MenuItem, OutlinedInput } from '@mui/material';
import baseURL from 'App/Config/baseUrl';

const AddCategory = ({ toggleModalFunction }) => {
    const dispatch = useDispatch()

    const [categoryNameField, setCategoryNameField] = useState("")
    const [assignToField, setAssignToField] = useState(null)

    const { administrationHistoryAssignedTo } = useSelector(state => state?.AdministrationHistory)

    useEffect(() => {
        dispatch(GetAdministrationHistoryAssignedTo())
    }, [])

    const addCategoryHandler = () => {
        const data = {
            category_type: "make-request",
            category_name: categoryNameField,
            assigned_to: assignToField?.value,
        }
        console.log("🚀 ~ file: AdministrationHistoryAddDiary.js ~ line 23 ~ addCategoryHandler ~ data", data)

        dispatch(CreateAdmnistrationHistoryCategory(data))
        setCategoryNameField("")
        setAssignToField(null)
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Add Category
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Category Name</label>
                                <Input className="custom-input" placeholder="" type="text" value={categoryNameField} onChange={e => setCategoryNameField(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Assign To</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={assignToField}
                                        onChange={(value) => setAssignToField(value)}
                                        options={
                                            administrationHistoryAssignedTo?.map(data => ({
                                                value: data?.id, label: data?.name
                                            }))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={addCategoryHandler} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

const AddSubCategory = ({ toggleModalFunction }) => {
    const dispatch = useDispatch()
    const { makeRequestCategories } = useSelector(state => state?.AdministrationHistory)

    const [categoryIdField, setCategoryIdField] = useState(null)
    const [subCategoryNameField, setSubCategoryNameField] = useState("")

    const addSubCategoryHandler = () => {
        const data = {
            category_id: categoryIdField?.value,
            sub_category_name: subCategoryNameField,
        }
        console.log("🚀 ~ file: AdministrationHistoryAddDiary.js ~ line 23 ~ addCategoryHandler ~ data", data)

        dispatch(CreateAdministrationHistorySubCategory(data))
        setCategoryIdField(null)
        setSubCategoryNameField("")
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Add Sub Category
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Category</label>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={categoryIdField}
                                        onChange={(value) => setCategoryIdField(value)}
                                        options={

                                            makeRequestCategories?.map(catgeory => (
                                                {
                                                    value: catgeory?.id, label: catgeory?.category_name
                                                }
                                            ))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Sub Category Name</label>
                                <Input className="custom-input" placeholder="" type="text" value={subCategoryNameField} onChange={e => setSubCategoryNameField(e.target.value)} />
                            </FormGroup>
                        </Col>

                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={addSubCategoryHandler} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

const AdministrationHistoryMakeRequest = ({ toggleModalFunction }) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const dispatch = useDispatch()
    const { makeRequestCategories, addDiarySubCategoryByCategoryId, usersOfDevelopment, administrationHistoryPriority, singleMakeRequestDetails } = useSelector(state => state?.AdministrationHistory)
    const { currentSelectedAcceptedDevelopment } = useSelector(state => state?.ExternalUsers)
    const { makeRequestMode } = useSelector(state => state?.shared)


    const [modalAddCategory, setModalAddCategory] = useState(false);
    const [modalAddSubCategory, setModalAddSubCategory] = useState(false);

    const [categoryIdField, setCategoryIdField] = useState(null)
    const [subCategoryIdField, setSubCategoryIdField] = useState(null)
    const [toField, setToField] = useState([])
    const [priorityField, setPriorityField] = useState(null)
    const [subjectField, setSubjectField] = useState(null)
    const [commentField, setCommentField] = useState(null)
    const [attachField, setAttachField] = useState(null)
    const [dueDateField, setDueDateField] = useState(null)

    useEffect(() => {
        if (makeRequestMode == "view") {
            setCategoryIdField({ value: singleMakeRequestDetails?.category?.id, label: singleMakeRequestDetails?.category?.category_name } || null)
            setSubCategoryIdField({ value: singleMakeRequestDetails?.sub_category?.id, label: singleMakeRequestDetails?.sub_category?.sub_category_name } || null)
            // setToField(singleMakeRequestDetails?.|| null)
            const dataTo = [];
            (singleMakeRequestDetails?.developer_user?.length > 0 && singleMakeRequestDetails?.contractor_user?.length && singleMakeRequestDetails?.surveyor_user?.length) ? dataTo.push("a") : singleMakeRequestDetails?.contractor_user?.length ? dataTo.push("b") : singleMakeRequestDetails?.developer_user?.length ? dataTo?.push("c") : singleMakeRequestDetails?.surveyor_user?.length ? dataTo?.push("d") : dataTo?.push("e")
            setToField(dataTo)
            setPriorityField({ value: singleMakeRequestDetails?.priority?.id, label: singleMakeRequestDetails?.priority?.name } || null)
            setSubjectField(singleMakeRequestDetails?.subject || null)
            setCommentField(singleMakeRequestDetails?.comment || null)
            setAttachField(singleMakeRequestDetails?.file || null)
            setDueDateField(singleMakeRequestDetails?.due_date || null)
        }
    }, [singleMakeRequestDetails])

    const handleChangeToField = (event) => {
        const {
            target: { value },
        } = event;
        setToField(
            typeof value === 'string' ? value.split(',') : value,
        );
        // setBuildUsage(
        //     ...buildUsage,
        //     value,
        // );
    };

    useEffect(() => {
        dispatch(GetAdministrationHistoryPriority())
    }, [])


    useEffect(() => {
        dispatch(GetMakeRequestCategory())
    }, [])

    const toggleModalAddCategory = () => {
        setModalAddCategory(!modalAddCategory);
    }
    const toggleModalAddSubCategory = () => {
        setModalAddSubCategory(!modalAddSubCategory);
    }

    const categoryIdFieldHandler = (value) => {
        setCategoryIdField(value)
        dispatch(GetAddDiarySubCategoryByCategoryId(value?.value))
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const makeRequestHandleChange = async () => {
        const aData = toField.includes("All Stakeholders for Development (External)") ? [usersOfDevelopment?.contractorID, usersOfDevelopment?.developerID, usersOfDevelopment?.surveyorID] : []
        const bData = toField.includes("Contractor for Development (External)") || toField.includes("All Stakeholders for Development (External)") ? usersOfDevelopment?.contractorID : []
        const cData = toField.includes("Developer for Development (External)") || toField.includes("All Stakeholders for Development (External)") ? usersOfDevelopment?.developerID : []
        const dData = toField.includes("Surveyor for Development (External)") || toField.includes("All Stakeholders for Development (External)") ? usersOfDevelopment?.surveyorID : []
        const eData = toField.includes("All AWS Staff") ? usersOfDevelopment?.internal_user?.map((user) => user?.id) : []
        let toData = [toData, aData, bData, cData, dData].flat(Infinity)
        toData = toData?.filter(d => d !== undefined)
        const data = {
            administration_types: "make-request",
            development_id: currentSelectedAcceptedDevelopment?.id,
            category_id: categoryIdField?.value,
            // sub_category_id: subCategoryIdField?.value,
            priority: priorityField?.value,
            subject: subjectField,
            comment: commentField,
            due_date: dueDateField,
            file: await convertBase64(attachField),
            file_name: attachField?.name,
            to: {
                developer: cData,
                contractor: bData,
                surveyor: dData,
                // stakeholder: toData,
                internal_user: eData
            }
        }
        console.log("🚀 ~ file: AdministrationHistoryMakeRequest.js ~ line 360 ~ makeRequestHandleChange ~ data", data)

        await dispatch(CreateAdministrationHistoryMakeRequest(data))
        toggleModalFunction(false)
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModalFunction(false)}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Make Request
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Category</label>
                                    <span style={{ fontSize: "16px", padding: "0 5px", color: "#ffffff", borderRadius: "15px", marginLeft: "16px", cursor: "pointer", backgroundColor: "#51BCDA" }} onClick={toggleModalAddCategory}>+</span>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={categoryIdField}
                                        onChange={(value) => categoryIdFieldHandler(value)}
                                        options={
                                            makeRequestCategories?.map(catgeory => (
                                                {
                                                    value: catgeory?.id, label: catgeory?.category_name
                                                }
                                            ))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                        </Col>
                    </Row>
                    <Row>


                        <Col md="6">
                            <FormGroup style={{ margin: 0, display: "flex", flexDirection: "column" }} >
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>To</label>
                                {/* <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">To</InputLabel> */}
                                <MUISelect
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    // value={[...toField]}
                                    value={toField}
                                    onChange={handleChangeToField}
                                    input={<OutlinedInput label="To" />}
                                    // renderValue={(selected) => { selected.join(', '); console.log("selected===========" + selected) }}
                                    renderValue={(selected) => selected?.map(obj => obj).join(", ")}
                                    // renderValue={(selected) => {
                                    //     console.log("🚀 ~ file: AddNewSurveyor.js ~ line 621 ~ AddNewSurveyor ~ selected", selected)
                                    //     selected?.map(obj => {
                                    //         const id = obj == 0 ? toField?.length : obj;
                                    //         return toField[id - 1]?.username
                                    //     }).join(", ")
                                    // }
                                    // }
                                    MenuProps={MenuProps}
                                >
                                    {/* TODO: for a  */}
                                    <MenuItem key={[usersOfDevelopment?.contractorId, usersOfDevelopment?.developerId, usersOfDevelopment?.surveyorId]} value="All Stakeholders for Development (External)" disabled={toField.includes("Contractor for Development (External)") || toField.includes("Developer for Development (External)") || toField.includes("Surveyor for Development (External)") ? true : false}>
                                        <Checkbox checked={toField.indexOf("All Stakeholders for Development (External)") > -1} />
                                        <ListItemText className='custom-to-input-field-size' primary="All Stakeholders for Development (External)" />
                                    </MenuItem>
                                    {/* TODO: for b  */}
                                    <MenuItem key={usersOfDevelopment?.contractorId} value="Contractor for Development (External)" disabled={toField.includes("All Stakeholders for Development (External)") ? true : false}>
                                        <Checkbox checked={toField.indexOf("Contractor for Development (External)") > -1} />
                                        <ListItemText className='custom-to-input-field-size' primary="Contractor for Development (External)" />
                                    </MenuItem>
                                    {/* TODO: for c  */}
                                    <MenuItem key={usersOfDevelopment?.developerId} value="Developer for Development (External)" disabled={toField.includes("All Stakeholders for Development (External)") ? true : false}>
                                        <Checkbox checked={toField.indexOf("Developer for Development (External)") > -1} />
                                        <ListItemText className='custom-to-input-field-size' primary="Developer for Development (External)" />
                                    </MenuItem>
                                    {/* TODO: for d  */}
                                    <MenuItem key={usersOfDevelopment?.surveyorId} value="Surveyor for Development (External)" disabled={toField.includes("All Stakeholders for Development (External)") ? true : false}>
                                        <Checkbox checked={toField.indexOf("Surveyor for Development (External)") > -1} />
                                        <ListItemText className='custom-to-input-field-size' primary="Surveyor for Development (External)" />
                                    </MenuItem>
                                    {/* TODO: for e  */}
                                    <MenuItem key={usersOfDevelopment?.surveyorId} value="All AWS Staff" disabled={toField.some(s => typeof s === "number" ? true : false)}>
                                        <Checkbox checked={toField.indexOf("All AWS Staff") > -1} />
                                        <ListItemText className='custom-to-input-field-size' primary="All AWS Staff" />
                                    </MenuItem>
                                    {usersOfDevelopment?.internal_user?.map((user) => (
                                        <MenuItem key={user?.id} value={user?.id} disabled={toField.includes("e") ? true : false}>
                                            <Checkbox checked={toField.indexOf(user?.id) > -1} />
                                            <ListItemText className='custom-to-input-field-size' primary={user?.username} />
                                        </MenuItem>
                                    ))}
                                </MUISelect>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Due Date</label>
                                <Input className="custom-input" placeholder="" type="date" value={dueDateField} onChange={e => setDueDateField(e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Priority</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={priorityField}
                                        onChange={(value) => setPriorityField(value)}
                                        options={
                                            administrationHistoryPriority?.map(priority => (
                                                { value: priority?.id, label: priority?.name }
                                            ))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Col md="12" style={{ padding: 0 }}>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" placeholder='Subject' max={150} style={{ minHeight: "80px", border: "1px solid #888888" }}
                                value={subjectField}
                                onChange={(e) => setSubjectField(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="12" style={{ padding: 0 }}>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" placeholder='Comments' style={{ minHeight: "100px", border: "1px solid #888888" }}
                                value={commentField} onChange={(e) => setCommentField(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Attach</label>
                            <Input className="custom-input" placeholder="" type="file" onChange={e => setAttachField(e.target.files[0])} />
                        </FormGroup>
                        <div>
                            <img style={{ width: "100%", height: "100%", aspectRatio: "16:9" }} src={`${baseURL}/storage${singleMakeRequestDetails?.file}`} alt="" />
                        </div>
                    </Col>
                    {makeRequestMode == "view" &&
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="Mark as completed" />
                        </FormGroup>
                    }
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={() => toggleModalFunction(false)} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={makeRequestHandleChange} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
            {/* Add Category Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAddCategory}
                toggle={toggleModalAddCategory}
                size="lg"
            >
                <AddCategory toggleModalFunction={toggleModalAddCategory} />
            </Modal>

            {/* Add Sub Category Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAddSubCategory}
                toggle={toggleModalAddSubCategory}
                size="lg"
            >
                <AddSubCategory toggleModalFunction={toggleModalAddSubCategory} />
            </Modal>
        </>
    )
}

export default AdministrationHistoryMakeRequest