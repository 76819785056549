import classnames from "classnames";
import React from "react";
// reactstrap components
import {
  Container, Navbar, NavbarBrand
} from "reactstrap";


function AuthNavbar(props) {
  return (
    <Navbar
      className={classnames("navbar-absolute fixed-top", null)}
      expand="lg"
    >
      <Container>
        <div className="navbar-wrapper">
          <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
            <div
              style={{
                position: 'absolute',
                top: 30,
                left: 50,
              }}
            >
              <img
                src={require('../../../Assets/Images/ARKlogo2.png').default}
                style={{
                  height: 60,
                }}
              />
            </div>
          </NavbarBrand>
        </div>
      </Container>
    </Navbar>
  );
}

export default AuthNavbar;

