import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Card, CardBody, CardHeader, Col, Row, Input, CardFooter, Button
} from "reactstrap";
import './AddNewStakeHolder.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { CreateStakeHolder, GetStakeHolderBusinessStatus, GetStakeHolderOtherTitle, GetStakeHolderType, UpdateStakeHolder } from './Store/AddNewStakeHolderApi';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Box, List, ListItemButton, ListItemText } from '@mui/material';
import ResetEmailModal from 'components/ResetEmailModal/ResetEmailModal';

function AddNewStakeHolder() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { stakeHolderType, stakeHolderBusinessStatus, stakeHolderOtherTitle, addStakeholderErrors } = useSelector(state => state?.AddNewStakeHolder)
    const { advanceSearchResult } = useSelector(state => state?.dashboard)
    const { contractorDetailForDevelopmentActivity, userDetailForTopLeftCard } = useSelector(state => state?.userData)

    const [otherTitle, setOtherTitle] = useState('');
    const [formNavigateState, setFormNavigateState] = useState(1)
    const [stakeHolderTypeField, setStakeHolderTypeField] = useState(null)
    const [businessStatusField, setBusinessStatusField] = useState(null)
    const [relationToDev, setRelationToDev] = useState(null)
    const [companyNameField, setCompanyNameField] = useState('')
    const [tradingNameField, setTradingNameField] = useState('')
    const [websiteUrlField, setWebsiteUrlField] = useState('')
    const [postCodeField, setPostCodeField] = useState('')
    const [correspondingAddressField, setCorrespondingAddressField] = useState('')
    const [siteAddressFieldData, setSiteAddressFieldData] = useState([])
    const [siteAddressFieldDataShow, setSiteAddressFieldDataShow] = useState(false)

    const [retrieveCompleteAddressFieldData, setRetrieveCompleteAddressFieldData] = useState(null)
    const [retrieveAddressField1, setRetrieveAddressField1] = useState("")
    const [retrieveAddressField2, setRetrieveAddressField2] = useState("")
    const [retrieveTownCity, setRetrieveTownCity] = useState("")
    const [retrieveCountyState, setRetrieveCountyState] = useState("")
    const [retrieveCountry, setRetrieveCountry] = useState("")


    const [propertyNameField, setPropertyNameField] = useState('Dummy Name')
    const [titleField, setTitleField] = useState('')
    const [otherTitleField, setOtherTitleField] = useState('')
    const [forenameField, setForenameField] = useState('')
    const [surnameField, setSurnameField] = useState('')
    const [jobTitleField, setJobTitleField] = useState('')
    const [emailField, setEmailField] = useState('')
    const [contactNumberField, setContactNumberField] = useState('')
    const [phoneNumberField, setPhoneNumberField] = useState('')
    const [other_contact_title, setOtherContactTitleField] = useState('')
    const [other_contact_fore_name, setOtherContactForenameField] = useState('')
    const [other_contact_sur_name, setOtherContactSurnameField] = useState('')
    const [other_contact_job_title, setOtherContactJobTitleField] = useState('')
    const [other_contact_email, setOtherContactEmailField] = useState('')
    const [other_contact_contact_number, setOtherContactContactNumberField] = useState('')
    const [other_contact_phone_number, setOtherContactPhoneNumberField] = useState('')
    const [businessIsActiveField, setBusinessIsActiveField] = useState('')
    const [other_contact_is_active, setOtherContactIsActiveField] = useState('')
    const [primaryIsActiveField, setPrimaryIsActiveField] = useState('')

    const [isAddOtherContact, setIsAddOtherContact] = useState(false)

    const [resetEmail, setResetEmail] = useState('')

    const [otherContactFormValues, setOtherContactFormValues] = useState([{
        other_contact_title: "",
        other_contact_other_title: "",
        other_contact_fore_name: "",
        other_contact_sur_name: "",
        other_contact_job_title: "",
        other_contact_email: "",
        other_contact_contact_number: "",
        other_contact_phone_number: "",
        other_contact_is_active: ""
    }])

    useEffect(() => {
        advanceSearchResult[0]?.other_contactuser?.length > 0 && setIsAddOtherContact(true)
        setBusinessStatusField(advanceSearchResult[0]?.business_details[0]?.business_status_id || userDetailForTopLeftCard?.general_detail?.business_status_id || null)
        setStakeHolderTypeField(advanceSearchResult[0]?.business_details[0]?.stake_holder_type_id || userDetailForTopLeftCard?.general_detail?.stake_holder_type_id || null)
        setCompanyNameField(advanceSearchResult[0]?.business_details[0]?.company_name || userDetailForTopLeftCard?.general_detail?.company_name || '')
        setTradingNameField(advanceSearchResult[0]?.business_details[0]?.trading_name || userDetailForTopLeftCard?.general_detail?.trading_name || '')
        setWebsiteUrlField(advanceSearchResult[0]?.business_details[0]?.website_url || userDetailForTopLeftCard?.general_detail?.website_url || '')
        setPostCodeField(advanceSearchResult[0]?.business_details[0]?.post_code || userDetailForTopLeftCard?.general_detail?.post_code || '')
        setCorrespondingAddressField(advanceSearchResult[0]?.business_details[0]?.correspondence_address || userDetailForTopLeftCard?.general_detail?.correspondence_address || '')
        setPropertyNameField(advanceSearchResult[0]?.business_details[0]?.property_name || userDetailForTopLeftCard?.general_detail?.property_name || '')
        setTitleField(advanceSearchResult[0]?.title || userDetailForTopLeftCard?.title || '')
        setOtherTitleField(advanceSearchResult[0]?.other_title || '')
        setForenameField(advanceSearchResult[0]?.fore_name || userDetailForTopLeftCard?.fore_name || '')
        setSurnameField(advanceSearchResult[0]?.sur_name || userDetailForTopLeftCard?.sur_name || '')
        setJobTitleField(advanceSearchResult[0]?.job_title || userDetailForTopLeftCard?.job_title || '')
        setEmailField(advanceSearchResult[0]?.email || userDetailForTopLeftCard?.email || '')
        setContactNumberField(advanceSearchResult[0]?.contact_number || userDetailForTopLeftCard?.contact_number || '')
        setPhoneNumberField(advanceSearchResult[0]?.phone_number || userDetailForTopLeftCard?.phone_number || '')
        setBusinessIsActiveField(advanceSearchResult[0]?.business_details[0]?.is_active || userDetailForTopLeftCard?.general_detail?.is_active || 0)
        setPrimaryIsActiveField(advanceSearchResult[0]?.is_active || userDetailForTopLeftCard?.is_active || 0)

        setRetrieveAddressField1(advanceSearchResult[0]?.business_details[0]?.address_line1 || userDetailForTopLeftCard?.general_detail?.address_line1 || '')
        setRetrieveAddressField2(advanceSearchResult[0]?.business_details[0]?.address_line2 || userDetailForTopLeftCard?.general_detail?.address_line2 || '')
        setRetrieveTownCity(advanceSearchResult[0]?.business_details[0]?.city || userDetailForTopLeftCard?.general_detail?.city || '')
        setRetrieveCountyState(advanceSearchResult[0]?.business_details[0]?.state || userDetailForTopLeftCard?.general_detail?.state || '')
        setRetrieveCountry(advanceSearchResult[0]?.business_details[0]?.country || userDetailForTopLeftCard?.general_detail?.country || '')

        const data = advanceSearchResult[0]?.other_contactuser?.map(contact => {
            return {
                other_contact_title: contact.title,
                other_contact_other_title: contact.other_title,
                other_contact_fore_name: contact.fore_name,
                other_contact_sur_name: contact.sur_name,
                other_contact_job_title: contact.job_title,
                other_contact_email: contact.email,
                other_contact_contact_number: contact.contact_number,
                other_contact_phone_number: contact.phone_number,
                other_contact_is_active: contact.is_active
            }
        })

        advanceSearchResult[0]?.other_contactuser?.length > 0 && setOtherContactFormValues(data)

    }, [advanceSearchResult])

    // useEffect(() => {
    //     setEmailField(contractorDetailForDevelopmentActivity?.email || '')
    // }, [contractorDetailForDevelopmentActivity])

    useEffect(() => {
        dispatch(GetStakeHolderType())
        dispatch(GetStakeHolderBusinessStatus())
    }, [])

    const handleOtherTitle = (event) => {
        setOtherTitle(event.target.value);
    };
    const [title, setTitle] = useState('');
    const handleTitle = (event) => {
        setTitle(event.target.value);
    };
    const [stakeholderType, setStakeholderType] = useState('');
    const handleStakeholderType = (event) => {
        setStakeholderType(event.target.value);
    };
    const [businessStatus, setBusinessStatus] = useState('');
    const handleBusinessStatus = (event) => {
        setBusinessStatus(event.target.value);
    };

    const [businessFormError, setBusinessFormError] = useState([]);
    console.log("🚀 ~ file: AddNewStakeHolder.js ~ line 73 ~ AddNewStakeHolder ~ businessFormError", businessFormError)


    useEffect(() => {
        dispatch(GetStakeHolderOtherTitle())
    }, [])

    const createStakeHolderHandler = () => {
        const data = {
            title: titleField,
            other_title: otherTitleField,
            fore_name: forenameField,
            sur_name: surnameField,
            job_title: jobTitleField,
            email: emailField,
            contact_number: contactNumberField,
            phone_number: phoneNumberField,
            stake_holder_type_id: stakeHolderTypeField,
            relationship_to_dev: relationToDev,
            business_status_id: businessStatusField,
            trading_name: tradingNameField,
            website_url: websiteUrlField,
            post_code: postCodeField,
            correspondence_address: Array.isArray(correspondingAddressField) ? correspondingAddressField[1] : correspondingAddressField,
            property_name: 'propertyNameField',
            company_name: companyNameField,
            business_is_active: businessIsActiveField,
            primary_is_active: primaryIsActiveField,
            another_contact: !isAddOtherContact ? null : otherContactFormValues,

            address_line1: retrieveAddressField1,
            address_line2: retrieveAddressField2,
            city: retrieveTownCity,
            state: retrieveCountyState,
            country: retrieveCountry,
        }
        console.log("🚀 ~ file: AddNewStakeHolder.js:173 ~ createStakeHolderHandler ~ data.correspondingAddressField:", data.correspondingAddressField)
        console.log("🚀 ~ file: AddNewStakeHolder.js ~ line 125 ~ createStakeHolderHandler ~ data", data)
        sessionStorage.getItem("currentEditUserId") ? dispatch(UpdateStakeHolder({ data: data, id: advanceSearchResult[0]?.id || userDetailForTopLeftCard?.id, clearForm: clearDataHandler })) : dispatch(CreateStakeHolder({ data: data, clearForm: clearDataHandler }))
        // clearDataHandler()
    }

    const clearDataHandler = () => {
        setBusinessStatusField(null)
        setStakeHolderTypeField(null)
        setCompanyNameField("")
        setTradingNameField("")
        setWebsiteUrlField("")
        setPostCodeField("")
        setCorrespondingAddressField("")
        setSiteAddressFieldData([])
        setPropertyNameField("")
        setTitleField("")
        setForenameField("")
        setSurnameField("")
        setJobTitleField("")
        setEmailField("")
        setPhoneNumberField("")
        setContactNumberField("")
        setBusinessIsActiveField("")
        setPrimaryIsActiveField("")
        setOtherContactFormValues([{
            other_contact_title: "",
            other_contact_other_title: "",
            other_contact_fore_name: "",
            other_contact_sur_name: "",
            other_contact_job_title: "",
            other_contact_email: "",
            other_contact_contact_number: "",
            other_contact_phone_number: "",
            other_contact_is_active: ""
        }])

        setRetrieveAddressField1("")
        setRetrieveAddressField2("")
        setRetrieveTownCity("")
        setRetrieveCountyState("")
        setRetrieveCountry("")
    }

    let otherContactFormHandleChange = (i, e) => {
        let newOtherContactFormFormValues = [...otherContactFormValues];
        newOtherContactFormFormValues[i][e.target.name] = e.target.value;
        setOtherContactFormValues(newOtherContactFormFormValues);
    }

    let addOtherContactFormFields = () => {
        setOtherContactFormValues([...otherContactFormValues, {
            other_contact_title: "",
            other_contact_other_title: "",
            other_contact_fore_name: "",
            other_contact_sur_name: "",
            other_contact_job_title: "",
            other_contact_email: "",
            other_contact_contact_number: "",
            other_contact_phone_number: "",
            other_contact_is_active: ""
        }])
    }

    let removeOtherContactFormFields = (i) => {
        let newOtherContactFormFormValues = [...otherContactFormValues];
        newOtherContactFormFormValues.splice(i, 1);
        setOtherContactFormValues(newOtherContactFormFormValues)
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <>
            <Col lg="5" sm="12">
                {formNavigateState >= 1 && <Card className="pb-4">
                    <CardHeader className="flex mb-2">
                        <span className="text-lg font-bold">
                            About the Business
                        </span>
                    </CardHeader>
                    <hr />
                    <CardBody className=" ">
                        <Row>
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth className='remove-legend'>
                                    <label id="demo-simple-select-label" size="small">Stakeholder Type</label>
                                    {/* <InputLabel id="demo-simple-select-label" size="small">Stakeholder Type</InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={stakeHolderTypeField}
                                        label="StakeholderType "
                                        onChange={(e) => setStakeHolderTypeField(e.target.value)}
                                        size="small"
                                    >
                                        {
                                            stakeHolderType?.map(stakeHolder => {
                                                const { id, stake_holder_type_name } = stakeHolder
                                                return (
                                                    <MenuItem key={id} value={id}>{stake_holder_type_name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Col>

                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth className='remove-legend'>
                                    <label id="demo-simple-select-label" size="small">Business Status  </label>
                                    {/* <InputLabel id="demo-simple-select-label" size="small">Business Status  </InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={businessStatusField}
                                        label="BusinessStatus"
                                        onChange={(e) => setBusinessStatusField(e.target.value)}
                                        size="small"
                                    >
                                        {
                                            stakeHolderBusinessStatus?.map(businessStatus => {
                                                const { id, business_name } = businessStatus
                                                return (
                                                    <MenuItem key={id} value={id}>{business_name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                            {
                                stakeHolderTypeField == 5 &&
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Relationship to Development"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        value={relationToDev}
                                        onChange={(e) => setRelationToDev(e.target.value)}
                                    />
                                </Col>
                            }
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Company Name "
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={companyNameField}
                                    onChange={(e) => { setCompanyNameField(e.target.value); setTradingNameField(e.target.value) }}
                                // type="string"
                                />
                                {addStakeholderErrors?.company_name && <p className="text-danger">{addStakeholderErrors?.company_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Business / Trading Name (if different)"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={tradingNameField}
                                    onChange={(e) => setTradingNameField(e.target.value)}
                                // type="string"
                                />
                                {addStakeholderErrors?.trading_name && <p className="text-danger">{addStakeholderErrors?.trading_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Website URL"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="url"
                                    value={websiteUrlField}
                                    onChange={(e) => setWebsiteUrlField(e.target.value)}
                                // type="string"
                                />
                                {addStakeholderErrors?.website_url && <p className="text-danger">{addStakeholderErrors?.website_url}</p>}
                            </Col>
                            {/* <Col className="mt-4" lg="12" sm="12">
                                <TextField
                                    label="Correspondence Address "
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    value={correspondingAddressField}
                                    onChange={(e) => setCorrespondingAddressField(e.target.value)}
                                />
                            </Col> */}
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Begin Typing Address"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    multiline
                                    maxRows={1}
                                    value={correspondingAddressField}
                                    onFocus={e => setSiteAddressFieldDataShow(true)}
                                    onChange={(e) => {
                                        setCorrespondingAddressField(e.target.value)

                                        const fieldValue = e.target.value

                                        var myHeaders = new Headers();
                                        myHeaders.append("Content-Type", "application/json");

                                        var raw = JSON.stringify({
                                            "key": "46ab2-3af78-4dfa7-07fcf",
                                            "query": fieldValue,
                                            "country": "gbr"
                                        });

                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: raw,
                                            redirect: 'follow'
                                        };

                                        fetch("https://api.craftyclicks.co.uk/address/1.1/find/", requestOptions)
                                            .then(response => response.json())
                                            .then(result => { console.log("result==>", result); setSiteAddressFieldData(result?.results) })
                                            .catch(error => console.log('error', error));
                                    }}
                                />
                                {siteAddressFieldData?.length > 0 && siteAddressFieldDataShow &&
                                    <Box sx={{}} style={{ position: "absolute", background: "white", boxShadow: "0px 0px 1px 1px rgba(0,0,0,100%)", zIndex: "99999", width: '789.45px', height: siteAddressFieldData?.length * 48, minHeight: "70px", maxHeight: "400px", overflow: "scroll", maxWidth: '100%', }} onClick={() => setSiteAddressFieldDataShow(false)}>
                                        <List component="nav" aria-label="secondary mailbox folder">
                                            {
                                                siteAddressFieldData?.map(obj => (

                                                    <ListItemButton
                                                        // selected={selectedIndex === 2}
                                                        onClick={(e) => {
                                                            setCorrespondingAddressField(obj?.labels);
                                                            setSiteAddressFieldDataShow(false)
                                                            // setPostCodeField(obj?.labels[0]); 

                                                            var myHeaders = new Headers();
                                                            myHeaders.append("Content-Type", "application/json");

                                                            var raw = JSON.stringify({
                                                                "key": "46ab2-3af78-4dfa7-07fcf",
                                                                "id": obj?.id,
                                                                "country": "gbr"
                                                            });

                                                            var requestOptions = {
                                                                method: 'POST',
                                                                headers: myHeaders,
                                                                body: raw,
                                                                redirect: 'follow'
                                                            };

                                                            fetch("https://api.craftyclicks.co.uk/address/1.1/retrieve", requestOptions)
                                                                .then(response => response.json())
                                                                .then(result => {
                                                                    console.log("RETRIEVE==>", result?.result);
                                                                    setRetrieveCompleteAddressFieldData(result?.result)
                                                                    setRetrieveAddressField1(result?.result?.line_1)
                                                                    setRetrieveAddressField2(result?.result?.line_2)
                                                                    setRetrieveTownCity(result?.result?.locality)
                                                                    setRetrieveCountyState(result?.result?.province_name)
                                                                    setPostCodeField(result?.result?.postal_code);
                                                                    setRetrieveCountry(result?.result?.country_name);
                                                                })
                                                                .catch(error => console.log('error', error));
                                                        }}
                                                    >
                                                        <ListItemText primary={obj?.labels} />
                                                    </ListItemButton>
                                                ))
                                            }
                                        </List>
                                    </Box>
                                }
                                {addStakeholderErrors?.correspondence_address && <p className="text-danger">{addStakeholderErrors?.correspondence_address}</p>}
                            </Col>


                            <>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Address Line 1"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveAddressField1}
                                        onChange={(e) => setRetrieveAddressField1(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Address Line 2"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveAddressField2}
                                        onChange={(e) => setRetrieveAddressField2(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>

                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Town/City"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveTownCity}
                                        onChange={(e) => setRetrieveTownCity(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="County/State"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveCountyState}
                                        onChange={(e) => setRetrieveCountyState(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>

                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Country"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveCountry}
                                        onChange={(e) => setRetrieveCountry(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Postcode"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type="text"
                                        value={postCodeField}
                                        onChange={(e) => setPostCodeField(e.target.value)}
                                    />
                                    {addStakeholderErrors?.post_code && <p className="text-danger">{addStakeholderErrors?.title}</p>}
                                </Col>
                            </>

                            <Col className="mt-2" lg="6" sm="12">
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label-about-business">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label-about-business"
                                        name="row-radio-buttons-group"
                                        value={businessIsActiveField}
                                        onChange={(e) => setBusinessIsActiveField(e.target.value)}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>}

                <div className="exit-btn-container mr-0">
                    {!isAddOtherContact ?
                        <Button
                            className="px-3"
                            style={{ backgroundColor: '#2BA9CD' }}
                            onClick={() => setIsAddOtherContact(true)} color="info" size="sm"
                        >
                            Add Another Contact
                        </Button>
                        :
                        <Button
                            className="px-3"
                            style={{ backgroundColor: '#2BA9CD' }}
                            onClick={() => {
                                setIsAddOtherContact(false)
                                setOtherContactFormValues([{
                                    other_contact_title: "",
                                    other_contact_other_title: "",
                                    other_contact_fore_name: "",
                                    other_contact_sur_name: "",
                                    other_contact_job_title: "",
                                    other_contact_email: "",
                                    other_contact_contact_number: "",
                                    other_contact_phone_number: "",
                                    other_contact_is_active: ""
                                }])
                            }} color="info" size="sm"
                        >
                            Remove All Other Contact
                        </Button>
                    }
                </div>

                {isAddOtherContact &&
                    <Card className="pb-4">
                        <CardHeader className="flex mb-2">
                            <span className="text-lg font-bold">
                                Other Contact Details
                            </span>
                        </CardHeader>
                        <hr />
                        {
                            otherContactFormValues?.map((element, index) => (
                                <>
                                    <CardBody className=" " key={index}>
                                        <Row>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size="small">Title</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="OtherTitle"
                                                        size="small"
                                                        name="other_contact_title"
                                                        value={element?.other_contact_title}
                                                        onChange={(e) => otherContactFormHandleChange(index, e)}
                                                    >
                                                        <MenuItem value="Mr">Mr</MenuItem>
                                                        <MenuItem value="Mrs">Mrs</MenuItem>
                                                        <MenuItem value="Miss">Miss</MenuItem>
                                                        <MenuItem value="Ms">Ms</MenuItem>
                                                        <MenuItem value="Dr">Dr</MenuItem>
                                                        <MenuItem value="Other">Other</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                            {element?.other_contact_title == "Other" && <Col className="mt-4" lg="6" sm="12">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size="small">Other Title</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="OtherTitle"
                                                        size="small"
                                                        name="other_contact_other_title"
                                                        value={element?.other_contact_other_title}
                                                        onChange={(e) => otherContactFormHandleChange(index, e)}
                                                    >
                                                        {
                                                            stakeHolderOtherTitle?.map(stakeholder => {
                                                                const { id, other_title } = stakeholder
                                                                return (
                                                                    <MenuItem key={id} value={other_title}>{other_title}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Col>}
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Forename"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    name="other_contact_fore_name"
                                                    value={element?.other_contact_fore_name}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Surname"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    name="other_contact_sur_name"
                                                    value={element?.other_contact_sur_name}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Job Title"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    name="other_contact_job_title"
                                                    value={element?.other_contact_job_title}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Contact Number"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    type="text"
                                                    name="other_contact_contact_number"
                                                    value={element?.other_contact_contact_number}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Mobile Number"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    type="string"
                                                    name="other_contact_phone_number"
                                                    value={element?.other_contact_phone_number}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Email Address"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    type="email"
                                                    name="other_contact_email"
                                                    value={element?.other_contact_email}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                />
                                            </Col>
                                            <Col className="mt-2" lg="6" sm="12">
                                                <FormControl>
                                                    <FormLabel id="otherContactStatus">Status</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="otherContactStatus"
                                                        name="other_contact_is_active"
                                                        value={element?.other_contact_is_active}
                                                        onChange={(e) => otherContactFormHandleChange(index, e)}
                                                    >
                                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Col>

                                            {
                                                otherContactFormValues?.length > 0 &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '16px'
                                                }}>
                                                    <Button
                                                        className="px-3"
                                                        style={{ backgroundColor: '#EF8157', marginLeft: "16px" }}
                                                        onClick={() => removeOtherContactFormFields(index)}
                                                        color="danger" size="sm"
                                                    >
                                                        Remove
                                                    </Button>
                                                    {
                                                        sessionStorage.getItem("currentEditUserId") &&
                                                        <span
                                                            style={{
                                                                fontSize: '16px',
                                                                color: '#EF8157',
                                                                fontWeight: 'bold',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => { setResetEmail(element?.other_contact_email), handleClickOpen() }}
                                                        >
                                                            Send Password Reset
                                                        </span>
                                                    }
                                                </div>
                                            }
                                        </Row>
                                    </CardBody>

                                </>
                            ))
                        }
                        <CardFooter>
                            <div className="exit-btn-container mr-0">
                                {/* {sessionStorage.getItem("currentEditUserId") === null && */}
                                <Button
                                    className="px-3"
                                    style={{ backgroundColor: '#2BA9CD' }}
                                    onClick={addOtherContactFormFields} color="info" size="sm"
                                >
                                    Add another contact
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                }
            </Col>
            {/* {formNavigateState >= 2 &&  */}
            <Col lg="4" sm="12">
                <Card className="pb-4">
                    <CardHeader className="flex mb-2">
                        <span className="text-lg font-bold">
                            Contact Details
                        </span>
                    </CardHeader>
                    <hr />
                    <CardBody className=" ">
                        <Row>
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Title</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Title"
                                        size="small"
                                        value={titleField}
                                        onChange={(e) => setTitleField(e.target.value)}
                                    >
                                        <MenuItem value="Mr">Mr</MenuItem>
                                        <MenuItem value="Mrs">Mrs</MenuItem>
                                        <MenuItem value="Miss">Miss</MenuItem>
                                        <MenuItem value="Ms">Ms</MenuItem>
                                        <MenuItem value="Dr">Dr</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                                {addStakeholderErrors?.title && <p className="text-danger">{addStakeholderErrors?.title}</p>}
                            </Col>
                            {titleField == "Other" &&
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size="small">Other Title</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Title"
                                            size="small"
                                            value={otherTitleField}
                                            onChange={(e) => setOtherTitleField(e.target.value)}
                                        >
                                            {
                                                stakeHolderOtherTitle?.map(stakeholder => {
                                                    const { id, other_title } = stakeholder
                                                    return (
                                                        <MenuItem key={id} value={other_title}>{other_title}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Col>}
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Forename"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={forenameField}
                                    onChange={(e) => setForenameField(e.target.value)}
                                // type="string"
                                />
                                {addStakeholderErrors?.fore_name && <p className="text-danger">{addStakeholderErrors?.fore_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Surname"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={surnameField}
                                    onChange={(e) => setSurnameField(e.target.value)}
                                // type="string"
                                />
                                {addStakeholderErrors?.sur_name && <p className="text-danger">{addStakeholderErrors?.sur_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Job Title"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={jobTitleField}
                                    onChange={(e) => setJobTitleField(e.target.value)}
                                // type="string"
                                />
                                {addStakeholderErrors?.job_title && <p className="text-danger">{addStakeholderErrors?.job_title}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Contact Number"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="text"
                                    value={contactNumberField}
                                    onChange={(e) => setContactNumberField(e.target.value)}
                                // type="string"
                                />
                                {addStakeholderErrors?.contact_number && <p className="text-danger">{addStakeholderErrors?.contact_number}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Mobile Number"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="string"
                                    value={phoneNumberField}
                                    onChange={(e) => setPhoneNumberField(e.target.value)}
                                />
                                {addStakeholderErrors?.phone_number && <p className="text-danger">{addStakeholderErrors?.phone_number}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Email Address"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="email"
                                    value={emailField}
                                    onChange={(e) => setEmailField(e.target.value)}
                                />
                                {addStakeholderErrors?.email && <p className="text-danger">{addStakeholderErrors?.email}</p>}
                            </Col>
                            <Col className="mt-2" lg="6" sm="12">
                                <FormControl>
                                    <FormLabel id="primaryContactStatus">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="primaryContactStatus"
                                        name="primaryContactStatusName"
                                        value={primaryIsActiveField}
                                        onChange={(e) => setPrimaryIsActiveField(e.target.value)}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <div className="exit-btn-container mr-0">
                            {
                                sessionStorage.getItem("currentEditUserId") &&
                                <span
                                    style={{
                                        fontSize: '16px',
                                        color: '#EF8157',
                                        fontWeight: 'bold',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => { setResetEmail(emailField), handleClickOpen() }}
                                >
                                    Send Password Reset
                                </span>
                            }
                            <Button
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD', marginLeft: "16px" }}
                                onClick={createStakeHolderHandler} color="info" size="sm"
                            >
                                {sessionStorage.getItem("currentEditUserId") ? "Update" : "Save"}
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Col>

            <ResetEmailModal
                handleClose={handleClose}
                open={open}
                oldEmail={resetEmail}
            />
        </>
    );
}

export default AddNewStakeHolder;
