import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const stakeHolderSlice = createSlice({
    name: 'stakeHolder',
    initialState: {
        stakeHolderType: null,
        surveyorBusinessStatus: null,
        surveyorQualification: [],
        allSurveyor: [],
        surveyor: [],
        addSurveyorErrors: {},
        surveyorInsurer: []
    },
    reducers: {
        ChangeUserData: (state, action) => {
            state.userData = action.payload
        }
    },
    extraReducers: {

        ['stakeHolder/GetStakeHolderType/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: AddNewStakeHolderSlice.js ~ line 18 ~ data", data)
            if (status >= 200 && status < 300) {

                state.stakeHolderType = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.stakeHolderType = data
            }
        },
        ['surveyor/GetAllSurveyor/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: AddNewStakeHolderSlice.js ~ line 31 ~ data", data)
            if (status >= 200 && status < 300) {
                state.allSurveyor = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.allSurveyor = data
            }
        },
        ['surveyor/GetSurveyorBusinessStatus/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.surveyorBusinessStatus = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.surveyorBusinessStatus = data
            }
        },
        ['surveyor/GetSurveyorQualification/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.surveyorQualification = data?.data.sort(function (a, b) {
                    return a.id - b.id;
                });
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.surveyorQualification = data
            }
        },
        ['surveyor/GetSurveyorInsurer/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.surveyorInsurer = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.surveyorInsurer = data
            }
        },
        ['surveyor/CreateSurveyor/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.surveyor = data?.data
                state.addSurveyorErrors = {}
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                state.addSurveyorErrors = data?.error
            }
        },
    },
});

export const {
    ChangeUserData,
} = stakeHolderSlice.actions;

export default stakeHolderSlice.reducer;