import MUISelect from '@mui/material/Select';
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
    Button,
    Card, CardBody, CardHeader, Col, Input, Modal, Row, Table
} from "reactstrap";
import DefectDetail from "../DefectDetail/DefectDetail";
// import './Dashboard.css';
import HeightIcon from '@mui/icons-material/Height';
import { FormControl, MenuItem } from "@mui/material";
import PerfectScrollbar from "perfect-scrollbar";
import { useHistory } from "react-router-dom";
import { GetDevelopmentBuildFactorDetails, GetDevelopmentGeneralDetails, GetDevelopmentRefurbishmentDetails, GetDevelopments, GetDevelopmentSiteInvestigationDetails, GetDevelopmentSurveyRequiremntDetails } from "../AddNewDevelopment/Store/AddNewDevelopmentApi";
import { ChangeDevelopmentMode } from "../AddNewDevelopment/Store/AddNewDevelopmentSlice";
import { GetAdminSingleDevelopmentDetail, GetDefectsOfSingleDevelopment, GetUserHistory } from "../Dashboard/Store/DashboardApi";
import { GetSingleDefectDetail } from "../DefectDetail/Store/DefectDetailApi";
import AddDiary from "../Diaries/AddDiary/AddDiary";
import AdministrationHistoryData from "../Diaries/AdministrationHistory/AdministrationHistoryData/AdministrationHistoryData";
import { GetAdmisistrationHistoryToFieldDataForSurveyor, GetDiaries, GetNotes, GetUsersOfDevelopments } from "../Diaries/AdministrationHistory/Store/AdministrationHistoryApi";
import AdministrationHistoryAddDefect from "../Diaries/AdministrationHistoryAddDefect/AdministrationHistoryAddDefect";
import AdministrationHistoryAddDiary from "../Diaries/AdministrationHistoryAddDiary/AdministrationHistoryAddDiary";
import AdministrationHistoryAddNote from "../Diaries/AdministrationHistoryAddNote/AdministrationHistoryAddNote";
import AdministrationHistoryMakeRequest from "../Diaries/AdministrationHistoryMakeRequest/AdministrationHistoryMakeRequest";
import AdministrationHistoryUploadDocument from "../Diaries/AdministrationHistoryUploadDocument/AdministrationHistoryUploadDocument";
import AllDiaries from "../Diaries/AllDiaries/AllDiaries";
import AllocateDevelopment from "../Diaries/AllocateDevelopment/AllocateDevelopment";
import MakeARequest from "../Diaries/MakeARequest/MakeARequest";
import {
    SetCurrentForm,
    ToggleModalAdministrationHistoryAddDiary,
    ToggleModalAdministrationHistoryMakeRequest
} from "../Shared/store/sharedSlice";
import {
    SetSingleUnAllocatedDetailsTitle,
    SetIsViewDevelopmentDetails,
    SetShowStackholderActivity,
    SetShowSingleUnAllocatedDevelopments,
    SetCurrentDashboard,
    SetShowDefectDetail,
    SetHideAllDefects,
    SetShowDefectDetailFullWidth,
    SetShowAllDefectsOnTopSide,
} from "../Shared/store/pageSlice";
import "./ExternalUsers.css";
import { ChangeDevelopmentStatus, GetCurrentViewedDevelopmentDetails, GetMakeRequestsForOtherUsers, GetSurveyorAcceptedDevelopments, GetSurveyorDetails, GetSurveyorDevelopments } from "./Store/ExternalUsersApi";
import { ChangeCurrentSelectedAcceptedDevelopment, ChangeDashboardNameForNavbar } from "./Store/ExternalUsersSlice";

var psTable;

function ExternalUsers() {

    const scrollDiv1 = useRef(null);
    const scrollDiv2 = useRef(null);
    const scrollDiv3 = useRef(null);
    const scrollDiv4 = useRef(null);
    const scrollDiv5 = useRef(null);
    const scrollDiv6 = useRef(null);
    const scrollDiv7 = useRef(null);
    const scrollDiv8 = useRef(null);
    const scrollDiv9 = useRef(null);
    const scrollDiv10 = useRef(null);

    const { currentSelectedAcceptedDevelopment } = useSelector(state => state?.ExternalUsers)
    const { defectsOfSingleDevelopment } = useSelector(state => state?.dashboard)

    const history = useHistory()
    const dispatch = useDispatch()
    const {
        modalAdministrationHistoryAddDiary,
        modalAdministrationHistoryMakeRequest
    } = useSelector(state => state.shared)
    const {
        showSingleUnAllocatedDevelopments,
        showStackholderActivity,
        isViewDevelopmentDetails,
        singleUnAllocatedDetailsTitle,
        showSingleUser,
        showDefectDetail,
        showDefectDetailFullWidth,
        showAllDefectsOnTopSide,
        hideAllDefects,
        singleDevelopmentDefects,
    } = useSelector(state => state.page.present)

    useEffect(() => {
        dispatch(GetUserHistory())
    }, [])


    const {
        unAllocatedDevelopments,
        surveyRequests,
        userHistory,
        developments,
        developmentNotifications,
        notes,
        userDevelopments,
        adminSingleDevelopmentDetail,
        defects,
        advanceSearchResult,
        getUserHistory
    } = useSelector(state => state.dashboard)




    const [stakeHolder, setStakeHolder] = useState(null);
    const [notificationRange, setNotificationRange] = useState("1");
    const [developer, setDeveloper] = useState(null);
    const [surveyor, setSurveyor] = useState(null);
    const [modalDiaries, setModalDiaries] = useState(false)
    const [modalAddDiary, setModalAddDiary] = useState(false)
    const [modalAllocateDevelopment, setModalAllocateDevelopment] = useState(false)
    const [modalMakeARequest, setModalMakeARequest] = useState(false)

    // const [modalAdministrationHistoryAddDiary, setModalAdministrationHistoryAddDiary] = useState(false)
    const [modalAdministrationHistoryAddNote, setModalAdministrationHistoryAddNote] = useState(false)
    // const [modalAdministrationHistoryMakeRequest, setModalAdministrationHistoryMakeRequest] = useState(false)
    const [modalAdministrationHistoryAddDefect, setModalAdministrationHistoryAddDefect] = useState(false)
    const [modalAdministrationHistoryUploadDocument, setModalAdministrationHistoryUploadDocument] = useState(false)
    // const [showAllDefectsOnTopSide, setShowAllDefectsOnTopSide] = useState(false)
    // const [hideAllDefects, setHideAllDefects] = useState(false)

    const toggleModalDiaries = () => {
        setModalDiaries(!modalDiaries);
    };
    const toggleModalAddDiary = () => {
        setModalAddDiary(!modalAddDiary);
    };
    const toggleModalAdministrationHistoryAddDiary = (value) => {
        // setModalAdministrationHistoryAddDiary(!modalAdministrationHistoryAddDiary);
        dispatch(ToggleModalAdministrationHistoryAddDiary(value))
    };
    const toggleModalAdministrationHistoryAddNote = () => {
        setModalAdministrationHistoryAddNote(!modalAdministrationHistoryAddNote);
    };
    const toggleModalAdministrationHistoryMakeRequest = (value) => {
        // setModalAdministrationHistoryMakeRequest(!modalAdministrationHistoryMakeRequest);
        dispatch(ToggleModalAdministrationHistoryMakeRequest(value))
    };
    const toggleModalAdministrationHistoryAddDefect = () => {
        setModalAdministrationHistoryAddDefect(!modalAdministrationHistoryAddDefect);
    };
    const toggleModalAdministrationHistoryUploadDocument = () => {
        setModalAdministrationHistoryUploadDocument(!modalAdministrationHistoryUploadDocument);
    };
    const toggleModalAllocateDevelopment = () => {
        setModalAllocateDevelopment(!modalAllocateDevelopment);
    };
    const toggleModalMakeARequest = () => {
        setModalMakeARequest(!modalMakeARequest);
    };

    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = React.useState(config);

        const sortedItems = React.useMemo(() => {
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const [developmentStatusField, setDevelopmentStatusField] = useState(null)

    function RenderUnAllocatedDevelopments(unAllocatedDevelopmentItems) {
        return unAllocatedDevelopmentItems?.map((item, key) => {
            const { srNo: id, surveyor, development, dateSubmitted, status } = item
            return (
                <tr
                    key={id}
                >
                    <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetShowStackholderActivity(true)); dispatch(SetIsViewDevelopmentDetails(true)); dispatch(SetSingleUnAllocatedDetailsTitle("Development")); dispatch(GetAdminSingleDevelopmentDetail(id)); dispatch(SetCurrentDashboard("AWS Development Dashboard")) }}>{development}</td>
                    <td className="">{moment(dateSubmitted).format("DD/MM/YY")}</td>
                    {userData?.role != "Surveyor" ?
                        <td className="">{status}</td>
                        :
                        <td className="development-status">
                            <FormControl fullWidth>
                                <MUISelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="BusinessStatus "
                                    size="small"
                                    value={developmentStatusField || status}
                                    onChange={(e) => { setDevelopmentStatusField(e.target.value); dispatch(ChangeDevelopmentStatus({ data: e?.target.value, id: id })) }}
                                >

                                    <MenuItem value={0}>Pending</MenuItem>
                                    <MenuItem value={1}>Accept</MenuItem>
                                    <MenuItem value={2}>Decline</MenuItem>

                                </MUISelect>
                            </FormControl>
                        </td>
                    }
                </tr>
            )
        })
    }
    function RenderSurveyRequests(surveyRequestItems) {
        return surveyRequestItems?.map((item, key) => {
            const { id, category, user, due_date } = item
            return (
                <tr key={id}>
                    {/* <td>{srNo}</td> */}
                    <td>{category?.category_name}</td>
                    <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetShowStackholderActivity(false)); dispatch(SetIsViewDevelopmentDetails(false)); dispatch(SetSingleUnAllocatedDetailsTitle("Surveyor")); dispatch(SetCurrentDashboard("AWS Surveyor Dashboard")) }}>{user?.username}</td>
                    <td className="">{moment(due_date).format("DD/MM/YY")}</td>
                </tr>
            )
        })
    }
    function RenderUserHistory(userHistoryItems) {
        return userHistoryItems?.map((item, key) => {
            const { id, log_name, description, created_at, properties } = item
            // const { username } = causer
            return (
                <tr
                    className="clickable-row"
                    key={id}
                // onClick={() => { dispatch(setShowSingleUser(true)) }}
                >
                    {/* <td>{srNo}</td> */}
                    <td width={60}>{properties?.type}</td>
                    <td className="" onClick={() => { username === "Developer" ? (dispatch(SetShowSingleUnAllocatedDevelopments(true)), dispatch(SetShowStackholderActivity(true)), dispatch(SetIsViewDevelopmentDetails(true)), dispatch(SetSingleUnAllocatedDetailsTitle("Development")), dispatch(SetCurrentDashboard("AWS Development Dashboard"))) : username === "Surveyor" ? (dispatch(SetShowSingleUnAllocatedDevelopments(true)), dispatch(SetShowStackholderActivity(false)), dispatch(SetIsViewDevelopmentDetails(false)), dispatch(SetSingleUnAllocatedDetailsTitle("Surveyor")), dispatch(SetCurrentDashboard("AWS Surveyor Dashboard"))) : null }}>{log_name}</td>
                    <td style={{ fontSize: "10px" }} className="">{moment(created_at).format("DD/MM/YY h:mm")}</td>
                </tr>
            )
        })
    }
    function RenderDevelopments(DevelopmentItems) {
        return DevelopmentItems?.map((item, key) => {
            const { id, developmentName, mainContractor, expectedCompletionDate, surveyor, nextSurveyDate, progress, actionNotified, defect, totalDefects, totalActions } = item
            return (
                <tr
                    key={id}
                >
                    <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetIsViewDevelopmentDetails(true)); dispatch(GetAdminSingleDevelopmentDetail(id)); dispatch(SetShowStackholderActivity(true)); dispatch(SetSingleUnAllocatedDetailsTitle("Development")); dispatch(SetCurrentDashboard("AWS Development Dashboard")); dispatch(ChangeCurrentSelectedAcceptedDevelopment(item)); dispatch(GetDiaries(id)); dispatch(GetDefectsOfSingleDevelopment(id)); dispatch(GetCurrentViewedDevelopmentDetails(id)); dispatch(GetNotes(id)); dispatch(ChangeDashboardNameForNavbar("Development Dashboard")) }}>{developmentName}</td>
                    <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetShowStackholderActivity(false)); dispatch(SetIsViewDevelopmentDetails(false)); dispatch(SetSingleUnAllocatedDetailsTitle("Contractor")); dispatch(SetCurrentDashboard("AWS Contractor Dashboard")) }}>{mainContractor}</td>
                    <td>{moment(expectedCompletionDate).format("DD/MM/YY")}</td>
                    <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetShowStackholderActivity(false)); dispatch(SetIsViewDevelopmentDetails(false)); dispatch(SetSingleUnAllocatedDetailsTitle("Surveyor")); dispatch(SetCurrentDashboard("AWS Surveyor Dashboard")) }}>{surveyor}</td>
                    <td>{moment(nextSurveyDate).format("DD/MM/YY")}</td>
                    <td>{moment(actionNotified).format("DD/MM/YY")}</td>
                    <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetShowStackholderActivity(true)) }}>{totalDefects}</td>
                    <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetShowStackholderActivity(true)) }}>{totalActions}</td>
                </tr>
            )
        })
    }
    function RenderDevelopmentNotifications(notificationItems) {
        return notificationItems?.map((item, key) => {
            // const { id, notification, notified, stage, sectionwithinStage, description, unitBlock, prirority, photoVideoAvailable, beingActions, isRemedialWorkComplete, } = item
            // const { id, notification, notification_date, description, block, unit, risk, attachment } = item
            const { id, defect_action, notified, category, sub_category, description, block, unit, risk, photoVideoAvailable, multiple_file, beingActions, isRemedialWorkComplete, } = item

            return (
                <tr
                    key={id}
                    className="cursor-pointer"
                    onClick={() => { dispatch(GetSingleDefectDetail(id)); dispatch(SetShowDefectDetail(true)); dispatch(SetShowDefectDetailFullWidth(false)) }}
                >
                    <td>{defect_action == "1" ? "Defect" : "Action"}</td>
                    <td>{moment(notified).format("DD/MM/YY")}</td>
                    <td>{category?.category_name}</td>
                    <td>{sub_category?.sub_category_name}</td>
                    <td>{description}</td>
                    <td>{block}/{unit}</td>
                    <td>{risk == "1" ? "High" : risk == "2" ? "Medium" : "Low"}</td>
                    {/* <td>{photoVideoAvailable != null ? "Yes" : "No"}</td> */}
                    <td>{multiple_file?.length > 0 ? "Yes" : "No"}</td>
                    <td>{beingActions}</td>
                    <td>{isRemedialWorkComplete}</td>
                </tr>
            )
        })
    }
    function RenderDefects(isOnTopSide) {
        return defects?.map((item, key) => {
            const { id, defectsAction, notified, stage, sectionWithStage, description, unitBlock, priority, photoVideoAvailable, beingActioned, remediaWorkCompleted, } = item
            return (
                <tr
                    key={id}
                    className="clickable-row"
                    onClick={null}
                // onClick={() => dispatch(setShowDefectDetail(true))}
                >
                    <td><input type="checkbox" /></td>
                    <td onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{defectsAction}</td>
                    <td onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{moment(notified).format("DD/MM/YY")}</td>
                    <td onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{stage}</td>
                    <td style={{ width: "110px" }} onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{sectionWithStage}</td>
                    <td style={{ width: "110px" }} onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{description}</td>
                    <td onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{unitBlock}</td>
                    <td onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{priority}</td>
                    <td onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{photoVideoAvailable}</td>
                    <td onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{beingActioned}</td>
                    <td onClick={() => { dispatch(setShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{remediaWorkCompleted}</td>
                </tr>
            )
        })
    }

    function RenderNotes(notesItems) {
        return notesItems?.map((item, key) => {
            const { id, category, user, created_at } = item
            return (
                <tr
                    key={id}
                >
                    <td>{id}</td>
                    <td>{category?.category_name}</td>
                    <td>{user?.username}</td>
                    <td>{moment(created_at).format("DD/MM/YY")}</td>
                </tr>
            )
        })
    }
    function RenderUserDevelopments() {
        return userDevelopments?.map((item, key) => {
            const { srNo, development, postCode, completed } = item
            return (
                <tr
                    key={srNo}
                >
                    <td>{srNo}</td>
                    <td>{development}</td>
                    <td>{postCode}</td>
                    <td className="date-time-row ">
                        <div>
                            {moment(completed).format("h:mm a")}
                        </div>
                        <div>
                            {moment(completed).format("DD/MM/YY")}
                        </div>
                    </td>
                </tr>
            )
        })
    }
    function AllDefects({ isOnTopSide, isFullHeight, style }) {
        return (
            // <div>
            <Card className="scroll-card" style={style}>
                <div ref={scrollDiv8}>

                    <CardHeader>
                        <span className="custom-card-header">
                            Lorem Care Homes / Developers Ltd/ Surveyors (List of all Defects)
                        </span>
                    </CardHeader>
                    <CardBody>
                        <Table
                            // responsive
                            className="custom-table"
                        >
                            <thead>
                                <tr>
                                    <th style={{ padding: "0 4px" }} ></th>
                                    <th style={{ padding: "0 4px" }} >Defects / Notifications</th>
                                    <th style={{ padding: "0 4px" }} >Notified</th>
                                    <th style={{ padding: "0 4px" }} >Stage</th>
                                    <th style={{ padding: "0 4px" }} >Section With Stage</th>
                                    <th style={{ padding: "0 4px" }} >Description</th>
                                    <th style={{ padding: "0 4px" }} >Block/Unit</th>
                                    <th style={{ padding: "0 4px" }} >Prirority</th>
                                    <th style={{ padding: "0 4px" }} >Photo / Video Available</th>
                                    <th style={{ padding: "0 4px" }} >Actioned in 30 days</th>
                                    <th style={{ padding: "0 4px" }} >Remedial Work Complete?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {RenderDefects(isOnTopSide)}
                            </tbody>
                        </Table>
                    </CardBody>
                </div>
            </Card>
            // </div>
        )
    }

    const { items: unAllocatedDevelopmentItems, requestSort: unAllocatedDevelopmentRequestSort, sortConfig: unAllocatedDevelopmentSortConfig } = useSortableData(unAllocatedDevelopments);
    const { items: surveyRequestItems, requestSort: surveyRequestRequestSort, sortConfig: surveyRequestSortConfig } = useSortableData(surveyRequests);
    const { items: DevelopmentItems, requestSort: DevelopmentRequestSort, sortConfig: DevelopmentSortConfig } = useSortableData(developments);
    const { items: userHistoryItems, requestSort: userHistoryRequestSort, sortConfig: userHistorySortConfig } = useSortableData(userHistory);
    const { items: notesItems, requestSort: notesRequestSort, sortConfig: notesSortConfig } = useSortableData(notes);
    const { items: developmentActivityItems, requestSort: developmentActivityRequestSort, sortConfig: developmentActivitySortConfig } = useSortableData(notes);
    const { items: notificationItems, requestSort: notificationRequestSort, sortConfig: notificationSortConfig } = useSortableData(developmentNotifications);


    useEffect(() => {
        console.log("SCROLLDIV7")
        console.log(scrollDiv7)

        if (scrollDiv1.current)
            psTable = new PerfectScrollbar(scrollDiv1.current);
        if (scrollDiv2.current)
            psTable = new PerfectScrollbar(scrollDiv2.current);
        if (scrollDiv3.current)
            psTable = new PerfectScrollbar(scrollDiv3.current);
        if (scrollDiv4.current)
            psTable = new PerfectScrollbar(scrollDiv4.current);
        if (scrollDiv5.current)
            psTable = new PerfectScrollbar(scrollDiv5.current);
        if (scrollDiv6.current)
            psTable = new PerfectScrollbar(scrollDiv6.current);
        if (scrollDiv7.current)
            psTable = new PerfectScrollbar(scrollDiv7.current);
        if (scrollDiv8.current)
            psTable = new PerfectScrollbar(scrollDiv8.current);
        if (scrollDiv9.current)
            psTable = new PerfectScrollbar(scrollDiv9.current);
        if (scrollDiv10.current)
            psTable = new PerfectScrollbar(scrollDiv10.current);
    })

    const { allDevelopments } = useSelector(state => state.AddNewDevelopment)
    const { surveyorDevelopments, surveyorAcceptedDevelopments, currentViewedDevelopmentDetails, allMakeRequests, allMakeRequestsForOtherUsers, surveyorDetails } = useSelector(state => state.ExternalUsers)
    const { allNotes, allDefects, administrationHistoryDataForSurveyor } = useSelector(state => state.AdministrationHistory)
    const { userData } = useSelector(state => state.userData)


    console.log("🚀 ~ file: ExternalUsers.js ~ line 441 ~ ExternalUsers ~ surveyorAcceptedDevelopments", surveyorAcceptedDevelopments)

    useEffect(() => {
        dispatch(GetDevelopments())
    }, [])

    useEffect(() => {
        dispatch(GetSurveyorDevelopments())
    }, [])

    useEffect(() => {
        dispatch(GetSurveyorAcceptedDevelopments())
    }, [])

    useEffect(() => {
        dispatch(GetSurveyorDetails())
    }, [])

    // useEffect(() => {
    //     dispatch(GetNotes())
    // }, [])

    // useEffect(() => {
    //     dispatch(GetDefects())
    // }, [])

    useEffect(() => {
        // dispatch(GetMakeRequests())
        dispatch(GetMakeRequestsForOtherUsers())
    }, [])

    const EditUserHandler = () => {
        console.log("🚀 ~ file: Dashboard.js ~ line 377 ~ EditUserHandler ~ advanceSearchResult", advanceSearchResult)
        if (advanceSearchResult[0]?.surveyor_id !== null) {
            history.push("/admin/system-maintenance")
            dispatch(SetCurrentForm('Surveyor'))
            sessionStorage.setItem("currentEditUserId", advanceSearchResult[0]?.surveyor?.id)
        }
        if (advanceSearchResult[0]?.insurer_id !== null) {
            history.push("/admin/system-maintenance")
            dispatch(SetCurrentForm('Insurer / Broker'))
            sessionStorage.setItem("currentEditUserId", advanceSearchResult[0]?.insurer_type?.id)
        }
        if (advanceSearchResult[0]?.broker_id !== null) {
            history.push("/admin/system-maintenance")
            dispatch(SetCurrentForm('Insurer / Broker'))
            sessionStorage.setItem("currentEditUserId", advanceSearchResult[0]?.broker_type?.id)
        }
        if (advanceSearchResult[0]?.stake_holder_id !== null) {
            history.push("/admin/system-maintenance")
            dispatch(SetCurrentForm('Stakeholder'))
            sessionStorage.setItem("currentEditUserId", advanceSearchResult[0]?.stakeholder_type?.id)
        }

    }
    // useEffect(() => {
    //   dispatch(SetShowStackholderActivity(false))
    //   dispatch(SetShowSingleUnAllocatedDevelopments(false))
    //   dispatch(SetSingleDevelopmentDefects(false))
    //   dispatch(SetShowSingleUnAllocatedDevelopments(false))
    //   dispatch(SetShowSingleUser(false))
    //   dispatch(SetShowDefectDetail(false))
    //   dispatch(SetShowDefectDetailFullWidth(false))
    //   dispatch(SetShowAllDefectsOnTopSide(false))
    //   dispatch(SetHideAllDefects(false))
    // }, [resetDashboard])

    return (
        <>
            <div className="content nested-perfect-scroll">
                <Row className="gx-5">
                    <Col lg="3" sm="12">
                        <Card className="scroll-card" style={{ height: "253px" }}>
                            <div ref={scrollDiv2}>
                                <CardHeader style={{ position: "sticky", background: "white", zIndex: 1000, height: "70px", top: 0 }}>
                                    <span className="custom-card-header">
                                        {/* {`${userData?.role == "Surveyor" ? 'Surveyor' : 'Developer'}`} Details */}
                                        {`${ userData?.role == "Surveyor" ? 'Surveyor' : userData?.role == "Broker" ? 'Broker' : userData?.role == "Insurer" ? 'Insurer' : userData?.role == "Developer" ? 'Developer' : userData?.role == "Contractor" ? 'Contractor' : userData?.role == "Financial Interest" ? 'Financial Interest' : 'Sub Contractor Hub'}`} Details
                                    </span>
                                </CardHeader>
                                <CardBody style={{ paddingTop: 0 }}>
                                    <div className="custom-card-detail" style={{ paddingBottom: "8px" }}>
                                        <b>Ongoing Developments</b>:  {surveyorDetails?.ongoingDevelopment}
                                    </div>
                                    <div className="custom-card-detail" style={{ paddingBottom: "8px" }}>
                                        <b>Completed Developments</b> :  {surveyorDetails?.completedDevelopment}
                                    </div>
                                    <div className="custom-card-detail" style={{ paddingBottom: "8px" }}>
                                        <b>Active Contacts</b> :  {surveyorDetails?.activeContact?.map(contact => (
                                            contact?.surveyor?.other_contact?.map(c => (
                                                `${contact?.surveyor?.username}, ${c?.fore_name} ${c?.sur_name}`
                                            ))
                                        ))}
                                    </div>
                                </CardBody>
                            </div>
                        </Card>
                        {!showSingleUser &&
                            <>
                                <Card
                                    className="scroll-card"
                                    style={{ height: "253px" }}
                                // ref={(ele) => setTabelDiv(ele)}
                                >
                                    <div
                                        ref={scrollDiv1}
                                    // style={{ height: 350 }}
                                    >
                                        {!showSingleUnAllocatedDevelopments ?

                                            <>
                                                <div className="header-fixed">
                                                    <CardHeader style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "500px" }}>
                                                        <span className="custom-card-header">
                                                            Unallocated Developments
                                                        </span>
                                                    </CardHeader>
                                                    <CardBody style={{ paddingTop: 0 }}>
                                                        <Table
                                                            // responsive
                                                            className="custom-table"
                                                            style={{ position: "relative" }}
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>#</th> */}
                                                                    {/* <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px", width: "90px" }}>Surveyor <HeightIcon onClick={() => unAllocatedDevelopmentRequestSort("surveyor")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th> */}
                                                                    <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px", width: "125px" }} className="">Development Name <HeightIcon onClick={() => unAllocatedDevelopmentRequestSort("development")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                    <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} className="" >Date Submitted <HeightIcon onClick={() => unAllocatedDevelopmentRequestSort("dateSubmitted")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                    <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} className="" >Status <HeightIcon onClick={() => unAllocatedDevelopmentRequestSort("status")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* FIXME: OLD VERSION  */}
                                                                {/* {RenderUnAllocatedDevelopments(unAllocatedDevelopmentItems)} */}

                                                                {/* TODO: NEW VERSION  */}
                                                                {RenderUnAllocatedDevelopments(surveyorDevelopments)}

                                                            </tbody>
                                                        </Table>
                                                    </CardBody>
                                                </div>
                                            </>

                                            :
                                            <div className="single-unallocated-dev-div">
                                                <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                                                    {singleUnAllocatedDetailsTitle} Details ewerwerwe
                                                </div>
                                                <div className="custom-card-detail">
                                                    {/* eSquall Technologies */}
                                                    {/* {`${advanceSearchResult[0]?.surveyor?.fore_name} ${advanceSearchResult[0]?.surveyor?.sur_name}` || `${advanceSearchResult[0]?.insurer_type?.fore_name} ${advanceSearchResult[0]?.insurer_type?.sur_name}`} */}
                                                    {advanceSearchResult[0]?.surveyor?.fore_name} {advanceSearchResult[0]?.surveyor?.sur_name} {advanceSearchResult[0]?.insurer_type?.fore_name} {advanceSearchResult[0]?.insurer_type?.sur_name} {advanceSearchResult[0]?.broker_type?.fore_name} {advanceSearchResult[0]?.broker_type?.sur_name} {advanceSearchResult[0]?.stake_holder?.fore_name} {advanceSearchResult[0]?.stake_holder?.sur_name}
                                                </div>
                                                <div className="custom-card-detail">
                                                    {advanceSearchResult[0]?.surveyor?.email || advanceSearchResult[0]?.insurer_type?.email || advanceSearchResult[0]?.broker_type?.email || advanceSearchResult[0]?.stake_holder?.email}
                                                    {/* 895lorem Common Apt. 899 */}
                                                </div>
                                                <div className="custom-card-detail">
                                                    {advanceSearchResult[0]?.surveyor?.contact_number || advanceSearchResult[0]?.insurer_type?.contact_number || advanceSearchResult[0]?.broker_type?.contact_number || advanceSearchResult[0]?.stake_holder?.contact_number}
                                                    {/* Kayliehaven */}
                                                </div>
                                                <div className="custom-card-detail">
                                                    {advanceSearchResult[0]?.surveyor?.phone_number || advanceSearchResult[0]?.insurer_type?.phone_number || advanceSearchResult[0]?.broker_type?.phone_number || advanceSearchResult[0]?.stake_holder?.phone_number}
                                                    {/* Bilzen */}
                                                </div>
                                                <div className="custom-card-detail">
                                                    {advanceSearchResult[0]?.surveyor?.job_title || advanceSearchResult[0]?.insurer_type?.job_title || advanceSearchResult[0]?.broker_type?.job_title || advanceSearchResult[0]?.stake_holder?.job_title}
                                                    {/* Taurus */}
                                                </div>
                                                <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                                                    Other Contact
                                                </div>
                                                <div className="custom-card-detail">
                                                    {/* eSquall Technologies */}
                                                    {advanceSearchResult[0]?.fore_name} {advanceSearchResult[0]?.sur_name}
                                                </div>
                                                <div className="custom-card-detail">
                                                    {advanceSearchResult[0]?.email}
                                                    {/* 895lorem Common Apt. 899 */}
                                                </div>
                                                <div className="custom-card-detail">
                                                    {advanceSearchResult[0]?.contact_number}
                                                    {/* Kayliehaven */}
                                                </div>
                                                <div className="custom-card-detail">
                                                    {advanceSearchResult[0]?.phone_number}
                                                    {/* Bilzen */}
                                                </div>
                                                <div className="custom-card-detail">
                                                    {advanceSearchResult[0]?.job_title}
                                                    {/* Taurus */}
                                                </div>
                                                <div className="custom-card-detail">
                                                    Company Name: {advanceSearchResult[0]?.business_details[0]?.company_name}
                                                </div>
                                                <div className="custom-card-detail">
                                                    Website: {advanceSearchResult[0]?.business_details[0]?.website_url}
                                                </div>

                                                <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                                                    Status
                                                </div>
                                                <div className="custom-card-detail">
                                                    Active
                                                </div>
                                                <div className="exit-btn-container ">
                                                    {isViewDevelopmentDetails && userData?.role == "Surveyor" && <Button
                                                        size="sm"
                                                        className="px-3"
                                                        style={{ backgroundColor: '#2BA9CD' }}
                                                        onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(false)); dispatch(GetDevelopmentGeneralDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentBuildFactorDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentRefurbishmentDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentSiteInvestigationDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentSurveyRequiremntDetails(adminSingleDevelopmentDetail?.id)); history.push("/user/add-new-development"); dispatch(ChangeDevelopmentMode("view")) }} color="info"
                                                    >
                                                        View SRA
                                                    </Button>}
                                                    {/* <Button
                            size="sm"
                            className="px-3"
                            style={{ backgroundColor: '#2BA9CD' }}
                            onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(false)); dispatch(SetShowStackholderActivity(false)); dispatch(setSingleDevelopmentDefects(false)) }} color="info"
                          >
                            Edit
                          </Button> */}
                                                    {/* <Button
                                                        size="sm"
                                                        className="px-3"
                                                        style={{ backgroundColor: '#2BA9CD' }}
                                                        onClick={EditUserHandler} color="info"
                                                    >
                                                        Edit
                                                    </Button> */}
                                                </div>
                                            </div>

                                        }
                                    </div>
                                </Card>
                                {!showSingleUnAllocatedDevelopments &&
                                    <Card className="scroll-card" style={{ height: "253px" }}>
                                        <div ref={scrollDiv2}>
                                            <CardHeader style={{ position: "sticky", background: "white", zIndex: 1000, height: "70px", top: 0 }}>
                                                <span className="custom-card-header">
                                                    Outstanding Requests & Responses
                                                </span>
                                            </CardHeader>
                                            <CardBody style={{ paddingTop: 0 }}>
                                                <Table
                                                    // responsive
                                                    className="custom-table"
                                                    style={{ position: "relative" }}
                                                >
                                                    <thead>
                                                        <tr>
                                                            {/* <th>#</th> */}
                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "70px" }} >Request Type <HeightIcon onClick={() => surveyRequestRequestSort("requestType")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "70px" }} className="">Name <HeightIcon onClick={() => surveyRequestRequestSort("name")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "70px" }} className="" >Date Made <HeightIcon onClick={() => surveyRequestRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {RenderSurveyRequests(surveyRequestItems)} */}
                                                        {/* {RenderSurveyRequests(allMakeRequests)} */}
                                                        {RenderSurveyRequests(allMakeRequestsForOtherUsers)}
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </div>
                                    </Card>
                                }
                            </>
                        }
                        {!showSingleUnAllocatedDevelopments &&
                            <Card className="scroll-card" style={{ height: "253px" }}>
                                {!showSingleUser ?
                                    <div ref={scrollDiv3}>
                                        <CardHeader style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0 }}>
                                            <span className="custom-card-header">
                                                User History
                                            </span>
                                        </CardHeader>
                                        <CardBody style={{ paddingTop: 0 }}>
                                            <Table
                                                // responsive
                                                className="custom-table"
                                                style={{ position: "relative" }}

                                            >
                                                <thead>
                                                    <tr>
                                                        {/* <th>#</th> */}
                                                        <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} >Type <HeightIcon onClick={() => userHistoryRequestSort("type")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                        <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} className="" >Name <HeightIcon onClick={() => userHistoryRequestSort("name")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                        <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} className="" >Date / Time <HeightIcon onClick={() => userHistoryRequestSort("lookedOn")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {RenderUserHistory(getUserHistory)}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </div>
                                    :
                                    <div ref={scrollDiv3} className="single-unallocated-dev-div">
                                        <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                                            Contact Details
                                        </div>
                                        <div className="custom-card-detail">
                                            Kim Jisso
                                        </div>
                                        <div className="custom-card-detail">
                                            839 Buckridge Common Apt. 503
                                        </div>
                                        <div className="custom-card-detail">
                                            Kayliehaven
                                        </div>
                                        <div className="custom-card-detail">
                                            Bilzen
                                        </div>
                                        <div className="custom-card-detail">
                                            Taurus
                                        </div>
                                        <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                                            Primary Contact
                                        </div>
                                        <div className="custom-card-detail">
                                            Gaylord, Grant and Goyette
                                        </div>
                                        <div className="custom-card-detail">
                                            839 Buckridge Common Apt. 503
                                        </div>
                                        <div className="custom-card-detail">
                                            Custom Tailor
                                        </div>
                                        <div className="custom-card-detail">
                                            Company Phone
                                        </div>
                                        <div className="custom-card-detail">
                                            (503) 408-3937 x2240
                                        </div>
                                        <div className="custom-card-detail">
                                            pbeer@lakin.com
                                        </div>
                                        <div className="custom-card-detail">
                                            Website: www.lorem.com
                                        </div>
                                        <div className="custom-card-detail">
                                            Additional Contact: 00 00000
                                        </div>
                                        <div className="custom-card-detail">
                                            Last Login: Today 9:00 AM
                                        </div>
                                        <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                                            Status
                                        </div>
                                        <div className="custom-card-detail">
                                            Active
                                        </div>
                                        <div className="exit-btn-container ">
                                            <Button
                                                size="sm"
                                                className="px-3"
                                                style={{ backgroundColor: '#2BA9CD' }}
                                                onClick={() => { dispatch(setShowSingleUser(false)); dispatch(setSingleDevelopmentDefects(false)) }} color="info"
                                            >
                                                Exit
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </Card>
                        }
                        {(showSingleUser || showSingleUnAllocatedDevelopments) &&
                            <Card className="scroll-card">
                                <div ref={scrollDiv5}>
                                    <CardHeader>
                                        <span className="custom-card-header">
                                            Notes
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <Table
                                            // responsive
                                            className="custom-table"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Request Type <HeightIcon onClick={() => notesRequestSort("requestType")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                    <th className="">Name <HeightIcon onClick={() => notesRequestSort("name")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                    <th className="">Date Made <HeightIcon onClick={() => notesRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {RenderNotes(notesItems)} */}
                                                {RenderNotes(allNotes)}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </div>
                            </Card>
                        }
                        {showSingleUser &&
                            <Card className="scroll-card">
                                <div ref={scrollDiv6}>
                                    <CardHeader>
                                        <span className="custom-card-header">
                                            Developments
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <Table
                                            // responsive
                                            className="custom-table"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Development Name</th>
                                                    <th className="">Post Code</th>
                                                    <th className="">Completed</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {RenderUserDevelopments()}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </div>
                            </Card>
                        }
                    </Col>

                    {/* {
                        !showStackholderActivity && <Col lg="3" sm="12">
                            <Card className="scroll-card-search-top">
                                <div ref={scrollDiv10}>

                                    <CardHeader>
                                        <div style={{ display: "flex", gap: "8px", justifyContent: "space-between" }}>
                                            <span className="custom-card-header">
                                                Search
                                            </span>
                                            <Input
                                                bsSize="sm"
                                                className="mb-3"
                                                placeholder="Search"
                                                style={{ width: "150px" }}
                                            />
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="radio-buttons ">
                                            <div className="radio-buttons-and-label ">
                                                <span>
                                                    <Input
                                                        className="radio-btn"
                                                        defaultValue="option1"
                                                        id="exampleRadios11"
                                                        name="exampleRadioz"
                                                        type="radio"
                                                        color="red"
                                                    />
                                                </span>
                                                <span className="radio-label">Development</span>
                                            </div>
                                            <div className="radio-buttons-and-label ">
                                                <span>
                                                    <Input
                                                        className="radio-btn"
                                                        defaultValue="option1"
                                                        id="exampleRadios11"
                                                        name="exampleRadioz"
                                                        type="radio"
                                                        color="red"
                                                    />
                                                </span>
                                                <span className="radio-label">Stakeholder</span>
                                            </div>
                                            <div className="radio-buttons-and-label ">
                                                <span>
                                                    <Input
                                                        className="radio-btn"
                                                        defaultValue="option1"
                                                        id="exampleRadios11"
                                                        name="exampleRadioz"
                                                        type="radio"
                                                        color="red"
                                                    />
                                                </span>
                                                <span className="radio-label">Broker</span>
                                            </div>
                                            <div className="radio-buttons-and-label ">
                                                <span>
                                                    <Input
                                                        className="radio-btn"
                                                        defaultValue="option1"
                                                        id="exampleRadios11"
                                                        name="exampleRadioz"
                                                        type="radio"
                                                        color="red"
                                                    />
                                                </span>
                                                <span className="radio-label">Surveyor</span>
                                            </div>
                                            <div className="radio-buttons-and-label ">
                                                <span>
                                                    <Input
                                                        className="radio-btn"
                                                        defaultValue="option1"
                                                        id="exampleRadios11"
                                                        name="exampleRadioz"
                                                        type="radio"
                                                        color="red"
                                                    />
                                                </span>
                                                <span className="radio-label">Insurer</span>
                                            </div>
                                            <div className="radio-buttons-and-label ">
                                                <span>
                                                    <Input
                                                        className="radio-btn"
                                                        defaultValue="option1"
                                                        id="exampleRadios11"
                                                        name="exampleRadioz"
                                                        type="radio"
                                                        color="red"
                                                    />
                                                </span>
                                                <span className="radio-label">All</span>
                                            </div>
                                        </div>

                                        <label className="custom-input-label">Company Name</label>
                                        <FormGroup>
                                            <Input className="custom-input" placeholder="Company Name" type="text" />
                                        </FormGroup>
                                        <label className="custom-input-label">Contact Name</label>
                                        <FormGroup>
                                            <Input className="custom-input" placeholder="Contact Name" type="text" />
                                        </FormGroup>
                                        <label className="custom-input-label">Development Postcode</label>
                                        <FormGroup>
                                            <Input className="custom-input" placeholder="Development Postcode" type="text" />
                                        </FormGroup>
                                        <label className="custom-input-label">Development Name</label>
                                        <FormGroup>
                                            <Input className="custom-input" placeholder="Development Name" type="text" />
                                        </FormGroup>
                                        <div className="search-clear-btns-div ">
                                            <Button className="px-3" size="sm" color="info" onClick={null}>
                                                Search
                                            </Button>
                                            <Button className="px-3" size="sm" color="info" onClick={null}>
                                                Clear
                                            </Button>
                                        </div>
                                        <label className="custom-input-label">Policy Number</label>
                                        <FormGroup>
                                            <Input className="custom-input" placeholder="Policy Number" type="text" />
                                        </FormGroup>
                                        <div className="search-clear-btns-div ">
                                            <Button className="px-3" size="sm" color="info" onClick={null}>
                                                Search
                                            </Button>
                                            <Button className="px-3" size="sm" color="info" onClick={null}>
                                                Clear
                                            </Button>
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>

                        </Col>
                    } */}

                    {
                        showAllDefectsOnTopSide && !hideAllDefects &&
                        <Col md="6" style={{ overflow: "hidden" }}>
                            <AllDefects isOnTopSide={true} isFullHeight={true} style={{ height: "800px" }} />
                        </Col>
                    }


                    {!showDefectDetail ?
                        <>

                            <Col lg="9" sm="12">
                                <Card>
                                    <CardHeader>
                                        <span className="custom-card-header">
                                            {/* Alerts */}
                                            Development Activity
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        {!showStackholderActivity ?
                                            <>
                                                <Row className="gx-5">
                                                    <Col className="" lg='12' sm='12'>
                                                        <Card className="scroll-card-noti" style={{ border: 0, margin: 0 }}>
                                                            <div ref={scrollDiv4}>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                    <Col
                                                        lg='12'
                                                        sm='12'

                                                    >
                                                        <div
                                                            className="custom-shadow-div table-12-div"
                                                        >
                                                            <div
                                                                className="scroll-card-nested-table"
                                                                ref={scrollDiv9}
                                                                style={{ height: "calc(67rem - 67px)" }}
                                                            >
                                                                <Table
                                                                    // responsive
                                                                    className="custom-table"
                                                                    style={{ position: "relative" }}
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                style={{
                                                                                    position: "sticky",
                                                                                    background: "white",
                                                                                    zIndex: 1000,
                                                                                    height: "50px",
                                                                                    top: 0,
                                                                                }}
                                                                            >
                                                                                Development Name
                                                                                <HeightIcon
                                                                                    onClick={() => DevelopmentRequestSort("developmentName")}
                                                                                    style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }}
                                                                                />
                                                                            </th>
                                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, }}>Main Contractor <HeightIcon onClick={() => DevelopmentRequestSort("mainContractor")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "100px" }}>Est.<br />Completion<br /> Date <HeightIcon onClick={() => DevelopmentRequestSort("expectedCompletionDate")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} /> </th>
                                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "70px" }} >Surveyor <HeightIcon onClick={() => DevelopmentRequestSort("surveyor")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0 }} >Next<br />Survey<br />Date <HeightIcon onClick={() => DevelopmentRequestSort("nextSurveyDate")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                                                            {/* <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "50px" }} onClick={() => DevelopmentRequestSort("progress")} className="w-13">Progress</th> */}
                                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0 }} >Notification <br />Due Date <HeightIcon onClick={() => DevelopmentRequestSort("actionNotified")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "60px" }} >Total<br />Defects <HeightIcon onClick={() => DevelopmentRequestSort("totalDefects")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                                                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "100px" }}>Total<br />Notifications <HeightIcon onClick={() => DevelopmentRequestSort("totalActions")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {RenderDevelopments(surveyorAcceptedDevelopments)}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <div className="custom-shadow-div table-12-div">
                                                    {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                        <Button className="px-3" size="sm" color="info" onClick={() => { }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                            Edit Stages Progress Bar
                                                        </Button>
                                                    </div> */}
                                                    <Table
                                                        // responsive
                                                        className="custom-table"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th>Development Name <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                <th>Main Contractor <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                <th>Est. Completion Date <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                <th>Surveyor <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                <th>Next Survey Date <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                {/* <th className="w-13">Progress <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th> */}
                                                                <th>Action Notified <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                <th>Total Defects <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                                <th>Total Actions <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr >
                                                                <td>{currentViewedDevelopmentDetails?.development_name}</td>
                                                                <td>{currentViewedDevelopmentDetails?.main_contractor == null ? currentViewedDevelopmentDetails?.developer?.username : currentViewedDevelopmentDetails?.main_contractor?.username}</td>
                                                                <td>{moment(currentViewedDevelopmentDetails?.created_at).format("DD/MM/YY")}</td>
                                                                <td>{currentViewedDevelopmentDetails?.surveyor?.username}</td>
                                                                <td>{moment(new Date(2022, 4, 1)).format("DD/MM/YY")}</td>
                                                                {/* <td className="w-13">
                                                                    <div style={{ position: "relative" }}>
                                                                        <Progress className="custom-progress mb-1" color="info" value={10} />
                                                                        <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>1</span>
                                                                    </div>
                                                                    <div style={{ position: "relative" }}>
                                                                        <Progress className="custom-progress mb-1" color="info" value={50} />
                                                                        <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>2</span>
                                                                    </div>
                                                                    <div style={{ position: "relative" }}>
                                                                        <Progress className="custom-progress mb-1" color="info" value={20} />
                                                                        <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>3</span>
                                                                    </div>
                                                                    <div style={{ position: "relative" }}>
                                                                        <Progress className="custom-progress mb-1" color="info" value={80} />
                                                                        <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>4</span>
                                                                    </div>
                                                                    <div style={{ position: "relative" }}>
                                                                        <Progress className="custom-progress" color="info" value={40} />
                                                                        <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>5</span>
                                                                    </div>
                                                                </td> */}
                                                                <td>{moment(new Date(2022, 5, 1)).format("DD/MM/YY")}</td>
                                                                <td>{currentViewedDevelopmentDetails?.defect[0]?.count || 0}</td>
                                                                <td>{currentViewedDevelopmentDetails?.action[0]?.count || 0}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <span
                                                    style={{ marginLeft: 5, }}
                                                    className="custom-card-header custom-card-header-second"
                                                >
                                                    Notifications
                                                </span>
                                                <div style={{ height: 10 }}></div>
                                                <div className="custom-shadow-div table-12-div nested-scroll-div">
                                                    <div
                                                        className="scroll-card-nested-table"
                                                        ref={scrollDiv7}
                                                        style={{ height: "calc(31rem - 57px)" }}
                                                    >
                                                        <Table
                                                            // responsive 
                                                            className="custom-table"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>Notification <HeightIcon onClick={() => notificationRequestSort("notification")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                    <th>Notified <HeightIcon onClick={() => notificationRequestSort("notified")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                    <th>Stage <HeightIcon onClick={() => notificationRequestSort("stage")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                    <th>Section within Stage <HeightIcon onClick={() => notificationRequestSort("sectionwithinStage")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                    <th>Description <HeightIcon onClick={() => notificationRequestSort("description")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                    <th>Block/Unit <HeightIcon onClick={() => notificationRequestSort("unitBlock")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                    <th>Prirority <HeightIcon onClick={() => notificationRequestSort("prirority")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                    <th>Photo / Video Available <HeightIcon onClick={() => notificationRequestSort("photoVideoAvailable")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                    <th>Actioned in 30 days <HeightIcon onClick={() => notificationRequestSort("beingActions")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                    <th>Remedial Work Complete? <HeightIcon onClick={() => notificationRequestSort("isRemedialWorkComplete")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* {RenderDevelopmentNotifications(notificationItems)} */}
                                                                {/* {RenderDevelopmentNotifications(allDefects)} */}
                                                                {RenderDevelopmentNotifications(defectsOfSingleDevelopment)}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div style={{ paddingRight: 10 }} className="exit-btn-container ">
                                                        <Button
                                                            size="sm"
                                                            className="px-3"
                                                            style={{ backgroundColor: '#2BA9CD' }}
                                                            onClick={() => { dispatch(SetShowStackholderActivity(false)); dispatch(setSingleDevelopmentDefects(false)) }} color="info"
                                                        >
                                                            Exit
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </CardBody>
                                </Card>

                                {/* <Card className="scroll-card-search-bottom">
                  <CardHeader>
                    <span className="custom-card-header">
                      Diaries
                    </span>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <Select
                          className="react-select primary custom-dropdown"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={developer}
                          onChange={(value) => setDeveloper(value)}
                          options={[
                            { value: "1", label: "Zeeshan Ahmad" },
                            { value: "2", label: "Zeeshan Saeed" },
                            { value: "3", label: "Usman Ali" },
                          ]}
                          placeholder="Developer"
                        />
                      </Col>
                      <div style={{ height: 5 }} />
                      <Col md="4">
                        <Select
                          className="react-select primary custom-dropdown"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={surveyor}
                          onChange={(value) => setSurveyor(value)}
                          options={[
                            { value: "1", label: "Erza Scarlet" },
                            { value: "2", label: "Zero Two" },
                            { value: "3", label: "Kim Jisso" },
                          ]}
                          placeholder="Surveyor"
                        />
                      </Col>
                      <Col md="4">
                        <FormGroup className="calendar-form-group">
                          <div className="icon-input-div">
                            <Input className="custom-input icon-input" placeholder="00/00/0000" disabled type="text" />
                            <div className="input-rigth-icon-div">
                              <img
                                src={require('../../Assets/Svg/Calendar.svg').default}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="diary-div">
                      {!showStackholderActivity &&
                        <>
                          <Button className="px-3" size="sm" color="info" onClick={toggleModalAddDiary} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Add Diary
                          </Button>
                          <Button className="px-3" size="sm" color="info" onClick={toggleModalAllocateDevelopment} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Allocate Development
                          </Button>
                          <Button className="px-3" size="sm" color="info" onClick={toggleModalMakeARequest} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Make a Request
                          </Button>
                        </>
                      }
                      {showStackholderActivity &&
                        <>
                          <Button className="px-3" size="sm" color="info" onClick={toggleModalAddDiary} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Add Note
                          </Button>
                          <Button className="px-3" size="sm" color="info" onClick={toggleModalAddDiary} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Add Diary
                          </Button>
                          <Button className="px-3" size="sm" color="info" onClick={toggleModalAllocateDevelopment} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Upload Documents
                          </Button>
                          <Button className="px-3" size="sm" color="info" onClick={toggleModalMakeARequest} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Make a Request
                          </Button>
                        </>
                      }
                    </div>
                    <Row>
                      <AllDiaries />
                    </Row>
                  </CardBody>
                </Card> */}
                            </Col>
                        </>
                        :
                        <DefectDetail
                            setShowDefectDetail={SetShowDefectDetail} isFullWidth={showDefectDetailFullWidth} setShowAllDefectsOnTopSide={SetShowAllDefectsOnTopSide} setHideAllDefects={SetHideAllDefects}
                        />
                    }
                </Row>
                <Row>
                    {/* {!showStackholderActivity && 
          <Col md="6" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
            <Card className="scroll-card-search-bottom">
              <CardHeader>
                <span className="custom-card-header">
                  Administration History
                </span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <Select
                      className="react-select primary custom-dropdown"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={developer}
                      onChange={(value) => setDeveloper(value)}
                      options={[
                        { value: "1", label: "DPT 1" },
                        { value: "2", label: "DPT 2" },
                        { value: "3", label: "DPT 3" },
                      ]}
                      placeholder="Department"
                    />
                  </Col>
                  <div style={{ height: 5 }} />
                  <Col md="4">
                    <Select
                      className="react-select primary custom-dropdown"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={surveyor}
                      onChange={(value) => setSurveyor(value)}
                      options={[
                        { value: "1", label: "TM 01" },
                        { value: "2", label: "TM 02" },
                        { value: "3", label: "TM 03" },
                      ]}
                      placeholder="Team Member"
                    />
                  </Col>
                </Row>
                <div className="diary-div">
                  <>
                    <Button className="px-3" size="sm" color="info" onClick={toggleModalMakeARequest} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                      Make Internal Request
                    </Button>
                    <Button className="px-3" size="sm" color="info" style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                      Completed Entries
                    </Button>
                  </>
                </div>
                <Row style={{ padding: "0 15px" }}>
                  <AllDiaries />
                </Row>
              </CardBody>
            </Card>
          </Col>
          } */}
                    {showStackholderActivity &&
                        <Col md="6" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                            <Card className="scroll-card-search-bottom">
                                <CardHeader>
                                    <span className="custom-card-header">
                                        Administration History
                                    </span>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <Select
                                                className="react-select primary custom-dropdown"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                value={developer}
                                                onChange={(value) => setDeveloper(value)}
                                                options={[
                                                    { value: "1", label: "DPT 1" },
                                                    { value: "2", label: "DPT 2" },
                                                    { value: "3", label: "DPT 3" },
                                                ]}
                                                placeholder="Department"
                                            />
                                        </Col>
                                        <div style={{ height: 5 }} />
                                        <Col md="4">
                                            <Select
                                                className="react-select primary custom-dropdown"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                value={surveyor}
                                                onChange={(value) => setSurveyor(value)}
                                                options={[
                                                    { value: "1", label: "TM 01" },
                                                    { value: "2", label: "TM 02" },
                                                    { value: "3", label: "TM 03" },
                                                ]}
                                                placeholder="Team Member"
                                            />
                                        </Col>
                                    </Row>
                                    <div className="diary-div">
                                        {/* {showStackholderActivity && */}
                                        <>
                                            <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryAddDiary(); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                Add Diary
                                            </Button>
                                            <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryAddNote(); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                Add Note
                                            </Button>
                                            {/* <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryMakeRequest(true); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)); dispatch(GetSingleMakeRequestDetail({ currentState: "view", id: currentSelectedAcceptedDevelopment?.id })) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                Make Request
                                            </Button> */}
                                            <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryMakeRequest(true); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)); }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                Make Request
                                            </Button>
                                            <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryUploadDocument(); dispatch(GetAdmisistrationHistoryToFieldDataForSurveyor(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                Upload Documents for review
                                            </Button>
                                            {userData?.role == "Surveyor" && <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryAddDefect(); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                Add Defect
                                            </Button>}
                                        </>
                                        {/* } */}
                                    </div>
                                    {/* <AllDiaries /> */}
                                    <Row style={{ padding: "0 15px" }}>
                                        <AdministrationHistoryData data={administrationHistoryDataForSurveyor} />
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    }
                    <Col Col md="6" style={{ paddingLeft: "5px", paddingRight: "5px" }
                    }>
                        {
                            showStackholderActivity &&
                            <Card className="scroll-card-search-bottom">
                                <CardHeader>
                                    <span className="custom-card-header">
                                        Diaries
                                    </span>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <Select
                                                className="react-select primary custom-dropdown"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                value={developer}
                                                onChange={(value) => setDeveloper(value)}
                                                options={[
                                                    { value: "1", label: "DPT 1" },
                                                    { value: "2", label: "DPT 2" },
                                                    { value: "3", label: "DPT 3" },
                                                ]}
                                                placeholder="Developer"
                                            />
                                        </Col>
                                        <div style={{ height: 5 }} />
                                        <Col md="4">
                                            <Select
                                                className="react-select primary custom-dropdown"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                value={surveyor}
                                                onChange={(value) => setSurveyor(value)}
                                                options={[
                                                    { value: "1", label: "TM 01" },
                                                    { value: "2", label: "TM 02" },
                                                    { value: "3", label: "TM 03" },
                                                ]}
                                                placeholder="Surveyor"
                                            />
                                        </Col>
                                        <Col md="4">
                                            <Input type="date" />
                                        </Col>
                                    </Row>
                                    <div className="diary-div">
                                        {!showStackholderActivity &&
                                            <>
                                                <Button className="px-3" size="sm" color="info" onClick={toggleModalAddDiary} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                    Add Internal Diary
                                                </Button>
                                                <Button className="px-3" size="sm" color="info" style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                    Completed Entries
                                                </Button>
                                            </>
                                        }
                                        {showStackholderActivity &&
                                            <>
                                                <Button className="px-3" size="sm" color="info" onClick={toggleModalAddDiary} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                    Add Note
                                                </Button>
                                                <Button className="px-3" size="sm" color="info" onClick={toggleModalAddDiary} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                    Add Internal Diary
                                                </Button>
                                                <Button className="px-3" size="sm" color="info" onClick={toggleModalAllocateDevelopment} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                    Upload Documents
                                                </Button>
                                                <Button className="px-3" size="sm" color="info" onClick={toggleModalMakeARequest} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                                                    Make Internal Request
                                                </Button>
                                            </>
                                        }
                                    </div>
                                    <Row style={{ padding: "0 15px" }}>
                                        <AllDiaries />
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                    </Col >

                    {singleDevelopmentDefects && !showAllDefectsOnTopSide && !hideAllDefects &&
                        <Col Col md="6" style={{ overflow: "hidden", paddingLeft: "5px", paddingRight: "5px" }}>
                            <AllDefects isOnTopSide={true} isFullHeight={false} style={{ height: "323px" }} />
                        </Col>
                    }
                </Row >
            </div >
            {/* <Modal
        modalClassName="modal-primary"
        isOpen={modalDiaries}
        toggle={toggleModalDiaries}
        size="lg"
      >
        <AllDiaries toggleModalFunction={toggleModalDiaries} />
      </Modal> */}

            {/* Administration History Add Diary Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAdministrationHistoryAddDiary}
                toggle={toggleModalAdministrationHistoryAddDiary}
                size="lg"
            >
                <AdministrationHistoryAddDiary toggleModalFunction={toggleModalAdministrationHistoryAddDiary} />
            </Modal>

            {/* Administration History Add Note Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAdministrationHistoryAddNote}
                toggle={toggleModalAdministrationHistoryAddNote}
                size="lg"
            >
                <AdministrationHistoryAddNote toggleModalFunction={toggleModalAdministrationHistoryAddNote} />
            </Modal>

            {/* Administration History Make Request Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAdministrationHistoryMakeRequest}
                toggle={toggleModalAdministrationHistoryMakeRequest}
                size="lg"
            >
                <AdministrationHistoryMakeRequest toggleModalFunction={toggleModalAdministrationHistoryMakeRequest} />
            </Modal>

            {/* Administration History Add Defect Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAdministrationHistoryUploadDocument}
                toggle={toggleModalAdministrationHistoryUploadDocument}
                size="lg"
            >
                <AdministrationHistoryUploadDocument toggleModalFunction={toggleModalAdministrationHistoryUploadDocument} />
            </Modal>

            {/* Administration History Add Defect Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAdministrationHistoryAddDefect}
                toggle={toggleModalAdministrationHistoryAddDefect}
                size="lg"
            >
                <AdministrationHistoryAddDefect toggleModalFunction={toggleModalAdministrationHistoryAddDefect} />
            </Modal>

            {/* Add Diary Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAddDiary}
                toggle={toggleModalAddDiary}
                size="lg"
            >
                <AddDiary toggleModalFunction={toggleModalAddDiary} />
            </Modal>

            {/* Add Allocate Development  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAllocateDevelopment}
                toggle={toggleModalAllocateDevelopment}
                size="lg"
            >
                <AllocateDevelopment toggleModalFunction={toggleModalAllocateDevelopment} />
            </Modal>

            {/* Add Make A Request  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalMakeARequest}
                toggle={toggleModalMakeARequest}
                size="lg"
            >
                <MakeARequest toggleModalFunction={toggleModalMakeARequest} />
            </Modal>
        </>
    );
}

export default ExternalUsers;
