import { createSlice } from '@reduxjs/toolkit';

const pageSlice = createSlice({
    name: 'page',
    initialState: {
        currentDashboard: "AWS Hub",
        showSingleUnAllocatedDevelopments: null,
        showStackholderActivity: null,
        isViewDevelopmentDetails: null,
        singleUnAllocatedDetailsTitle: "",

        showSingleUser: false,
        showDefectDetail: false,
        showDefectDetailFullWidth: false,
        showAllDefectsOnTopSide: false,
        hideAllDefects: false,
        singleDevelopmentDefects: false,

        bugFixer: 0,
    },
    reducers: {
        ToggleResetPage: (state, action) => {
            state.showStackholderActivity = false
            state.showSingleUnAllocatedDevelopments = false
            state.bugFixer = state.bugFixer + 1
        },
        SetCurrentDashboard: (state, action) => {
            state.currentDashboard = action.payload
            state.bugFixer = state.bugFixer + 1
        },
        SetShowSingleUnAllocatedDevelopments: (state, action) => {
            state.showSingleUnAllocatedDevelopments = action.payload
            state.bugFixer = state.bugFixer + 1
        },
        SetShowStackholderActivity: (state, action) => {
            state.showStackholderActivity = action.payload
            state.bugFixer = state.bugFixer + 1
        },
        SetIsViewDevelopmentDetails: (state, action) => {
            state.isViewDevelopmentDetails = action.payload
            state.bugFixer = state.bugFixer + 1
        },
        SetSingleUnAllocatedDetailsTitle: (state, action) => {
            state.singleUnAllocatedDetailsTitle = action.payload
            state.bugFixer = state.bugFixer + 1
        },

        ToggleResetDashboard: (state, action) => {
            state.showSingleUser = false
            state.showDefectDetail = false
            state.showDefectDetailFullWidth = false
            state.showAllDefectsOnTopSide = false
            state.hideAllDefects = false
            state.singleDevelopmentDefects = false
            state.bugFixer = state.bugFixer + 1
        },
        SetShowSingleUser: (state, action) => {
            state.showSingleUser = action.payload
            state.bugFixer = state.bugFixer + 1
        },
        SetShowDefectDetail: (state, action) => {
            state.showDefectDetail = action.payload
            state.bugFixer = state.bugFixer + 1
        },
        SetShowDefectDetailFullWidth: (state, action) => {
            state.showDefectDetailFullWidth = action.payload
            state.bugFixer = state.bugFixer + 1
        },
        SetShowAllDefectsOnTopSide: (state, action) => {
            state.showAllDefectsOnTopSide = action.payload
            state.bugFixer = state.bugFixer + 1
        },
        SetHideAllDefects: (state, action) => {
            state.hideAllDefects = action.payload
            state.bugFixer = state.bugFixer + 1
        },
        SetSingleDevelopmentDefects: (state, action) => {
            state.singleDevelopmentDefects = action.payload
            state.bugFixer = state.bugFixer + 1
        },
    },
    extraReducers: {
    },
});

export const {
    SetSingleUnAllocatedDetailsTitle,
    SetIsViewDevelopmentDetails,
    SetShowStackholderActivity,
    SetShowSingleUnAllocatedDevelopments,
    SetCurrentDashboard,
    ToggleResetPage,
    ToggleResetDashboard,
    SetShowSingleUser,
    SetShowDefectDetail,
    SetShowDefectDetailFullWidth,
    SetShowAllDefectsOnTopSide,
    SetHideAllDefects,
    SetSingleDevelopmentDefects,
} = pageSlice.actions;

export default pageSlice.reducer;