import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const administrationHistorySlice = createSlice({
    name: 'stakeHolder',
    initialState: {
        addDiaryCategory: null,
        addDiarySubCategory: null,
        addDiaryCategories: [],
        addDefectCategories: [],
        adminDevelopments: [],
        allDiaries: [],
        loginUserDiaries: [],
        administrationHistoryDataForSurveyor: [],
        allNotes: [],
        allDefects: [],
        addNoteCategories: [],
        makeRequestCategories: [],
        uploadDocumentsCategories: [],
        addDiarySubCategoryByCategoryId: [],
        usersOfDevelopment: {},
        administrationHistoryAssignedTo: [],
        administrationHistoryPriority: [],
        administrationHistoryToFieldDataForSurveyor: null,
        createDiary: null,
        createNote: null,
        makeRequest: null,
        uploadDocument: null,
        addDefect: null,
        administrationHistoryMarkAsComplete: null,
        singleMakeRequestDetail: { currentState: "", data: null },
        singleAddDiaryDetail: {},
        singleAddNoteDetail: {},
        singleMakeRequestDetails: {},
        singleUploadDocumentDetail: {},
        showCompletedEntries: false,
    },
    reducers: {
        ChangeUserData: (state, action) => {
            state.userData = action.payload
        },

        ChangeAllDiaries: (state, action) => {
            console.log("🚀 ~ file: AdministrationHistorySlice.js ~ line 43 ~ action", action)
            state.showCompletedEntries = action.payload.value
            state.allDiaries = action.payload.value == true ? action.payload.diaries.payload.data.data.filter(d => (d.status == 1)) : action.payload.diaries.payload.data.data
        }
    },
    extraReducers: {

        ['administrationHistory/GetAdminDevelopments/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.adminDevelopments = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.adminDevelopments = data
            }
        },

        ['administrationHistory/AdministrationHistoryMarkAsComplete/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            // console.log("🚀 ~ file: AddNewStakeHolderSlice.js ~ line 18 ~ data", data)
            if (status >= 200 && status < 300) {

                state.administrationHistoryMarkAsComplete = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.administrationHistoryMarkAsComplete = data
            }
        },

        ['administrationHistory/GetSingleMakeRequestDetail/fulfilled']: (state, action) => {
            const { data, status, currentState } = action.payload || {}
            // console.log("🚀 ~ file: AddNewStakeHolderSlice.js ~ line 18 ~ data", data)
            if (status >= 200 && status < 300) {

                state.singleMakeRequestDetail = { currentState: currentState, data: data?.data }
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.singleMakeRequestDetail = data
            }
        },

        ['administrationHistory/GetSingleAddDiaryDetail/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            // console.log("🚀 ~ file: AddNewStakeHolderSlice.js ~ line 18 ~ data", data)
            if (status >= 200 && status < 300) {

                state.singleAddDiaryDetail = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.singleAddDiaryDetail = data
            }
        },

        ['administrationHistory/GetSingleAddNoteDetail/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.singleAddNoteDetail = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.singleAddNoteDetail = data
            }
        },

        ['administrationHistory/GetSingleMakeRequestDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.singleMakeRequestDetails = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.singleMakeRequestDetails = data
            }
        },

        ['administrationHistory/GetSingleUploadDocumentDetail/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.singleUploadDocumentDetail = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.singleUploadDocumentDetail = data
            }
        },

        ['administrationHistory/GetAddDiaryCategory/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.addDiaryCategories = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.addDiaryCategories = data
            }
        },

        ['administrationHistory/GetAddDefectCategory/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.addDefectCategories = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.addDefectCategories = data
            }
        },

        ['administrationHistory/GetDiaries/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.allDiaries = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.allDiaries = data
            }
        },

        ['administrationHistory/GetLoginUserDiaries/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.loginUserDiaries = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.loginUserDiaries = data
            }
        },

        ['administrationHistory/GetAdmisistrationHistoryDataForSurveyor/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            // console.log("🚀 ~ file: AddNewStakeHolderSlice.js ~ line 18 ~ data", data)
            if (status >= 200 && status < 300) {

                state.administrationHistoryDataForSurveyor = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.administrationHistoryDataForSurveyor = data
            }
        },

        ['administrationHistory/GetAdmisistrationHistoryToFieldDataForSurveyor/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.administrationHistoryToFieldDataForSurveyor = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.administrationHistoryToFieldDataForSurveyor = data
            }
        },

        ['administrationHistory/GetNotes/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.allNotes = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.allNotes = data
            }
        },

        ['administrationHistory/GetDefects/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.allDefects = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.allDefects = data
            }
        },

        ['administrationHistory/GetAdministrationHistoryPriority/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.administrationHistoryPriority = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.administrationHistoryPriority = data
            }
        },

        ['administrationHistory/GetAddNoteCategory/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.addNoteCategories = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.addNoteCategories = data
            }
        },

        ['administrationHistory/GetMakeRequestCategory/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.makeRequestCategories = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.makeRequestCategories = data
            }
        },

        ['administrationHistory/GetUploadDocumentCategory/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.uploadDocumentsCategories = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.uploadDocumentsCategories = data
            }
        },

        ['administrationHistory/GetAddDiarySubCategoryByCategoryId/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.addDiarySubCategoryByCategoryId = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.addDiarySubCategoryByCategoryId = data
            }
        },

        ['administrationHistory/GetUsersOfDevelopments/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.usersOfDevelopment = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.usersOfDevelopment = data
            }
        },

        ['administrationHistory/GetAdministrationHistoryAssignedTo/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.administrationHistoryAssignedTo = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.administrationHistoryAssignedTo = data
            }
        },

        ['administrationHistory/CreateAdmnistrationHistoryCategory/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.addDiaryCategory = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.addDiaryCategory = data
            }
        },

        ['administrationHistory/CreateAdministrationHistorySubCategory/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.addDiarySubCategory = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.addDiarySubCategory = data
            }
        },

        ['administrationHistory/CreateAdministrationHistoryAddDiary/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.createDiary = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.createDiary = data
            }
        },

        ['administrationHistory/CreateAdministrationHistoryAddNote/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.createNote = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.createNote = data
            }
        },

        ['administrationHistory/CreateAdministrationHistoryMakeRequest/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.makeRequest = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.makeRequest = data
            }
        },

        ['administrationHistory/CreateAdministrationHistoryUploadDocument/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.uploadDocument = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.uploadDocument = data
            }
        },

        ['administrationHistory/CreateAdministrationHistoryAddDefect/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.addDefect = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.addDefect = data
            }
        },
    },
});

export const {
    ChangeUserData, ChangeAllDiaries
} = administrationHistorySlice.actions;

export default administrationHistorySlice.reducer;