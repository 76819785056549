import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const externalUserSlice = createSlice({
    name: 'stakeHolder',
    initialState: {
        surveyorDevelopments: [],
        surveyorAcceptedDevelopments: [],
        allMakeRequests: [],
        allMakeRequestsForOtherUsers: [],
        changeDevelopmentStatus: null,
        surveyorDetails: null,
        currentSelectedAcceptedDevelopment: null,
        currentViewedDevelopmentDetails: null,
        dashboardNameForNavbar: "Surveyor Hub"
    },
    reducers: {
        ChangeCurrentSelectedAcceptedDevelopment: (state, action) => {
            state.currentSelectedAcceptedDevelopment = action.payload
        },
        ChangeDashboardNameForNavbar: (state, action) => {
            state.dashboardNameForNavbar = action.payload
        },
    },
    extraReducers: {

        ['externalUsers/GetSurveyorDevelopments/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.surveyorDevelopments = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.surveyorDevelopments = data
            }
        },

        ['externalUsers/GetSurveyorDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.surveyorDetails = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.surveyorDetails = data
            }
        },

        ['externalUsers/GetCurrentViewedDevelopmentDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.currentViewedDevelopmentDetails = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.currentViewedDevelopmentDetails = data
            }
        },

        ['externalUsers/GetMakeRequests/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.allMakeRequests = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.allMakeRequests = data
            }
        },

        ['externalUsers/GetMakeRequestsForOtherUsers/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.allMakeRequestsForOtherUsers = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.allMakeRequestsForOtherUsers = data
            }
        },

        ['externalUsers/GetSurveyorAcceptedDevelopments/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.surveyorAcceptedDevelopments = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.surveyorAcceptedDevelopments = data
            }
        },

        ['externalUsers/ChangeDevelopmentStatus/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.changeDevelopmentStatus = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.changeDevelopmentStatus = data
            }
        },
    },
});

export const {
    ChangeCurrentSelectedAcceptedDevelopment, ChangeDashboardNameForNavbar
} = externalUserSlice.actions;

export default externalUserSlice.reducer;