import moment from "moment"
import React, { useState } from "react"
import ReactDatetime from "react-datetime"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import {
    Button,
    Card, CardBody, CardFooter, CardHeader, Col, Container, FormGroup,
    Input, Progress, Row, Table
} from "reactstrap"
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import GeneralDetails from "../GeneralDetails/GeneralDetails"
import SiteInvestigation from "../SiteInvestigation/SiteInvestigation"
import BuildFactors from "../BuildFactors/BuildFactors"
import Refurbishment from "../Refurbishment/Refurbishment"
import SurveyRequirements from "../SurveyRequirements/SurveyRequirements"
import { Prompt } from "react-router-dom"
import { ChangeDevelopmentStepValue } from "./Store/AddNewDevelopmentSlice"

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function AddNewDevelopment(props) {

    const dispatch = useDispatch()

    const { } = useSelector(state => state.defectDetail)

    // const [value, setValue] = React.useState(0)

    const { value, developmentMode } = useSelector(state => state.AddNewDevelopment)

    const handleChange = (event, newValue) => {
        // setValue(newValue)
        dispatch(ChangeDevelopmentStepValue(newValue))
    }

    return (
        <>
            <Prompt
                // when={formIsHalfFilledOut}
                message="Are you sure you want to leave?"
            />
            <div className="content flex flex-column justify-center">
                <span className="text-lg font-bold mb-4 ml-20">Add New Development</span>
                <Row className="flex flex-1 justify-center">
                    <Col className="" lg="11" sm="12">
                        <Card className="rounded-xl pb-3">
                            <CardHeader className="p-0">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    className="px-2 pt-2"
                                >
                                    <Tab label="General Details" {...a11yProps(0)} />
                                    <Tab label="Site Investigation" {...a11yProps(1)} disabled={(value != 1 && developmentMode == "add") ? true : false} />
                                    <Tab label="Build Factors" {...a11yProps(2)} disabled={(value != 2 && developmentMode == "add") ? true : false} />
                                    <Tab label="Refurbishment" {...a11yProps(3)} disabled={(value != 3 && developmentMode == "add") ? true : false} />
                                    <Tab label="Survey Requirements" {...a11yProps(4)} disabled={(value != 4 && developmentMode == "add") ? true : false} />
                                </Tabs>
                                <hr />
                            </CardHeader>
                            <CardBody>
                                <TabPanel value={value} index={0}>
                                    <GeneralDetails />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <SiteInvestigation />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <BuildFactors />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <Refurbishment />
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <SurveyRequirements />
                                </TabPanel>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AddNewDevelopment