import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
} from "reactstrap";
import { GetUserDataById, setExternalUserPassword } from '../Auth/Store/authSlice';

const SetPassword = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { userDataById } = useSelector(state => state.userData)
    console.log("🚀 ~ file: SetPassword.js ~ line 26 ~ SetPassword ~ userDataById", userDataById)


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const params = useParams()

    useEffect(() => {
        setEmail(userDataById?.email)
    }, [userDataById])


    useEffect(() => {
        dispatch(GetUserDataById({ id: params?.id, history: history }))
    }, [])

    console.log("🚀 ~ file: SetPassword.js ~ line 25 ~ SetPassword ~ params", params)

    const submitSetPasswordHandler = () => {
        const passwordRegex = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/
        if (!passwordRegex.test(password)) {
            return setPasswordError("Make sure to use atleast 8 characters long password including Uppercase letters, numbers and special characters.")
        }

        else if (password !== confirmPassword) {
            return setPasswordError("Password does not match.")
        }

        else {
            const data = {
                password: password,
                password_confirmation: confirmPassword
            }

            dispatch(setExternalUserPassword({ id: params?.id, data: data, history }))
        }

    }

    return (
        <div className="login-page">
            <Container className="login-container">
                <Row>
                    <Col className="ml-auto mr-auto" lg="4" md="6">
                        {/* {authError?.data && <div className="alert alert-danger">{authError?.data?.error}</div>} */}
                        <Form action="" className="form" method="">
                            <Card className="card-login">
                                <CardHeader>
                                    <CardHeader>
                                        <div className="text-3xl text-center">
                                            Set Password {params?.id}
                                        </div>
                                    </CardHeader>
                                </CardHeader>
                                <CardBody className="my-4">
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-single-02" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="Email" type="email" value={email} disabled />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-key-25" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type="password"
                                            autoComplete="off"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-key-25" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Confirm Password"
                                            type="password"
                                            autoComplete="off"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </InputGroup>
                                    {passwordError !== "" && <p style={{ color: "red" }}>{passwordError}</p>}
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        block
                                        className="btn-round mb-3 custom-btn btn-info"
                                        href="#pablo"
                                        onClick={submitSetPasswordHandler}
                                    >
                                        Submit
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../Assets/Images/AuthBackground.png").default})`,
                }}
            />
        </div >
    );
}

export default SetPassword