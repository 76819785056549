import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

const stylesSlice = createSlice({
    name: 'styles',
    initialState: {
    },
    reducers: {
    },
    extraReducers: {
    },
});

export const {
} = stylesSlice.actions;

export default stylesSlice.reducer;