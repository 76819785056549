import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetUserTypeForInternalUser = createAsyncThunk('addUser/GetUserTypeForInternalUser', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-user-type').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const CreateInternalUser = createAsyncThunk('addUser/CreateInternalUser', async (params, { dispatch, getState }) => {
    const {
        titleField,
        otherTitleField,
        forenameField,
        surnameField,
        jobTitleField,
        emailField,
        contactNumberField,
        phoneNumberField,
        userTypeField,
        primaryIsActiveField,
    } = params?.data || {}
    let result = await apiInstance.post('/api/admin/create/user-type', {
        title: titleField,
        other_title: otherTitleField,
        fore_name: forenameField,
        sur_name: surnameField,
        job_title: jobTitleField,
        email: emailField,
        contact_number: contactNumberField,
        phone_number: phoneNumberField,
        user_type: userTypeField,
        primary_is_active: primaryIsActiveField,
    }).then(function (response) {
        toast("User created successfully")
        params?.clearForm()
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const UpdateInternalUser = createAsyncThunk('addUser/UpdateInternalUser', async (params, { dispatch, getState }) => {
    const {
        titleField,
        otherTitleField,
        forenameField,
        surnameField,
        jobTitleField,
        emailField,
        contactNumberField,
        phoneNumberField,
        userTypeField,
        primaryIsActiveField,
    } = params?.data || {}
    let result = await apiInstance.post(`/api/admin/update/internal-user/${params?.id}`, {
        title: titleField,
        other_title: otherTitleField,
        fore_name: forenameField,
        sur_name: surnameField,
        job_title: jobTitleField,
        email: emailField,
        contact_number: contactNumberField,
        phone_number: phoneNumberField,
        user_type: userTypeField,
        primary_is_active: primaryIsActiveField,
    }).then(function (response) {
        toast("Record updated successfully")
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})
