import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, CardFooter, Col, Row } from "reactstrap";
import './BuildFactors.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import { CircularProgress, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CreateDevelopmentBuildFactors, GetDevelopmentSiteInvestigationDetails, UpdateDevelopmentBuildFactors } from '../AddNewDevelopment/Store/AddNewDevelopmentApi';
import { ChangeDevelopmentMode, ChangeDevelopmentStepValue } from '../AddNewDevelopment/Store/AddNewDevelopmentSlice';


function BuildFactors(props) {

    const dispatch = useDispatch()
    const [numberOfStoreys, setNumberOfStoreys] = useState(null)
    const { adminSingleDevelopmentDetail } = useSelector(state => state.dashboard)
    const { value, developmentGeneralDetails, getDevelopmentBuildFactorDetails, developmentMode, developmentBuildFactorsError, getDevelopmentGeneralDetails } = useSelector(state => state.AddNewDevelopment)


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const buildTypes = [
        {
            id: 1,
            name: 'Brick and Block',
        },
        {
            id: 2,
            name: 'Timber Frame',
        },
        {
            id: 3,
            name: 'Light Steel frame',
        },
        {
            id: 4,
            name: 'Insulated Concrete Framework (ICF)',
        },
        {
            id: 5,
            name: 'Steel Framed',
        },
        {
            id: 6,
            name: 'Structural Insulated Panels (SIPS)',
        },
        {
            id: 7,
            name: 'Non Structural (Infill System)',
        },
        {
            id: 8,
            name: 'COB Construction',
        },
        {
            id: 9,
            name: 'Straw Bale',
        },
        {
            id: 0,
            name: 'Other',
        },


    ];

    const isTheSlopeLessThan17Options = [
        'Yes',
        'No',
    ];
    const [isTheSlopeLessThan17, setIsTheSlopeLessThan17] = useState("");
    useEffect(() => {
        developmentMode == "add" &&
            setIsTheSlopeLessThan17(developmentMode == "add" ? "" : 0)
    }, [developmentMode])
    const handleChangeIsTheSlopeLessThan17 = (event) => {
        const {
            target: { value },
        } = event;
        setIsTheSlopeLessThan17(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const superstructureExternalWallConstructionTypes = [
        {
            id: 1,
            name: 'Traditional Cavity',
        },
        {
            id: 2,
            name: 'Steel Frame',
        },
        {
            id: 3,
            name: 'Timber Frame',
        },
        {
            id: 4,
            name: 'ICF',
        },
        {
            id: 5,
            name: 'MMC',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [superstructureExternalWallConstructionType, setSuperstructureExternalWallConstructionType] = useState([]);
    const handleChangeSuperstructureExternalWallConstructionTypes = (event) => {
        const {
            target: { value },
        } = event;
        setSuperstructureExternalWallConstructionType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const groundFloorConstructionTypes = [
        {
            id: 1,
            name: 'Ground Bearing',
        },
        {
            id: 2,
            name: 'Suspended Slab',
        },
        {
            id: 3,
            name: 'Beam & Block',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [groundFloorConstructionType, setGroundFloorConstructionType] = useState([]);
    const handleChangeGroundFloorConstructionTypes = (event) => {
        const {
            target: { value },
        } = event;
        setGroundFloorConstructionType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const foundationTypes = [
        {
            id: 1,
            name: 'Basement',
        },
        {
            id: 2,
            name: 'Piled foundations',
        },
        {
            id: 3,
            name: 'Raft (Traditional)',
        },
        {
            id: 4,
            name: 'Raft (Insulated)',
        },
        {
            id: 5,
            name: 'Strip & Trench fill foundations',
        },
        {
            id: 6,
            name: 'Vibro piling',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [foundationType, setFoundationType] = useState([]);
    const handleChangeFoundationTypes = (event) => {
        const {
            target: { value },
        } = event;
        setFoundationType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const insulationFills = [
        {
            id: 1,
            name: 'Partial Fill',
        },
        {
            id: 2,
            name: 'Full Fill',
        },
        {
            id: 3,
            name: 'Independently lined',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [insulationFill, setInsulationFill] = useState([]);
    const handleChangeInsulationFills = (event) => {
        const {
            target: { value },
        } = event;
        setInsulationFill(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const insulationMaterialTypes = [
        {
            id: 1,
            name: 'Fibreglass',
        },
        {
            id: 2,
            name: 'Mineral Wool',
        },
        {
            id: 3,
            name: 'Cellulose',
        },
        {
            id: 4,
            name: 'Natural Fibres',
        },
        {
            id: 5,
            name: 'Polystyrene',
        },
        {
            id: 6,
            name: 'Polyisocyanurate',
        },
        {
            id: 7,
            name: 'Polyurethane',
        },
        {
            id: 8,
            name: 'Vermiculate and perlite',
        },
        {
            id: 9,
            name: 'Urea-formaldehyde foam',
        },
        {
            id: 10,
            name: 'Cementitious foam',
        },
        {
            id: 11,
            name: 'Phenolic foam',
        },
        {
            id: 12,
            name: 'Insulation facings',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [insulationMaterialType, setInsulationMaterialType] = useState([]);
    const handleChangeInsulationMaterialTypes = (event) => {
        const {
            target: { value },
        } = event;
        setInsulationMaterialType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const separatingWallsFloorsCompliances = [
        {
            id: 1,
            name: 'Robust Details',
        },
        {
            id: 2,
            name: 'Part E',
        },
        {
            id: 3,
            name: 'Not applicable',
        },

    ];
    const [separatingWallsFloorsCompliance, setSeparatingWallsFloorsCompliance] = useState([]);
    const handleChangeSeparatingWallsFloorsCompliances = (event) => {
        const {
            target: { value },
        } = event;
        setSeparatingWallsFloorsCompliance(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const gradientOfLessthan7DegreesList = [
        'Yes',
        'No',
    ];
    const [gradientOfLessthan7Degrees, setGradientOfLessthan7Degrees] = useState(0);
    const handleChangeGradientOfLessthan7Degreess = (event) => {
        const {
            target: { value },
        } = event;
        setGradientOfLessthan7Degrees(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const roofStructureTypes = [
        {
            id: 1,
            name: 'Trussed Rafter',
        },
        {
            id: 2,
            name: 'Traditional Cut',
        },
        {
            id: 3,
            name: 'Cassette',
        },
        {
            id: 0,
            name: 'Other',
        },

    ];
    const [roofStructureType, setRoofStructureType] = useState([]);
    const handleChangeRoofStructureTypes = (event) => {
        const {
            target: { value },
        } = event;
        setRoofStructureType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const roofCoveringTypes = [
        {
            id: 1,
            name: 'Clay Tile',
        },
        {
            id: 2,
            name: 'Concrete Tile',
        },
        {
            id: 3,
            name: 'Synthetic Slate',
        },
        {
            id: 4,
            name: 'Natural Slate',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [roofCoveringType, setRoofCoveringType] = useState([]);
    const handleChangeRoofCoveringTypes = (event) => {
        const {
            target: { value },
        } = event;
        setRoofCoveringType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const externalWallFinishTypes = [
        {
            id: 1,
            name: 'Brick Clad',
        },
        {
            id: 2,
            name: 'Stone Clad',
        },
        {
            id: 3,
            name: 'Render',
        },
        {
            id: 4,
            name: 'Timber Clad',
        },
        {
            id: 5,
            name: 'Brick Slip',
        },
        {
            id: 6,
            name: 'Rainscreen',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [externalWallFinishType, setExternalWallFinishType] = useState([]);
    const handleChangeExternalWallFinishTypes = (event) => {
        const {
            target: { value },
        } = event;
        setExternalWallFinishType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const upperFloorsConstructionTypes = [
        {
            id: 1,
            name: 'Solid Timber Joist',
        },
        {
            id: 2,
            name: 'Engineered Timber Joist',
        },
        {
            id: 3,
            name: 'Beam & Block',
        },
        {
            id: 4,
            name: 'Concrete Plank',
        },
        {
            id: 5,
            name: 'Metal Deck',
        },
        {
            id: 6,
            name: 'No upper floors',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [upperFloorsConstructionType, setUpperFloorsConstructionType] = useState([]);
    const handleChangeUpperFloorsConstructionTypes = (event) => {
        const {
            target: { value },
        } = event;
        setUpperFloorsConstructionType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const specificFlatRoofStructuralDeckRiskAreasList = [
        {
            id: 1,
            name: 'Balcony',
        },
        {
            id: 2,
            name: 'Parapet',
        },
        {
            id: 3,
            name: 'Chimney',
        },
        {
            id: 4,
            name: 'Basement',
        },
        {
            id: 5,
            name: 'Podium Deck',
        },
    ];
    const [specificFlatRoofStructuralDeckRiskAreas, setSpecificFlatRoofStructuralDeckRiskAreas] = useState([]);
    const handleChangeSpecificFlatRoofStructuralDeckRiskAreass = (event) => {
        const {
            target: { value },
        } = event;
        setSpecificFlatRoofStructuralDeckRiskAreas(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const hasInstallationCertifiedList = [
        'Yes',
        "Don't know",
        'No',
    ];
    const [hasInstallationCertified, setHasInstallationCertified] = useState(0);
    const handleHasInstallationCertifieds = (event) => {
        const {
            target: { value },
        } = event;
        setHasInstallationCertified(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const hasBasementInstallationCertifiedList = [
        {
            id: 1,
            name: 'Yes',
        },
        {
            id: 2,
            name: "Don't know",
        },
        {
            id: 3,
            name: 'No',
        },
        {
            id: 4,
            name: 'Work in Progress',
        },
        {
            id: 5,
            name: 'Not applicable',
        },
    ];
    const [hasBasementInstallationCertified, setHasBasementInstallationCertified] = useState([]);
    const handleHasBasementInstallationCertifieds = (event) => {
        const {
            target: { value },
        } = event;
        setHasBasementInstallationCertified(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const belowGroundRequiresWaterProofingList = [
        'Yes',
        'No',
    ];
    const [belowGroundRequiresWaterProofing, setBelowGroundRequiresWaterProofing] = useState(0);
    const handleBelowGroundRequiresWaterProofings = (event) => {
        const {
            target: { value },
        } = event;
        setBelowGroundRequiresWaterProofing(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const flatRoofStructuralDeckDrainageList = [
        'Foul',
        'Surface',
    ];
    const [flatRoofStructuralDeckDrainage, setFlatRoofStructuralDeckDrainage] = useState([]);
    const handleChangeFlatRoofStructuralDeckDrainages = (event) => {
        const {
            target: { value },
        } = event;
        setFlatRoofStructuralDeckDrainage(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const flatRoofStructuralDeckCoveringTypes = [
        {
            id: 1,
            name: 'Single Ply',
        },
        {
            id: 2,
            name: 'Mastic Asphalt',
        },
        {
            id: 3,
            name: 'Liquid Applied',
        },
        {
            id: 4,
            name: 'GRP',
        },
    ];
    const [flatRoofStructuralDeckCoveringType, setFlatRoofStructuralDeckCoveringType] = useState([]);
    const handleChangeFlatRoofStructuralDeckCoveringTypes = (event) => {
        const {
            target: { value },
        } = event;
        setFlatRoofStructuralDeckCoveringType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const structuralDeckConstructionTypes = [
        {
            id: 1,
            name: 'Timber',
        },
        {
            id: 2,
            name: 'Concrete',
        },
        {
            id: 3,
            name: 'Metal Deck',
        },
        {
            id: 4,
            name: 'Warm Deck',
        },
        {
            id: 5,
            name: 'Cold Deck',
        },
        {
            id: 6,
            name: 'Inverted Warm Deck',
        },
        {
            id: 0,
            name: 'Other',
        },
    ];
    const [structuralDeckConstructionType, setStructuralDeckConstructionType] = useState([]);
    const handleChangeStructuralDeckConstructionTypes = (event) => {
        const {
            target: { value },
        } = event;
        setStructuralDeckConstructionType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const [buildUsage, setBuildUsage] = React.useState([]);
    const handleChangeBuildUsages = (event) => {
        const {
            target: { value },
        } = event;
        setBuildUsage(
            typeof value === 'string' ? value.split(',') : value,
        );
        const dummy = buildUsage?.map(b => ({
            construction_material_id: b
        }))
        console.log("🚀 ~ file: BuildFactors.js ~ line 410 ~ handleChangeBuildUsages ~ dummy", dummy)
        // setBuildUsage(
        //     ...buildUsage,
        //     value,
        // );
    };

    const [addMixedUse, setMixedUse] = useState('');
    const handleChangeMixedUse = (event) => {
        setMixedUse(event.target.value);
    };
    const [addFirstUnit, setAddFirstUnit] = useState('');
    const handleChangeAddFirstUnit = (event) => {
        setAddFirstUnit(event.target.value);
    };
    const [addAnotherBlock, setAddAnotherBlock] = useState('');
    const handleChangeAddAnotherBlock = (event) => {
        setAddAnotherBlock(event.target.value);
    };
    const [addFirstBlock, setAddFirstBlock] = useState('');
    const handleChangeAddFirstBlock = (event) => {
        setAddFirstBlock(event.target.value);
    };
    const [atDevelopment, setAtDevelopment] = useState('');
    const handleChangeAtDevelopment = (event) => {
        setAtDevelopment(event.target.value);
    };

    const [addBlockFormValues, setAddBlockFormValues] = useState([])
    const [newUnitAdded, setNewUnitAdded] = useState(null)
    const [newBlockAdded, setNewBlockAdded] = useState(null)
    console.log("🚀 ~ file: BuildFactors.js ~ line 394 ~ BuildFactors ~ addBlockFormValues", addBlockFormValues)
    let addBlockFormHandleChange = (i, e) => {
        let newFormFormValues = [...addBlockFormValues];
        newFormFormValues[i][e?.target?.name || e?.field_name] = e?.target?.value || e?.field_data;
        setAddBlockFormValues(newFormFormValues);
    }

    let addMoreBlockFormFields = () => {
        setNewBlockAdded(newBlockAdded + 1)
        setAddBlockFormValues([...addBlockFormValues, {
            block: "",
            units: []
        }])
    }

    let addMoreUnitFormFields = (i) => {
        setNewUnitAdded(newUnitAdded + 1)
        addBlockFormValues[i]?.units?.push({
            unit: ""
        })
        console.log("called")
    }

    useEffect(() => {
        setAddBlockFormValues([...addBlockFormValues])
    }, [newUnitAdded])

    const [averageFloorAboveGroundField, setAverageFloorAboveGroundField] = useState(null)
    const [numFloorBelowGroundField, setNumFloorBelowGroundField] = useState(null)
    const [maxFloorBelowGroundField, setMaxFloorBelowGroundField] = useState(null)
    const [numberOfParapetsField, setNumberOfParapetsField] = useState(null)
    const [numberOfPlotsField, setNumberOfPlotsField] = useState(null)
    const [numberOfDetachedField, setNumberOfDetachedField] = useState(null)
    const [contractValueField, setContractValueField] = useState(null)
    const [squareFeetField, setSquareFeetField] = useState(null)
    const [otherConstructionMaterialField, setOtherConstructionMaterialField] = useState("")
    const [otherFoundationTypeField, setOtherFoundationTypeField] = useState("")
    const [otherGroundFloorTypeField, setOtherGroundFloorTypeField] = useState("")
    const [otherSuperStructureField, setOtherSuperStructureField] = useState("")
    const [otherInsulationFillField, setOtherInsulationFillField] = useState("")
    const [otherInsulationMaterialField, setOtherInsulationMaterialField] = useState("")
    const [otherExternalWallFinishField, setOtherExternalWallFinishField] = useState("")
    const [otherRoofStructureTypeField, setOtherRoofStructureTypeField] = useState("")
    const [otherRoofCoveringTypeField, setOtherRoofCoveringTypeField] = useState("")
    const [otherFlatConstructionField, setOtherFlatConstructionField] = useState("")
    const [basementCSSWDesignerField, setBasementCSSWDesignerField] = useState("")
    const [basementInstallerCompanyField, setBasementInstallerCompanyField] = useState("")
    const [overseenCSSWDesignerField, setOverseenCSSWDesignerField] = useState("")
    const [overseenInstallerCompanyField, setOverseenInstallerCompanyField] = useState("")

    useEffect(() => {
        if (developmentMode !== "add" || developmentMode === "previousBtn") {
            const constructBlockData = getDevelopmentBuildFactorDetails?.block?.map(b => (
                {
                    block: b?.blocks,
                    units: b?.unit
                }
            ))
            console.log("🚀 ~ file: BuildFactors.js ~ line 735 ~ useEffect ~ constructBlockData", constructBlockData)
            setNewBlockAdded(getDevelopmentBuildFactorDetails?.block?.length)
            setMixedUse(getDevelopmentBuildFactorDetails?.build_usage || "")
            setAverageFloorAboveGroundField(getDevelopmentBuildFactorDetails?.avg_floor_above_ground || null)
            setNumFloorBelowGroundField(getDevelopmentBuildFactorDetails?.num_floor_below_ground || null)
            setMaxFloorBelowGroundField(getDevelopmentBuildFactorDetails?.max_floor_below_ground || null)
            setNumberOfStoreys(getDevelopmentBuildFactorDetails?.number_of_storeys || null)
            setNumberOfParapetsField(getDevelopmentBuildFactorDetails?.number_of_parapets || null)
            setNumberOfPlotsField(getDevelopmentBuildFactorDetails?.number_of_plots || null)
            setNumberOfDetachedField(getDevelopmentBuildFactorDetails?.number_of_detached || null)
            setContractValueField(getDevelopmentBuildFactorDetails?.contract_value || null)
            setSquareFeetField(getDevelopmentBuildFactorDetails?.square_feet || null)
            setIsTheSlopeLessThan17(getDevelopmentBuildFactorDetails?.slope || 0)
            setAddBlockFormValues(constructBlockData || [])

            const constructBuildUsageData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "construction_material")
            const constructBuildUsageDataSimplified = constructBuildUsageData?.map(b => b?.value)
            setBuildUsage(constructBuildUsageDataSimplified || [])

            const constructBuildUsageOtherData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "construction_material" && b?.value == 0)
            const constructBuildUsageOtherDataSimplified = constructBuildUsageOtherData?.map(b => b?.other)
            setOtherConstructionMaterialField(constructBuildUsageOtherDataSimplified || "")

            const constructFoundationTypeData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "foundation_type")
            const constructFoundationTypeDataSimplified = constructFoundationTypeData?.map(b => b?.value)
            setFoundationType(constructFoundationTypeDataSimplified || [])

            const constructFoundationTypeOtherData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "foundation_type" && b?.value == 0)
            const constructFoundationTypeOtherDataSimplified = constructFoundationTypeOtherData?.map(b => b?.other)
            setOtherFoundationTypeField(constructFoundationTypeOtherDataSimplified || "")

            const constructGroundFloorData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "ground_floor_type")
            const constructGroundFloorDataSimplified = constructGroundFloorData?.map(b => b?.value)
            setGroundFloorConstructionType(constructGroundFloorDataSimplified || [])

            const constructGroundFloorOtherData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "ground_floor_type" && b?.value == 0)
            const constructGroundFloorOtherDataSimplified = constructGroundFloorOtherData?.map(b => b?.other)
            setOtherGroundFloorTypeField(constructGroundFloorOtherDataSimplified || "")

            const constructSuperStructureData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "super_structure")
            const constructSuperStructureDataSimplified = constructSuperStructureData?.map(b => b?.value)
            setSuperstructureExternalWallConstructionType(constructSuperStructureDataSimplified || [])

            const constructSuperStructureOtherData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "super_structure" && b?.value == 0)
            const constructSuperStructureOtherDataSimplified = constructSuperStructureOtherData?.map(b => b?.other)
            setOtherSuperStructureField(constructSuperStructureOtherDataSimplified || "")

            const constructInsulationFillData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "insulation_fill")
            const constructInsulationFillDataSimplified = constructInsulationFillData?.map(b => b?.value)
            setInsulationFill(constructInsulationFillDataSimplified || [])

            const constructInsulationFillOtherData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "insulation_fill" && b?.value == 0)
            const constructInsulationFillOtherDataSimplified = constructInsulationFillOtherData?.map(b => b?.other)
            setOtherInsulationFillField(constructInsulationFillOtherDataSimplified || "")

            const constructInsulationMaterialData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "insulation_material")
            const constructInsulationMaterialDataSimplified = constructInsulationMaterialData?.map(b => b?.value)
            setInsulationMaterialType(constructInsulationMaterialDataSimplified || [])
            // setOtherInsulationMaterialField(getDevelopmentBuildFactorDetails?. || "")

            const constructSeperatingWallData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "separating_wall")
            const constructSeperatingWallDataSimplified = constructSeperatingWallData?.map(b => b?.value)
            setSeparatingWallsFloorsCompliance(constructSeperatingWallDataSimplified || [])

            const constructExternalWallData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "external_wall")
            const constructExternalWallDataSimplified = constructExternalWallData?.map(b => b?.value)
            setExternalWallFinishType(constructExternalWallDataSimplified || [])
            // setOtherExternalWallFinishField(getDevelopmentBuildFactorDetails?. || "")

            const constructUpperFloorData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "upper_floor")
            const constructUpperFloorDataSimplified = constructUpperFloorData?.map(b => b?.value)
            setUpperFloorsConstructionType(constructUpperFloorDataSimplified || [])

            const constructRoofStructureData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "roof_structure")
            const constructRoofStructureDataSimplified = constructRoofStructureData?.map(b => b?.value)
            setRoofStructureType(constructRoofStructureDataSimplified || [])
            // setOtherRoofStructureTypeField(getDevelopmentBuildFactorDetails?. || "")

            const constructRoofCoveringData = getDevelopmentBuildFactorDetails?.build_factor_type?.filter(b => b?.type === "roof_covering")
            const constructRoofCoveringDataSimplified = constructRoofCoveringData?.map(b => b?.value)
            setRoofCoveringType(constructRoofCoveringDataSimplified || [])
            // setOtherRoofCoveringTypeField(getDevelopmentBuildFactorDetails?. || "")


            // setOtherFlatConstructionField(getDevelopmentBuildFactorDetails?. || "")
            setBasementCSSWDesignerField(getDevelopmentBuildFactorDetails?.basement_cssw_designer || "")
            setBasementInstallerCompanyField(getDevelopmentBuildFactorDetails?.basement_installer_company || "")
            setOverseenCSSWDesignerField(getDevelopmentBuildFactorDetails?.overseen_cssw_designer || "")
            setOverseenInstallerCompanyField(getDevelopmentBuildFactorDetails?.overseen_installer_company || "")
            setGradientOfLessthan7Degrees(getDevelopmentBuildFactorDetails?.flat_roof || 0)
            // setStructuralDeckConstructionType(getDevelopmentBuildFactorDetails?. || "")
            // setFlatRoofStructuralDeckCoveringType(getDevelopmentBuildFactorDetails?. || "")
            // setSpecificFlatRoofStructuralDeckRiskAreas(getDevelopmentBuildFactorDetails?. || "")
            setFlatRoofStructuralDeckDrainage(getDevelopmentBuildFactorDetails?.flat_drainage || [])
            setHasBasementInstallationCertified(getDevelopmentBuildFactorDetails?.basement_waterproofing || [])
            setBelowGroundRequiresWaterProofing(getDevelopmentBuildFactorDetails?.part_of_build || 0)
            setHasInstallationCertified(getDevelopmentBuildFactorDetails?.overseen_waterproofing || 0)
        }
    }, [getDevelopmentBuildFactorDetails])

    const [loading, setLoading] = useState(false);

    const CreateDevelopmentBuildFactorHandler = async () => {
        setLoading(true)
        const data = {
            general_details_id: developmentGeneralDetails?.id || getDevelopmentGeneralDetails?.id,
            build_usage: addMixedUse,
            avg_floor_above_ground: averageFloorAboveGroundField,
            num_floor_below_ground: numFloorBelowGroundField,
            max_floor_below_ground: maxFloorBelowGroundField,
            number_of_storeys: numberOfStoreys,
            number_of_parapets: numberOfParapetsField,
            number_of_plots: numberOfPlotsField,
            number_of_detached: numberOfDetachedField,
            contract_value: contractValueField,
            square_feet: squareFeetField,
            slope: isTheSlopeLessThan17[0] || isTheSlopeLessThan17,
            blocks: addBlockFormValues,
            construction_material: buildUsage?.map(b => ({
                construction_material_id: b
            })),
            other_construction_material: otherConstructionMaterialField,
            foundation_type: foundationType?.map(b => ({
                foundation_type_id: b
            })),
            other_foundation_type: otherFoundationTypeField,
            ground_floor_type: groundFloorConstructionType?.map(b => ({
                ground_floor_type_id: b
            })),
            other_ground_floor: otherGroundFloorTypeField,
            super_structure: superstructureExternalWallConstructionType?.map(b => ({
                super_structure_id: b
            })),
            other_super_structure: otherSuperStructureField,
            insulation_fill: insulationFill?.map(b => ({
                insulation_fill_id: b
            })),
            other_insulation_fill: otherInsulationFillField,
            insulation_material: insulationMaterialType?.map(b => ({
                insulation_material_id: b
            })),
            other_insulation_material: otherInsulationMaterialField,
            separating_wall: separatingWallsFloorsCompliance?.map(b => ({
                separating_wall_id: b
            })),
            external_wall: externalWallFinishType?.map(b => ({
                external_wall_id: b
            })),
            other_external_wall_id: otherExternalWallFinishField,
            upper_floor: upperFloorsConstructionType?.map(b => ({
                upper_floor_id: b
            })),
            roof_structure: roofStructureType?.map(b => ({
                roof_structure_type: b
            })),
            other_roof_structure: otherRoofStructureTypeField,
            roof_covering: roofCoveringType?.map(b => ({
                roof_covering_id: b
            })),
            other_roof_covering: otherRoofCoveringTypeField,
            flat_roof: gradientOfLessthan7Degrees[0],
            flat_construction: structuralDeckConstructionType?.map(b => ({
                flat_construction_id: b
            })),
            other_flat_construction: otherFlatConstructionField,
            flat_covering: flatRoofStructuralDeckCoveringType?.map(b => ({
                flat_covering_id: b
            })),
            flat_disk_area: specificFlatRoofStructuralDeckRiskAreas?.map(b => ({
                flat_disk_area_id: b
            })),
            flat_drainage: flatRoofStructuralDeckDrainage[0],
            basement_waterproofing: hasBasementInstallationCertified[0],
            basement_cssw_designer: basementCSSWDesignerField,
            basement_installer_company: basementInstallerCompanyField,
            part_of_build: belowGroundRequiresWaterProofing[0],
            overseen_waterproofing: hasInstallationCertified[0],
            overseen_cssw_designer: overseenCSSWDesignerField,
            overseen_installer_company: overseenInstallerCompanyField,
        }
        try {
            developmentMode === "edit"
                ? await dispatch(UpdateDevelopmentBuildFactors({ id: getDevelopmentBuildFactorDetails?.id, data: data }))
                : await dispatch(CreateDevelopmentBuildFactors(data));

            console.log("API call successful!");
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
        console.log("🚀 ~ file: BuildFactors.js ~ line 502 ~ CreateDevelopmentBuildFactorHandler ~ data", data)

    }

    return (
        <>
            <Row style={{ pointerEvents: `${developmentMode == "view" && 'none'}` }}>
                <Col className="mt-0" lg="12" sm="12">
                    <span className="text-lg font-bold mt-4">Build Factors at Development </span>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" size="small">Build Usage</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={addMixedUse}
                            label="Add another 'Unit'"
                            onChange={handleChangeMixedUse}
                            size="small"
                        >
                            <MenuItem value={10}>Residential</MenuItem>
                            <MenuItem value={20}>Commercial</MenuItem>
                            <MenuItem value={30}>Mixed Use</MenuItem>
                        </Select>
                    </FormControl>
                    {developmentBuildFactorsError?.build_usage && <p className="text-danger">{developmentBuildFactorsError?.build_usage}</p>}
                </Col>
                <Col className="mt-4" lg="12" sm="12">
                    <span className="text-lg font-bold mt-4">Number of Blocks and Units</span>
                </Col>
                {
                    addBlockFormValues?.map((element, index) => (
                        <>
                            {newBlockAdded != null &&
                                <Col className="mt-2" lg="12" sm="12">
                                    <span className='text-sm font-bold'>{`Block ${index + 1}`}</span>
                                </Col>
                            }
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Block"
                                    className="custom-input"
                                    fullWidth
                                    // helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="number"
                                    value={developmentMode == "add" ? element[index]?.block : element?.block}
                                    onChange={(e) => {
                                        let addBlockFormValuesTemp = addBlockFormValues
                                        addBlockFormValuesTemp[index].block = e.target.value
                                        setAddBlockFormValues(addBlockFormValuesTemp)
                                    }}
                                />
                            </Col>
                            {
                                element?.units?.map((unitElement, unitIndex) => (
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Unit"
                                            className="custom-input"
                                            fullWidth
                                            // helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            type="number"
                                            value={developmentMode == "add" ? unitElement[index]?.unit : unitElement?.unit}
                                            onChange={(e) => {
                                                let addUnitFormValuesTemp = addBlockFormValues
                                                addUnitFormValuesTemp[index].units[unitIndex].unit = e.target.value
                                                setAddBlockFormValues(addUnitFormValuesTemp)
                                            }}
                                        />
                                    </Col>
                                ))
                            }
                            <Col className="mt-4" lg="6" sm="12">
                                <div
                                    className='flex flex-row cursor-pointer'
                                    onClick={() => addMoreUnitFormFields(index)}
                                >
                                    <div
                                        style={{ backgroundColor: '#475BE2' }}
                                        className='h-5 w-5 rounded-xl flex justify-center align-center'
                                    >
                                        <AddIcon color='action' fontSize='small' />
                                    </div>
                                    <span className='font-bold ml-2' style={{ color: '#475BE2' }}>Click to Add Unit</span>
                                </div>
                            </Col>
                            <br />
                        </>
                    ))
                }
                <Col className="mt-4" lg="12" sm="12">
                    <div className='flex flex-row align-center'>
                        <div
                            className='flex flex-row cursor-pointer'
                            onClick={addMoreBlockFormFields}
                        >
                            <div
                                style={{ backgroundColor: '#475BE2' }}
                                className='h-5 w-5 rounded-xl flex justify-center align-center'
                            >
                                <AddIcon color='action' fontSize='small' />
                            </div>
                            <span className='font-bold ml-2' style={{ color: '#475BE2' }}>Click to Add Block</span>
                        </div>
                    </div>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Average Number of 'Floors above ground'?"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        type="number"
                        value={averageFloorAboveGroundField}
                        onChange={(e) => setAverageFloorAboveGroundField(e.target.value)}
                    />
                    {developmentBuildFactorsError?.avg_floor_above_ground && <p className="text-danger">{developmentBuildFactorsError?.avg_floor_above_ground}</p>}
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Total number of storeys (including the ground floor storey) in the tallest block."
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        type="number"
                        value={numberOfStoreys}
                        onChange={(e) => setNumberOfStoreys(e.target.value)}
                    />
                    {developmentBuildFactorsError?.number_of_storeys && <p className="text-danger">{developmentBuildFactorsError?.number_of_storeys}</p>}
                </Col>
                {
                    numberOfStoreys >= 6 &&
                    <Col className="mt-4" lg="6" sm="12">
                        <span className="text-sm font-bold mt-4">Ther are more than 6 storeys in the tallest block.</span>
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Number of 'Floors below ground'? i.e. car parking, plant rooms, lift pits etc."
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        type="number"
                        value={numFloorBelowGroundField}
                        onChange={(e) => setNumFloorBelowGroundField(e.target.value)}
                    />
                    {developmentBuildFactorsError?.num_floor_below_ground && <p className="text-danger">{developmentBuildFactorsError?.num_floor_below_ground}</p>}
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Maximum number of 'Floors below ground'? i.e. car parking, plant rooms, lift pits etc."
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        type="number"
                        value={maxFloorBelowGroundField}
                        onChange={(e) => setMaxFloorBelowGroundField(e.target.value)}
                    />
                    {developmentBuildFactorsError?.max_floor_below_ground && <p className="text-danger">{developmentBuildFactorsError?.max_floor_below_ground}</p>}
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Number of 'Parapets'?"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        type="number"
                        value={numberOfParapetsField}
                        onChange={(e) => setNumberOfParapetsField(e.target.value)}
                    />
                    {developmentBuildFactorsError?.number_of_parapets && <p className="text-danger">{developmentBuildFactorsError?.number_of_parapets}</p>}
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Number of 'Plots'?"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        type="number"
                        value={numberOfPlotsField}
                        onChange={(e) => setNumberOfPlotsField(e.target.value)}
                    />
                    {developmentBuildFactorsError?.number_of_plots && <p className="text-danger">{developmentBuildFactorsError?.number_of_plots}</p>}
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Number of detached ancillary buildings, including garages, which are not exempt."
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        type="number"
                        value={numberOfDetachedField}
                        onChange={(e) => setNumberOfDetachedField(e.target.value)}
                    />
                    {developmentBuildFactorsError?.number_of_detached && <p className="text-danger">{developmentBuildFactorsError?.number_of_detached}</p>}
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Contract Value"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        type="number"
                        value={contractValueField}
                        onChange={(e) => setContractValueField(e.target.value)}
                    />
                    {developmentBuildFactorsError?.contract_value && <p className="text-danger">{developmentBuildFactorsError?.contract_value}</p>}
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Total Square Footage"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                        type="number"
                        value={squareFeetField}
                        onChange={(e) => setSquareFeetField(e.target.value)}
                    />
                    {developmentBuildFactorsError?.square_feet && <p className="text-danger">{developmentBuildFactorsError?.square_feet}</p>}
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Construction Materials / Build Type(s)</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={buildUsage}
                            onChange={handleChangeBuildUsages}
                            input={<OutlinedInput label="Construction Materials / Build Type(s)" />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => buildTypes[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? buildTypes?.length : obj;
                                    return buildTypes[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {buildTypes?.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={buildUsage.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {buildUsage.includes(0) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Construction Materials / Build Type(s) Other"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            value={otherConstructionMaterialField}
                            onChange={(e) => setOtherConstructionMaterialField(e.target.value)}
                        />
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Foundation Type(s)</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={foundationType}
                            onChange={handleChangeFoundationTypes}
                            input={<OutlinedInput label="What are the Foundation Type(s)" />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => foundationTypes[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? foundationTypes?.length : obj;
                                    return foundationTypes[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {foundationTypes?.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={foundationType.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {foundationType.includes(0) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Foundation Type(s) Other"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            value={otherFoundationTypeField}
                            onChange={(e) => setOtherFoundationTypeField(e.target.value)}
                        />
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Ground Floor Construction Type(s)</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={groundFloorConstructionType}
                            onChange={handleChangeGroundFloorConstructionTypes}
                            input={<OutlinedInput label="Ground Floor Construction Type(s)" />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => groundFloorConstructionTypes[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? groundFloorConstructionTypes?.length : obj;
                                    return groundFloorConstructionTypes[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {groundFloorConstructionTypes?.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={groundFloorConstructionType.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {groundFloorConstructionType.includes(0) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Ground Floor Construction Type(s) Other"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            value={otherGroundFloorTypeField}
                            onChange={(e) => setOtherGroundFloorTypeField(e.target.value)}
                        />
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth>
                        <FormLabel id="otherContactStatus">Is the slope less than 1:7?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="otherContactStatus"
                            name=""
                            label="Pre planning Desktop Survey report received?"
                            value={isTheSlopeLessThan17}
                            onChange={handleChangeIsTheSlopeLessThan17}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    {developmentBuildFactorsError?.slope && <p className="text-danger">{developmentBuildFactorsError?.slope}</p>}
                </Col>
                {isTheSlopeLessThan17 == 0 &&
                    <Col className="mt-2" lg="12" sm="12">
                        <span className="text-sm font-bold mt-4">Refer to underwriting as the slope is GREATER than 1:7. </span>
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Superstructure - External Wall Construction Type(s)</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={superstructureExternalWallConstructionType}
                            onChange={handleChangeSuperstructureExternalWallConstructionTypes}
                            input={<OutlinedInput label="Superstructure - External Wall Construction Type(s)" />}
                            // renderValue={(selected) => selected?.map(obj => superstructureExternalWallConstructionTypes[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? superstructureExternalWallConstructionTypes?.length : obj;
                                    return superstructureExternalWallConstructionTypes[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {superstructureExternalWallConstructionTypes?.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={superstructureExternalWallConstructionType.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {superstructureExternalWallConstructionType.includes(0) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Superstructure - External Wall Construction Type(s) Other"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            value={otherSuperStructureField}
                            onChange={(e) => setOtherSuperStructureField(e.target.value)}
                        />
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Insulation Fill(s)?</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={insulationFill}
                            onChange={handleChangeInsulationFills}
                            input={<OutlinedInput label="What are the Insulation Fill(s)? " />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => insulationFills[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? insulationFills?.length : obj;
                                    return insulationFills[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {insulationFills?.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={insulationFill.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {(insulationFill.includes('Full Fill') && insulationFill != []) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <span className="text-sm font-bold mt-4">Coastal Location & Full Fill Insulation: Refer to Underwriter </span>
                    </Col>
                }
                {insulationFill.includes(0) &&
                    <>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Insulation Fill(s)? Other"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                multiline
                                maxRows={1}
                                value={otherInsulationFillField}
                                onChange={(e) => setOtherInsulationFillField(e.target.value)}
                            />
                        </Col>
                    </>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Insulation Material Type(s)?</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={insulationMaterialType}
                            onChange={handleChangeInsulationMaterialTypes}
                            input={<OutlinedInput label="What are the Insulation Material Type(s)?" />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => insulationMaterialTypes[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? insulationMaterialTypes?.length : obj;
                                    return insulationMaterialTypes[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {insulationMaterialTypes?.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={insulationMaterialType.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {insulationMaterialType.includes(0) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Insulation Material Type(s) Other"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            value={otherInsulationMaterialField}
                            onChange={(e) => setOtherInsulationMaterialField(e.target.value)}
                        />
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Separating Walls / Floors Compliance (choose which apply)</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={separatingWallsFloorsCompliance}
                            onChange={handleChangeSeparatingWallsFloorsCompliances}
                            input={<OutlinedInput label="Separating Walls / Floors Compliance (choose which apply)" />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => separatingWallsFloorsCompliances[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? separatingWallsFloorsCompliances?.length : obj;
                                    return separatingWallsFloorsCompliances[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {separatingWallsFloorsCompliances?.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={separatingWallsFloorsCompliance.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {separatingWallsFloorsCompliance.includes(0) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Other Insulation Material Type(s)"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                        />
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">External Wall Finish Type(s)</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={externalWallFinishType}
                            onChange={handleChangeExternalWallFinishTypes}
                            input={<OutlinedInput label="What are the External Wall Finish Type(s)" />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => externalWallFinishTypes[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? externalWallFinishTypes?.length : obj;
                                    return externalWallFinishTypes[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {externalWallFinishTypes?.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={externalWallFinishType.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {externalWallFinishType.includes(0) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="External Wall Finish Type(s) Other"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            value={otherExternalWallFinishField}
                            onChange={(e) => setOtherExternalWallFinishField(e.target.value)}
                        />
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Upper Floor(s) Construction Type(s)?</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={upperFloorsConstructionType}
                            onChange={handleChangeUpperFloorsConstructionTypes}
                            input={<OutlinedInput label="Upper Floor(s) Construction Type(s)?" />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => upperFloorsConstructionTypes[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? upperFloorsConstructionTypes?.length : obj;
                                    return upperFloorsConstructionTypes[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {upperFloorsConstructionTypes.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={upperFloorsConstructionType.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Roof Structure Type(s)</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={roofStructureType}
                            onChange={handleChangeRoofStructureTypes}
                            input={<OutlinedInput label="Roof Structure Type(s)" />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => roofStructureTypes[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? roofStructureTypes?.length : obj;
                                    return roofStructureTypes[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {roofStructureTypes.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={roofStructureType.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {roofStructureType.includes(0) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Roof Structure Type(s) Other"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            value={otherRoofStructureTypeField}
                            onChange={(e) => setOtherRoofStructureTypeField(e.target.value)}
                        />
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Roof Covering Type(s)</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={roofCoveringType}
                            onChange={handleChangeRoofCoveringTypes}
                            input={<OutlinedInput label="Roof Covering Type(s)" />}
                            // renderValue={(selected) => selected.join(', ')}
                            // renderValue={(selected) => selected?.map(obj => roofCoveringTypes[obj - 1].name).join(", ")}
                            renderValue={(selected) =>
                                selected?.map(obj => {
                                    const id = obj == 0 ? roofCoveringTypes?.length : obj;
                                    return roofCoveringTypes[id - 1].name
                                }).join(", ")
                            }
                            MenuProps={MenuProps}
                        >
                            {roofCoveringTypes?.map((name) => (
                                <MenuItem key={name?.id} value={name?.id}>
                                    <Checkbox checked={roofCoveringType.indexOf(name?.id) > -1} />
                                    <ListItemText primary={name?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                {roofCoveringType.includes(0) &&
                    <Col className="mt-4" lg="6" sm="12">
                        <TextField
                            label="Roof Covering Type(s) Other"
                            className="custom-input"
                            fullWidth
                            helperText=""
                            id="demo-helper-text-misaligned"
                            size="small"
                            multiline
                            maxRows={1}
                            value={otherRoofCoveringTypeField}
                            onChange={(e) => setOtherRoofCoveringTypeField(e.target.value)}
                        />
                    </Col>
                }
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl fullWidth>
                        <FormLabel id="otherContactStatus">Flat Roof / Structural Deck (Any roof type / structural deck / balcony with a gradient of less than 7 degrees).</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="otherContactStatus"
                            name=""
                            label="Pre planning Desktop Survey report received?"
                            value={gradientOfLessthan7Degrees}
                            onChange={handleChangeGradientOfLessthan7Degreess}
                        // onChange={(e) => setGradientOfLessthan7Degrees(e.target.value)}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Col>
                {gradientOfLessthan7Degrees == "1" &&
                    <>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">Flat Roof / Structural Deck Construction Type(s)</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={structuralDeckConstructionType}
                                    onChange={handleChangeStructuralDeckConstructionTypes}
                                    input={<OutlinedInput label="Flat Roof / Structural Deck Construction Type(s)" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    // renderValue={(selected) => selected?.map(obj => structuralDeckConstructionTypes[obj - 1].name).join(", ")}
                                    renderValue={(selected) =>
                                        selected?.map(obj => {
                                            const id = obj == 0 ? structuralDeckConstructionTypes?.length : obj;
                                            return structuralDeckConstructionTypes[id - 1].name
                                        }).join(", ")
                                    }
                                    MenuProps={MenuProps}
                                >
                                    {structuralDeckConstructionTypes?.map((name) => (
                                        <MenuItem key={name?.id} value={name?.id}>
                                            <Checkbox checked={structuralDeckConstructionType.indexOf(name?.id) > -1} />
                                            <ListItemText primary={name?.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        {structuralDeckConstructionType.includes(0) &&
                            <>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Flat Roof / Structural Deck Construction Type(s) Other"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        multiline
                                        maxRows={1}
                                        value={otherFlatConstructionField}
                                        onChange={(e) => setOtherFlatConstructionField(e.target.value)}
                                    />
                                </Col>
                            </>
                        }
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">Flat Roof / Structural Deck Covering Type(s)</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={flatRoofStructuralDeckCoveringType}
                                    onChange={handleChangeFlatRoofStructuralDeckCoveringTypes}
                                    input={<OutlinedInput label="Flat Roof / Structural Deck Covering Type(s)" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    // renderValue={(selected) => selected?.map(obj => flatRoofStructuralDeckCoveringTypes[obj - 1].name).join(", ")}
                                    renderValue={(selected) =>
                                        selected?.map(obj => {
                                            const id = obj == 0 ? flatRoofStructuralDeckCoveringTypes?.length : obj;
                                            return flatRoofStructuralDeckCoveringTypes[id - 1].name
                                        }).join(", ")
                                    }
                                    MenuProps={MenuProps}
                                >
                                    {flatRoofStructuralDeckCoveringTypes?.map((name) => (
                                        <MenuItem key={name?.id} value={name?.id}>
                                            <Checkbox checked={flatRoofStructuralDeckCoveringType.indexOf(name?.id) > -1} />
                                            <ListItemText primary={name?.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">Specific Flat Roof / Structural Deck Risk Areas</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={specificFlatRoofStructuralDeckRiskAreas}
                                    onChange={handleChangeSpecificFlatRoofStructuralDeckRiskAreass}
                                    input={<OutlinedInput label="Specific Flat Roof / Structural Deck Risk Areas" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    // renderValue={(selected) => selected?.map(obj => specificFlatRoofStructuralDeckRiskAreasList[obj - 1].name).join(", ")}
                                    renderValue={(selected) =>
                                        selected?.map(obj => {
                                            const id = obj == 0 ? specificFlatRoofStructuralDeckRiskAreasList?.length : obj;
                                            return specificFlatRoofStructuralDeckRiskAreasList[id - 1].name
                                        }).join(", ")
                                    }
                                    MenuProps={MenuProps}
                                >
                                    {specificFlatRoofStructuralDeckRiskAreasList?.map((name) => (
                                        <MenuItem key={name?.id} value={name?.id}>
                                            <Checkbox checked={specificFlatRoofStructuralDeckRiskAreas.indexOf(name?.id) > -1} />
                                            <ListItemText primary={name?.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">Flat Roof / Structural Deck Drainage</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={flatRoofStructuralDeckDrainage}
                                    onChange={handleChangeFlatRoofStructuralDeckDrainages}
                                    input={<OutlinedInput label="Flat Roof / Structural Deck Drainage" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    renderValue={(selected) => selected}
                                    MenuProps={MenuProps}
                                >
                                    {flatRoofStructuralDeckDrainageList?.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>

                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth sx={{}}>
                                <InputLabel size="small" id="demo-multiple-checkbox-label">Has the basement installation been designed to BS8102 by a Certified Surveyor in Structural Waterproofing (CSSW)?</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={hasBasementInstallationCertified}
                                    onChange={handleHasBasementInstallationCertifieds}
                                    input={<OutlinedInput label="Has the basement installation been designed to BS8102 by a Certified Surveyor in Structural Waterproofing (CSSW)?" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {hasBasementInstallationCertifiedList?.map((name) => (
                                        <MenuItem key={name?.id} value={name?.id}>
                                            <ListItemText primary={name?.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        {hasBasementInstallationCertified == '3' &&
                            <Col className="mt-2" lg="12" sm="12">
                                <span className="text-sm font-bold mt-4">The basement installation has NOT been designed to BS8102 by a Certified Surveyor in Structural Waterproofing (CSSW). Ark Insurance have been notified. </span>
                            </Col>
                        }
                        {hasBasementInstallationCertified == "2" &&
                            <Col className="mt-2" lg="12" sm="12">
                                <span className="text-sm font-bold mt-4">We are unsure whether the basement installation has been designed to BS8102 by a Certified Surveyor in Structural Waterproofing (CSSW). Ark Insurance have been notified. </span>
                            </Col>
                        }
                        {hasBasementInstallationCertified == '1' &&
                            <>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Name of CSSW Designer (if known)?"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        value={basementCSSWDesignerField}
                                        onChange={(e) => setBasementCSSWDesignerField(e.target.value)}
                                    />
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Name of Installer Company (if known)?"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        value={basementInstallerCompanyField}
                                        onChange={(e) => setBasementInstallerCompanyField(e.target.value)}
                                    />
                                </Col>
                                <Col className="mt-2" lg="12" sm="12">
                                    <span className="text-sm font-bold mt-4">The basement installation been designed to BS8102 by a Certified Surveyor in Structural Waterproofing (CSSW). Ark Insurance must obtain evidence of PI cover. (Show name of CSSW Designer and Installation Company)</span>
                                </Col>
                            </>
                        }
                    </>
                }
                <Col className="mt-4" lg="6" sm="12">
                    {/* <FormControl fullWidth sx={{}}>
                        <InputLabel size="small" id="demo-multiple-checkbox-label">Any part of the building that is partially or entirely below ground level that requires structural waterproofing?</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={belowGroundRequiresWaterProofing}
                            onChange={handleBelowGroundRequiresWaterProofings}
                            input={<OutlinedInput label="Any part of the building that is partially or entirely below ground level that requires structural waterproofing?" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {belowGroundRequiresWaterProofingList.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl> */}

                    <FormControl fullWidth>
                        <FormLabel id="otherContactStatus">Any part of the building that is partially or entirely below ground level that requires structural waterproofing?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="otherContactStatus"
                            name=""
                            label="Pre planning Desktop Survey report received?"
                            value={belowGroundRequiresWaterProofing}
                            onChange={handleBelowGroundRequiresWaterProofings}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Col>
                {belowGroundRequiresWaterProofing == 1 &&
                    <Col className="mt-2" lg="12" sm="12">
                        <span className="text-sm font-bold mt-4">There are parts of the building that are partially or entirely below ground level that require structural waterproofing? </span>
                    </Col>
                }
                {hasInstallationCertified == "Don't know" &&
                    <Col className="mt-2" lg="12" sm="12">
                        <span className="text-sm font-bold mt-4">We are unsure whether the basement installation has been overseen by a Certified Surveyor in Structural Waterproofing (CSSW). Ark Insurance have been notified.</span>
                    </Col>
                }
                {belowGroundRequiresWaterProofing == 1 &&
                    <Col className="mt-4" lg="6" sm="12">
                        <FormControl fullWidth>
                            <FormLabel id="otherContactStatus">Has the installation been overseen by a Certified Surveyor in Structural Waterproofing (CSSW)?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="otherContactStatus"
                                name=""
                                label="Pre planning Desktop Survey report received?"
                                value={hasInstallationCertified}
                                onChange={handleHasInstallationCertifieds}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                <FormControlLabel value={0} control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Col>}
                {hasInstallationCertified == "0" &&
                    <Col className="mt-2" lg="12" sm="12">
                        <span className="text-sm font-bold mt-4">The basement installation has NOT been overseen by a Certified Surveyor in Structural Waterproofing (CSSW). Ark Insurance have been notified.</span>
                    </Col>
                }
                {hasInstallationCertified == '1' &&
                    <>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Name of CSSW Designer (if known)?"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                value={overseenCSSWDesignerField}
                                onChange={(e) => setOverseenCSSWDesignerField(e.target.value)}
                            />
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <TextField
                                label="Name of Installer Company (if known)?"
                                className="custom-input"
                                fullWidth
                                helperText=""
                                id="demo-helper-text-misaligned"
                                size="small"
                                value={overseenInstallerCompanyField}
                                onChange={(e) => setOverseenInstallerCompanyField(e.target.value)}
                            />
                        </Col>
                        <Col className="mt-2" lg="12" sm="12">
                            <span className="text-sm font-bold mt-4">The basement installation been overseen by a Certified Surveyor in Structural Waterproofing (CSSW). Ark Insurance must obtain evidence of PI cover. (Show name of CSSW Designer and Installation Company)</span>
                        </Col>
                    </>
                }
                <Col className="mt-3" lg="12" sm="12">
                    <span className="text-sm font-bold mt-4">IMPORTANT: It is imperative that if any of the Build Factors has changed Ark Warranty Services must be notified immediately. Failure to do so could result in cover being declined.</span>
                </Col>
            </Row>
            <CardFooter>
                {developmentMode !== "view" && <div className="exit-btn-container mr-3">
                    <Button
                        disabled={value == 0}
                        className="mr-3"
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={() => { dispatch(ChangeDevelopmentMode("previousBtn")); dispatch(GetDevelopmentSiteInvestigationDetails(developmentGeneralDetails?.id)); dispatch(ChangeDevelopmentStepValue(value - 1)) }} color="info" size="sm"
                    >
                        Previous
                    </Button>
                    <Button
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={CreateDevelopmentBuildFactorHandler}
                        color="info" size="sm"
                    >
                        {
                            !loading
                                ?
                                value == 4 ?
                                    "Save"
                                    :
                                    "Next"
                                :
                                <CircularProgress color='inherit' size={18} />
                        }
                    </Button>
                </div>}
            </CardFooter>
        </>
    );
}

export default BuildFactors;
