import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import undoable from 'redux-undo'
import AddNewDevelopment from '../Screens/AddNewDevelopment/Store/AddNewDevelopmentSlice'
import AddNewInsurerOrBroker from '../Screens/AddNewInsurerOrBroker/Store/AddNewInsurerOrBrokerSlice'
import AddNewStakeHolder from '../Screens/AddNewStakeHolder/Store/AddNewStakeHolderSlice'
import AddNewSurveyor from '../Screens/AddNewSurveyor/Store/AddNewSurveyorSlice'
import AddNewUser from '../Screens/AddNewUser/Store/AddNewUserSlice'
import AdminProfile from '../Screens/AdminProfile/Store/AdminProfileSlice'
import dashboard from '../Screens/Dashboard/Store/dashboardSlice'
import defectDetail from '../Screens/DefectDetail/Store/defectDetailSlice'
import AdministrationHistory from '../Screens/Diaries/AdministrationHistory/Store/AdministrationHistorySlice'
import diaries from '../Screens/Diaries/Store/diariesSlice'
import ExternalUsersProfile from '../Screens/ExternalUsers/ExternalUsersProfile/Store/ExternalUsersProfileSlice'
import ExternalUsers from '../Screens/ExternalUsers/Store/ExternalUsersSlice'
import page from '../Screens/Shared/store/pageSlice'
import shared from '../Screens/Shared/store/sharedSlice'
import userData from '../Screens/Shared/store/userDataSlice'
import systemMaintenance from '../Screens/SystemMaintenance/Store/systemMaintenanceSlice'
import styles from '../Styles/Store/stylesSlice'

export default configureStore({
    reducer: {
        AddNewDevelopment,
        AddNewInsurerOrBroker,
        AddNewStakeHolder,
        AddNewSurveyor,
        AddNewUser,
        AdministrationHistory,
        AdminProfile,
        dashboard,
        defectDetail,
        diaries,
        ExternalUsers,
        ExternalUsersProfile,
        page: undoable(page),
        shared,
        styles,
        systemMaintenance,
        userData,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})