import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Row
} from "reactstrap";
import AddNewInsurerOrBroker from "../AddNewInsurerOrBroker/AddNewInsurerOrBroker";
import AddNewStakeHolder from "../AddNewStakeHolder/AddNewStakeHolder";
import AddNewSurveyor from "../AddNewSurveyor/AddNewSurveyor";
import { GetAllSurveyor } from "../AddNewSurveyor/Store/AddNewSurveyorApi";
import AddNewUser from "../AddNewUser/AddNewUser";
import { AdvanceSearch } from "../Dashboard/Store/DashboardApi";
import './SystemMaintenance.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GetAllBroker, GetAllInsurer } from "../AddNewInsurerOrBroker/Store/AddNewInsurerOrBrokerApi";
import { GetAllStakeHolder } from "../AddNewStakeHolder/Store/AddNewStakeHolderApi";
import { AlterAdvanceSearchResult, EmptyAdvanceSearchResult } from "../Dashboard/Store/dashboardSlice";
import {
    SetCurrentForm,
} from "../Shared/store/sharedSlice";
import {
    SetSingleUnAllocatedDetailsTitle,
    SetIsViewDevelopmentDetails,
    SetShowStackholderActivity,
    SetShowSingleUnAllocatedDevelopments,
} from "../Shared/store/pageSlice";


function SimpleDialog(props) {
    const history = useHistory()
    const dispatch = useDispatch();
    const { onClose, selectedValue, open } = props;
    const { advanceSearchResult } = useSelector(state => state.dashboard)

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        // onClose(value);
        dispatch(AlterAdvanceSearchResult(value))
        history.push("/admin/dashboard")
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Search Results</DialogTitle>
            <List sx={{ pt: 0 }}>
                {/* {advanceSearchResult?.map((searchResult) => (
                    <ListItem button onClick={() => handleListItemClick(searchResult?.surveyor?.id)} key={searchResult?.surveyor?.fore_name}>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${searchResult?.surveyor?.fore_name} ${searchResult?.surveyor?.sur_name}`} />
                    </ListItem>
                ))} */}
            </List>
        </Dialog>
    );
}

function SimpleDialogDemo() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            <Typography variant="subtitle1" component="div">
                Selected: {selectedValue}
            </Typography>
            <br />
            <Button variant="outlined" onClick={handleClickOpen}>
                Open simple dialog
            </Button>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </div>
    );
}

function SystemMaintenance() {

    const history = useHistory()
    const dispatch = useDispatch()
    const { allSurveyor } = useSelector(state => state?.AddNewSurveyor)
    const { allStakeHolder } = useSelector(state => state?.AddNewStakeHolder)
    const { allBroker, allInsurer } = useSelector(state => state?.AddNewInsurerOrBroker)
    const { advanceSearchResult } = useSelector(state => state?.dashboard)
    const { currentForm } = useSelector(state => state?.shared)
    console.log("🚀 ~ file: SystemMaintenance.js ~ line 113 ~ SystemMaintenance ~ advanceSearchResult", advanceSearchResult)

    const [open, setOpen] = useState(false);
    const [selectedUserField, setSelectedUserField] = useState("")
    const [surveyorIdField, setSurveyorIdField] = useState("")
    const [companyNameField, setCompanyNameField] = useState("")
    const [contactNameField, setContactNameField] = useState("")
    const [developmentNameField, setDevelopmentNameField] = useState("")
    const [developmentPostcodeField, setDevelopmentPostcodeField] = useState("")

    const [internalUserNameField, setInternalUserNameField] = useState("")

    const { } = useSelector(state => state.systemMaintenance)

    const [stakeHolder, setStakeHolder] = useState(null);
    // const [currentForm, SetCurrentForm] = useState('User')

    useEffect(() => {
        dispatch(GetAllSurveyor())
        dispatch(GetAllBroker())
        dispatch(GetAllInsurer())
        dispatch(GetAllStakeHolder())
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const advanceSearchHandler = () => {
        const data = {
            selectedUserField,
            surveyorIdField,
            companyNameField,
            contactNameField,
        }
        dispatch(AdvanceSearch({ data, handleClickOpen, history }))
        dispatch(SetShowSingleUnAllocatedDevelopments(true))
        dispatch(SetShowStackholderActivity(false))
        dispatch(SetIsViewDevelopmentDetails(false))
        dispatch(SetSingleUnAllocatedDetailsTitle("Surveyor"))
    }

    const clearAdvanceSearchData = () => {
        setSelectedUserField("")
        setSurveyorIdField("")
        setCompanyNameField("")
        setContactNameField("")
    }

    const advanceSearchInternalUserHandler = () => {
        const data = {
            user_name: internalUserNameField
        }
        //FIXME: Get Info about internal user search
        // dispatch(AdvanceSearchInternalUser({ data, handleClickOpen, history }))
    }

    return (
        <>
            <div className="content">
                <Row className="gx-5">
                    <Col lg="3" sm="12">
                        <Card className="pb-4">
                            <CardHeader className="flex justify-center">
                                <span className="custom-card-header text-xl">
                                    Add New
                                </span>
                            </CardHeader>
                            <CardBody className="flex justify-center ">
                                <div className="flex flex-column flex-1 px-3">
                                    <div
                                        onClick={() => {
                                            sessionStorage.removeItem("currentEditUserId")
                                            // if (currentForm == 'Stakeholder')
                                            //     dispatch(SetCurrentForm('User'))
                                            // else
                                            dispatch(SetCurrentForm('Stakeholder'))
                                            dispatch(EmptyAdvanceSearchResult([]))
                                        }}
                                        className="flex flex-1 justify-center py-2 mt-2 rounded-sm cursor-pointer"
                                        style={{ backgroundColor: currentForm == 'Stakeholder' ? '#87E7F6' : '#DFDFDF' }}
                                    >
                                        <span style={{ color: '#5C5C5C' }}>Stakeholder</span>
                                    </div>
                                    <div
                                        onClick={() => {
                                            sessionStorage.removeItem("currentEditUserId")
                                            // if (currentForm == 'Surveyor')
                                            //     dispatch(SetCurrentForm('User'))
                                            // else
                                            dispatch(SetCurrentForm('Surveyor'))
                                            dispatch(EmptyAdvanceSearchResult([]))
                                        }}
                                        className="flex flex-1 justify-center py-2 mt-2 rounded-sm cursor-pointer"
                                        style={{ backgroundColor: currentForm == 'Surveyor' ? '#87E7F6' : '#DFDFDF' }}
                                    >
                                        <span style={{ color: '#5C5C5C' }}>Surveyor</span>
                                    </div>
                                    <div
                                        onClick={() => {
                                            sessionStorage.removeItem("currentEditUserId")
                                            // if (currentForm == 'Insurer / Broker')
                                            //     dispatch(SetCurrentForm('User'))
                                            // else
                                            dispatch(SetCurrentForm('Insurer / Broker'))
                                            dispatch(EmptyAdvanceSearchResult([]))
                                        }}
                                        className="flex flex-1 justify-center py-2 mt-2 rounded-sm cursor-pointer"
                                        style={{ backgroundColor: currentForm == 'Insurer / Broker' ? '#87E7F6' : '#DFDFDF' }}
                                    >
                                        <span style={{ color: '#5C5C5C' }}>Insurer / Broker</span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="pb-4">
                            <CardHeader className="flex ">
                                <span style={{ color: '#5C5C5C' }} className="text-lg font-bold">
                                    View/Edit Existing
                                </span>
                            </CardHeader>
                            <CardBody className="">
                                <div className="radio-buttons ">
                                    <div className="radio-buttons-and-label ">
                                        <span>
                                            <Input
                                                className="radio-btn"
                                                defaultValue="option1"
                                                id="exampleRadios11"
                                                name="exampleRadioz"
                                                type="radio"
                                                color="red"
                                                value={selectedUserField}
                                                onChange={() => setSelectedUserField("development")}
                                            />
                                        </span>
                                        <span className="radio-label">Development</span>
                                    </div>
                                    <div className="radio-buttons-and-label ">
                                        <span>
                                            <Input
                                                className="radio-btn"
                                                defaultValue="option1"
                                                id="exampleRadios11"
                                                name="exampleRadioz"
                                                type="radio"
                                                color="red"
                                                value={selectedUserField}
                                                onChange={() => setSelectedUserField("stakeholder")}
                                            />
                                        </span>
                                        <span className="radio-label">Stakeholder</span>
                                    </div>
                                    <div className="radio-buttons-and-label ">
                                        <span>
                                            <Input
                                                className="radio-btn"
                                                defaultValue="option1"
                                                id="exampleRadios11"
                                                name="exampleRadioz"
                                                type="radio"
                                                color="red"
                                                value={selectedUserField}
                                                onChange={() => setSelectedUserField("broker")}
                                            />
                                        </span>
                                        <span className="radio-label">Broker</span>
                                    </div>
                                    <div className="radio-buttons-and-label ">
                                        <span>
                                            <Input
                                                className="radio-btn"
                                                defaultValue="option1"
                                                id="exampleRadios11"
                                                name="exampleRadioz"
                                                type="radio"
                                                color="red"
                                                value={selectedUserField}
                                                onChange={() => setSelectedUserField("surveyor")}
                                            />
                                        </span>
                                        <span className="radio-label">Surveyor</span>
                                    </div>
                                    <div className="radio-buttons-and-label ">
                                        <span>
                                            <Input
                                                className="radio-btn"
                                                defaultValue="option1"
                                                id="exampleRadios11"
                                                name="exampleRadioz"
                                                type="radio"
                                                color="red"
                                                value={selectedUserField}
                                                onChange={() => setSelectedUserField("insurer")}
                                            />
                                        </span>
                                        <span className="radio-label">Insurer</span>
                                    </div>
                                </div>
                                {selectedUserField === "surveyor" &&
                                    <>
                                        <label className="custom-input-label">Surveyor</label>
                                        <div className="custom-select-input">
                                            <Select
                                                className="react-select primary "
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                value={surveyorIdField}
                                                onChange={(value) => setSurveyorIdField(value)}
                                                placeholder="Surveyor"
                                                // options={[
                                                //     {
                                                //         value: "",
                                                //         label: "Stakeholder ",
                                                //         isDisabled: true,
                                                //     },
                                                //     { value: "2", label: "Zero Two" },
                                                //     { value: "3", label: "Erza Scarlet" },
                                                // ]}
                                                options={
                                                    allSurveyor?.map(surveyor => {
                                                        const { id, fore_name, sur_name } = surveyor?.primary_surveyor
                                                        return (
                                                            { value: id, label: `${fore_name} ${sur_name}` }
                                                        )
                                                    })
                                                }
                                            />

                                        </div>
                                    </>
                                }
                                {selectedUserField === "development" &&
                                    <>
                                        <label className="custom-input-label">Development</label>
                                        <div className="custom-select-input">
                                            <Select
                                                className="react-select primary "
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                value={stakeHolder}
                                                onChange={(value) => setStakeHolder(value)}
                                                placeholder="Development"
                                                options={[
                                                    {
                                                        value: "",
                                                        label: "Stakeholder ",
                                                        isDisabled: true,
                                                    },
                                                    { value: "2", label: "Zero Two" },
                                                    { value: "3", label: "Erza Scarlet" },
                                                ]}
                                            />

                                        </div>
                                    </>
                                }
                                {selectedUserField === "insurer" &&
                                    <>
                                        <label className="custom-input-label">Insurer</label>
                                        <div className="custom-select-input">
                                            <Select
                                                className="react-select primary "
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                // value={stakeHolder}
                                                // onChange={(value) => setStakeHolder(value)}
                                                placeholder="Insurer"
                                                value={surveyorIdField}
                                                onChange={(value) => setSurveyorIdField(value)}
                                                options={
                                                    allInsurer?.map(insurer => {
                                                        const { id, fore_name, sur_name } = insurer?.insurer_type
                                                        return (
                                                            { value: id, label: `${fore_name} ${sur_name}` }
                                                        )
                                                    })
                                                }
                                            />

                                        </div>
                                    </>
                                }
                                {selectedUserField === "broker" &&
                                    <>
                                        <label className="custom-input-label">Broker</label>
                                        <div className="custom-select-input">
                                            <Select
                                                className="react-select primary "
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                // value={stakeHolder}
                                                // onChange={(value) => setStakeHolder(value)}
                                                value={surveyorIdField}
                                                onChange={(value) => setSurveyorIdField(value)}
                                                placeholder="Broker"
                                                options={
                                                    allBroker?.map(broker => {
                                                        const { id, fore_name, sur_name } = broker?.broker_type
                                                        return (
                                                            { value: id, label: `${fore_name} ${sur_name}` }
                                                        )
                                                    })
                                                }
                                            />

                                        </div>
                                    </>
                                }
                                {selectedUserField === "stakeholder" &&
                                    <>
                                        <label className="custom-input-label">Stakeholder</label>
                                        <div className="custom-select-input">
                                            <Select
                                                className="react-select primary "
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                // value={stakeHolder}
                                                // onChange={(value) => setStakeHolder(value)}
                                                value={surveyorIdField}
                                                onChange={(value) => setSurveyorIdField(value)}
                                                placeholder="Stakeholder"
                                                options={
                                                    allStakeHolder?.map(stakeHolder => {
                                                        const { id, fore_name, sur_name } = stakeHolder?.primary_stake_holder
                                                        return (
                                                            { value: id, label: `${fore_name} ${sur_name}` }
                                                        )
                                                    })
                                                }
                                            />

                                        </div>
                                    </>
                                }
                                <label className="custom-input-label">Company Name</label>
                                <FormGroup>
                                    <Input className="custom-input" placeholder="Company Name" type="text" value={companyNameField} onChange={(e) => setCompanyNameField(e.target.value)} />
                                </FormGroup>
                                <label className="custom-input-label">Contact Name</label>
                                <FormGroup>
                                    <Input className="custom-input" placeholder="Contact Name" type="text" value={contactNameField} onChange={(e) => setContactNameField(e.target.value)} />
                                </FormGroup>
                                <label className="custom-input-label">Development Postcode</label>
                                <FormGroup>
                                    <Input className="custom-input" placeholder="Development Postcode" type="text" value={developmentPostcodeField} onChange={(e) => setDevelopmentPostcodeField(e.target.value)} />
                                </FormGroup>
                                <label className="custom-input-label">Development Name</label>
                                <FormGroup>
                                    <Input className="custom-input" placeholder="Development Name" type="text" value={developmentNameField} onChange={(e) => setDevelopmentNameField(e.target.value)} />
                                </FormGroup>
                                <div className="search-clear-btns-div ">
                                    <Button className="px-3" size="sm" style={{ background: "#51BCDA" }} color="info" onClick={advanceSearchHandler}>
                                        Search
                                    </Button>
                                    <Button className="px-3" size="sm" color="info" style={{ background: "#51BCDA" }} onClick={clearAdvanceSearchData}>
                                        Clear
                                    </Button>
                                </div>
                                <label className="custom-input-label">Policy Number</label>
                                <FormGroup>
                                    <Input className="custom-input" placeholder="Policy Number" type="text" />
                                </FormGroup>
                                <div className="search-clear-btns-div ">
                                    <Button className="px-3" size="sm" color="info" onClick={null}>
                                        Search
                                    </Button>
                                    <Button className="px-3" size="sm" color="info" onClick={null}>
                                        Clear
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="pb-4">
                            <CardHeader className="flex ">
                                <span style={{ color: '#5C5C5C' }} className="text-lg font-bold">
                                    View/Edit Existing
                                </span>
                            </CardHeader>
                            <CardBody className="">
                                <label className="custom-input-label">User Name</label>
                                <FormGroup>
                                    <Input className="custom-input" placeholder="Enter Name" type="text" value={internalUserNameField} onChange={e => setInternalUserNameField(e.target.value)} />
                                </FormGroup>
                                <div className="search-clear-btns-div ">
                                    <Button size="sm" style={{ background: "#51BCDA" }} color="info" onClick={() => {
                                        sessionStorage.removeItem("currentEditUserId")
                                        // if (currentForm == 'Add New User')
                                        dispatch(SetCurrentForm('User'))
                                        dispatch(EmptyAdvanceSearchResult([]))
                                        // else
                                        //     dispatch(SetCurrentForm('Add New User'))
                                    }}>
                                        Add New User
                                    </Button>
                                    <Button size="sm" color="info" style={{ background: "#51BCDA" }} onClick={advanceSearchInternalUserHandler}>
                                        Search
                                    </Button>
                                    <Button size="sm" color="info" onClick={null}>
                                        Clear
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {currentForm == 'User' &&
                        <AddNewUser />
                    }
                    {currentForm == 'Stakeholder' &&
                        <AddNewStakeHolder />
                    }
                    {currentForm == 'Surveyor' &&
                        <AddNewSurveyor />
                    }
                    {currentForm == 'Insurer / Broker' &&
                        <AddNewInsurerOrBroker />
                    }
                    <SimpleDialog
                        // selectedValue={selectedValue}
                        open={open}
                        onClose={handleClose}
                    />
                    {/* <SimpleDialogDemo /> */}
                </Row>
            </div>
        </>
    );
}

export default SystemMaintenance;
