import HeightIcon from '@mui/icons-material/Height';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch
} from "@mui/material";
import { blue } from '@mui/material/colors';
import moment from "moment";
import PerfectScrollbar from "perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Modal,
  Progress,
  Row,
  Table
} from "reactstrap";
import {
  CreateDevelopmentAssignToSurveyor,
  GetContractorPrimaryContact,
  GetDevelopmentBuildFactorDetails,
  GetDevelopmentGeneralDetails,
  GetDevelopmentRefurbishmentDetails,
  GetDevelopments,
  GetDevelopmentSiteInvestigationDetails,
  GetDevelopmentSurveyRequiremntDetails,
  GetSingleSurveyorDetails
} from "../AddNewDevelopment/Store/AddNewDevelopmentApi";
import { ChangeDevelopmentMode } from "../AddNewDevelopment/Store/AddNewDevelopmentSlice";
import { GetAllSurveyor } from "../AddNewSurveyor/Store/AddNewSurveyorApi";
import {
  GetContratorDetailForDevelopmentActivity,
  GetUserDetailForTopLeftCard
} from "../Auth/Store/authSlice";
import DefectDetail from "../DefectDetail/DefectDetail";
import { GetSingleDefectDetail } from "../DefectDetail/Store/DefectDetailApi";
import AddDiary from "../Diaries/AddDiary/AddDiary";
import AdministrationHistoryData from "../Diaries/AdministrationHistory/AdministrationHistoryData/AdministrationHistoryData";
import {
  GetAdminDevelopments,
  GetAdmisistrationHistoryToFieldDataForSurveyor,
  GetDiaries,
  GetLoginUserDiaries,
  GetNotes,
  GetUsersOfDevelopments
} from "../Diaries/AdministrationHistory/Store/AdministrationHistoryApi";
import { ChangeAllDiaries } from "../Diaries/AdministrationHistory/Store/AdministrationHistorySlice";
import AdministrationHistoryAddDefect from "../Diaries/AdministrationHistoryAddDefect/AdministrationHistoryAddDefect";
import AdministrationHistoryAddDiary from "../Diaries/AdministrationHistoryAddDiary/AdministrationHistoryAddDiary";
import AdministrationHistoryAddNote from "../Diaries/AdministrationHistoryAddNote/AdministrationHistoryAddNote";
import AdministrationHistoryMakeRequest from "../Diaries/AdministrationHistoryMakeRequest/AdministrationHistoryMakeRequest";
import AdministrationHistoryUploadDocument from "../Diaries/AdministrationHistoryUploadDocument/AdministrationHistoryUploadDocument";
import AllDiaries from "../Diaries/AllDiaries/AllDiaries";
import AllocateDevelopment from "../Diaries/AllocateDevelopment/AllocateDevelopment";
import MakeARequest from "../Diaries/MakeARequest/MakeARequest";
import { GetMakeRequests } from "../ExternalUsers/Store/ExternalUsersApi";
import { ChangeCurrentSelectedAcceptedDevelopment } from "../ExternalUsers/Store/ExternalUsersSlice";
import LoginUserDiaries from "../LoginUserDiaries/LoginUserDiaries";
import {
  ChangeAddDiaryMode,
  ChangeAddNoteMode,
  ChangeMakeRequestMode,
  ChangeUploadDocumentMode,
  PushJumps,
  SetCurrentForm,
  ToggleModalAdministrationHistoryAddDiary,
  ToggleModalAdministrationHistoryAddNote,
  ToggleModalAdministrationHistoryMakeRequest,
  ToggleModalAdministrationHistoryUploadDocument
} from "../Shared/store/sharedSlice";
import {
  SetSingleUnAllocatedDetailsTitle,
  SetIsViewDevelopmentDetails,
  SetShowStackholderActivity,
  SetShowSingleUnAllocatedDevelopments,
  SetCurrentDashboard,
  SetShowDefectDetail,
  SetShowDefectDetailFullWidth,
  SetShowAllDefectsOnTopSide,
  SetHideAllDefects,
} from '../Shared/store/pageSlice'
import { EmptyUserDetailForTopLeftCard } from "../Shared/store/userDataSlice";
import './Dashboard.css';
import {
  AdvanceSearch,
  CreateProgressBarOfSingleDevelopment,
  GetAdminSingleDevelopmentDetail,
  GetAdmisistrationHistoryDataForAdmin,
  GetDefectsOfSingleDevelopment,
  GetUserHistory
} from "./Store/DashboardApi";
import {
  AlterAdvanceSearchResult,
  EmptyAdminSingleDevelopmentDetail,
  EmptyAdvanceSearchResult
} from "./Store/dashboardSlice";

var psTable;

function ReAssignSurveyorDialog(props) {
  const history = useHistory()
  const dispatch = useDispatch();
  const {
    onClose,
    selectedValue,
    open,
    devId
  } = props;
  const [selectedSurveyor, setSelectedSurveyor] = useState([])
  const [dueDate, setDueDate] = useState([])
  const { allSurveyor } = useSelector(state => state?.AddNewSurveyor)

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const assignToSurveyor = () => {
    const data = {
      generalDetailsId: devId,
      surveyorId: selectedSurveyor,
      dueDate: dueDate,
    }
    console.log("🚀 ~ file: SurveyRequirements.js ~ line 52 ~ assignToSurveyor ~ data", data)

    dispatch(CreateDevelopmentAssignToSurveyor({ data: data, clearForm: clearFormFields }))
  }

  const clearFormFields = () => {
    setSelectedSurveyor([])
    setDueDate([])
    onClose()
  }

  useEffect(() => {

    const today = new Date()
    let tomorrow = new Date()
    setDueDate(new Date(tomorrow.setDate(today.getDate() + 1)))

    dispatch(GetAllSurveyor())
  }, [])

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select Surveyor</DialogTitle>
      <DialogContent>
        <Col className="mt-4 p-0" lg="12" sm="12" style={{ width: "400px" }}>
          <FormControl fullWidth sx={{}}>
            <InputLabel size="small" id="demo-multiple-checkbox-label">Surveyors</InputLabel>
            <Select
              size="small"
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              // multiple
              value={selectedSurveyor}
              onChange={e => setSelectedSurveyor(e.target.value)}

              input={<OutlinedInput label="Surveyors" />}
              MenuProps={MenuProps}
            >
              {allSurveyor?.length > 0 && allSurveyor?.map((surveyor) => (
                <MenuItem value={surveyor?.surveyor_id}>{surveyor?.trading_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <Col className="mt-4 p-0" lg="12" sm="12" style={{ width: "400px" }}>
          <FormGroup>
            <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Due Date</label>
            <Input className="custom-input" placeholder="" type="date" value={dueDate} onChange={e => setDueDate(e.target.value)} />
          </FormGroup>
        </Col>
      </DialogContent>
      <DialogActions>
        <Button className='mr-3' onClick={assignToSurveyor} style={{ backgroundColor: '#2BA9CD' }}
          color="info" size="sm">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

function Dashboard() {

  const scrollDiv1 = useRef(null);
  const scrollDiv2 = useRef(null);
  const scrollDiv3 = useRef(null);
  const scrollDiv4 = useRef(null);
  const scrollDiv5 = useRef(null);
  const scrollDiv6 = useRef(null);
  const scrollDiv7 = useRef(null);
  const scrollDiv8 = useRef(null);
  const scrollDiv9 = useRef(null);
  const scrollDiv10 = useRef(null);

  const history = useHistory()
  const dispatch = useDispatch()

  const [openReAssignSurveyorDialog, setOpenReAssignSurveyorDialog] = useState(false);
  const [openReAssignSurveyorDialogDevId, setOpenReAssignSurveyorDialogDevId] = useState(null);

  const {
    allDevelopments
  } = useSelector(state => state.AddNewDevelopment)
  const {
    allNotes,
    adminDevelopments
  } = useSelector(state => state.AdministrationHistory)
  const {
    modalAdministrationHistoryAddDiary,
    modalAdministrationHistoryAddNote,
    modalAdministrationHistoryMakeRequest,
    modalAdministrationHistoryUploadDocument,
  } = useSelector(state => state.shared)
  const {

    present: {
      showSingleUnAllocatedDevelopments,
      showStackholderActivity,
      isViewDevelopmentDetails,
      singleUnAllocatedDetailsTitle,
      showSingleUser,
      showDefectDetail,
      showDefectDetailFullWidth,
      showAllDefectsOnTopSide,
      hideAllDefects,
      singleDevelopmentDefects
    },
    past,
  } = useSelector(state => state.page)
  const {
    contractorDetailForDevelopmentActivity,
    userDetailForTopLeftCard
  } = useSelector(state => state?.userData)
  const {
    unAllocatedDevelopments,
    surveyRequests,
    userHistory,
    developments,
    developmentNotifications,
    notes,
    userDevelopments,
    defects,
    advanceSearchResult,
    administrationHistoryDataForAdmin,
    adminSingleDevelopmentDetail,
    defectsOfSingleDevelopment,
    getUserHistory
  } = useSelector(state => state.dashboard)
  const {
    allMakeRequests,
    currentSelectedAcceptedDevelopment
  } = useSelector(state => state?.ExternalUsers)

  const [locationKeys, setLocationKeys] = useState([])

  const [developer, setDeveloper] = useState(null);
  const [surveyor, setSurveyor] = useState(null);
  const [modalAddDiary, setModalAddDiary] = useState(false)
  const [modalAllocateDevelopment, setModalAllocateDevelopment] = useState(false)
  const [modalMakeARequest, setModalMakeARequest] = useState(false)
  const [modalAdministrationHistoryAddDefect, setModalAdministrationHistoryAddDefect] = useState(false)

  const [selectedUserField, setSelectedUserField] = useState("all")
  const [companyNameField, setCompanyNameField] = useState("")
  const [contactNameField, setContactNameField] = useState("")
  const [developmentNameField, setDevelopmentNameField] = useState("")
  const [developmentPostcodeField, setDevelopmentPostcodeField] = useState("")

  const [showCompletedEntries, setShowCompletedEntries] = useState(false)

  const toggleModalAddDiary = () => {
    setModalAddDiary(!modalAddDiary);
  };
  const toggleModalAdministrationHistoryAddDiary = (value) => {
    dispatch(ToggleModalAdministrationHistoryAddDiary(value))
  };
  const toggleModalAdministrationHistoryAddNote = (value) => {
    dispatch(ToggleModalAdministrationHistoryAddNote(value))
  };
  const toggleModalAdministrationHistoryMakeRequest = (value) => {
    dispatch(ToggleModalAdministrationHistoryMakeRequest(value))
  };
  const toggleModalAdministrationHistoryAddDefect = () => {
    setModalAdministrationHistoryAddDefect(!modalAdministrationHistoryAddDefect);
  };
  const toggleModalAdministrationHistoryUploadDocument = (value) => {
    dispatch(ToggleModalAdministrationHistoryUploadDocument(value))
  };
  const toggleModalAllocateDevelopment = () => {
    setModalAllocateDevelopment(!modalAllocateDevelopment);
  };
  const toggleModalMakeARequest = () => {
    setModalMakeARequest(!modalMakeARequest);
  };

  const reAssignSurveyorDialogHandleClose = () => {
    setOpenReAssignSurveyorDialog(false);
  };

  const reAssignSurveyorDialogHandleOpen = () => {
    setOpenReAssignSurveyorDialog(true);
  };

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  }

  function RenderUnAllocatedDevelopments(unAllocatedDevelopmentItems) {
    return unAllocatedDevelopmentItems?.length > 0 && unAllocatedDevelopmentItems?.map((item, key) => {
      const { srNo: id, surveyor, development, dateSubmitted, status, surveyorId } = item
      console.log("🚀 ~ file: Dashboard.js ~ line 168 ~ returnunAllocatedDevelopmentItems?.map ~ surveyorId", surveyorId)
      console.log("🚀 ~ file: Dashboard.js ~ line 168 ~ returnunAllocatedDevelopmentItems?.map ~ item", item)
      const data = {
        "selectedUserField": "surveyor",
        "surveyorIdField": { "value": surveyorId },
      }
      return (
        <tr
          key={id}
        >
          <td className="clickable-col clickable-underline"
            onClick={() => {

              dispatch(PushJumps({
                size: 5,
                goBack: false,
              }))

              dispatch(SetShowSingleUnAllocatedDevelopments(true));
              dispatch(SetShowStackholderActivity(false));
              dispatch(SetIsViewDevelopmentDetails(false));
              dispatch(SetSingleUnAllocatedDetailsTitle("Surveyor"));
              dispatch(GetSingleSurveyorDetails(surveyorId));
              dispatch(SetCurrentDashboard("AWS Surveyor Dashboard"))
            }}
          >
            {surveyor}
          </td>
          <td
            className="clickable-col clickable-underline"
            onClick={() => {

              dispatch(PushJumps({
                size: 5,
                goBack: false,
              }))

              dispatch(SetShowSingleUnAllocatedDevelopments(true));
              dispatch(SetShowStackholderActivity(true));
              dispatch(SetIsViewDevelopmentDetails(true));
              dispatch(SetSingleUnAllocatedDetailsTitle("Development"));
              dispatch(SetCurrentDashboard("AWS Development Dashboard"));

              dispatch(ChangeCurrentSelectedAcceptedDevelopment({ ...item, id: id }));

              dispatch(GetAdminSingleDevelopmentDetail(id));
              dispatch(GetDiaries(id));
              dispatch(GetNotes(id));
              dispatch(GetAdmisistrationHistoryDataForAdmin(id));
            }}
          >
            {development}
          </td>
          <td className="">{moment(dateSubmitted).format("DD/MM/YY")}</td>
          <td className="">
            {status == "Pending" && status}
            {status == "Outstanding" && <span style={{ color: "red" }}>{status}</span>}
            {status == "Outstanding" && <Button
              style={{ backgroundColor: '#2BA9CD', fontSize: "10px" }}
              onClick={() => { setOpenReAssignSurveyorDialogDevId(id); reAssignSurveyorDialogHandleOpen() }}
              color="info" size="sm"
            >
              Re assign
            </Button>}
            {status == "Pending" && <Button
              style={{ backgroundColor: '#2BA9CD', fontSize: "10px" }}
              onClick={() => { setOpenReAssignSurveyorDialogDevId(id); reAssignSurveyorDialogHandleOpen() }}
              color="info" size="sm"
            >
              Re assign
            </Button>}
          </td>
        </tr>
      )
    })
  }
  function RenderSurveyRequests(surveyRequestItems) {
    return surveyRequestItems?.length > 0 && surveyRequestItems?.map((item, key) => {
      const { id, category, user, due_date } = item
      return (
        <tr key={id}>
          <td>{category?.category_name}</td>
          <td
            className="clickable-col clickable-underline"
            onClick={() => {
              dispatch(PushJumps({
                size: 5,
                goBack: false,
              }))

              dispatch(SetShowSingleUnAllocatedDevelopments(true))
              dispatch(SetShowStackholderActivity(false))
              dispatch(SetIsViewDevelopmentDetails(false))
              dispatch(SetSingleUnAllocatedDetailsTitle("Surveyor"))
              dispatch(SetCurrentDashboard("AWS Surveyor Dashboard"))
            }}
          >
            {user?.username}
          </td>
          <td className="">{moment(due_date).format("DD/MM/YY")}</td>
        </tr>
      )
    })
  }
  function RenderUserHistory(userHistoryItems) {
    return userHistoryItems?.length > 0 && userHistoryItems?.map((item, key) => {
      const { id, log_name, description, created_at, properties } = item
      // const { username } = causer
      return (
        <tr
          className="clickable-row"
          key={id}
        >
          <td width={60}>{properties?.type}</td>
          <td className=""
            onClick={() => {
              username === "Developer" ?
                (
                  dispatch(SetShowSingleUnAllocatedDevelopments(true)),
                  dispatch(SetShowStackholderActivity(true)),
                  dispatch(SetIsViewDevelopmentDetails(true)),
                  dispatch(SetSingleUnAllocatedDetailsTitle("Development")),
                  dispatch(SetCurrentDashboard("AWS Development Dashboard"))
                )
                :
                username === "Surveyor" ?
                  (
                    dispatch(SetShowSingleUnAllocatedDevelopments(true)),
                    dispatch(SetShowStackholderActivity(false)),
                    dispatch(SetIsViewDevelopmentDetails(false)),
                    dispatch(SetSingleUnAllocatedDetailsTitle("Surveyor")),
                    dispatch(SetCurrentDashboard("AWS Surveyor Dashboard"))
                  )
                  :
                  null
            }}
          >{log_name}</td>
          <td style={{ fontSize: "10px" }} className="">{moment(created_at).format("DD/MM/YY h:mm")}</td>
        </tr>
      )
    })
  }

  function RenderDevelopments(DevelopmentItems) {
    console.log("🚀 ~ file: Dashboard.js ~ line 369 ~ RenderDevelopments ~ DevelopmentItems", DevelopmentItems)
    return DevelopmentItems?.length > 0 && DevelopmentItems?.map((item, key) => {
      const { id, development_name, main_contractor, expectedCompletionDate, stages, surveyor, nextSurveyDate, actionNotified, defect, total_notification } = item
      return (
        <tr
          key={id}
        >
          <td
            className="clickable-col clickable-underline"
            onClick={async () => {

              dispatch(PushJumps({
                size: 5,
                goBack: false,
              }))
              dispatch(SetShowSingleUnAllocatedDevelopments(true))
              dispatch(SetIsViewDevelopmentDetails(true))
              dispatch(SetShowStackholderActivity(true))
              dispatch(SetSingleUnAllocatedDetailsTitle("Development"))
              dispatch(SetCurrentDashboard("AWS Development Dashboard"))

              dispatch(ChangeCurrentSelectedAcceptedDevelopment(item))
              dispatch(GetAdminSingleDevelopmentDetail(id))
              dispatch(GetAdmisistrationHistoryDataForAdmin(id))
              dispatch(GetNotes(id))
              dispatch(GetDefectsOfSingleDevelopment(id))
              dispatch(GetDiaries(id))
            }}
          >
            {development_name}
          </td>
          <td className="clickable-col clickable-underline" onClick={() => {

            dispatch(PushJumps({
              size: 5,
              goBack: false,
            }))

            dispatch(SetShowSingleUnAllocatedDevelopments(true))
            dispatch(SetShowStackholderActivity(false))
            dispatch(SetIsViewDevelopmentDetails(false))
            dispatch(SetSingleUnAllocatedDetailsTitle("Contractor"))
            dispatch(SetCurrentDashboard("AWS Contractor Dashboard"))

            dispatch(GetContractorPrimaryContact(main_contractor?.id))
            dispatch(EmptyAdminSingleDevelopmentDetail(null))
            dispatch(EmptyAdvanceSearchResult([]))
            dispatch(GetContratorDetailForDevelopmentActivity(main_contractor?.id))
            dispatch(GetUserDetailForTopLeftCard(main_contractor?.id))
          }}
          >
            {main_contractor?.username}
          </td>
          {/* <td>{moment(expectedCompletionDate).format("DD/MM/YY")}</td> */}
          <td>00/00/0000</td>
          <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetShowStackholderActivity(false)); dispatch(SetIsViewDevelopmentDetails(false)); dispatch(SetSingleUnAllocatedDetailsTitle("Surveyor")); dispatch(SetCurrentDashboard("AWS Surveyor Dashboard")) }}>{surveyor?.username}</td>
          {/* <td>{moment(nextSurveyDate).format("DD/MM/YY")}</td> */}
          <td>00/00/0000</td>
          <td className="w-13">
            <div style={{ position: "relative" }}>
              <Progress className="custom-progress mb-1" color="info" value={stages[0]?.stage_percentage1} />
              <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>1</span>
            </div>
            <div style={{ position: "relative" }}>
              <Progress className="custom-progress mb-1" color="info" value={stages[0]?.stage_percentage2} />
              <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>2</span>
            </div>
            <div style={{ position: "relative" }}>
              <Progress className="custom-progress mb-1" color="info" value={stages[0]?.stage_percentage3} />
              <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>3</span>
            </div>
            <div style={{ position: "relative" }}>
              <Progress className="custom-progress mb-1" color="info" value={stages[0]?.stage_percentage4} />
              <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>4</span>
            </div>
            <div style={{ position: "relative" }}>
              <Progress className="custom-progress" color="info" value={stages[0]?.stage_percentage5} />
              <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>5</span>
            </div>
          </td>
          {/* <td>{moment(actionNotified).format("DD/MM/YY")}</td> */}
          <td>00/00/0000</td>
          <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetShowStackholderActivity(true)) }}>{defect[0]?.count}</td>
          <td className="clickable-col clickable-underline" onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(true)); dispatch(SetShowStackholderActivity(true)) }}>{total_notification[0]?.count}</td>
        </tr>
      )
    })
  }
  function RenderDevelopmentNotifications(notificationItems) {
    return notificationItems?.length > 0 && notificationItems?.map((item, key) => {
      const { id, defect_action, notified, category, sub_category, description, block, unit, risk, photoVideoAvailable, multiple_file, beingActions, isRemedialWorkComplete, } = item
      return (
        <tr
          key={id}
          className="cursor-pointer"
          onClick={() => {

            dispatch(PushJumps({
              size: 2,
              goBack: false,
            }))

            dispatch(SetShowDefectDetail(true))
            dispatch(SetShowDefectDetailFullWidth(false))

            dispatch(GetSingleDefectDetail(id))
          }}
        >
          <td>{defect_action == "1" ? "Defect" : "Action"}</td>
          <td>{moment(notified).format("DD/MM/YY")}</td>
          <td>{category?.category_name}</td>
          <td>{sub_category?.sub_category_name}</td>
          <td>{description}</td>
          <td>{block}/{unit}/{id}</td>
          <td>{risk == "1" ? "High" : risk == "2" ? "Medium" : "Low"}</td>
          {/* <td>{photoVideoAvailable != null ? "Yes" : "No"}</td> */}
          <td>{multiple_file?.length > 0 ? "Yes" : "No"}</td>
          <td>{beingActions}</td>
          <td>{isRemedialWorkComplete}</td>
        </tr>
      )
    })
  }
  function RenderDefects(isOnTopSide) {
    return defects?.length > 0 && defects.map((item, key) => {
      const { id, defectsAction, notified, stage, sectionWithStage, description, unitBlock, priority, photoVideoAvailable, beingActioned, remediaWorkCompleted, } = item
      return (
        <tr
          key={id}
          className="clickable-row"
          onClick={null}
        // onClick={() => dispatch(setShowDefectDetail(true))}
        >
          <td><input type="checkbox" /></td>
          <td onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{defectsAction}</td>
          <td onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{moment(notified).format("DD/MM/YY")}</td>
          <td onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{stage}</td>
          <td style={{ width: "110px" }} onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{sectionWithStage}</td>
          <td style={{ width: "110px" }} onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{description}</td>
          <td onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{unitBlock}</td>
          <td onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{priority}</td>
          <td onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{photoVideoAvailable}</td>
          <td onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{beingActioned}</td>
          <td onClick={() => { dispatch(SetShowDefectDetail(true)); dispatch(setShowDefectDetailFullWidth(true)); SetShowAllDefectsOnTopSide(isOnTopSide) }}>{remediaWorkCompleted}</td>
        </tr>
      )
    })
  }
  function RenderNotes(notesItems) {
    return notesItems?.length > 0 ? notesItems?.map((item, key) => {
      const { id, category, user, created_at } = item
      return (
        <tr
          key={id}
        >
          <td>{id}</td>
          <td>{category?.category_name}</td>
          <td>{user?.username}</td>
          <td>{moment(created_at).format("DD/MM/YY")}</td>
        </tr>
      )
    })
      :
      []
  }

  const [modalSingleDevelopmentProgressBar, setSingleDevelopmentProgressBar] = useState(false);

  const toggleModalSingleDevelopmentProgressBar = () => {
    setSingleDevelopmentProgressBar(!modalSingleDevelopmentProgressBar);
  }

  const SingleDevelopmentProgressBar = ({ toggleModalFunction }) => {
    const dispatch = useDispatch()

    const [stageOneField, setStageOneField] = useState(adminSingleDevelopmentDetail?.stages[0]?.stage_percentage1 || 0)
    const [stageTwoField, setStageTwoField] = useState(adminSingleDevelopmentDetail?.stages[0]?.stage_percentage2 || 0)
    const [stageThreeField, setStageThreeField] = useState(adminSingleDevelopmentDetail?.stages[0]?.stage_percentage3 || 0)
    const [stageFourField, setStageFourField] = useState(adminSingleDevelopmentDetail?.stages[0]?.stage_percentage4 || 0)
    const [stageFiveField, setStageFiveField] = useState(adminSingleDevelopmentDetail?.stages[0]?.stage_percentage5 || 0)

    const addCategoryHandler = () => {
      const data = {
        development_id: adminSingleDevelopmentDetail?.id,
        stage_percentage1: stageOneField,
        stage_percentage2: stageTwoField,
        stage_percentage3: stageThreeField,
        stage_percentage4: stageFourField,
        stage_percentage5: stageFiveField
      }
      console.log("🚀 ~ file: Dashboard.js ~ line 340 ~ addCategoryHandler ~ data", data)

      dispatch(CreateProgressBarOfSingleDevelopment(data))
      toggleModalFunction()
      // setCategoryNameField("")
      // setAssignToField(null)
    }

    return (
      <>
        <div className="modal-header">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalFunction}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h5 className="modal-title text-left" id="myModalLabel">
            Edit Development Progress Bar Stages
          </h5>
        </div>
        <div className="modal-body">
          <div className="add-diary-form">
            <Row>
              <Col md="6">
                <FormGroup>
                  <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Stage 1</label>
                  <Input className="custom-input" placeholder="" type="string" value={stageOneField} onChange={e => setStageOneField(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Stage 2</label>
                  <Input className="custom-input" placeholder="" type="string" value={stageTwoField} onChange={e => setStageTwoField(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Stage 3</label>
                  <Input className="custom-input" placeholder="" type="string" value={stageThreeField} onChange={e => setStageThreeField(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Stage 4</label>
                  <Input className="custom-input" placeholder="" type="string" value={stageFourField} onChange={e => setStageFourField(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Stage 5</label>
                  <Input className="custom-input" placeholder="" type="string" value={stageFiveField} onChange={e => setStageFiveField(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
              <Button className='btn-info' onClick={addCategoryHandler} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
            </div>
          </div>
        </div>
      </>
    )
  }

  function RenderUserDevelopments() {
    return userDevelopments?.length > 0 && userDevelopments?.map((item, key) => {
      const { srNo, development, postCode, completed } = item
      return (
        <tr
          key={srNo}
        >
          <td>{srNo}</td>
          <td>{development}</td>
          <td>{postCode}</td>
          <td className="date-time-row ">
            <div>
              {moment(completed).format("h:mm a")}
            </div>
            <div>
              {moment(completed).format("DD/MM/YY")}
            </div>
          </td>
        </tr>
      )
    })
  }
  function AllDefects({ isOnTopSide, isFullHeight, style }) {
    return (
      // <div>
      <Card className="scroll-card" style={style}>
        <div ref={scrollDiv8}>

          <CardHeader>
            <span className="custom-card-header">
              Lorem Care Homes / Developers Ltd/ Surveyors (List of all Defects)
            </span>
          </CardHeader>
          <CardBody>
            <Table
              // responsive
              className="custom-table"
            >
              <thead>
                <tr>
                  <th style={{ padding: "0 4px" }} ></th>
                  <th style={{ padding: "0 4px" }} >Defects / Notifications</th>
                  <th style={{ padding: "0 4px" }} >Notified</th>
                  <th style={{ padding: "0 4px" }} >Stage</th>
                  <th style={{ padding: "0 4px" }} >Section With Stage</th>
                  <th style={{ padding: "0 4px" }} >Description</th>
                  <th style={{ padding: "0 4px" }} >Block/Unit</th>
                  <th style={{ padding: "0 4px" }} >Prirority</th>
                  <th style={{ padding: "0 4px" }} >Photo / Video Available</th>
                  <th style={{ padding: "0 4px" }} >Actioned in 30 days</th>
                  <th style={{ padding: "0 4px" }} >Remedial Work Complete?</th>
                </tr>
              </thead>
              <tbody>
                {RenderDefects(isOnTopSide)}
              </tbody>
            </Table>
          </CardBody>
        </div>
      </Card>
      // </div>
    )
  }

  const { requestSort: unAllocatedDevelopmentRequestSort } = useSortableData(unAllocatedDevelopments);
  const { requestSort: surveyRequestRequestSort } = useSortableData(surveyRequests);
  const { requestSort: DevelopmentRequestSort } = useSortableData(developments);
  const { requestSort: userHistoryRequestSort } = useSortableData(userHistory);
  const { requestSort: notesRequestSort } = useSortableData(notes);
  const { requestSort: developmentActivityRequestSort } = useSortableData(notes);
  const { requestSort: notificationRequestSort } = useSortableData(developmentNotifications);

  const EditUserHandler = () => {
    console.log("🚀 ~ file: Dashboard.js ~ line 377 ~ EditUserHandler ~ advanceSearchResult", advanceSearchResult)
    if (advanceSearchResult[0]?.roles[0]?.name == "Surveyor") {
      history.push("/admin/system-maintenance")
      dispatch(SetCurrentForm('Surveyor'))
      window.sessionStorage.setItem("currentEditUserId", advanceSearchResult[0]?.id)
    }
    else if (advanceSearchResult[0]?.roles[0]?.name == "Insurer") {
      history.push("/admin/system-maintenance")
      dispatch(SetCurrentForm('Insurer / Broker'))
      window.sessionStorage.setItem("currentEditUserId", advanceSearchResult[0]?.id)
    }
    else if (advanceSearchResult[0]?.roles[0]?.name == "Broker") {
      history.push("/admin/system-maintenance")
      dispatch(SetCurrentForm('Insurer / Broker'))
      window.sessionStorage.setItem("currentEditUserId", advanceSearchResult[0]?.id)
    }
    else if (advanceSearchResult[0]?.roles[0]?.name == "Stakeholder" || advanceSearchResult[0]?.roles[0]?.name == "Contractor" || advanceSearchResult[0]?.roles[0]?.name == "Sub Contractor" || advanceSearchResult[0]?.roles[0]?.name == "Developer" || advanceSearchResult[0]?.roles[0]?.name == "Financial Interest") {
      history.push("/admin/system-maintenance")
      dispatch(SetCurrentForm('Stakeholder'))
      window.sessionStorage.setItem("currentEditUserId", advanceSearchResult[0]?.id)
    }

  }

  const EditSurveyorDetail = () => {
    dispatch(SetCurrentForm('Stakeholder'))
    sessionStorage.setItem("currentEditUserId", contractorDetailForDevelopmentActivity?.id)
    history.push("/admin/system-maintenance")
  }

  function SimpleDialog(props) {
    const history = useHistory()
    const dispatch = useDispatch();
    const { onClose, selectedValue, open } = props;
    const { advanceSearchResult } = useSelector(state => state.dashboard)

    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleListItemClick = (objId, id, role, roleForAll) => {
      // onClose(id);
      dispatch(AlterAdvanceSearchResult({ objId, selectedUserField }))
      if (role == "surveyor") {
        dispatch(SetCurrentForm('Surveyor'))
        sessionStorage.setItem("currentEditUserId", id)
      }
      if (role == "insurer") {
        dispatch(SetCurrentForm('Insurer / Broker'))
        sessionStorage.setItem("currentEditUserId", id)
      }
      if (role == "broker") {
        dispatch(SetCurrentForm('Insurer / Broker'))
        sessionStorage.setItem("currentEditUserId", id)
      }
      if (role == "stakeholder") {
        dispatch(SetCurrentForm('Stakeholder'))
        sessionStorage.setItem("currentEditUserId", id)
      }
      if (role == "all") {
        if (roleForAll == "surveyor") {
          dispatch(SetCurrentForm('Surveyor'))
          sessionStorage.setItem("currentEditUserId", id)
        }
        if (roleForAll == "insurer") {
          dispatch(SetCurrentForm('Insurer / Broker'))
          sessionStorage.setItem("currentEditUserId", id)
        }
        if (roleForAll == "broker") {
          dispatch(SetCurrentForm('Insurer / Broker'))
          sessionStorage.setItem("currentEditUserId", id)
        }
        if (roleForAll == "stakeholder") {
          dispatch(SetCurrentForm('Stakeholder'))
          sessionStorage.setItem("currentEditUserId", id)
        }
        if (roleForAll == "User") {
          dispatch(SetCurrentForm('User'))
          sessionStorage.setItem("currentEditUserId", id)
        }
      }

      history.push("/admin/system-maintenance")
    };

    const searchQuery = selectedUserField === "insurer" ? advanceSearchResult?.insurer : selectedUserField === "broker" ? advanceSearchResult?.broker : selectedUserField === "all" ? advanceSearchResult : advanceSearchResult?.businessDetails

    return (
      <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle>Search Results</DialogTitle>
        {
          selectedUserField !== "development" &&
          <List sx={{ pt: 0 }}>
            {advanceSearchResult?.length > 0 && advanceSearchResult?.map((searchResult) => {
              const identifyUserRoleForAll = searchResult?.business_details[0]?.stake_holder_id != null ? "stakeholder" : (searchResult?.business_details[0]?.insurer_id != null || searchResult?.role === "insurer") ? "insurer" : (searchResult?.business_details[0]?.broker_id != null || searchResult?.role === "broker") ? "broker" : (searchResult?.business_details[0]?.surveyor_id != null || searchResult?.role === "surveyor") ? "surveyor" : (searchResult?.role === "Developer" || searchResult?.role === "Contractor" || searchResult?.role === "Sub Contractor" || searchResult?.role === "Financial Interest") ? "stakeholder" : "User"
              console.log("🚀 ~ file: Dashboard.js ~ line 784 ~ {advanceSearchResult?.map ~ searchResult?.business_details?.surveyor_id", searchResult?.business_details)
              return (
                <ListItem >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`${companyNameField != "" ? searchResult?.business_details[0]?.company_name : searchResult?.username}`} />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button className='sm' size="sm" onClick={() => handleListItemClick(searchResult?.sub_user === null ? searchResult?.id : searchResult?.sub_user, searchResult?.stake_holder?.id || searchResult?.surveyor?.id || searchResult?.broker?.id || searchResult?.insurer?.id, selectedUserField, identifyUserRoleForAll)} key={searchResult?.stake_holder?.id || searchResult?.surveyor?.id || searchResult?.broker?.id || searchResult?.insurer?.id} style={{ backgroundColor: "rgb(43, 169, 205)" }}>View Profile</Button>
                    <Button className='btn-info' size="sm" onClick={() => {
                      dispatch(AlterAdvanceSearchResult({ objId: searchResult?.id }))
                      setOpen(false)
                      dispatch(SetShowSingleUnAllocatedDevelopments(true))
                      dispatch(SetShowStackholderActivity(false))
                      dispatch(SetIsViewDevelopmentDetails(false))
                      dispatch(SetSingleUnAllocatedDetailsTitle(selectedUserField))
                    }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>View Dashboard</Button>
                  </div>
                </ListItem>
              )
            }
            )}
          </List>
        }

        {
          selectedUserField === "development" &&
          <List sx={{ pt: 0 }}>
            {advanceSearchResult?.length > 0 && advanceSearchResult?.map((searchResult) => {
              return (
                <ListItem >
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "Center", gap: "32px" }}>
                    <ListItemText primary={searchResult?.development_name} />
                    <ListItemText primary={searchResult?.site_postcode} />
                    <Button className='btn-info' size="sm" onClick={() => {
                      setOpen(false)
                      dispatch(SetIsViewDevelopmentDetails(true)); dispatch(SetShowStackholderActivity(true)); dispatch(ChangeCurrentSelectedAcceptedDevelopment(searchResult)); dispatch(SetSingleUnAllocatedDetailsTitle("Development")); dispatch(GetAdminSingleDevelopmentDetail(searchResult?.id)); dispatch(GetAdmisistrationHistoryDataForAdmin(searchResult?.id)); dispatch(GetNotes(searchResult?.id)); dispatch(GetDefectsOfSingleDevelopment(searchResult?.id)); dispatch(GetDiaries(searchResult?.id)); dispatch(SetCurrentDashboard("AWS Development Dashboard"))
                    }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>View Dashboard</Button>
                  </div>
                </ListItem>
              )
            }
            )}
          </List>
        }
      </Dialog>
    );
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    // setSelectedValue(value);
  };

  const advanceSearchHandler = () => {
    dispatch(EmptyUserDetailForTopLeftCard(null))
    const data = selectedUserField === "development" ? {
      advance_search: selectedUserField,
      development_name: developmentNameField,
      site_postcode: developmentPostcodeField,
    } : {
      advance_search: selectedUserField,
      // surveyorIdField,
      company_name: companyNameField,
      contact_name: contactNameField,
    }
    console.log("🚀 ~ file: Dashboard.js ~ line 641 ~ advanceSearchHandler ~ data", data)
    dispatch(AdvanceSearch({ data, handleClickOpen, history }))

  }

  const clearAdvanceSearchData = () => {
    setSelectedUserField("")
    setCompanyNameField("")
    setContactNameField("")
  }

  useEffect(() => {
    console.log("SCROLLDIV7")
    console.log(scrollDiv7)

    if (scrollDiv1.current)
      psTable = new PerfectScrollbar(scrollDiv1.current);
    if (scrollDiv2.current)
      psTable = new PerfectScrollbar(scrollDiv2.current);
    if (scrollDiv3.current)
      psTable = new PerfectScrollbar(scrollDiv3.current);
    if (scrollDiv4.current)
      psTable = new PerfectScrollbar(scrollDiv4.current);
    if (scrollDiv5.current)
      psTable = new PerfectScrollbar(scrollDiv5.current);
    if (scrollDiv6.current)
      psTable = new PerfectScrollbar(scrollDiv6.current);
    if (scrollDiv7.current)
      psTable = new PerfectScrollbar(scrollDiv7.current);
    if (scrollDiv8.current)
      psTable = new PerfectScrollbar(scrollDiv8.current);
    if (scrollDiv9.current)
      psTable = new PerfectScrollbar(scrollDiv9.current);
    if (scrollDiv10.current)
      psTable = new PerfectScrollbar(scrollDiv10.current);
  })

  useEffect(() => {
    dispatch(GetUserHistory())
    dispatch(GetLoginUserDiaries("dairy"))
    dispatch(GetDevelopments())
    dispatch(GetAdminDevelopments())
    dispatch(GetMakeRequests())

  }, [])

  return (
    <>
      <div className="content nested-perfect-scroll">
        <Row className="gx-5">
          <Col lg="3" sm="12">
            {!showSingleUser &&
              <>
                <Card
                  className="scroll-card"
                  style={{ height: "253px" }}
                // ref={(ele) => setTabelDiv(ele)}
                >
                  <div
                    ref={scrollDiv1}
                  // style={{ height: 350 }}
                  >
                    {!showSingleUnAllocatedDevelopments ?

                      <>
                        <div className="header-fixed">
                          <CardHeader style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "500px" }}>
                            <span className="custom-card-header">
                              Unallocated Developments
                            </span>
                          </CardHeader>
                          <CardBody style={{ paddingTop: 0 }}>
                            <Table
                              className="custom-table"
                              style={{ position: "relative" }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px", width: "90px" }}>Surveyor <HeightIcon onClick={() => unAllocatedDevelopmentRequestSort("surveyor")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                  <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px", width: "125px" }} className="">Development Name <HeightIcon onClick={() => unAllocatedDevelopmentRequestSort("development")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                  <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} className="" >Date Submitted <HeightIcon onClick={() => unAllocatedDevelopmentRequestSort("dateSubmitted")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                  <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} className="" >Status <HeightIcon onClick={() => unAllocatedDevelopmentRequestSort("status")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {RenderUnAllocatedDevelopments(allDevelopments)}
                              </tbody>
                            </Table>
                          </CardBody>
                        </div>
                      </>
                      :
                      adminSingleDevelopmentDetail !== null ?

                        <div className="single-unallocated-dev-div">
                          <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                            {singleUnAllocatedDetailsTitle} Details
                          </div>
                          <div className="custom-card-detail">
                            Development Name: {adminSingleDevelopmentDetail?.development_name}
                          </div>
                          <div className="custom-card-detail">
                            No of defects: {adminSingleDevelopmentDetail?.defect?.length || 0}
                          </div>
                          <div className="custom-card-detail">
                            No of notes: {adminSingleDevelopmentDetail?.notes?.length || 0}
                          </div>
                          <div className="custom-card-detail">
                            No of diaries: {adminSingleDevelopmentDetail?.diaries?.length || 0}
                          </div>
                          <div className="exit-btn-container ">
                            {
                              isViewDevelopmentDetails &&
                              <Button
                                size="sm"
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={() => {

                                  dispatch(PushJumps({
                                    size: 1,
                                    goBack: true,
                                  }))

                                  dispatch(SetShowSingleUnAllocatedDevelopments(false))

                                  dispatch(GetDevelopmentGeneralDetails(adminSingleDevelopmentDetail?.id))
                                  dispatch(GetDevelopmentBuildFactorDetails(adminSingleDevelopmentDetail?.id))
                                  dispatch(GetDevelopmentRefurbishmentDetails(adminSingleDevelopmentDetail?.id))
                                  dispatch(GetDevelopmentSiteInvestigationDetails(adminSingleDevelopmentDetail?.id))
                                  dispatch(GetDevelopmentSurveyRequiremntDetails(adminSingleDevelopmentDetail?.id))
                                  dispatch(ChangeDevelopmentMode("view"))
                                  history.push("/admin/add-new-development")
                                }}
                                color="info"
                              >
                                View SRA
                              </Button>
                            }
                            <Button
                              size="sm"
                              className="px-3"
                              style={{ backgroundColor: '#2BA9CD' }}
                              // onClick={EditUserHandler} color="info"
                              color="info"
                              onClick={() => {
                                history.push("/admin/add-new-development")
                                dispatch(ChangeDevelopmentMode("edit"))
                                dispatch(GetDevelopmentGeneralDetails(adminSingleDevelopmentDetail?.id))
                                dispatch(GetDevelopmentSiteInvestigationDetails(adminSingleDevelopmentDetail?.id))
                                dispatch(GetDevelopmentBuildFactorDetails(adminSingleDevelopmentDetail?.id))
                                dispatch(GetDevelopmentRefurbishmentDetails(adminSingleDevelopmentDetail?.id))
                                dispatch(GetDevelopmentSurveyRequiremntDetails(adminSingleDevelopmentDetail?.id))
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                        </div>

                        :

                        advanceSearchResult?.length > 0 ?
                          <div className="single-unallocated-dev-div">
                            <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                              {singleUnAllocatedDetailsTitle} Details
                            </div>
                            <div className="custom-card-detail">
                              {advanceSearchResult[0]?.surveyor?.fore_name} {advanceSearchResult[0]?.surveyor?.sur_name} {advanceSearchResult[0]?.insurer_type?.fore_name} {advanceSearchResult[0]?.insurer_type?.sur_name} {advanceSearchResult[0]?.broker_type?.fore_name} {advanceSearchResult[0]?.broker_type?.sur_name} {advanceSearchResult[0]?.stake_holder?.fore_name} {advanceSearchResult[0]?.stake_holder?.sur_name}
                            </div>
                            <div className="custom-card-detail">
                              {advanceSearchResult[0]?.surveyor?.email || advanceSearchResult[0]?.insurer_type?.email || advanceSearchResult[0]?.broker_type?.email || advanceSearchResult[0]?.stake_holder?.email}
                              {/* 895lorem Common Apt. 899 */}
                            </div>
                            <div className="custom-card-detail">
                              {advanceSearchResult[0]?.surveyor?.contact_number || advanceSearchResult[0]?.insurer_type?.contact_number || advanceSearchResult[0]?.broker_type?.contact_number || advanceSearchResult[0]?.stake_holder?.contact_number}
                              {/* Kayliehaven */}
                            </div>
                            <div className="custom-card-detail">
                              {advanceSearchResult[0]?.surveyor?.phone_number || advanceSearchResult[0]?.insurer_type?.phone_number || advanceSearchResult[0]?.broker_type?.phone_number || advanceSearchResult[0]?.stake_holder?.phone_number}
                              {/* Bilzen */}
                            </div>
                            <div className="custom-card-detail">
                              {advanceSearchResult[0]?.surveyor?.job_title || advanceSearchResult[0]?.insurer_type?.job_title || advanceSearchResult[0]?.broker_type?.job_title || advanceSearchResult[0]?.stake_holder?.job_title}
                              {/* Taurus */}
                            </div>
                            <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                              Other Contact
                            </div>
                            <div className="custom-card-detail">
                              {/* eSquall Technologies */}
                              {advanceSearchResult[0]?.fore_name} {advanceSearchResult[0]?.sur_name}
                            </div>
                            <div className="custom-card-detail">
                              {advanceSearchResult[0]?.email}
                              {/* 895lorem Common Apt. 899 */}
                            </div>
                            <div className="custom-card-detail">
                              {advanceSearchResult[0]?.contact_number}
                              {/* Kayliehaven */}
                            </div>
                            <div className="custom-card-detail">
                              {advanceSearchResult[0]?.phone_number}
                              {/* Bilzen */}
                            </div>
                            <div className="custom-card-detail">
                              {advanceSearchResult[0]?.job_title}
                              {/* Taurus */}
                            </div>
                            <div className="custom-card-detail">
                              Company Name: {advanceSearchResult[0]?.business_details[0]?.company_name}
                            </div>
                            <div className="custom-card-detail">
                              Website: {advanceSearchResult[0]?.business_details[0]?.website_url}
                            </div>

                            <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                              Status
                            </div>
                            <div className="custom-card-detail">
                              Active
                            </div>
                            <div className="exit-btn-container ">
                              {isViewDevelopmentDetails && <Button
                                size="sm"
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(false)); dispatch(GetDevelopmentGeneralDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentBuildFactorDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentRefurbishmentDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentSiteInvestigationDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentSurveyRequiremntDetails(adminSingleDevelopmentDetail?.id)); history.push("/admin/add-new-development"); dispatch(ChangeDevelopmentMode("view")) }} color="info"
                              >
                                View SRA
                              </Button>}
                              <Button
                                size="sm"
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={EditUserHandler} color="info"
                              >
                                Edit
                              </Button>
                            </div>
                          </div>

                          :

                          <div className="single-unallocated-dev-div">
                            <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                              {singleUnAllocatedDetailsTitle} Details
                            </div>
                            <div className="custom-card-detail">
                              {contractorDetailForDevelopmentActivity?.username}
                            </div>
                            <div className="custom-card-detail">
                              {contractorDetailForDevelopmentActivity?.email}
                              {/* 895lorem Common Apt. 899 */}
                            </div>
                            <div className="custom-card-detail">
                              {contractorDetailForDevelopmentActivity?.contact_number}
                              {/* Kayliehaven */}
                            </div>
                            <div className="custom-card-detail">
                              {contractorDetailForDevelopmentActivity?.phone_number}
                              {/* Bilzen */}
                            </div>
                            <div className="custom-card-detail">
                              {contractorDetailForDevelopmentActivity?.job_title}
                              {/* Taurus */}
                            </div>
                            <div className="custom-card-detail">
                              Company Name: {userDetailForTopLeftCard?.general_detail?.company_name}
                            </div>
                            <div className="custom-card-detail">
                              Website: {userDetailForTopLeftCard?.general_detail?.website_url}
                            </div>

                            <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                              Status
                            </div>
                            <div className="custom-card-detail">
                              {contractorDetailForDevelopmentActivity?.is_active == 1 ? "Active" : "Inactive"}
                            </div>
                            <div className="exit-btn-container ">
                              {isViewDevelopmentDetails && <Button
                                size="sm"
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={() => { dispatch(SetShowSingleUnAllocatedDevelopments(false)); dispatch(GetDevelopmentGeneralDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentBuildFactorDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentRefurbishmentDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentSiteInvestigationDetails(adminSingleDevelopmentDetail?.id)); dispatch(GetDevelopmentSurveyRequiremntDetails(adminSingleDevelopmentDetail?.id)); history.push("/admin/add-new-development"); dispatch(ChangeDevelopmentMode("view")) }} color="info"
                              >
                                View SRA
                              </Button>}
                              <Button
                                size="sm"
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={EditSurveyorDetail} color="info"
                              >
                                Edit
                              </Button>
                            </div>
                          </div>

                    }
                  </div>
                </Card>
                {!showSingleUnAllocatedDevelopments &&
                  <Card className="scroll-card" style={{ height: "253px" }}>
                    <div ref={scrollDiv2}>
                      <CardHeader style={{ position: "sticky", background: "white", zIndex: 1000, height: "70px", top: 0 }}>
                        <span className="custom-card-header">
                          Outstanding Requests & Responses
                        </span>
                      </CardHeader>
                      <CardBody style={{ paddingTop: 0 }}>
                        <Table
                          // responsive
                          className="custom-table"
                          style={{ position: "relative" }}
                        >
                          <thead>
                            <tr>
                              {/* <th>#</th> */}
                              <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "70px" }} >Request Type <HeightIcon onClick={() => surveyRequestRequestSort("requestType")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                              <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "70px" }} className="">Name <HeightIcon onClick={() => surveyRequestRequestSort("name")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                              <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "70px" }} className="" >Date Made <HeightIcon onClick={() => surveyRequestRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                            </tr>
                          </thead>
                          <tbody>
                            {RenderSurveyRequests(allMakeRequests)}
                          </tbody>
                        </Table>
                      </CardBody>
                    </div>
                  </Card>
                }
              </>
            }
            {!showSingleUnAllocatedDevelopments &&
              <Card className="scroll-card" style={{ height: "253px" }}>
                {!showSingleUser ?
                  <div ref={scrollDiv3}>
                    <CardHeader style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0 }}>
                      <span className="custom-card-header">
                        User History
                      </span>
                    </CardHeader>
                    <CardBody style={{ paddingTop: 0 }}>
                      <Table
                        // responsive
                        className="custom-table"
                        style={{ position: "relative" }}

                      >
                        <thead>
                          <tr>
                            {/* <th>#</th> */}
                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} >Type <HeightIcon onClick={() => userHistoryRequestSort("type")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} className="" >Name <HeightIcon onClick={() => userHistoryRequestSort("name")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                            <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: "50px" }} className="" >Date / Time <HeightIcon onClick={() => userHistoryRequestSort("lookedOn")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                          </tr>
                        </thead>
                        <tbody>
                          {RenderUserHistory(getUserHistory)}
                        </tbody>
                      </Table>
                    </CardBody>
                  </div>
                  :
                  <div ref={scrollDiv3} className="single-unallocated-dev-div">
                    <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                      Contact Details
                    </div>
                    <div className="custom-card-detail">
                      Kim Jisso
                    </div>
                    <div className="custom-card-detail">
                      839 Buckridge Common Apt. 503
                    </div>
                    <div className="custom-card-detail">
                      Kayliehaven
                    </div>
                    <div className="custom-card-detail">
                      Bilzen
                    </div>
                    <div className="custom-card-detail">
                      Taurus
                    </div>
                    <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                      Primary Contact
                    </div>
                    <div className="custom-card-detail">
                      Gaylord, Grant and Goyette
                    </div>
                    <div className="custom-card-detail">
                      839 Buckridge Common Apt. 503
                    </div>
                    <div className="custom-card-detail">
                      Custom Tailor
                    </div>
                    <div className="custom-card-detail">
                      Company Phone
                    </div>
                    <div className="custom-card-detail">
                      (503) 408-3937 x2240
                    </div>
                    <div className="custom-card-detail">
                      pbeer@lakin.com
                    </div>
                    <div className="custom-card-detail">
                      Website: www.lorem.com
                    </div>
                    <div className="custom-card-detail">
                      Additional Contact: 00 00000
                    </div>
                    <div className="custom-card-detail">
                      Last Login: Today 9:00 AM
                    </div>
                    <div className="custom-card-header custom-card-header-second header-bottom-top-gitter">
                      Status
                    </div>
                    <div className="custom-card-detail">
                      Active
                    </div>
                    <div className="exit-btn-container ">
                      <Button
                        size="sm"
                        className="px-3"
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={() => { dispatch(setShowSingleUser(false)); dispatch(setSingleDevelopmentDefects(false)) }} color="info"
                      >
                        Exit
                      </Button>
                    </div>
                  </div>
                }
              </Card>
            }
            {(showSingleUser || showSingleUnAllocatedDevelopments) &&
              <Card className="scroll-card">
                <div ref={scrollDiv5}>
                  <CardHeader>
                    <span className="custom-card-header">
                      Notes
                    </span>
                  </CardHeader>
                  <CardBody>
                    <Table
                      // responsive
                      className="custom-table"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Request Type <HeightIcon onClick={() => notesRequestSort("requestType")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                          <th className="">Name <HeightIcon onClick={() => notesRequestSort("name")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                          <th className="">Date Made <HeightIcon onClick={() => notesRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {RenderNotes(notesItems)} */}
                        {RenderNotes(allNotes)}
                      </tbody>
                    </Table>
                  </CardBody>
                </div>
              </Card>
            }
            {showSingleUser &&
              <Card className="scroll-card">
                <div ref={scrollDiv6}>
                  <CardHeader>
                    <span className="custom-card-header">
                      Developments
                    </span>
                  </CardHeader>
                  <CardBody>
                    <Table
                      // responsive
                      className="custom-table"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Development Name</th>
                          <th className="">Post Code</th>
                          <th className="">Completed</th>
                        </tr>
                      </thead>
                      <tbody>
                        {RenderUserDevelopments()}
                      </tbody>
                    </Table>
                  </CardBody>
                </div>
              </Card>
            }
          </Col>

          {
            !showStackholderActivity && <Col lg="3" sm="12">
              <Card className="scroll-card-search-top">
                <div ref={scrollDiv10}>

                  <CardHeader>
                    <div style={{ display: "flex", gap: "8px", justifyContent: "space-between" }}>
                      <span className="custom-card-header">
                        Search
                      </span>
                      <Input
                        bsSize="sm"
                        className="mb-3"
                        placeholder="Search"
                        style={{ width: "150px" }}
                      />
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="radio-buttons ">
                      <div className="radio-buttons-and-label ">
                        <span>
                          <Input
                            className="radio-btn"
                            defaultValue="option1"
                            id="exampleRadios11"
                            name="exampleRadioz"
                            type="radio"
                            color="red"
                            value={selectedUserField}
                            onChange={() => setSelectedUserField("stakeholder")}
                          />
                        </span>
                        <span className="radio-label">Stakeholder</span>
                      </div>
                      <div className="radio-buttons-and-label ">
                        <span>
                          <Input
                            className="radio-btn"
                            defaultValue="option1"
                            id="exampleRadios11"
                            name="exampleRadioz"
                            type="radio"
                            color="red"
                            value={selectedUserField}
                            onChange={() => setSelectedUserField("broker")}
                          />
                        </span>
                        <span className="radio-label">Broker</span>
                      </div>
                      <div className="radio-buttons-and-label ">
                        <span>
                          <Input
                            className="radio-btn"
                            defaultValue="option1"
                            id="exampleRadios11"
                            name="exampleRadioz"
                            type="radio"
                            color="red"
                            value={selectedUserField}
                            onChange={() => setSelectedUserField("surveyor")}
                          />
                        </span>
                        <span className="radio-label">Surveyor</span>
                      </div>
                      <div className="radio-buttons-and-label ">
                        <span>
                          <Input
                            className="radio-btn"
                            defaultValue="option1"
                            id="exampleRadios11"
                            name="exampleRadioz"
                            type="radio"
                            color="red"
                            value={selectedUserField}
                            onChange={() => setSelectedUserField("insurer")}
                          />
                        </span>
                        <span className="radio-label">Insurer</span>
                      </div>
                      <div className="radio-buttons-and-label ">
                        <span>
                          <Input
                            className="radio-btn"
                            defaultValue="option1"
                            id="exampleRadios11"
                            name="exampleRadioz"
                            type="radio"
                            color="red"
                            value={selectedUserField}
                            onChange={() => setSelectedUserField("development")}
                          />
                        </span>
                        <span className="radio-label">Development</span>
                      </div>
                      <div className="radio-buttons-and-label ">
                        <span>
                          <Input
                            className="radio-btn"
                            defaultValue="option1"
                            id="exampleRadios11"
                            name="exampleRadioz"
                            type="radio"
                            color="red"
                            value={selectedUserField}
                            onChange={() => setSelectedUserField("all")}
                          />
                        </span>
                        <span className="radio-label">All</span>
                      </div>
                    </div>

                    {
                      selectedUserField !== "development" &&
                      <>
                        <label className="custom-input-label">Company Name</label>
                        <FormGroup>
                          <Input className="custom-input" placeholder="Company Name" type="text" value={companyNameField} onChange={(e) => setCompanyNameField(e.target.value)} />
                        </FormGroup>
                        <label className="custom-input-label">Contact Name</label>
                        <FormGroup>
                          <Input className="custom-input" placeholder="Contact Name" type="text" value={contactNameField} onChange={(e) => setContactNameField(e.target.value)} />
                        </FormGroup>
                      </>
                    }

                    {
                      selectedUserField === "development" &&
                      <>
                        <label className="custom-input-label">Development Postcode</label>
                        <FormGroup>
                          <Input className="custom-input" placeholder="Development Postcode" type="text" value={developmentPostcodeField} onChange={(e) => setDevelopmentPostcodeField(e.target.value)} />
                        </FormGroup>
                        <label className="custom-input-label">Development Name</label>
                        <FormGroup>
                          <Input className="custom-input" placeholder="Development Name" type="text" value={developmentNameField} onChange={(e) => setDevelopmentNameField(e.target.value)} />
                        </FormGroup>
                      </>
                    }

                    <div className="search-clear-btns-div ">
                      <Button className="px-3" size="sm" style={{ background: "#51BCDA" }} color="info" onClick={advanceSearchHandler}>
                        Search
                      </Button>
                      <Button className="px-3" size="sm" color="info" style={{ background: "#51BCDA" }} onClick={clearAdvanceSearchData}>
                        Clear
                      </Button>
                    </div>
                  </CardBody>
                </div>
              </Card>

            </Col>
          }

          {
            showAllDefectsOnTopSide && !hideAllDefects &&
            <Col md="6" style={{ overflow: "hidden" }}>
              <AllDefects isOnTopSide={true} isFullHeight={true} style={{ height: "800px" }} />
            </Col>
          }


          {!showDefectDetail ?
            <>

              <Col lg={`${showStackholderActivity ? 9 : 6}`} sm="12">
                <Card>
                  <CardHeader>
                    <span className="custom-card-header">
                      {/*//* Alerts */}
                      Development Activity
                    </span>
                  </CardHeader>
                  <CardBody>
                    {!showStackholderActivity ?
                      <>
                        <Row className="gx-5">

                          <Col className="" lg='12' sm='12'>
                            <Card className="scroll-card-noti" style={{ border: 0, margin: 0 }}>
                              <div ref={scrollDiv4}>

                              </div>
                            </Card>
                          </Col>
                          <Col
                            lg='12'
                            sm='12'

                          >
                            <div
                              className="custom-shadow-div table-12-div"
                            >
                              <div
                                className="scroll-card-nested-table"
                                ref={scrollDiv9}
                                style={{ height: "calc(50rem - 67px)" }}
                              >
                                <Table
                                  // responsive
                                  className="custom-table"
                                  style={{ position: "relative" }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          position: "sticky",
                                          background: "white",
                                          zIndex: 1000,
                                          height: "50px",
                                          top: 0,
                                        }}
                                      >
                                        Development Name
                                        <HeightIcon
                                          onClick={() => DevelopmentRequestSort("developmentName")}
                                          style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }}
                                        />
                                      </th>
                                      <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, }}>Main Contractor <HeightIcon onClick={() => DevelopmentRequestSort("mainContractor")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                      <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "100px" }}>Est.<br />Completion<br /> Date <HeightIcon onClick={() => DevelopmentRequestSort("expectedCompletionDate")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} /> </th>
                                      <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "70px" }} >Surveyor <HeightIcon onClick={() => DevelopmentRequestSort("surveyor")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                      <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0 }} >Next<br />Survey<br />Date <HeightIcon onClick={() => DevelopmentRequestSort("nextSurveyDate")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                      <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "50px" }} onClick={() => DevelopmentRequestSort("progress")} className="w-13">Progress</th>
                                      <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0 }} >Notification <br />Due Date <HeightIcon onClick={() => DevelopmentRequestSort("actionNotified")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                      <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "60px" }} >Total<br />Defects <HeightIcon onClick={() => DevelopmentRequestSort("totalDefects")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                      <th style={{ position: "sticky", background: "white", zIndex: 1000, height: "50px", top: 0, width: "100px" }}>Total<br />Notifications <HeightIcon onClick={() => DevelopmentRequestSort("totalActions")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer", position: "absolute", right: 0, top: "50%", bottom: "50%", transform: "translateY(-50%)" }} />  </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {RenderDevelopments(adminDevelopments)}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                      :
                      <>
                        <div className="custom-shadow-div table-12-div">
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button className="px-3" size="sm" color="info" onClick={toggleModalSingleDevelopmentProgressBar} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                              Edit Stages Progress Bar
                            </Button>
                          </div>
                          <Table
                            // responsive
                            className="custom-table"
                          >
                            <thead>
                              <tr>
                                <th>Development Name <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                <th>Main Contractor <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                <th>Est. Completion Date qweqwe <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                <th>Surveyor <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                <th>Next Survey Date <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                <th className="w-13">Progress <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                <th>Action Notified <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                <th>Total Defects <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                                <th>Total Actions <HeightIcon onClick={() => developmentActivityRequestSort("dateMade")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr >
                                <td>{adminSingleDevelopmentDetail?.development_name}</td>
                                <td>{adminSingleDevelopmentDetail?.main_contractor?.username}</td>
                                <td>{moment(new Date(2022, 7, 1)).format("DD/MM/YY")}</td>
                                <td>{adminSingleDevelopmentDetail?.surveyor?.username}</td>
                                <td>{moment(new Date(2022, 4, 1)).format("DD/MM/YY")}</td>
                                <td className="w-13">
                                  <div style={{ position: "relative" }}>
                                    <Progress className="custom-progress mb-1" color="info" value={adminSingleDevelopmentDetail?.stages[0]?.stage_percentage1 || 0} />
                                    <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>1</span>
                                  </div>
                                  <div style={{ position: "relative" }}>
                                    <Progress className="custom-progress mb-1" color="info" value={adminSingleDevelopmentDetail?.stages[0]?.stage_percentage2 || 0} />
                                    <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>2</span>
                                  </div>
                                  <div style={{ position: "relative" }}>
                                    <Progress className="custom-progress mb-1" color="info" value={adminSingleDevelopmentDetail?.stages[0]?.stage_percentage3 || 0} />
                                    <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>3</span>
                                  </div>
                                  <div style={{ position: "relative" }}>
                                    <Progress className="custom-progress mb-1" color="info" value={adminSingleDevelopmentDetail?.stages[0]?.stage_percentage4 || 0} />
                                    <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>4</span>
                                  </div>
                                  <div style={{ position: "relative" }}>
                                    <Progress className="custom-progress" color="info" value={adminSingleDevelopmentDetail?.stages[0]?.stage_percentage5 || 0} />
                                    <span style={{ position: "absolute", top: "-1px", left: "50%", fontSize: "8px" }}>5</span>
                                  </div>
                                </td>
                                <td>{moment(new Date(2022, 5, 1)).format("DD/MM/YY")}</td>
                                <td>{adminSingleDevelopmentDetail?.defect[0]?.count}</td>
                                <td>{adminSingleDevelopmentDetail?.action[0]?.count || 0}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <span
                          style={{ marginLeft: 5, }}
                          className="custom-card-header custom-card-header-second"
                        >
                          Notifications
                        </span>
                        <div style={{ height: 10 }}></div>
                        <div className="custom-shadow-div table-12-div nested-scroll-div">
                          <div
                            className="scroll-card-nested-table"
                            ref={scrollDiv7}
                            style={{ height: "calc(31rem - 57px)" }}
                          >
                            <Table
                              // responsive 
                              className="custom-table"
                            >
                              <thead>
                                <tr>
                                  <th>Notification <HeightIcon onClick={() => notificationRequestSort("notification")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                  <th>Notified <HeightIcon onClick={() => notificationRequestSort("notified")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                  <th>Stage <HeightIcon onClick={() => notificationRequestSort("stage")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                  <th>Section within Stage <HeightIcon onClick={() => notificationRequestSort("sectionwithinStage")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                  <th>Description <HeightIcon onClick={() => notificationRequestSort("description")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                  <th>Block/Unit <HeightIcon onClick={() => notificationRequestSort("unitBlock")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                  <th>Prirority <HeightIcon onClick={() => notificationRequestSort("prirority")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                  <th>Photo / Video Available <HeightIcon onClick={() => notificationRequestSort("photoVideoAvailable")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                  <th>Actioned in 30 days <HeightIcon onClick={() => notificationRequestSort("beingActions")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                  <th>Remedial Work Complete? <HeightIcon onClick={() => notificationRequestSort("isRemedialWorkComplete")} style={{ color: "#bab7b7", width: "15px", cursor: "pointer" }} /> </th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {RenderDevelopmentNotifications(notificationItems)} */}
                                {RenderDevelopmentNotifications(defectsOfSingleDevelopment)}
                              </tbody>
                            </Table>
                          </div>
                          <div style={{ paddingRight: 10 }} className="exit-btn-container ">
                            <Button
                              size="sm"
                              className="px-3"
                              style={{ backgroundColor: '#2BA9CD' }}
                              onClick={() => { dispatch(SetShowStackholderActivity(false)); dispatch(setSingleDevelopmentDefects(false)) }} color="info"
                            >
                              Exit
                            </Button>
                          </div>
                        </div>
                      </>
                    }
                  </CardBody>
                </Card>
              </Col>
            </>
            :
            <DefectDetail
              setShowDefectDetail={SetShowDefectDetail} isFullWidth={showDefectDetailFullWidth} setShowAllDefectsOnTopSide={SetShowAllDefectsOnTopSide} setHideAllDefects={SetHideAllDefects}
            />
          }
        </Row>
        <Row>

          {showStackholderActivity &&
            <Col md="6" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <Card className="scroll-card-search-bottom">
                <CardHeader>
                  <span className="custom-card-header">
                    Administration History
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <Select
                        className="react-select primary custom-dropdown"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={developer}
                        onChange={(value) => setDeveloper(value)}
                        options={[
                          { value: "1", label: "DPT 1" },
                          { value: "2", label: "DPT 2" },
                          { value: "3", label: "DPT 3" },
                        ]}
                        placeholder="Department"
                      />
                    </Col>
                    <div style={{ height: 5 }} />
                    <Col md="4">
                      <Select
                        className="react-select primary custom-dropdown"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={surveyor}
                        onChange={(value) => setSurveyor(value)}
                        options={[
                          { value: "1", label: "TM 01" },
                          { value: "2", label: "TM 02" },
                          { value: "3", label: "TM 03" },
                        ]}
                        placeholder="Team Member"
                      />
                    </Col>
                  </Row>
                  <div className="diary-div">
                    {/* {showStackholderActivity && */}
                    <>
                      <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryAddDiary(true); dispatch(ChangeAddDiaryMode("add")); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                        Add Diary
                      </Button>
                      <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryAddNote(true); dispatch(ChangeAddNoteMode("add")); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                        Add Note
                      </Button>
                      <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryMakeRequest(true); dispatch(ChangeMakeRequestMode("add")); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                        Make Request
                      </Button>
                      <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryUploadDocument(true); dispatch(ChangeUploadDocumentMode("add")); dispatch(GetAdmisistrationHistoryToFieldDataForSurveyor(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                        Upload Documents for review
                      </Button>
                    </>
                    {/* } */}
                  </div>
                  <Row style={{ padding: "0 15px" }}>
                    <AdministrationHistoryData data={administrationHistoryDataForAdmin} />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          }
          <Col Col md="6" style={{ paddingLeft: "5px", paddingRight: "5px" }
          }>
            {
              showStackholderActivity &&
              <Card className="scroll-card-search-bottom">
                <CardHeader>
                  <span className="custom-card-header">
                    Diaries ererew
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <Select
                        className="react-select primary custom-dropdown"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={developer}
                        onChange={(value) => setDeveloper(value)}
                        options={[
                          { value: "1", label: "DPT 1" },
                          { value: "2", label: "DPT 2" },
                          { value: "3", label: "DPT 3" },
                        ]}
                        placeholder="Developer"
                      />
                    </Col>
                    <div style={{ height: 5 }} />
                    <Col md="4">
                      <Select
                        className="react-select primary custom-dropdown"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={surveyor}
                        onChange={(value) => setSurveyor(value)}
                        options={[
                          { value: "1", label: "TM 01" },
                          { value: "2", label: "TM 02" },
                          { value: "3", label: "TM 03" },
                        ]}
                        placeholder="Surveyor"
                      />
                    </Col>
                    <Col md="4">
                      <Input type="date" />
                    </Col>
                  </Row>
                  <div className="diary-div">
                    {!showStackholderActivity &&
                      <>
                        <Button className="px-3" size="sm" color="info" onClick={toggleModalAddDiary} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                          Add Internal Diary
                        </Button>
                        <Button className="px-3" size="sm" color="info" style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                          Completed Entries
                        </Button>
                      </>
                    }
                    {showStackholderActivity &&
                      <>
                        <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryAddDiary(true); dispatch(ChangeAddDiaryMode("add")); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                          Add Diary
                        </Button>
                        {/* <FormGroup aria-label="position" row> */}
                        <FormControlLabel
                          value="start"
                          control={<Switch color="primary" checked={showCompletedEntries} onChange={async (e) => {
                            setShowCompletedEntries(e.target.checked);
                            const allDiaries = await dispatch(GetDiaries(adminSingleDevelopmentDetail?.id));
                            dispatch(ChangeAllDiaries({ value: e.target.checked, diaries: allDiaries }))
                          }} />}
                          label="Completed Entries"
                          labelPlacement="start"
                        />
                        {/* </FormGroup> */}
                      </>
                    }
                  </div>
                  <Row style={{ padding: "0 15px" }}>
                    <AllDiaries />
                  </Row>
                </CardBody>
              </Card>
            }
          </Col >

          {singleDevelopmentDefects && !showAllDefectsOnTopSide && !hideAllDefects &&
            <Col Col md="6" style={{ overflow: "hidden", paddingLeft: "5px", paddingRight: "5px" }}>
              <AllDefects isOnTopSide={true} isFullHeight={false} style={{ height: "323px" }} />
            </Col>
          }

        </Row >
        <Col Col md="6" style={{ paddingLeft: "5px", paddingRight: "5px" }
        }>
          <LoginUserDiaries />
        </Col>
      </div >
      <Modal
        // className="modal-sm"
        modalClassName="modal-primary"
        // isOpen={modalAdministrationHistoryAddDiary}
        toggle={toggleModalAdministrationHistoryAddDiary}
        isOpen={modalAdministrationHistoryAddDiary}
        // toggle={ToggleModalAdministrationHistoryAddDiary}
        size="lg"
      >
        <AdministrationHistoryAddDiary toggleModalFunction={toggleModalAdministrationHistoryAddDiary} />
      </Modal>

      {/* Administration History Add Note Modal  */}
      <Modal
        // className="modal-sm"
        modalClassName="modal-primary"
        isOpen={modalAdministrationHistoryAddNote}
        toggle={toggleModalAdministrationHistoryAddNote}
        size="lg"
      >
        <AdministrationHistoryAddNote toggleModalFunction={toggleModalAdministrationHistoryAddNote} />
      </Modal>

      {/* Administration History Make Request Modal  */}
      <Modal
        // className="modal-sm"
        modalClassName="modal-primary"
        isOpen={modalAdministrationHistoryMakeRequest}
        toggle={toggleModalAdministrationHistoryMakeRequest}
        size="lg"
      >
        <AdministrationHistoryMakeRequest toggleModalFunction={toggleModalAdministrationHistoryMakeRequest} />
      </Modal>

      {/* Administration History Add Defect Modal  */}
      <Modal
        // className="modal-sm"
        modalClassName="modal-primary"
        isOpen={modalAdministrationHistoryUploadDocument}
        toggle={toggleModalAdministrationHistoryUploadDocument}
        size="lg"
      >
        <AdministrationHistoryUploadDocument toggleModalFunction={toggleModalAdministrationHistoryUploadDocument} />
      </Modal>

      {/* Administration History Add Defect Modal  */}
      <Modal
        // className="modal-sm"
        modalClassName="modal-primary"
        isOpen={modalAdministrationHistoryAddDefect}
        toggle={toggleModalAdministrationHistoryAddDefect}
        size="lg"
      >
        <AdministrationHistoryAddDefect toggleModalFunction={toggleModalAdministrationHistoryAddDefect} />
      </Modal>

      {/* Add Diary Modal  */}
      <Modal
        // className="modal-sm"
        modalClassName="modal-primary"
        isOpen={modalAddDiary}
        toggle={toggleModalAddDiary}
        size="lg"
      >
        <AddDiary toggleModalFunction={toggleModalAddDiary} />
      </Modal>

      {/* Add Allocate Development  */}
      <Modal
        // className="modal-sm"
        modalClassName="modal-primary"
        isOpen={modalAllocateDevelopment}
        toggle={toggleModalAllocateDevelopment}
        size="lg"
      >
        <AllocateDevelopment toggleModalFunction={toggleModalAllocateDevelopment} />
      </Modal>

      {/* Add Make A Request  */}
      <Modal
        // className="modal-sm"
        modalClassName="modal-primary"
        isOpen={modalMakeARequest}
        toggle={toggleModalMakeARequest}
        size="lg"
      >
        <MakeARequest toggleModalFunction={toggleModalMakeARequest} />
      </Modal>

      <Modal
        // className="modal-sm"
        modalClassName="modal-primary"
        isOpen={modalSingleDevelopmentProgressBar}
        toggle={toggleModalSingleDevelopmentProgressBar}
        size="lg"
      >
        <SingleDevelopmentProgressBar toggleModalFunction={toggleModalSingleDevelopmentProgressBar} />
      </Modal>

      <SimpleDialog
        // selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />

      <ReAssignSurveyorDialog
        // selectedValue={selectedValue}
        open={openReAssignSurveyorDialog}
        onClose={reAssignSurveyorDialogHandleClose}
        devId={openReAssignSurveyorDialogDevId}
      />

    </>
  );
}

export default Dashboard;
