import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetStakeHolderType = createAsyncThunk('stakeHolder/GetStakeHolderType', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/stake-holder-type').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetStakeHolderTypeForGeneralDetails = createAsyncThunk('stakeHolder/GetStakeHolderTypeForGeneralDetails', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('api/admin/get-another-stakeholder').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    return { data, status }
})

export const GetStakeHolderOtherTitle = createAsyncThunk('stakeHolder/GetStakeHolderOtherTitle', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-other-title').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})
export const GetAllStakeHolder = createAsyncThunk('stakeHolder/GetAllStakeHolder', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/get-all-stakeholder').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetStakeHolderBusinessStatus = createAsyncThunk('stakeHolder/GetStakeHolderBusinessStatus', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get('/api/admin/business-status').then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 24 ~ GetBusinessStatus ~ result", result)
    return { data, status }
})

export const CreateStakeHolder = createAsyncThunk('stakeHolder/CreateStakeHolder', async (params, { dispatch, getState }) => {
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 53 ~ CreateStakeHolder ~ params", params)
    let result = await apiInstance.post('/api/admin/create/stakeholder', {
        ...params?.data
    }).then(function (response) {
        toast("User created successfully")
        params.clearForm()
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})

export const UpdateStakeHolder = createAsyncThunk('stakeHolder/UpdateStakeHolder', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post(`/api/admin/update/stakeholder/${params.id}`, {
        ...params?.data
    }).then(function (response) {
        toast("Record updated successfully")
        params.clearForm()
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})

// export const CreateSurveyor = createAsyncThunk('stakeHolder/CreateSurveyor', async (params, { dispatch, getState }) => {
//     const {
//         stakeHolderTypeField,
//         businessStatusField,
//         companyNameField,
//         tradingNameField,
//         websiteUrlField,
//         postCodeField,
//         correspondingAddressField,
//         propertyNameField,
//         titleField,
//         forenameField,
//         surnameField,
//         jobTitleField,
//         emailField,
//         contactNumberField,
//         phoneNumberField,
//         otherContactTitleField,
//         otherContactForenameField,
//         otherContactSurnameField,
//         otherContactJobTitleField,
//         otherContactEmailField,
//         otherContactContactNumberField,
//         otherContactPhoneNumberField,
//         businessIsActiveField,
//         otherContactIsActiveField,
//         primaryIsActiveField,
//     } = params || {}
//     let result = await apiInstance.post('/api/admin/create/stakeholder', {
//         title: titleField,
//         fore_name: forenameField,
//         sur_name: surnameField,
//         job_title: jobTitleField,
//         email: emailField,
//         contact_number: contactNumberField,
//         phone_number: phoneNumberField,
//         stake_holder_type_id: stakeHolderTypeField,
//         business_status_id: businessStatusField,
//         trading_name: tradingNameField,
//         website_url: websiteUrlField,
//         post_code: postCodeField,
//         correspondence_address: correspondingAddressField,
//         property_name: propertyNameField,
//         company_name: companyNameField,
//         other_contact_title: otherContactTitleField,
//         other_contact_fore_name: otherContactForenameField,
//         other_contact_sur_name: otherContactSurnameField,
//         other_contact_job_title: otherContactJobTitleField,
//         other_contact_email: otherContactEmailField,
//         other_contact_contact_number: otherContactContactNumberField,
//         other_contact_phone_number: otherContactPhoneNumberField,
//         business_is_active: businessIsActiveField,
//         other_contact_is_active: otherContactIsActiveField,
//         primary_is_active: primaryIsActiveField,
//     }).then(function (response) {
//         return response
//     }).catch(function (error) {
//         return error.response
//     })

//     const { data, status } = result
//     console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
//     return { data, status }
// })