import AdminProfile from "App/Screens/AdminProfile/AdminProfile";
import ExternalUsers from "App/Screens/ExternalUsers/ExternalUsers";
import ExternalUsersProfile from "App/Screens/ExternalUsers/ExternalUsersProfile/ExternalUsersProfile";
import SetPassword from "App/Screens/SetPassword/SetPassword";
import AddNewDevelopment from "../../App/Screens/AddNewDevelopment/AddNewDevelopment";
import SystemMaintenance from "../../App/Screens/SystemMaintenance/SystemMaintenance";
import Login from "../Screens/Auth/Login";
import Dashboard from "../Screens/Dashboard/Dashboard";


const routes = [
  {
    path: "/setpassword/:id",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: SetPassword,
    // component: ExternalUsers,
    layout: "/auth",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    // component: ExternalUsers,
    layout: "/admin",
  },
  {
    path: "/add-new-development",
    name: "Add New Development",
    icon: "nc-icon nc-bank",
    component: AddNewDevelopment,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    // component: Dashboard,
    component: AdminProfile,
    layout: "/admin",
  },
  {
    path: "/system-maintenance",
    name: "Add New Development",
    icon: "nc-icon nc-bank",
    component: SystemMaintenance,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: Login,
    layout: "/auth",
  },
];

export default routes;
