import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, CardFooter, Col, Input, Row } from "reactstrap";
import './SiteInvestigation.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import { CircularProgress, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeDevelopmentMode, ChangeDevelopmentStepValue } from '../AddNewDevelopment/Store/AddNewDevelopmentSlice';
import { CreateDevelopmentSiteInvestigation, GetDevelopmentGeneralDetails, UpdateDevelopmentSiteInvestigation } from '../AddNewDevelopment/Store/AddNewDevelopmentApi';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import baseURL from 'App/Config/baseUrl';

function SiteInvestigation(props) {

    const dispatch = useDispatch()

    const { } = useSelector(state => state.defectDetail)
    const { adminSingleDevelopmentDetail } = useSelector(state => state.dashboard)
    const { value, developmentGeneralDetails, getDevelopmentSiteInvestigationDetails, developmentMode, developmentSiteInvestigationError, getDevelopmentGeneralDetails } = useSelector(state => state.AddNewDevelopment)

    const [isExposureZone, setIsExposureZone] = useState('');
    const [isPlasticityIndexReport, setIsPlasticityIndexReport] = useState('');
    const [areTreesOnSite, setAreTreesOnSite] = useState('');
    const [isFloodRisksReport, setIsFloodRisksReport] = useState('');
    const [isMiningAreaReport, setIsMiningAreaReport] = useState('');
    const [isContaminatedLandReport, setIsContaminatedLandReport] = useState('');
    const [isGroundGasesReport, setIsGroundGasesReport] = useState('');
    const [requiredEnhancedGeoInfo, setgeotechnicalInformationRequired] = useState(0);
    const [coastalLocationField, setCoastalLocationField] = useState(0);
    const [geotechnicalInformationRequired, setIsPreplanningDesktop] = useState(0);
    const [groundGasReportFile, setGroundGasReportFile] = useState('');
    const [landReportFile, setLandReportFile] = useState("")
    const [miningAreaReportFile, setMiningAreaReportFile] = useState("")
    const [floodRisksReportFile, setFloodRisksReportFile] = useState("")
    const [treeOnSiteFile, setTreeOnSiteFile] = useState("")
    const [soilsTypeFile, setSoilsTypeFile] = useState("")
    const [exposureReportFile, setExposureReportFile] = useState("")

    useEffect(() => {
        if (developmentMode !== "add" || developmentMode === "previousBtn") {
            setIsExposureZone(getDevelopmentSiteInvestigationDetails?.exposure_report || "")
            setIsPlasticityIndexReport(getDevelopmentSiteInvestigationDetails?.soils_type || "")
            setAreTreesOnSite(getDevelopmentSiteInvestigationDetails?.tree_on_site || "")
            setIsFloodRisksReport(getDevelopmentSiteInvestigationDetails?.flood_risks_report || "")
            setIsMiningAreaReport(getDevelopmentSiteInvestigationDetails?.mining_area_report || "")
            setIsContaminatedLandReport(getDevelopmentSiteInvestigationDetails?.land_report || "")
            setIsGroundGasesReport(getDevelopmentSiteInvestigationDetails?.ground_gases_report || "")
            setgeotechnicalInformationRequired(getDevelopmentSiteInvestigationDetails?.enhance_geo_info || 0)
            setCoastalLocationField(getDevelopmentSiteInvestigationDetails?.coastel_location || 0)
            setIsPreplanningDesktop(getDevelopmentSiteInvestigationDetails?.is_report_recieved || 0)
            // setGroundGasReportFile(getDevelopmentSiteInvestigationDetails?.ground_gases_report_file || "")
            // setLandReportFile(getDevelopmentSiteInvestigationDetails?.land_report_file || "")
            // setMiningAreaReportFile(getDevelopmentSiteInvestigationDetails?.mining_area_report_file || "")
            // setFloodRisksReportFile(getDevelopmentSiteInvestigationDetails?.flood_risks_report_file || "")
            // setTreeOnSiteFile(getDevelopmentSiteInvestigationDetails?.tree_on_site_file || "")
            // setSoilsTypeFile(getDevelopmentSiteInvestigationDetails?.soils_type_file || "")
            // setExposureReportFile(getDevelopmentSiteInvestigationDetails?.exposure_report_file || "")
        }
    }, [getDevelopmentSiteInvestigationDetails])

    const handleChangeGeotechnicalInformationRequired = (event) => {
        setgeotechnicalInformationRequired(event.target.value);
    };
    const handleChangeIsPreplanningDesktop = (event) => {
        setIsPreplanningDesktop(event.target.value);
    };


    const handleChangeIsExposureZone = (event) => {
        setIsExposureZone(event.target.value);
    };
    const handleChangeIsGroundGasesReport = (event) => {
        setIsGroundGasesReport(event.target.value);
    };


    const handleChangeIsMiningAreaReport = (event) => {
        setIsMiningAreaReport(event.target.value);
    };
    const handleChangeIsContaminatedLandReport = (event) => {
        setIsContaminatedLandReport(event.target.value);
    };
    const handleChangeIsPlasticityIndexReport = (event) => {
        setIsPlasticityIndexReport(event.target.value);
    };
    const handleChangeAreTreesOnSite = (event) => {
        setAreTreesOnSite(event.target.value);
    };
    const handleChangeIsFloodRisksReport = (event) => {
        setIsFloodRisksReport(event.target.value);
    };

    const getFilePreviewURL = (file: any) => {
        if (file) return URL.createObjectURL(file);
    };

    const [loading, setLoading] = useState(false);

    const CreateDevelopmentSiteInvestigationHandler = async () => {
        setLoading(true)
        const data = {
            general_details_id: developmentGeneralDetails?.id || getDevelopmentGeneralDetails?.id,
            // general_details_id: "55",
            isExposureZone,
            isPlasticityIndexReport,
            areTreesOnSite,
            isFloodRisksReport,
            isMiningAreaReport,
            isContaminatedLandReport,
            isGroundGasesReport,
            requiredEnhancedGeoInfo,
            coastalLocationField,
            geotechnicalInformationRequired,
            groundGasReportFile,
            landReportFile,
            miningAreaReportFile,
            floodRisksReportFile,
            treeOnSiteFile,
            soilsTypeFile,
            exposureReportFile,
        }

        try {
            developmentMode === "edit"
                ? await dispatch(UpdateDevelopmentSiteInvestigation({ id: getDevelopmentSiteInvestigationDetails?.id, data: data }))
                : await dispatch(CreateDevelopmentSiteInvestigation(data));

            console.log("API call successful!");
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
        console.log("🚀 ~ file: SiteInvestigation.js ~ line 65 ~ CreateDevelopmentSiteInvestigationHandler ~ data", data)

    }

    return (
        <>
            <Row style={{ pointerEvents: `${developmentMode == "view" && 'none'}` }}>
                <Col className="mt-0" lg="12" sm="12">
                    <span className="text-lg font-bold mt-4">Site Investigation / Geotechnical Information </span>
                </Col>
                {/* <Col className="mt-4" lg="6" sm="12">
                    <TextField
                        label="Site Contact Name"
                        className="custom-input"
                        fullWidth
                        helperText=""
                        id="demo-helper-text-misaligned"
                        size="small"
                    />
                </Col> */}
                <Col className="mt-4" lg="6" sm="12">
                    <FormControl>
                        <FormLabel id="otherContactStatus">Coastal Location?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="otherContactStatus"
                            name=""
                            value={coastalLocationField}
                            onChange={(e) => { setCoastalLocationField(e.target.value) }}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Col>
                <Col className="mt-4 " lg="6" sm="12">
                    <FormControl fullWidth>
                        <FormLabel id="otherContactStatus">Pre planning Desktop Survey report received?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="otherContactStatus"
                            name=""
                            label="Pre planning Desktop Survey report received?"
                            value={geotechnicalInformationRequired}
                            onChange={handleChangeIsPreplanningDesktop}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>

                </Col>
                {geotechnicalInformationRequired == 1 &&
                    <>
                        <Col className="mt-4" lg="6" sm="12">
                            <span className="text-sm font-bold mt-4">The Pre planning Desktop Survey report has been received and requires reviewing? </span>
                        </Col>
                        <Col className="mt-4" lg="6" sm="12">
                            <FormControl fullWidth>
                                <FormLabel id="otherContactStatus">Do we require "enhanced" Geotechnical Information?</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="otherContactStatus"
                                    name=""
                                    label="Pre planning Desktop Survey report received?"
                                    value={requiredEnhancedGeoInfo}
                                    onChange={handleChangeGeotechnicalInformationRequired}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={0} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </>
                }
                {requiredEnhancedGeoInfo == 1 &&
                    <>
                        <Col className="mt-4 flex flex-row select-left-col" lg="6" sm="12">
                            {isGroundGasesReport == "required" &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file1">
                                        <Input accept="image/*" id="icon-button-file1" type="file" style={{ display: "none" }} onChange={(e) => setGroundGasReportFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Ground Gases Report Required or Received?</InputLabel>
                                <Select
                                    className={isGroundGasesReport == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isGroundGasesReport}
                                    label="Ground Gases Report Required or Received?"
                                    onChange={handleChangeIsGroundGasesReport}
                                    size="small"
                                >
                                    <MenuItem value="received">Received</MenuItem>
                                    <MenuItem value="required">Required </MenuItem>
                                </Select>
                                {developmentSiteInvestigationError?.ground_gases_report && <p className="text-danger">{developmentSiteInvestigationError?.ground_gases_report}</p>}
                                {
                                    getDevelopmentSiteInvestigationDetails?.ground_gases_report_file !== null && developmentMode === "edit" &&
                                    <a href={`${baseURL}/storage${getDevelopmentSiteInvestigationDetails?.ground_gases_report_file}`} download target="_blank" style={{ pointerEvents: "auto", color: "blue" }}>Download File</a>
                                }
                                {
                                    groundGasReportFile &&
                                    <div style={{
                                        marginTop: '8px'
                                    }}>
                                        <img
                                            src={getFilePreviewURL(groundGasReportFile)}
                                            alt="Image"
                                            style={{
                                                width: '100px',
                                            }}
                                        />
                                    </div>
                                }
                            </FormControl>
                        </Col>
                        <Col className="mt-4 flex flex-row " lg="6" sm="12">
                            {isContaminatedLandReport == "required" &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file2">
                                        <Input accept="image/*" id="icon-button-file2" type="file" style={{ display: "none" }} onChange={(e) => setLandReportFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Contaminated Land Report Required or Received?</InputLabel>
                                <Select
                                    className={isContaminatedLandReport == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isContaminatedLandReport}
                                    label="Contaminated Land Report Required or Received?"
                                    onChange={handleChangeIsContaminatedLandReport}
                                    size="small"
                                >
                                    <MenuItem value="received">Received</MenuItem>
                                    <MenuItem value="required">Required </MenuItem>
                                </Select>
                                {developmentSiteInvestigationError?.land_report && <p className="text-danger">{developmentSiteInvestigationError?.land_report}</p>}
                                {
                                    getDevelopmentSiteInvestigationDetails?.land_report_file !== null && developmentMode === "edit" &&
                                    <a href={`${baseURL}/storage${getDevelopmentSiteInvestigationDetails?.land_report_file}`} download target="_blank" style={{ pointerEvents: "auto", color: "blue" }}>Download File</a>
                                }
                                {
                                    landReportFile &&
                                    <div style={{
                                        marginTop: '8px'
                                    }}>
                                        <img
                                            src={getFilePreviewURL(landReportFile)}
                                            alt="Image"
                                            style={{
                                                width: '100px',
                                            }}
                                        />
                                    </div>
                                }
                            </FormControl>
                        </Col>
                        <Col className="mt-4  flex flex-row " lg="6" sm="12">
                            {isMiningAreaReport == "required" &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file3">
                                        <Input accept="image/*" id="icon-button-file3" type="file" style={{ display: "none" }} onChange={(e) => setMiningAreaReportFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Mining Area Report Required or Received? </InputLabel>
                                <Select
                                    className={isMiningAreaReport == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isMiningAreaReport}
                                    label="Mining Area Report Required or Received?"
                                    onChange={handleChangeIsMiningAreaReport}
                                    size="small"
                                >
                                    <MenuItem value="received">Received</MenuItem>
                                    <MenuItem value="required">Required </MenuItem>
                                </Select>
                                {developmentSiteInvestigationError?.mining_area_report && <p className="text-danger">{developmentSiteInvestigationError?.mining_area_report}</p>}
                                {
                                    getDevelopmentSiteInvestigationDetails?.mining_area_report_file !== null && developmentMode === "edit" &&
                                    <a href={`${baseURL}/storage${getDevelopmentSiteInvestigationDetails?.mining_area_report_file}`} download target="_blank" style={{ pointerEvents: "auto", color: "blue" }}>Download File</a>
                                }
                                {
                                    miningAreaReportFile &&
                                    <div style={{
                                        marginTop: '8px'
                                    }}>
                                        <img
                                            src={getFilePreviewURL(miningAreaReportFile)}
                                            alt="Image"
                                            style={{
                                                width: '100px',
                                            }}
                                        />
                                    </div>
                                }
                            </FormControl>
                        </Col>
                        <Col className="mt-4 flex flex-row " lg="6" sm="12">
                            {isFloodRisksReport == "required" &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file4">
                                        <Input accept="image/*" id="icon-button-file4" type="file" style={{ display: "none" }} onChange={(e) => setFloodRisksReportFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Flood Risks Report Required or Received?</InputLabel>
                                <Select
                                    className={isFloodRisksReport == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isFloodRisksReport}
                                    label="Flood Risks Report Required or Received?"
                                    onChange={handleChangeIsFloodRisksReport}
                                    size="small"
                                >
                                    <MenuItem value="received">Received</MenuItem>
                                    <MenuItem value="required">Required </MenuItem>
                                </Select>
                                {developmentSiteInvestigationError?.flood_risks_report && <p className="text-danger">{developmentSiteInvestigationError?.flood_risks_report}</p>}
                                {
                                    getDevelopmentSiteInvestigationDetails?.flood_risks_report_file !== null && developmentMode === "edit" &&
                                    <a href={`${baseURL}/storage${getDevelopmentSiteInvestigationDetails?.flood_risks_report_file}`} download target="_blank" style={{ pointerEvents: "auto", color: "blue" }}>Download File</a>
                                }
                                {
                                    floodRisksReportFile &&
                                    <div style={{
                                        marginTop: '8px'
                                    }}>
                                        <img
                                            src={getFilePreviewURL(floodRisksReportFile)}
                                            alt="Image"
                                            style={{
                                                width: '100px',
                                            }}
                                        />
                                    </div>
                                }
                            </FormControl>
                        </Col>
                        <Col className="mt-4  flex flex-row " lg="6" sm="12">
                            {areTreesOnSite == "required" &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file5">
                                        <Input accept="image/*" id="icon-button-file5" type="file" style={{ display: "none" }} onChange={(e) => setTreeOnSiteFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Trees on Site: Further information Required or Received?</InputLabel>
                                <Select
                                    className={areTreesOnSite == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={areTreesOnSite}
                                    label="Trees on Site: Further information Required or Received?"
                                    onChange={handleChangeAreTreesOnSite}
                                    size="small"
                                >
                                    <MenuItem value="received">Received</MenuItem>
                                    <MenuItem value="required">Required </MenuItem>
                                </Select>
                                {developmentSiteInvestigationError?.tree_on_site && <p className="text-danger">{developmentSiteInvestigationError?.tree_on_site}</p>}
                                {
                                    getDevelopmentSiteInvestigationDetails?.tree_on_site_file !== null && developmentMode === "edit" &&
                                    <a href={`${baseURL}/storage${getDevelopmentSiteInvestigationDetails?.tree_on_site_file}`} download target="_blank" style={{ pointerEvents: "auto", color: "blue" }}>Download File</a>
                                }
                                {
                                    treeOnSiteFile &&
                                    <div style={{
                                        marginTop: '8px'
                                    }}>
                                        <img
                                            src={getFilePreviewURL(treeOnSiteFile)}
                                            alt="Image"
                                            style={{
                                                width: '100px',
                                            }}
                                        />
                                    </div>
                                }
                            </FormControl>
                        </Col>
                        <Col className="mt-4 flex flex-row " lg="6" sm="12">
                            {isPlasticityIndexReport == "required" &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file6">
                                        <Input accept="image/*" id="icon-button-file6" type="file" style={{ display: "none" }} onChange={(e) => setSoilsTypeFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Soils Type / Plasticity Index Report: Required or Received?</InputLabel>
                                <Select
                                    className={isPlasticityIndexReport == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isPlasticityIndexReport}
                                    label="Soils Type / Plasticity Index Report: Required or Received?"
                                    onChange={handleChangeIsPlasticityIndexReport}
                                    size="small"
                                >
                                    <MenuItem value="received">Received</MenuItem>
                                    <MenuItem value="required">Required </MenuItem>
                                </Select>
                                {developmentSiteInvestigationError?.soils_type && <p className="text-danger">{developmentSiteInvestigationError?.soils_type}</p>}
                                {
                                    getDevelopmentSiteInvestigationDetails?.soils_type_file !== null && developmentMode === "edit" &&
                                    <a href={`${baseURL}/storage${getDevelopmentSiteInvestigationDetails?.soils_type_file}`} download target="_blank" style={{ pointerEvents: "auto", color: "blue" }}>Download File</a>
                                }
                                {
                                    soilsTypeFile &&
                                    <div style={{
                                        marginTop: '8px'
                                    }}>
                                        <img
                                            src={getFilePreviewURL(soilsTypeFile)}
                                            alt="Image"
                                            style={{
                                                width: '100px',
                                            }}
                                        />
                                    </div>
                                }
                            </FormControl>
                        </Col>
                        <Col className="mt-4 flex flex-row " lg="6" sm="12">
                            {isExposureZone == "required" &&
                                <div className='select-left-btn-wrap'>
                                    <label htmlFor="icon-button-file7">
                                        <Input accept="image/*" id="icon-button-file7" type="file" style={{ display: "none" }} onChange={(e) => setExposureReportFile(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            }
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Exposure Zone report Required or Received?</InputLabel>
                                <Select
                                    className={isExposureZone == 1 ? 'select-with-left-icon' : ``}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isExposureZone}
                                    label="Exposure Zone report Required or Received?"
                                    onChange={handleChangeIsExposureZone}
                                    size="small"
                                >
                                    <MenuItem value="received">Received</MenuItem>
                                    <MenuItem value="required">Required </MenuItem>
                                </Select>
                                {developmentSiteInvestigationError?.exposure_report && <p className="text-danger">{developmentSiteInvestigationError?.exposure_report}</p>}
                                {
                                    getDevelopmentSiteInvestigationDetails?.exposure_report_file !== null && developmentMode === "edit" &&
                                    <a href={`${baseURL}/storage${getDevelopmentSiteInvestigationDetails?.exposure_report_file}`} download target="_blank" style={{ pointerEvents: "auto", color: "blue" }}>Download File</a>
                                }
                                {
                                    exposureReportFile &&
                                    <div style={{
                                        marginTop: '8px'
                                    }}>
                                        <img
                                            src={getFilePreviewURL(exposureReportFile)}
                                            alt="Image"
                                            style={{
                                                width: '100px',
                                            }}
                                        />
                                    </div>
                                }
                            </FormControl>
                        </Col>
                    </>
                }
                {geotechnicalInformationRequired == 0 &&
                    <Col className="mt-4" lg="12" sm="12">
                        <span className="text-sm font-bold mt-4">The Pre planning Desktop Survey report has NOT been received. </span>
                    </Col>
                }
            </Row>
            <CardFooter>
                {developmentMode !== "view" && <div className="exit-btn-container mr-3">
                    <Button
                        disabled={value == 0}
                        className="mr-3"
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={() => { dispatch(ChangeDevelopmentMode("previousBtn")); dispatch(GetDevelopmentGeneralDetails(developmentGeneralDetails?.id)); dispatch(ChangeDevelopmentStepValue(value - 1)) }} color="info" size="sm"
                    >
                        Previous
                    </Button>
                    <Button
                        style={{ backgroundColor: '#2BA9CD' }}
                        onClick={CreateDevelopmentSiteInvestigationHandler}
                        color="info" size="sm"
                    >
                        {
                            !loading ?
                                value == 4 ?
                                    "Save"
                                    :
                                    "Next"
                                :
                                <CircularProgress color='inherit' size={18} />
                        }
                    </Button>
                </div>}
            </CardFooter>
        </>
    );
}

export default SiteInvestigation;
