import { Select } from "@mui/material";
import moment from "moment";
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Card, CardBody, CardHeader, Col, Input, Row, Table
} from "reactstrap";
import { GetAdminSingleDevelopmentDetail, GetAdmisistrationHistoryDataForAdmin, GetDefectsOfSingleDevelopment } from '../Dashboard/Store/DashboardApi';
import { GetDiaries, GetNotes } from '../Diaries/AdministrationHistory/Store/AdministrationHistoryApi';
import { ChangeCurrentSelectedAcceptedDevelopment } from '../ExternalUsers/Store/ExternalUsersSlice';
import { PushJumps } from "../Shared/store/sharedSlice";
import {
    SetSingleUnAllocatedDetailsTitle,
    SetIsViewDevelopmentDetails,
    SetShowStackholderActivity,
    SetShowSingleUnAllocatedDevelopments,
    SetCurrentDashboard,
} from '../Shared/store/pageSlice';

const LoginUserDiaries = () => {
    const dispatch = useDispatch()
    const scrollDiv1 = useRef(null);

    const [developer, setDeveloper] = useState(null)
    const [surveyor, setSurveyor] = useState(null)

    const { loginUserDiaries } = useSelector(state => state.AdministrationHistory)

    function RenderDiaries() {
        return loginUserDiaries?.length > 0 && loginUserDiaries?.map((item, key) => {
            // const { id, dueDate, clientNo, from, subject, created, category, subCategory } = item
            const { id, due_date, clientNo, from, subject, development_id, user, created_at, category, sub_category } = item
            return (
                <tr
                    key={id}
                >
                    <td className="w-13">{moment(created_at).format("DD/MM/YY")}</td>
                    <td className="clickable-col">{moment(due_date).format("DD/MM/YY")}</td>
                    <td className="clickable-col">{user?.username}</td>
                    <td>{user?.username}</td>
                    <td className="clickable-col" onClick={() => {

                        dispatch(PushJumps({
                            size: 5,
                            goBack: false,
                        }))

                        dispatch(SetShowSingleUnAllocatedDevelopments(true))
                        dispatch(SetIsViewDevelopmentDetails(true))
                        dispatch(SetShowStackholderActivity(true))
                        dispatch(SetSingleUnAllocatedDetailsTitle("Development"))
                        dispatch(SetCurrentDashboard("AWS Development Dashboard"))

                        dispatch(ChangeCurrentSelectedAcceptedDevelopment(item))
                        dispatch(GetAdminSingleDevelopmentDetail(development_id))
                        dispatch(GetAdmisistrationHistoryDataForAdmin(development_id))
                        dispatch(GetNotes(development_id))
                        dispatch(GetDefectsOfSingleDevelopment(development_id))
                        dispatch(GetDiaries(development_id))
                    }}
                    >
                        {subject}
                    </td>
                    <td className="clickable-col">{category?.category_name}</td>
                    <td className="clickable-col">{sub_category?.sub_category_name}</td>
                </tr>
            )
        })
    }

    return (
        <Card className="scroll-card-search-bottom">
            <CardHeader>
                <span className="custom-card-header">
                    Diaries ererew
                </span>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="4">
                        <Select
                            className="react-select primary custom-dropdown"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={developer}
                            onChange={(value) => setDeveloper(value)}
                            options={[
                                { value: "1", label: "DPT 1" },
                                { value: "2", label: "DPT 2" },
                                { value: "3", label: "DPT 3" },
                            ]}
                            placeholder="Developer"
                        />
                    </Col>
                    <div style={{ height: 5 }} />
                    <Col md="4">
                        <Select
                            className="react-select primary custom-dropdown"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={surveyor}
                            onChange={(value) => setSurveyor(value)}
                            options={[
                                { value: "1", label: "TM 01" },
                                { value: "2", label: "TM 02" },
                                { value: "3", label: "TM 03" },
                            ]}
                            placeholder="Surveyor"
                        />
                    </Col>
                    <Col md="4">
                        <Input type="date" />
                    </Col>
                </Row>
                <div className="diary-div">

                    {/* <>
                        <Button className="px-3" size="sm" color="info" onClick={toggleModalAddDiary} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Add Internal Diary
                        </Button>

                        <Button className="px-3" size="sm" color="info" style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Completed Entries
                        </Button>
                    </> */}
                    {/* <>
                        <Button className="px-3" size="sm" color="info" onClick={() => { toggleModalAdministrationHistoryAddDiary(true); dispatch(ChangeAddDiaryMode("add")); dispatch(GetUsersOfDevelopments(currentSelectedAcceptedDevelopment?.id)) }} style={{ backgroundColor: "rgb(43, 169, 205)" }}>
                            Add Diary
                        </Button>
                        <FormControlLabel
                            value="start"
                            control={<Switch color="primary" checked={showCompletedEntries} onChange={async (e) => {
                                setShowCompletedEntries(e.target.checked);
                                const allDiaries = await dispatch(GetDiaries(adminSingleDevelopmentDetail?.id));
                                dispatch(ChangeAllDiaries({ value: e.target.checked, diaries: allDiaries }))
                            }} />}
                            label="Completed Entries"
                            labelPlacement="start"
                        />
                    </> */}
                </div>
                <Row style={{ padding: "0 15px" }}>
                    <div className="modal-body" style={{ padding: 0 }}>
                        <div
                            // className="scroll-card-nested-table"
                            ref={scrollDiv1}
                        >
                            <Table
                                // responsive
                                className="custom-table"
                            >
                                <thead>
                                    <tr>
                                        <th>Created</th>
                                        <th>Due Date</th>
                                        <th>Client No</th>
                                        <th>From</th>
                                        <th>Subject</th>
                                        <th>Category</th>
                                        <th>Sub Category</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {RenderDiaries()}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Row>
            </CardBody>
        </Card>
    )
}

export default LoginUserDiaries