import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const stakeHolderSlice = createSlice({
    name: 'stakeHolder',
    initialState: {
        stakeHolderType: null,
        stakeHolderTypeForGeneralDetails: null,
        stakeHolderOtherTitle: [],
        stakeHolderBusinessStatus: null,
        allStakeHolder: [],
        stakeHolder: [],
        addStakeholderErrors: {}
    },
    reducers: {
        ChangeUserData: (state, action) => {
            state.userData = action.payload
        }
    },
    extraReducers: {

        ['stakeHolder/GetStakeHolderType/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: AddNewStakeHolderSlice.js ~ line 18 ~ data", data)
            if (status >= 200 && status < 300) {

                state.stakeHolderType = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.stakeHolderType = data
            }
        },

        ['stakeHolder/GetStakeHolderTypeForGeneralDetails/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.stakeHolderTypeForGeneralDetails = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.stakeHolderTypeForGeneralDetails = data
            }
        },

        ['stakeHolder/GetStakeHolderOtherTitle/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.stakeHolderOtherTitle = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.stakeHolderOtherTitle = data
            }
        },
        ['stakeHolder/GetAllStakeHolder/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.allStakeHolder = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.allStakeHolder = data
            }
        },
        ['stakeHolder/GetStakeHolderBusinessStatus/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            console.log("🚀 ~ file: AddNewStakeHolderSlice.js ~ line 31 ~ data", data)
            if (status >= 200 && status < 300) {
                state.stakeHolderBusinessStatus = data?.data
            }
            else if (status >= 400 && status < 500) {

                // state.stakeHolderBusinessStatus = data
            }
        },
        ['stakeHolder/CreateStakeHolder/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.stakeHolder = data?.data
                state.addStakeholderErrors = {}
            }
            else if (status >= 400 && status < 500) {

                state.addStakeholderErrors = data?.error
            }
        },

        // ['auth/Logout/fulfilled']: (state, action) => {
        //     state.userData = null

        // },
    },
});

export const {
    ChangeUserData,
} = stakeHolderSlice.actions;

export default stakeHolderSlice.reducer;