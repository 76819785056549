import { createSlice } from '@reduxjs/toolkit';

const sharedSlice = createSlice({
    name: 'shared',
    initialState: {
        resetDashboard: false,
        currentForm: "",
        modalAdministrationHistoryMakeRequest: false,
        modalAdministrationHistoryAddDiary: false,
        modalAdministrationHistoryAddNote: false,
        modalAdministrationHistoryUploadDocument: false,
        addDiaryMode: "add",
        makeRequestMode: "add",
        addNoteMode: "add",
        uploadDocumentMode: "add",
        jumps: [],
    },
    reducers: {
        // TODO: States of administration history (add diary, note, make request, upload document)
        ToggleModalAdministrationHistoryMakeRequest: (state, action) => {
            state.modalAdministrationHistoryMakeRequest = action.payload
        },
        ChangeMakeRequestMode: (state, action) => {
            state.makeRequestMode = action.payload
        },
        ToggleModalAdministrationHistoryAddDiary: (state, action) => {
            state.modalAdministrationHistoryAddDiary = action.payload
        },
        ChangeAddDiaryMode: (state, action) => {
            state.addDiaryMode = action.payload
        },
        ToggleModalAdministrationHistoryAddNote: (state, action) => {
            state.modalAdministrationHistoryAddNote = action.payload
        },
        ChangeAddNoteMode: (state, action) => {
            state.addNoteMode = action.payload
        },
        ToggleModalAdministrationHistoryUploadDocument: (state, action) => {
            state.modalAdministrationHistoryUploadDocument = action.payload
        },
        ChangeUploadDocumentMode: (state, action) => {
            state.uploadDocumentMode = action.payload
        },
        SetCurrentForm: (state, action) => {
            state.currentForm = action.payload
        },
        PushJumps: (state, action) => {
            state.jumps = [
                ...state.jumps,
                action.payload
            ]
        },
        PopJumps: (state, action) => {
            state.jumps
                = state.jumps.slice(0, -1)
        },


    },
    extraReducers: {
    },
});

export const {
    SetCurrentForm,
    ToggleModalAdministrationHistoryMakeRequest,
    ToggleModalAdministrationHistoryAddDiary, ChangeAddDiaryMode,
    ChangeMakeRequestMode,
    ToggleModalAdministrationHistoryAddNote,
    ChangeAddNoteMode,
    ToggleModalAdministrationHistoryUploadDocument,
    ChangeUploadDocumentMode,
    PushJumps,
    PopJumps,
} = sharedSlice.actions;

export default sharedSlice.reducer;