import { Checkbox, FormControlLabel } from '@mui/material';
import baseURL from 'App/Config/baseUrl';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import {
    Button,
    Card, CardBody, CardHeader, Col, Container, FormGroup, Modal,
    Input, Progress, Row, Table
} from "reactstrap";
import { CreateAdministrationHistorySubCategory, CreateAdministrationHistoryUploadDocument, CreateAdmnistrationHistoryCategory, GetAddDiarySubCategoryByCategoryId, GetAdministrationHistoryAssignedTo, GetUploadDocumentCategory } from '../AdministrationHistory/Store/AdministrationHistoryApi';

// const AddCategory = ({ toggleModalFunction }) => {
//     return (
//         <>
//             <div className="modal-header">
//                 <button
//                     aria-hidden={true}
//                     className="close"
//                     data-dismiss="modal"
//                     type="button"
//                     onClick={toggleModalFunction}
//                 >
//                     <i className="nc-icon nc-simple-remove" />
//                 </button>
//                 <h5 className="modal-title text-left" id="myModalLabel">
//                     Add Category
//                 </h5>
//             </div>
//             <div className="modal-body">
//                 <div className="add-diary-form">
//                     <Row>
//                         <Col md="6">
//                             <FormGroup>
//                                 <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Category Name</label>
//                                 <Input className="custom-input" placeholder="" type="text" />
//                             </FormGroup>
//                         </Col>
//                         <Col md="6">
//                             <FormGroup style={{ margin: 0 }}>
//                                 <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Assign To</label>
//                                 <div className="custom-select-input">
//                                     <Select
//                                         className="react-select primary "
//                                         classNamePrefix="react-select"
//                                         name="singleSelect"
//                                         // value={stakeHolder}
//                                         // onChange={(value) => setStakeHolder(value)}
//                                         options={[
//                                             {
//                                                 value: "",
//                                                 label: "Category",
//                                                 isDisabled: true,
//                                             },
//                                             { value: "1", label: "AWS Hub" },
//                                             { value: "2", label: "AWS Dev" },
//                                             { value: "3", label: "Surveyor" },
//                                             { value: "3", label: "ARK (Insurer)" },
//                                             { value: "3", label: "Broker" },
//                                             { value: "3", label: "Developer" },
//                                             { value: "3", label: "Contractor" },
//                                         ]}
//                                         placeholder=""
//                                     />
//                                 </div>
//                             </FormGroup>
//                         </Col>
//                     </Row>
//                     <div style={{ display: "flex", justifyContent: "flex-end" }}>
//                         <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
//                         <Button className='btn-info' onClick={toggleModalFunction} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

const AddCategory = ({ toggleModalFunction }) => {
    const dispatch = useDispatch()

    const [categoryNameField, setCategoryNameField] = useState("")
    const [assignToField, setAssignToField] = useState(null)

    const { administrationHistoryAssignedTo } = useSelector(state => state?.AdministrationHistory)

    useEffect(() => {
        dispatch(GetAdministrationHistoryAssignedTo())
    }, [])

    const addCategoryHandler = () => {
        const data = {
            category_type: "upload-document",
            category_name: categoryNameField,
            assigned_to: assignToField?.value,
        }
        console.log("🚀 ~ file: AdministrationHistoryAddDiary.js ~ line 23 ~ addCategoryHandler ~ data", data)

        dispatch(CreateAdmnistrationHistoryCategory(data))
        setCategoryNameField("")
        setAssignToField(null)
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Add Category
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Category Name</label>
                                <Input className="custom-input" placeholder="" type="text" value={categoryNameField} onChange={e => setCategoryNameField(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Assign To</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={assignToField}
                                        onChange={(value) => setAssignToField(value)}
                                        options={
                                            administrationHistoryAssignedTo?.map(data => ({
                                                value: data?.id, label: data?.name
                                            }))
                                            // {
                                            //     value: "",
                                            //     label: "Category",
                                            //     isDisabled: true,
                                            // },
                                            // { value: "6", label: "AWS Hub" },
                                            // { value: "2", label: "AWS Dev" },
                                            // { value: "3", label: "Surveyor" },
                                            // { value: "3", label: "ARK (Insurer)" },
                                            // { value: "3", label: "Broker" },
                                            // { value: "3", label: "Developer" },
                                            // { value: "3", label: "Contractor" },
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={addCategoryHandler} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

// const AddSubCategory = ({ toggleModalFunction }) => {
//     return (
//         <>
//             <div className="modal-header">
//                 <button
//                     aria-hidden={true}
//                     className="close"
//                     data-dismiss="modal"
//                     type="button"
//                     onClick={toggleModalFunction}
//                 >
//                     <i className="nc-icon nc-simple-remove" />
//                 </button>
//                 <h5 className="modal-title text-left" id="myModalLabel">
//                     Add Sub Category
//                 </h5>
//             </div>
//             <div className="modal-body">
//                 <div className="add-diary-form">
//                     <Row>
//                         <Col md="6">
//                             <FormGroup style={{ margin: 0 }}>
//                                 <div>
//                                     <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Category</label>
//                                 </div>
//                                 <div className="custom-select-input">
//                                     <Select
//                                         className="react-select primary"
//                                         classNamePrefix="react-select"
//                                         name="singleSelect"
//                                         // value={stakeHolder}
//                                         // onChange={(value) => setStakeHolder(value)}
//                                         options={[
//                                             {
//                                                 value: "",
//                                                 label: "Category",
//                                                 isDisabled: true,
//                                             },
//                                             { value: "1", label: "Accounts" },
//                                             { value: "2", label: "Admin" },
//                                             { value: "3", label: "Claims" },
//                                             { value: "3", label: "Debt Collection" },
//                                             { value: "3", label: "Inception" },
//                                             { value: "3", label: "Manager" },
//                                             { value: "3", label: "Misc" },
//                                             { value: "3", label: "MTA" },
//                                             { value: "3", label: "Outbound" },
//                                             { value: "3", label: "Renewal" },
//                                             { value: "3", label: "Sales" },
//                                             { value: "3", label: "Warning" },
//                                         ]}
//                                         placeholder=""
//                                     />
//                                 </div>
//                             </FormGroup>
//                         </Col>
//                         <Col md="6">
//                             <FormGroup>
//                                 <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Sub Category Name</label>
//                                 <Input className="custom-input" placeholder="" type="text" />
//                             </FormGroup>
//                         </Col>

//                     </Row>
//                     <div style={{ display: "flex", justifyContent: "flex-end" }}>
//                         <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
//                         <Button className='btn-info' onClick={toggleModalFunction} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

const AddSubCategory = ({ toggleModalFunction }) => {
    const dispatch = useDispatch()
    const { uploadDocumentsCategories } = useSelector(state => state?.AdministrationHistory)

    const [categoryIdField, setCategoryIdField] = useState(null)
    const [subCategoryNameField, setSubCategoryNameField] = useState("")

    const addSubCategoryHandler = () => {
        const data = {
            category_id: categoryIdField?.value,
            sub_category_name: subCategoryNameField,
        }
        console.log("🚀 ~ file: AdministrationHistoryAddDiary.js ~ line 23 ~ addCategoryHandler ~ data", data)

        dispatch(CreateAdministrationHistorySubCategory(data))
        setCategoryIdField(null)
        setSubCategoryNameField("")
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalFunction}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Add Sub Category
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Category</label>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={categoryIdField}
                                        onChange={(value) => setCategoryIdField(value)}
                                        options={

                                            uploadDocumentsCategories?.map(catgeory => (
                                                {
                                                    value: catgeory?.id, label: catgeory?.category_name
                                                }
                                            ))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="" style={{ fontSize: "12px", marginTop: 0 }}>Sub Category Name</label>
                                <Input className="custom-input" placeholder="" type="text" value={subCategoryNameField} onChange={e => setSubCategoryNameField(e.target.value)} />
                            </FormGroup>
                        </Col>

                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={toggleModalFunction} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={addSubCategoryHandler} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

const AdministrationHistoryUploadDocument = ({ toggleModalFunction }) => {
    const dispatch = useDispatch()
    const { uploadDocumentsCategories, addDiarySubCategoryByCategoryId, administrationHistoryToFieldDataForSurveyor, singleUploadDocumentDetail } = useSelector(state => state?.AdministrationHistory)
    const { currentSelectedAcceptedDevelopment } = useSelector(state => state?.ExternalUsers)
    const { uploadDocumentMode } = useSelector(state => state?.shared)

    const [modalAddCategory, setModalAddCategory] = useState(false);
    const [modalAddSubCategory, setModalAddSubCategory] = useState(false);
    const [isDueDateRequired, setIsDueDateRequired] = useState(0);
    const [categoryIdField, setCategoryIdField] = useState(null)

    const [subCategoryIdField, setSubCategoryIdField] = useState(null)
    const [toField, setToField] = useState([])
    const [subjectField, setSubjectField] = useState(null)
    const [commentField, setCommentField] = useState(null)
    const [attachField, setAttachField] = useState(null)
    const [dueDateField, setDueDateField] = useState(null)

    useEffect(() => {
        if (uploadDocumentMode == "view") {
            setCategoryIdField({ value: singleUploadDocumentDetail?.category?.id, label: singleUploadDocumentDetail?.category?.category_name } || null)
            setSubCategoryIdField({ value: singleUploadDocumentDetail?.sub_category?.id, label: singleUploadDocumentDetail?.sub_category?.sub_category_name } || null)
            // setToField(singleUploadDocumentDetail?.|| null)
            // setPriorityField(singleUploadDocumentDetail?.priority || null)
            setSubjectField(singleUploadDocumentDetail?.subject || null)
            setCommentField(singleUploadDocumentDetail?.comment || null)
            setAttachField(singleUploadDocumentDetail?.file || null)
            setIsDueDateRequired(singleUploadDocumentDetail?.is_due_date || 0)
            setDueDateField(singleUploadDocumentDetail?.due_date || null)
        }
    }, [singleUploadDocumentDetail])


    useEffect(() => {
        dispatch(GetUploadDocumentCategory())
    }, [])

    const toggleModalAddCategory = () => {
        setModalAddCategory(!modalAddCategory);
    }
    const toggleModalAddSubCategory = () => {
        setModalAddSubCategory(!modalAddSubCategory);
    }

    const categoryIdFieldHandler = (value) => {
        setCategoryIdField(value)
        dispatch(GetAddDiarySubCategoryByCategoryId(value?.value))
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const uploadDocumentHandleChange = async () => {
        // const modifyData = toField?.map(field => field?.value)
        console.log("🚀 ~ file: AdministrationHistoryUploadDocument.js ~ line 373 ~ uploadDocumentHandleChange ~ toField", toField)
        const aData = toField?.value == "a" ? administrationHistoryToFieldDataForSurveyor?.surveyorID : null
        const bData = toField?.value == "b" ? administrationHistoryToFieldDataForSurveyor?.system_user : []
        const cData = toField?.value == "c" && {
            surveyor: administrationHistoryToFieldDataForSurveyor?.surveyorID,
            system_user: administrationHistoryToFieldDataForSurveyor?.system_user
        }
        const data = {
            administration_types: "upload-document",
            development_id: currentSelectedAcceptedDevelopment?.id,
            category_id: categoryIdField?.value,
            sub_category_id: subCategoryIdField?.value,
            is_due_date: isDueDateRequired?.value,
            // priority: priorityField?.value,
            subject: subjectField,
            comment: commentField,
            due_date: dueDateField,
            file: await convertBase64(attachField),
            file_name: attachField?.name,
            to: toField?.value !== "c" ? {
                "surveyor": aData,
                "system_user": bData
            } : cData
        }
        console.log("🚀 ~ file: AdministrationHistoryUploadDocument.js ~ line 392 ~ uploadDocumentHandleChange ~ data", data)

        await dispatch(CreateAdministrationHistoryUploadDocument(data))
        toggleModalFunction(false)
    }

    return (
        <>
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModalFunction(false)}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title text-left" id="myModalLabel">
                    Upload Document
                </h5>
            </div>
            <div className="modal-body">
                <div className="add-diary-form">
                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Category</label>
                                    <span style={{ fontSize: "16px", padding: "0 5px", color: "#ffffff", borderRadius: "15px", marginLeft: "16px", cursor: "pointer", backgroundColor: "#51BCDA" }} onClick={toggleModalAddCategory}>+</span>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={categoryIdField}
                                        onChange={(value) => categoryIdFieldHandler(value)}
                                        options={
                                            uploadDocumentsCategories?.map(catgeory => (
                                                {
                                                    value: catgeory?.id, label: catgeory?.category_name
                                                }
                                            ))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <div>
                                    <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Sub Category</label>
                                    <span style={{ fontSize: "16px", padding: "0 5px", color: "#ffffff", borderRadius: "15px", marginLeft: "16px", cursor: "pointer", backgroundColor: "#51BCDA" }} onClick={toggleModalAddSubCategory}>+</span>
                                </div>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={subCategoryIdField}
                                        onChange={(value) => setSubCategoryIdField(value)}
                                        options={
                                            addDiarySubCategoryByCategoryId?.map(catgeory => (
                                                {
                                                    value: catgeory?.id, label: catgeory?.sub_category_name
                                                }
                                            ))
                                        }
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>To</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={toField}
                                        onChange={(value) => setToField(value)}
                                        options={[
                                            {
                                                value: "",
                                                label: "To",
                                                isDisabled: true,
                                            },
                                            { value: "a", label: "Surveyor" },
                                            { value: "b", label: "System User (AWS staff)" },
                                            { value: "c", label: "Surveyor & System User (AWS staff)" },
                                        ]}
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup style={{ margin: 0 }}>
                                <label className="custom-input-label" style={{ fontSize: "12px", marginTop: 0 }}>Due Date required</label>
                                <div className="custom-select-input">
                                    <Select
                                        className="react-select primary "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={isDueDateRequired}
                                        onChange={(value) => setIsDueDateRequired(value)}
                                        options={[
                                            {
                                                value: "",
                                                label: "Due Date required",
                                                isDisabled: true,
                                            },
                                            { value: 1, label: "Yes" },
                                            { value: 0, label: "No" },
                                        ]}
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        {isDueDateRequired?.value === 1 &&
                            <Col md="6">
                                <FormGroup>
                                    <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Due Date</label>
                                    <Input className="custom-input" placeholder="" type="date" value={dueDateField} onChange={e => setDueDateField(e.target.value)} />
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    <Col md="12" style={{ padding: 0 }}>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" placeholder='Subject' max={150} style={{ minHeight: "80px", border: "1px solid #888888" }}
                                value={subjectField}
                                onChange={(e) => setSubjectField(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="12" style={{ padding: 0 }}>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" placeholder='Comments' style={{ minHeight: "100px", border: "1px solid #888888" }}
                                value={commentField} onChange={(e) => setCommentField(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Attach</label>
                            <Input className="custom-input" placeholder="" type="file" onChange={e => setAttachField(e.target.files[0])} />
                        </FormGroup>
                        <div>
                            <img style={{ width: "100%", height: "100%", aspectRatio: "16:9" }} src={`${baseURL}/storage${singleUploadDocumentDetail?.file}`} alt="" />
                        </div>
                    </Col>
                    {uploadDocumentMode == "view" &&
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="Mark as completed" />
                        </FormGroup>
                    }
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='' onClick={() => toggleModalFunction(false)} style={{ backgroundColor: "#5a6268" }}>Cancel</Button>
                        <Button className='btn-info' onClick={uploadDocumentHandleChange} style={{ backgroundColor: "rgb(43, 169, 205)" }}>Save</Button>
                    </div>
                </div>
            </div>
            {/* Add Category Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAddCategory}
                toggle={toggleModalAddCategory}
                size="lg"
            >
                <AddCategory toggleModalFunction={toggleModalAddCategory} />
            </Modal>

            {/* Add Sub Category Modal  */}
            <Modal
                // className="modal-sm"
                modalClassName="modal-primary"
                isOpen={modalAddSubCategory}
                toggle={toggleModalAddSubCategory}
                size="lg"
            >
                <AddSubCategory toggleModalFunction={toggleModalAddSubCategory} />
            </Modal>
        </>
    )
}

export default AdministrationHistoryUploadDocument