import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const adminProfileSlice = createSlice({
    name: 'stakeHolder',
    initialState: {
        adminProfileData: null,
        sendRequestToUpdateProfile: null,
    },
    reducers: {
        ChangeUserData: (state, action) => {
            state.userData = action.payload
        }
    },
    extraReducers: {

        ['admin/GetAdminProfileData/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                state.adminProfileData = data?.data
            }
            else if (status >= 400 && status < 500) {
                // toast(data.message)
                // state.adminProfileData = data
            }
        },

    },
});

export const {
    ChangeUserData,
} = adminProfileSlice.actions;

export default adminProfileSlice.reducer;