import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetAdminSingleDevelopmentDetail = createAsyncThunk('dashboard/GetAdminSingleDevelopmentDetail', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-development-activity/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AdministrationHistoryApi.js ~ line 24 ~ GetAdminDevelopments ~ data", data)
    return { data, status }
})

export const GetUserHistory = createAsyncThunk('dashboard/GetUserHistory', async (params, { dispatch, getState }) => {
    let result = await apiInstance.get(`/api/admin/get-activity`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    console.log("🚀 ~ file: AdministrationHistoryApi.js ~ line 24 ~ GetAdminDevelopments ~ data", data)
    return { data, status }
})

export const GetAdmisistrationHistoryDataForAdmin = createAsyncThunk('administrationHistory/GetAdmisistrationHistoryDataForAdmin', async (params, { dispatch, getState }) => {
    console.log("🚀 ~ file: DashboardApi.js ~ line 17 ~ GetAdmisistrationHistoryDataForAdmin ~ params", params)
    let result = await apiInstance.get(`/api/admin/get-all-administration/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const FetchifyApiAddress = createAsyncThunk('dashboard/FetchifyApiAddress', async (params, { dispatch, getState }) => {
    console.log("🚀 ~ file: DashboardApi.js ~ line 43 ~ FetchifyApiAddress ~ params", params)
    let result = await apiInstance.get(`http://api.craftyclicks.co.uk/address/1.1/find?key=${params.key}&query=${params.query}&country=${params.country}`).then(function (response) {
        console.log("🚀 ~ file: DashboardApi.js ~ line 49 ~ result ~ response", response)
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const GetDefectsOfSingleDevelopment = createAsyncThunk('dashboard/GetDefectsOfSingleDevelopment', async (params, { dispatch, getState }) => {
    console.log("🚀 ~ file: DashboardApi.js ~ line 17 ~ GetAdmisistrationHistoryDataForAdmin ~ params", params)
    let result = await apiInstance.get(`/api/admin/get-defect/${params}`).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 13 ~ GetStakeHolderType ~ result", result)
    return { data, status }
})

export const CreateProgressBarOfSingleDevelopment = createAsyncThunk('dashboard/CreateProgressBarOfSingleDevelopment', async (params, { dispatch, getState }) => {
    let result = await apiInstance.post('/api/admin/add-stages-progress-bar', { ...params }).then(function (response) {
        toast("Development Progress updated successfully")
        dispatch(GetAdminSingleDevelopmentDetail(params?.development_id))
        return response
    }).catch(function (error) {
        toast("Something went wrong.")
        return error.response
    })

    const { data, status } = result
    // console.log("🚀 ~ file: AddNewStakeHolderApi.js ~ line 36 ~ CreateStakeHolder ~ result", result)
    return { data, status }
})



export const AdvanceSearch = createAsyncThunk('search/AdvanceSearch', async (params, { dispatch, getState }) => {
    // const {
    //     selectedUserField,
    //     surveyorIdField,
    //     companyNameField,
    //     contactNameField,
    // } = params.data || {}

    // const user = selectedUserField === "surveyor" ? { surveyor_id: Number(surveyorIdField?.value) } : selectedUserField === "insurer" ? { insurer_id: Number(surveyorIdField?.value) } : selectedUserField === "broker" ? { broker_id: Number(surveyorIdField?.value) } : selectedUserField === "stakeholder" ? { stake_holder_id: Number(surveyorIdField?.value) } : {}
    let result = await apiInstance.post('/api/admin/advance-search', {
        ...params.data
    }).then(function (response) {
        console.log("🚀 ~ file: DashboardApi.js ~ line 19 ~ AdvanceSearch ~ response", response)
        return response
    }).catch(function (error) {
        return error.responses
    })

    const { data, status } = result
    console.log("🚀 ~ file: DashboardApi.js ~ line 83 ~ AdvanceSearch ~ data?.data", data?.data)
    if (data?.data?.businessDetails?.length > 0 || data?.data?.length > 0) {
        params.handleClickOpen()
    } else {
        toast("No record found")
        // params.history.push("/admin/system-maintenance")
    }
    return { data, status }
})
