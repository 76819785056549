import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card, CardBody, CardHeader, Col, Row, Input, CardFooter, Button
} from "reactstrap";
import './AddNewSurveyor.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ReactDatetime from "react-datetime";
import FileBase64 from 'react-file-base64';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Checkbox, FormGroup, Input as MuiInput, List, ListItemButton, ListItemText, Switch } from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { CreateSurveyor, GetSurveyorBusinessStatus, GetSurveyorInsurer, GetSurveyorQualification, UpdateSurveyor } from './Store/AddNewSurveyorApi';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { GetStakeHolderOtherTitle } from '../AddNewStakeHolder/Store/AddNewStakeHolderApi';
import baseURL from './../../Config/baseUrl';
import ResetEmailModal from 'components/ResetEmailModal/ResetEmailModal';

function AddNewSurveyor() {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const MenuPropsQual = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 450,
            },
        },
    };

    const dispatch = useDispatch()
    const { surveyorBusinessStatus, surveyorQualification, surveyorInsurer, addSurveyorErrors } = useSelector(state => state?.AddNewSurveyor)
    const { stakeHolderOtherTitle } = useSelector(state => state?.AddNewStakeHolder)
    const { advanceSearchResult } = useSelector(state => state?.dashboard)

    const [businessStatusField, setBusinessStatusField] = useState(null)
    const [companyNameField, setCompanyNameField] = useState('')
    const [tradingNameField, setTradingNameField] = useState('')
    const [websiteUrlField, setWebsiteUrlField] = useState('')
    const [postCodeField, setPostCodeField] = useState('')
    const [correspondingAddressField, setCorrespondingAddressField] = useState('')
    const [siteAddressFieldData, setSiteAddressFieldData] = useState([])
    const [siteAddressFieldDataShow, setSiteAddressFieldDataShow] = useState(false)

    const [propertyNameField, setPropertyNameField] = useState('Dummy Name')
    const [titleField, setTitleField] = useState('')
    const [otherTitleField, setOtherTitleField] = useState('')
    const [forenameField, setForenameField] = useState('')
    const [surnameField, setSurnameField] = useState('')
    const [jobTitleField, setJobTitleField] = useState('')
    const [emailField, setEmailField] = useState('')
    const [contactNumberField, setContactNumberField] = useState('')
    const [phoneNumberField, setPhoneNumberField] = useState('')
    const [otherContactTitleField, setOtherContactTitleField] = useState('')
    const [otherContactForenameField, setOtherContactForenameField] = useState('')
    const [otherContactSurnameField, setOtherContactSurnameField] = useState('')
    const [otherContactJobTitleField, setOtherContactJobTitleField] = useState('')
    const [otherContactEmailField, setOtherContactEmailField] = useState('')
    const [otherContactContactNumberField, setOtherContactContactNumberField] = useState('')
    const [otherContactPhoneNumberField, setOtherContactPhoneNumberField] = useState('')
    const [businessIsActiveField, setBusinessIsActiveField] = useState(0)
    const [otherContactIsActiveField, setOtherContactIsActiveField] = useState('')
    const [primaryIsActiveField, setPrimaryIsActiveField] = useState(0)
    const [qualificationIdField, setQualificationIdField] = useState([])
    const [otherContactQualificationIdField, setOtherContactQualificationIdField] = useState('')
    const [isPiActiveField, setIsPiActiveField] = useState('')
    const [piRenewalDateField, setPiRenewalDateField] = useState('')
    const [insurerIdField, setInsurerIdField] = useState('')
    const [piLimitedClainField, setPiLimitedClainField] = useState('')
    const [piLimitedAggregateField, setPiLimitedAggregateField] = useState('')
    const [piIsActiveField, setPiIsActiveField] = useState(false)
    const [fileField, setFileField] = useState(null)

    const [isAddOtherContact, setIsAddOtherContact] = useState(false)

    const [retrieveCompleteAddressFieldData, setRetrieveCompleteAddressFieldData] = useState(null)
    const [retrieveAddressField1, setRetrieveAddressField1] = useState("")
    const [retrieveAddressField2, setRetrieveAddressField2] = useState("")
    const [retrieveTownCity, setRetrieveTownCity] = useState("")
    const [retrieveCountyState, setRetrieveCountyState] = useState("")
    const [retrieveCountry, setRetrieveCountry] = useState("")

    const [resetEmail, setResetEmail] = useState('')



    console.log("🚀 ~ file: AddNewSurveyor.js ~ line 75 ~ AddNewSurveyor ~ qualificationIdField", qualificationIdField)
    const handleChangeQualificationIdField = (event) => {
        const {
            target: { value },
        } = event;

        setQualificationIdField(
            typeof value === 'string' ? value.split(',') : value,
        );
        // setBuildUsage(
        //     ...buildUsage,
        //     value,
        // );
    };

    const [otherContactFormValues, setOtherContactFormValues] = useState([{
        other_contact_title: "",
        other_contact_other_title: "",
        other_contact_fore_name: "",
        other_contact_sur_name: "",
        other_contact_job_title: "",
        other_contact_email: "",
        other_contact_contact_number: "",
        other_contact_phone_number: "",
        other_contact_is_active: "",
        other_contact_qualification_id: []
    }])

    useEffect(() => {
        advanceSearchResult[0]?.other_contactuser?.length > 0 && setIsAddOtherContact(true)
        setBusinessStatusField(advanceSearchResult[0]?.business_details[0]?.business_status_id || null)
        setCompanyNameField(advanceSearchResult[0]?.business_details[0]?.company_name || '')
        setTradingNameField(advanceSearchResult[0]?.business_details[0]?.trading_name || '')
        setWebsiteUrlField(advanceSearchResult[0]?.business_details[0]?.website_url || '')
        setPostCodeField(advanceSearchResult[0]?.business_details[0]?.post_code || '')
        setCorrespondingAddressField(advanceSearchResult[0]?.business_details[0]?.correspondence_address || '')
        setPropertyNameField(advanceSearchResult[0]?.business_details[0]?.property_name || '')
        setTitleField(advanceSearchResult[0]?.title || '')
        setForenameField(advanceSearchResult[0]?.fore_name || '')
        setSurnameField(advanceSearchResult[0]?.sur_name || '')
        setJobTitleField(advanceSearchResult[0]?.job_title || '')
        setEmailField(advanceSearchResult[0]?.email || '')
        setContactNumberField(advanceSearchResult[0]?.contact_number || '')
        setPhoneNumberField(advanceSearchResult[0]?.phone_number || '')
        setBusinessIsActiveField(advanceSearchResult[0]?.business_details[0]?.is_active || 0)
        setPrimaryIsActiveField(advanceSearchResult[0]?.is_active || 0)
        const primaryQualification = advanceSearchResult[0]?.qualification?.map(({ id }) => id)
        setQualificationIdField(primaryQualification || [])
        setIsPiActiveField(advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.is_PI_active || '')
        setPiRenewalDateField(advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.PI_renewal_date || '')
        setInsurerIdField(advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.insurer || '')
        setPiLimitedClainField(advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.PI_limited_claim || '')
        setPiLimitedAggregateField(advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.PI_limited_aggregate || '')
        setPiIsActiveField(advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.is_pi_active || '')
        setFileField(advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.file || null)

        setRetrieveAddressField1(advanceSearchResult[0]?.business_details[0]?.address_line1 || '')
        setRetrieveAddressField2(advanceSearchResult[0]?.business_details[0]?.address_line2 || '')
        setRetrieveTownCity(advanceSearchResult[0]?.business_details[0]?.city || '')
        setRetrieveCountyState(advanceSearchResult[0]?.business_details[0]?.state || '')
        setRetrieveCountry(advanceSearchResult[0]?.business_details[0]?.country || '')

        const data = advanceSearchResult[0]?.other_contactuser?.map(contact => {
            const OtherQualification = contact?.qualification?.map(({ id }) => id)
            return {
                other_contact_title: contact.title,
                other_contact_other_title: contact.other_title,
                other_contact_fore_name: contact.fore_name,
                other_contact_sur_name: contact.sur_name,
                other_contact_job_title: contact.job_title,
                other_contact_email: contact.email,
                other_contact_contact_number: contact.contact_number,
                other_contact_phone_number: contact.phone_number,
                other_contact_is_active: contact.is_active,
                other_contact_qualification_id: OtherQualification || []
            }
        })
        console.log("🚀 ~ file: AddNewSurveyor.js ~ line 128 ~ useEffect ~ data", data)

        advanceSearchResult[0]?.other_contactuser?.length > 0 && setOtherContactFormValues(data)

    }, [advanceSearchResult])

    useEffect(() => {
        dispatch(GetStakeHolderOtherTitle())
    }, [])

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const [insurer, setInsurer] = useState('');
    const handleInsurer = (event) => {
        setInsurer(event.target.value);
    };
    const [haveIdentityInsurance, setHaveIdentityInsurance] = useState('');
    const handleHaveIdentityInsurance = (event) => {
        setHaveIdentityInsurance(event.target.value);
    };
    const [qualification, setQualification] = useState('');
    const handleQualification = (event) => {
        setQualification(event.target.value);
    };
    const [OtherTitle, setOtherTitle] = useState('');
    const handleOtherTitle = (event) => {
        setOtherTitle(event.target.value);
    };
    const [title, setTitle] = useState('');
    const handleTitle = (event) => {
        setTitle(event.target.value);
    };
    const [businessStatus, setBusinessStatus] = useState('');
    const handleBusinessStatus = (event) => {
        setBusinessStatus(event.target.value);
    };
    const [datePicker, setDatePicker] = useState(false)

    useEffect(() => {
        dispatch(GetSurveyorQualification())
        dispatch(GetSurveyorBusinessStatus())
        dispatch(GetSurveyorInsurer())
    }, [])

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const createSurveyorHandler = async () => {
        const data = {
            title: titleField,
            other_title: otherTitleField,
            fore_name: forenameField,
            sur_name: surnameField,
            job_title: jobTitleField,
            email: emailField,
            contact_number: contactNumberField,
            phone_number: phoneNumberField,
            business_status_id: businessStatusField,
            trading_name: tradingNameField,
            website_url: websiteUrlField,
            post_code: postCodeField,
            correspondence_address: Array.isArray(correspondingAddressField) ? correspondingAddressField[1] : correspondingAddressField,
            property_name: 'propertyNameField',
            company_name: companyNameField,
            business_is_active: businessIsActiveField,
            other_contact_is_active: otherContactIsActiveField,
            primary_is_active: primaryIsActiveField,
            is_PI_active: isPiActiveField,
            PI_renewal_date: piRenewalDateField,
            insurer_id: insurerIdField,
            PI_limited_claim: piLimitedClainField,
            PI_limited_aggregate: piLimitedAggregateField,
            Pi_is_active: piIsActiveField ? 1 : 0,
            // file: fileField == null ? null : await convertBase64(fileField),
            file: fileField?.base64,
            // file: {
            //     file_name: fileField?.name,
            //     file: fileField?.base64,
            //     type: fileField?.type,
            //     extra: fileField?.file
            // },
            file_name: fileField?.name,
            qualification_id: qualificationIdField?.filter(qual => qual != null),
            another_contact: !isAddOtherContact ? null : otherContactFormValues,

            address_line1: retrieveAddressField1,
            address_line2: retrieveAddressField2,
            city: retrieveTownCity,
            state: retrieveCountyState,
            country: retrieveCountry,

        }
        sessionStorage.getItem("currentEditUserId") ? dispatch(UpdateSurveyor({ data: data, id: advanceSearchResult[0]?.id, clearForm: clearDataHandler })) : dispatch(CreateSurveyor({ data: data, clearForm: clearDataHandler }))
        // alert("called")
        // clearDataHandler()
    }

    const clearDataHandler = () => {
        setBusinessStatusField('')
        setCompanyNameField('')
        setTradingNameField('')
        setWebsiteUrlField('')
        setPostCodeField('')
        setCorrespondingAddressField('')
        setPropertyNameField('')
        setTitleField('')
        setForenameField('')
        setSurnameField('')
        setJobTitleField('')
        setEmailField('')
        setContactNumberField('')
        setPhoneNumberField('')
        setBusinessIsActiveField(0)
        setPrimaryIsActiveField(0)
        setQualificationIdField([])
        setIsPiActiveField('')
        setPiRenewalDateField('')
        setInsurerIdField('')
        setPiLimitedClainField('')
        setPiLimitedAggregateField('')
        setPiIsActiveField('')
        setOtherContactFormValues([{
            other_contact_title: "",
            other_contact_other_title: "",
            other_contact_fore_name: "",
            other_contact_sur_name: "",
            other_contact_job_title: "",
            other_contact_email: "",
            other_contact_contact_number: "",
            other_contact_phone_number: "",
            other_contact_is_active: "",
            other_contact_qualification_id: []
        }])

        setRetrieveAddressField1("")
        setRetrieveAddressField2("")
        setRetrieveTownCity("")
        setRetrieveCountyState("")
        setRetrieveCountry("")
    }


    let otherContactFormHandleChange = (i, e) => {
        let newOtherContactFormFormValues = [...otherContactFormValues];
        newOtherContactFormFormValues[i][e.target.name] = e.target.value;
        setOtherContactFormValues(newOtherContactFormFormValues);
    }

    let addOtherContactFormFields = () => {
        setOtherContactFormValues([...otherContactFormValues, {
            other_contact_title: "",
            other_contact_other_title: "",
            other_contact_fore_name: "",
            other_contact_sur_name: "",
            other_contact_job_title: "",
            other_contact_email: "",
            other_contact_contact_number: "",
            other_contact_phone_number: "",
            other_contact_is_active: "",
            other_contact_qualification_id: []
        }])
    }

    let removeOtherContactFormFields = (i) => {
        let newOtherContactFormFormValues = [...otherContactFormValues];
        newOtherContactFormFormValues.splice(i, 1);
        setOtherContactFormValues(newOtherContactFormFormValues)
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <>
            <Col lg="5" sm="12">
                <Card className="pb-4">
                    <CardHeader className="flex mb-2">
                        <span className="text-lg font-bold">
                            About the Business
                        </span>
                    </CardHeader>
                    <hr />
                    <CardBody className=" ">
                        <Row>
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Business Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={businessStatus}
                                        label="BusinessStatus "
                                        // onChange={handleBusinessStatus}
                                        size="small"
                                        value={businessStatusField}
                                        onChange={(e) => setBusinessStatusField(e.target.value)}
                                    >
                                        {
                                            surveyorBusinessStatus?.map(businessStatus => {
                                                const { id, business_name } = businessStatus
                                                return (
                                                    <MenuItem key={id} value={id}>{business_name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Company Name "
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={companyNameField}
                                    onChange={(e) => { setCompanyNameField(e.target.value); setTradingNameField(e.target.value) }}
                                // type="string"
                                />
                                {addSurveyorErrors?.company_name && <p className="text-danger">{addSurveyorErrors?.company_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Business / Trading Name (if different)"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={tradingNameField}
                                    onChange={(e) => setTradingNameField(e.target.value)}
                                // type="string"
                                />
                                {addSurveyorErrors?.trading_name && <p className="text-danger">{addSurveyorErrors?.trading_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Website URL"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="url"
                                    value={websiteUrlField}
                                    onChange={(e) => setWebsiteUrlField(e.target.value)}
                                // type="string"
                                />
                                {addSurveyorErrors?.website_url && <p className="text-danger">{addSurveyorErrors?.website_url}</p>}
                            </Col>
                            {/* <Col className="mt-4" lg="12" sm="12">
                                <TextField
                                    label="Correspondence Address "
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    value={correspondingAddressField}
                                    onChange={(e) => setCorrespondingAddressField(e.target.value)}
                                />
                            </Col> */}
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Begin Typing Address"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    multiline
                                    maxRows={1}
                                    value={correspondingAddressField}
                                    onFocus={e => setSiteAddressFieldDataShow(true)}
                                    onChange={(e) => {
                                        setCorrespondingAddressField(e.target.value)

                                        const fieldValue = e.target.value

                                        var myHeaders = new Headers();
                                        myHeaders.append("Content-Type", "application/json");

                                        var raw = JSON.stringify({
                                            "key": "46ab2-3af78-4dfa7-07fcf",
                                            "query": fieldValue,
                                            "country": "gbr"
                                        });

                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: raw,
                                            redirect: 'follow'
                                        };

                                        fetch("https://api.craftyclicks.co.uk/address/1.1/find/", requestOptions)
                                            .then(response => response.json())
                                            .then(result => { console.log("result==>", result); setSiteAddressFieldData(result?.results) })
                                            .catch(error => console.log('error', error));
                                    }}
                                />
                                {siteAddressFieldData?.length > 0 && siteAddressFieldDataShow &&
                                    <Box sx={{}} style={{ position: "absolute", background: "white", boxShadow: "0px 0px 1px 1px rgba(0,0,0,100%)", zIndex: "99999", width: '789.45px', height: siteAddressFieldData?.length * 48, minHeight: "70px", maxHeight: "400px", overflow: "scroll", maxWidth: '100%', }}>
                                        <List component="nav" aria-label="secondary mailbox folder">
                                            {
                                                siteAddressFieldData?.map(obj => (

                                                    <ListItemButton
                                                        // selected={selectedIndex === 2}
                                                        onClick={(e) => {
                                                            setCorrespondingAddressField(obj?.labels);
                                                            setSiteAddressFieldDataShow(false)
                                                            // setPostCodeField(obj?.labels[0]); 

                                                            var myHeaders = new Headers();
                                                            myHeaders.append("Content-Type", "application/json");

                                                            var raw = JSON.stringify({
                                                                "key": "46ab2-3af78-4dfa7-07fcf",
                                                                "id": obj?.id,
                                                                "country": "gbr"
                                                            });

                                                            var requestOptions = {
                                                                method: 'POST',
                                                                headers: myHeaders,
                                                                body: raw,
                                                                redirect: 'follow'
                                                            };

                                                            fetch("https://api.craftyclicks.co.uk/address/1.1/retrieve", requestOptions)
                                                                .then(response => response.json())
                                                                .then(result => {
                                                                    console.log("RETRIEVE==>", result?.result);
                                                                    setRetrieveCompleteAddressFieldData(result?.result)
                                                                    setRetrieveAddressField1(result?.result?.line_1)
                                                                    setRetrieveAddressField2(result?.result?.line_2)
                                                                    setRetrieveTownCity(result?.result?.locality)
                                                                    setRetrieveCountyState(result?.result?.province_name)
                                                                    setPostCodeField(result?.result?.postal_code);
                                                                    setRetrieveCountry(result?.result?.country_name);
                                                                })
                                                                .catch(error => console.log('error', error));
                                                        }}
                                                    >
                                                        <ListItemText primary={obj?.labels} />
                                                    </ListItemButton>
                                                ))
                                            }
                                        </List>
                                    </Box>
                                }
                                {addSurveyorErrors?.correspondence_address && <p className="text-danger">{addSurveyorErrors?.correspondence_address}</p>}
                            </Col>
                            {/* <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Post Code"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="text"
                                    value={postCodeField}
                                    onChange={(e) => setPostCodeField(e.target.value)}
                                />
                                {addSurveyorErrors?.post_code && <p className="text-danger">{addSurveyorErrors?.post_code}</p>}
                            </Col> */}

                            <>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Address Line 1"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveAddressField1}
                                        onChange={(e) => setRetrieveAddressField1(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Address Line 2"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveAddressField2}
                                        onChange={(e) => setRetrieveAddressField2(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>

                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Town/City"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveTownCity}
                                        onChange={(e) => setRetrieveTownCity(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="County/State"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveCountyState}
                                        onChange={(e) => setRetrieveCountyState(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>

                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Country"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type='text'
                                        value={retrieveCountry}
                                        onChange={(e) => setRetrieveCountry(e.target.value)}
                                    />
                                    {/* {developmentGeneralDetailsError?.site_postcode && <p className="text-danger">{developmentGeneralDetailsError?.site_postcode}</p>} */}
                                </Col>
                                <Col className="mt-4" lg="6" sm="12">
                                    <TextField
                                        label="Postcode"
                                        className="custom-input"
                                        fullWidth
                                        helperText=""
                                        id="demo-helper-text-misaligned"
                                        size="small"
                                        type="text"
                                        value={postCodeField}
                                        onChange={(e) => setPostCodeField(e.target.value)}
                                    />
                                    {addSurveyorErrors?.post_code && <p className="text-danger">{addSurveyorErrors?.title}</p>}
                                </Col>
                            </>

                            {/* <Col className="mt-4" lg="12" sm="12">
                                <TextField
                                    label="Property Name/Number "
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    value={propertyNameField}
                                    onChange={(e) => setPropertyNameField(e.target.value)}
                                />
                                {addSurveyorErrors?.property_name && <p className="text-danger">{addSurveyorErrors?.property_name}</p>}
                            </Col> */}
                            <Col className="mt-2" lg="6" sm="12">
                                <FormControl>
                                    <FormLabel id="otherContactStatus">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="otherContactStatus"
                                        name=""
                                        value={businessIsActiveField}
                                        onChange={(e) => setBusinessIsActiveField(e.target.value)}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        {/* <div className="exit-btn-container mr-0">
                            <Button
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={null} color="info" size="sm"
                            >
                                Save
                            </Button>
                        </div> */}
                    </CardFooter>
                </Card>
                <Card className="pb-4">
                    <CardHeader className="flex mb-2">
                        <span className="text-lg font-bold">
                            Contact Details
                        </span>
                    </CardHeader>
                    <hr />
                    <CardBody className=" ">
                        <Row>
                            <Col className="mt-4" lg="6" sm="12">
                                <FormControl fullWidth>
                                    <InputLabel id="primaryTitle" size="small">Title</InputLabel>
                                    <Select
                                        labelId="primaryTitle"
                                        id="demo-simple-select"
                                        label="OtherTitle "
                                        size="small"
                                        value={titleField}
                                        onChange={(e) => setTitleField(e.target.value)}
                                    >
                                        <MenuItem value="Mr">Mr</MenuItem>
                                        <MenuItem value="Mrs">Mrs</MenuItem>
                                        <MenuItem value="Miss">Miss</MenuItem>
                                        <MenuItem value="Ms">Ms</MenuItem>
                                        <MenuItem value="Dr">Dr</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                                {addSurveyorErrors?.title && <p className="text-danger">{addSurveyorErrors?.title}</p>}
                            </Col>
                            {titleField == "Other" &&
                                <Col className="mt-4" lg="6" sm="12">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size="small">Other Title</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Title"
                                            size="small"
                                            value={otherTitleField}
                                            onChange={(e) => setOtherTitleField(e.target.value)}
                                        >
                                            {
                                                stakeHolderOtherTitle?.map(stakeholder => {
                                                    const { id, other_title } = stakeholder
                                                    return (
                                                        <MenuItem key={id} value={other_title}>{other_title}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Col>}
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Forename"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={forenameField}
                                    onChange={(e) => setForenameField(e.target.value)}
                                // type="string"
                                />
                                {addSurveyorErrors?.fore_name && <p className="text-danger">{addSurveyorErrors?.fore_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Surname"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={surnameField}
                                    onChange={(e) => setSurnameField(e.target.value)}
                                // type="string"
                                />
                                {addSurveyorErrors?.sur_name && <p className="text-danger">{addSurveyorErrors?.sur_name}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Job Title"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    value={jobTitleField}
                                    onChange={(e) => setJobTitleField(e.target.value)}
                                // type="string"
                                />
                                {addSurveyorErrors?.job_title && <p className="text-danger">{addSurveyorErrors?.job_title}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size="small">Qualification</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="OtherQualification "
                                        size="small"
                                        multiple
                                        value={qualificationIdField}
                                        onChange={(e) => setQualificationIdField(typeof value === 'string' ? e.target.value.split(',') : e.target.value)}
                                        input={<OutlinedInput label="Construction Materials / Build Type(s)" />}
                                        renderValue={(selected) =>
                                            selected?.map(obj => {
                                                const id = obj == 0 ? surveyorQualification?.length : obj;
                                                return surveyorQualification[id - 1].name
                                            }).join(", ")
                                        }
                                    >
                                        {
                                            surveyorQualification?.map(qualification => {
                                                const { id, qualification_name } = qualification

                                                return (
                                                    // <MenuItem value={id}>{qualification_name}</MenuItem>
                                                    <MenuItem key={id} value={id}>
                                                        <Checkbox checked={qualificationIdField.indexOf(id) > -1} />
                                                        <ListItemText primary={qualification_name} />
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl> */}

                                <FormControl className='custom-qualification-formcontrol' fullWidth sx={{}}>
                                    <InputLabel size="small" id="demo-multiple-checkbox-label">Qualification</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={qualificationIdField}
                                        onChange={handleChangeQualificationIdField}
                                        input={<OutlinedInput label="Qualification" />}
                                        // renderValue={(selected) => selected.join(', ')}
                                        // renderValue={(selected) => selected.join(', ')}
                                        renderValue={(selected) => selected?.map((obj, index) => surveyorQualification[obj - 1]?.qualification_name).join(", ")}
                                        // renderValue={(selected) =>
                                        //     console.log("🚀 ~ file: AddNewSurveyor.js ~ line 621 ~ AddNewSurveyor ~ selected", selected)
                                        //     selected?.map(obj => {
                                        //         const id = obj == 0 ? surveyorQualification?.length : obj;
                                        //         return surveyorQualification[id - 1]?.qualification_name
                                        //     }).join(", ")
                                        // }
                                        MenuProps={MenuPropsQual}
                                    >
                                        {surveyorQualification?.map((qualification) => (
                                            <MenuItem key={qualification?.id} value={qualification?.id}>
                                                <Checkbox checked={qualificationIdField?.indexOf(qualification?.id) > -1} />
                                                <ListItemText className='custom-qualification-input-field-size' primary={qualification?.qualification_name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Contact Number"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="text"
                                    value={contactNumberField}
                                    onChange={(e) => setContactNumberField(e.target.value)}
                                // type="string"
                                />
                                {addSurveyorErrors?.contact_number && <p className="text-danger">{addSurveyorErrors?.contact_number}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Mobile Number"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="string"
                                    value={phoneNumberField}
                                    onChange={(e) => setPhoneNumberField(e.target.value)}
                                />
                                {addSurveyorErrors?.phone_number && <p className="text-danger">{addSurveyorErrors?.phone_number}</p>}
                            </Col>
                            <Col className="mt-4" lg="6" sm="12">
                                <TextField
                                    label="Email Address"
                                    className="custom-input"
                                    fullWidth
                                    helperText=""
                                    id="demo-helper-text-misaligned"
                                    size="small"
                                    type="email"
                                    value={emailField}
                                    onChange={(e) => setEmailField(e.target.value)}
                                />
                                {addSurveyorErrors?.email && <p className="text-danger">{addSurveyorErrors?.email}</p>}
                            </Col>
                            <Col className="mt-2" lg="6" sm="12">
                                <FormControl>
                                    <FormLabel id="otherContactStatus">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="otherContactStatus"
                                        name=""
                                        value={primaryIsActiveField}
                                        onChange={(e) => setPrimaryIsActiveField(e.target.value)}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <div className="exit-btn-container mr-0">
                            {
                                sessionStorage.getItem("currentEditUserId") &&
                                <span
                                    style={{
                                        fontSize: '16px',
                                        color: '#EF8157',
                                        fontWeight: 'bold',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => { setResetEmail(emailField), handleClickOpen() }}
                                >
                                    Send Password Reset
                                </span>
                            }
                            <Button
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD', marginLeft: "16px" }}
                                onClick={createSurveyorHandler} color="info" size="sm"
                            >
                                {sessionStorage.getItem("currentEditUserId") ? "Update" : "Save"}
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Col>
            <Col lg="4" sm="12">
                <Card className="pb-4">
                    <CardHeader className="flex mb-2">
                        <span className="text-lg font-bold">
                            Professional Indemnity Insurance
                        </span>
                    </CardHeader>
                    <hr />
                    <CardBody className=" ">
                        <Row>
                            <Col className="mt-4" lg="12" sm="12">
                                <FormControl
                                    fullWidth
                                // className='w-1/2'
                                >
                                    <InputLabel id="demo-simple-select-label" size="small">Do you have Professional Indemnity Insurance?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={haveIdentityInsurance}
                                        label="Do you have Professional Indemnity Insurance?"
                                        // onChange={handleHaveIdentityInsurance}
                                        size="small"
                                        value={isPiActiveField}
                                        onChange={(e) => setIsPiActiveField(e.target.value)}
                                    >
                                        <MenuItem value="yes">Yes</MenuItem>
                                        <MenuItem value="no">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            {/* <Col className="mt-0 flex flex-row align-items-center" lg="12" sm="12">
                                <Switch color='secondary' {...label} value={piIsActiveField} checked={piIsActiveField} onChange={(e) => setPiIsActiveField(!piIsActiveField)} />
                                <span className='text-sm'>Inactive Surveyor</span>
                            </Col> */}
                            {
                                isPiActiveField == "no" &&
                                <span className="text-sm font-bold mt-4" style={{ marginLeft: "16px" }}>Surveyor must remain inactive until Professional Indemnity Insurance acquired.</span>
                            }
                            {isPiActiveField == "yes" &&
                                <>
                                    {/* <Col className="mt-4" lg="6" sm="12">
                                        <ReactDatetime
                                            open={datePicker}
                                            onClose={() => setDatePicker(false)}
                                            className="custom-date-picker"
                                            inputProps={{
                                                className: "form-control ",
                                                placeholder: "Date Picker Here",
                                            }}
                                            renderInput={(event) => {
                                                console.log("EVENT")
                                                console.log(event)
                                                return (
                                                    <FormControl variant="outlined">
                                                        <InputLabel size='small' type="date" value={piRenewalDateField}
                                                            onChange={(e) => setPiRenewalDateField(e.target.value)} htmlFor="outlined-adornment-date">Renewal Date </InputLabel>
                                                        <OutlinedInput
                                                            disabled
                                                            size='small'
                                                            id="outlined-adornment-date"
                                                            value={piRenewalDateField}
                                                            onChange={(e) => setPiRenewalDateField(e.target.value)}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => setDatePicker(!datePicker)}
                                                                        aria-label="toggle date visibility"
                                                                        edge="end"
                                                                    >
                                                                        <CalendarTodayIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label="Renewal Date"
                                                        />
                                                    </FormControl>
                                                )
                                            }}
                                            timeFormat={false}
                                        // input={false}
                                        />
                                    </Col> */}
                                    <Col className="mt-4" lg="6" sm="12">
                                        <TextField
                                            label="Renewal Date"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            type="date"
                                            value={piRenewalDateField}
                                            onChange={(e) => setPiRenewalDateField(e.target.value)}
                                        // type="string"
                                        />
                                    </Col>
                                    <Col className="mt-4" lg="6" sm="12">
                                        {/* <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label" size="small">Insurer</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                // value={insurer}
                                                label="Insurer "
                                                // onChange={handleInsurer}
                                                size="small"
                                                value={insurerIdField}
                                                onChange={(e) => setInsurerIdField(e.target.value)}
                                            >
                                                {
                                                    surveyorInsurer?.map(insurer => {
                                                        const { id, fore_name, sur_name } = insurer.insurer_type

                                                        return (
                                                            <MenuItem value={id}>{fore_name} {sur_name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl> */}

                                        <TextField
                                            label="Insurer"
                                            className="custom-input"
                                            fullWidth
                                            helperText=""
                                            id="demo-helper-text-misaligned"
                                            size="small"
                                            type="text"
                                            value={insurerIdField}
                                            onChange={(e) => setInsurerIdField(e.target.value)}
                                        // type="string"
                                        />
                                    </Col>
                                    <Col className="mt-4" lg="6" sm="12">
                                        <FormControl variant="outlined">
                                            <InputLabel size='small' htmlFor="outlined-adornment-date">PI Limit Per Claim </InputLabel>
                                            <OutlinedInput
                                                size='small'
                                                id="outlined-adornment-date"
                                                value={piLimitedClainField}
                                                onChange={(e) => setPiLimitedClainField(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        {/* <AttachMoneyIcon /> */}
                                                        £
                                                    </InputAdornment>
                                                }
                                                label="PI Limit Per Claim"
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col className="mt-4" lg="6" sm="12">
                                        <FormControl variant="outlined">
                                            <InputLabel size='small' htmlFor="outlined-adornment-date">PI Limit In Aggregate </InputLabel>
                                            <OutlinedInput
                                                size='small'
                                                id="outlined-adornment-date"
                                                value={piLimitedAggregateField}
                                                onChange={(e) => setPiLimitedAggregateField(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        {/* <AttachMoneyIcon /> */}
                                                        £
                                                    </InputAdornment>
                                                }
                                                label="PI Limit In Aggregate"
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label htmlFor="DueDate" style={{ fontSize: "12px", marginTop: 0 }}>Attachment</label>
                                            {/* <Input className="custom-input" placeholder="" type="file" onChange={e => setFileField(e.target.files[0])} /> */}
                                            <FileBase64
                                                multiple={false}
                                                onDone={(files) => setFileField(files)} />
                                            {
                                                fileField != null && <p style={{ color: "blue", fontSize: "12px" }}>File attached</p>
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">

                                        <div style={{ width: "100%", marginTop: "16px" }}>
                                            <img src={`${baseURL}/storage/${advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.file}`} alt="" />
                                        </div>
                                        {
                                            advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.file &&
                                            <a target="_blank" download href={`${baseURL}/storage/${advanceSearchResult[0]?.business_details[0]?.professional_indemnity?.file}`}>Download File</a>
                                        }
                                    </Col>
                                </>
                            }
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <div className="exit-btn-container mr-0">
                            {/* <Button
                                className="px-3"
                                style={{ backgroundColor: '#2BA9CD' }}
                                onClick={null} color="info" size="sm"
                            >
                                Save
                            </Button> */}
                        </div>
                    </CardFooter>
                </Card>

                <div className="exit-btn-container mr-0">
                    {!isAddOtherContact ?
                        <Button
                            className="px-3"
                            style={{ backgroundColor: '#2BA9CD' }}
                            onClick={() => setIsAddOtherContact(true)} color="info" size="sm"
                        >
                            Add Another Contact
                        </Button>
                        :
                        <Button
                            className="px-3"
                            style={{ backgroundColor: '#2BA9CD' }}
                            onClick={() => {
                                setIsAddOtherContact(false)
                                setOtherContactFormValues([{
                                    other_contact_title: "",
                                    other_contact_other_title: "",
                                    other_contact_fore_name: "",
                                    other_contact_sur_name: "",
                                    other_contact_job_title: "",
                                    other_contact_email: "",
                                    other_contact_contact_number: "",
                                    other_contact_phone_number: "",
                                    other_contact_is_active: "",
                                    other_contact_qualification_id: []
                                }])
                            }} color="info" size="sm"
                        >
                            Remove All Other Contact
                        </Button>
                    }
                </div>

                {
                    isAddOtherContact &&
                    <Card className="pb-4">
                        <CardHeader className="flex mb-2">
                            <span className="text-lg font-bold">
                                Other Contact Details
                            </span>
                        </CardHeader>
                        <hr />
                        {
                            otherContactFormValues?.map((element, index) => (
                                <>
                                    <CardBody className=" " key={index}>
                                        <Row>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size="small">Title</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="OtherTitle"
                                                        size="small"
                                                        name="other_contact_title"
                                                        value={element?.other_contact_title}
                                                        onChange={(e) => otherContactFormHandleChange(index, e)}
                                                    >
                                                        <MenuItem value="Mr">Mr</MenuItem>
                                                        <MenuItem value="Mrs">Mrs</MenuItem>
                                                        <MenuItem value="Miss">Miss</MenuItem>
                                                        <MenuItem value="Ms">Ms</MenuItem>
                                                        <MenuItem value="Dr">Dr</MenuItem>
                                                        <MenuItem value="Other">Other</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                            {element?.other_contact_title == "Other" && <Col className="mt-4" lg="6" sm="12">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size="small">Other Title</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="OtherTitle"
                                                        size="small"
                                                        name="other_contact_other_title"
                                                        value={element?.other_contact_other_title}
                                                        onChange={(e) => otherContactFormHandleChange(index, e)}
                                                    >
                                                        {
                                                            stakeHolderOtherTitle?.map(stakeholder => {
                                                                const { id, other_title } = stakeholder
                                                                return (
                                                                    <MenuItem key={id} value={other_title}>{other_title}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                        {/* <MenuItem value="Mrs">Mrs</MenuItem>
                                                    <MenuItem value="Miss">Miss</MenuItem>
                                                    <MenuItem value="Ms">Ms</MenuItem>
                                                    <MenuItem value="Dr">Dr</MenuItem>
                                                    <MenuItem value="Other">Other</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </Col>}
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Forename"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    name="other_contact_fore_name"
                                                    value={element?.other_contact_fore_name}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Surname"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    name="other_contact_sur_name"
                                                    value={element?.other_contact_sur_name}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Job Title"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    name="other_contact_job_title"
                                                    value={element?.other_contact_job_title}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                {/* <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label" size="small">Qualification</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="OtherQualification "
                                                    size="small"
                                                    name="other_contact_qualification_id"
                                                    value={element?.other_contact_qualification_id}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                >
                                                    {
                                                        surveyorQualification?.map(qualification => {
                                                            const { id, qualification_name } = qualification

                                                            return (
                                                                <MenuItem value={id}>{qualification_name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl> */}

                                                <FormControl fullWidth sx={{}}>
                                                    <InputLabel size="small" id="demo-multiple-checkbox-label">Qualification</InputLabel>
                                                    <Select
                                                        size="small"
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        name="other_contact_qualification_id"
                                                        value={element?.other_contact_qualification_id}
                                                        onChange={(e) => otherContactFormHandleChange(index, e)}
                                                        input={<OutlinedInput label="Qualification" />}
                                                        // renderValue={(selected) => selected.join(', ')}
                                                        renderValue={(selected) => selected?.map((obj, index) => surveyorQualification[obj - 1]?.qualification_name).join(", ")}
                                                        // renderValue={(selected) =>
                                                        //     console.log("🚀 ~ file: AddNewSurveyor.js ~ line 621 ~ AddNewSurveyor ~ selected", selected)
                                                        //     selected?.map(obj => {
                                                        //         const id = obj == 0 ? surveyorQualification?.length : obj;
                                                        //         return surveyorQualification[id - 1]?.qualification_name
                                                        //     }).join(", ")
                                                        // }
                                                        MenuProps={MenuPropsQual}
                                                    >
                                                        {surveyorQualification?.map((qualification) => (
                                                            <MenuItem key={qualification?.id} value={qualification?.id}>
                                                                <Checkbox checked={element?.other_contact_qualification_id?.indexOf(qualification?.id) > -1} />
                                                                <ListItemText className='custom-qualification-input-field-size' primary={qualification?.qualification_name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Contact Number"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    type="text"
                                                    name="other_contact_contact_number"
                                                    value={element?.other_contact_contact_number}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                // type="string"
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Mobile Number"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    type="string"
                                                    name="other_contact_phone_number"
                                                    value={element?.other_contact_phone_number}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                />
                                            </Col>
                                            <Col className="mt-4" lg="6" sm="12">
                                                <TextField
                                                    label="Email Address"
                                                    className="custom-input"
                                                    fullWidth
                                                    helperText=""
                                                    id="demo-helper-text-misaligned"
                                                    size="small"
                                                    type="email"
                                                    name="other_contact_email"
                                                    value={element?.other_contact_email}
                                                    onChange={(e) => otherContactFormHandleChange(index, e)}
                                                />
                                            </Col>
                                            <Col className="mt-2" lg="6" sm="12">
                                                <FormControl>
                                                    <FormLabel id="otherContactStatus">Status</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="otherContactStatus"
                                                        name="other_contact_is_active"
                                                        value={element?.other_contact_is_active}
                                                        onChange={(e) => otherContactFormHandleChange(index, e)}
                                                    >
                                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                        {
                                            otherContactFormValues?.length > 0 &&
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '16px'
                                            }}>
                                                <Button
                                                    className="px-3"
                                                    style={{ backgroundColor: '#EF8157', marginLeft: "16px" }}
                                                    onClick={() => removeOtherContactFormFields(index)}
                                                    color="danger" size="sm"
                                                >
                                                    Remove
                                                </Button>
                                                {
                                                    sessionStorage.getItem("currentEditUserId") &&
                                                    <span
                                                        style={{
                                                            fontSize: '16px',
                                                            color: '#EF8157',
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => { setResetEmail(element?.other_contact_email), handleClickOpen() }}
                                                    >
                                                        Send Password Reset
                                                    </span>
                                                }
                                            </div>
                                        }
                                    </CardBody>

                                </>
                            ))
                        }
                        <CardFooter>
                            <div className="exit-btn-container mr-0">
                                {/* {sessionStorage.getItem("currentEditUserId") === null && */}
                                <Button
                                    className="px-3"
                                    style={{ backgroundColor: '#2BA9CD' }}
                                    onClick={addOtherContactFormFields} color="info" size="sm"
                                >
                                    Add another contact
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                }
            </Col>

            <ResetEmailModal
                handleClose={handleClose}
                open={open}
                oldEmail={resetEmail}
            />
        </>
    );
}

export default AddNewSurveyor;
